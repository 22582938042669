<template>
  <div class="container-text-mining-entities">
    <div class="container-head-text-mining-entities">
      <span v-bind="{innerHTML: returnDiv()}" class="text-16px-black-medium"></span>
    </div>
    <div style="display: grid;grid-template-columns: auto auto;gap: 20px">
      <div v-for="(entity,i) in storeUtils.textAnalysisGetters.Entities.entities" :key="i"
           class="container-list-entities">
        <div class="container-content-list-entities">
          <div class="container-head-single-entity"
               :style="{background: storeUtils.dictionaryEntityTextMiningGetters[entity.type.value]}">
            {{ entity.type.value_verbose_translated }}
          </div>
          <div class="container-body-single-entity">
            <span class="text-16px-black-medium">{{ entity.name }}</span>
            <div class="text-14px-dark-grey-medium">Rilevanza: <span
                class="text-14px-black-regular">{{ entity.salience.toFixed(2) }}</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useUtilsStore} from "@/stores/utils";

export default {
  name: "ComponentTextMiningEntities",
  props: {
    currentObject: {
      type: Object
    },
    currentField: {
      type: String
    }
  },
  setup() {
    const storeUtils = useUtilsStore()
    return {
      storeUtils
    }
  },
  data() {
    return {}
  },
  created() {
  },
  watch: {},
  computed: {},
  mounted() {
  },
  methods: {
    returnDiv() {
      let testoOriginale
      if (this.currentField === 'wiki')
        testoOriginale = this.currentObject[this.currentField].innerHTML
      else
        testoOriginale = this.currentObject[this.currentField]
      let testoModificato = testoOriginale;
      this.storeUtils.textAnalysisGetters.Entities.entities.forEach((parola, index) => {
        testoModificato = testoModificato.replace(
            new RegExp(parola.name + ' ', 'g'),
            `<span style="color: ${this.storeUtils.dictionaryEntityTextMiningGetters[parola.type.value]}">
             ${parola.name}<sub>${index + 1}&nbsp; </sub></span>
            `);
      });
      return testoModificato
    }
  },
  updated() {
  },
  beforeUnmount() {
  },
  unmounted() {
  }
}
</script>

<style scoped>

.table table td {
  border-width: 1px !important;
}

:deep(.table table td) {
  border-width: 1px !important;
}

.text-16px-black-medium {
  word-break: break-word;
}

.container-content-list-entities {
  display: flex;
  width: 100%;
  border-radius: 4px;
  flex-direction: column;
}

.container-body-single-entity {
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
}

.container-head-single-entity {
  display: flex;
  color: var(--white-color);
  border-radius: 4px;
  padding: 5px 10px;
  width: auto;
  margin-left: auto;
}

.container-list-entities {
  background: var(--white-color);
  display: flex;
  border-bottom: 1px solid var(--light-grey-color);
}

.text-14px-primary-regular {
  text-decoration: underline;
}

.container-head-text-mining-entities {
  display: flex;
  align-items: center;
  padding: 10px;
}

.container-text-mining-entities {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  border-radius: 4px;
  width: 100%;

}
</style>