<template>
  <ComponentDetailCategory v-if="$route.name === 'detailCategory'"></ComponentDetailCategory>
  <router-view v-else></router-view>
</template>

<script>
import ComponentDetailCategory from "@/components/category/ComponentDetailCategory.vue";
import {pages} from "../../vue.config";
export default {
  name: "DetailCategoryView",
  components: {ComponentDetailCategory},
  props: {
    bookTitle: {
      type: String
    }
  },
  setup() {
    return {}
  },
  data() {
    return {}
  },
  created() {
  },
  watch: {},
  computed: {},
  mounted() {
    document.title = 'Dettaglio categoria' + ' | ' + pages.index.title
  },
  methods: {},
  updated() {
  },
  beforeUnmount() {
  },
  unmounted() {
  }
}
</script>

<style scoped>

</style>