<template>
  <div class="container-detail-sub-category">
    <div v-if="showTabs" :class="{'container_tabs': showTabs, 'order_tabs': showTabs}" class="container-tabs">
      <span
          @click="showProjects = false,showManage = true"
          :class="{'text-14px-secondary-medium': showManage && !showProjects}"
          class="text-14px-grey-regular">Gestisci</span>
      <span @click="showProjects = true, showManage = false,isEditing = false"
            :class="{'text-14px-secondary-medium': showProjects && !showManage}"
            class="text-14px-grey-regular">Progetti</span>
    </div>
    <div class="container-content-detail-sub-category">
      <div
          v-if="storeSubcategory.subcategoryGetters && !isEditing && (!showTabs) || (showManage && showTabs && !isEditing) && storeSubcategory.subcategoryGetters"
          class="container-head-detail">
        <div class="container-content-left-head-detail">
          <span class="text-14px-dark-grey-medium">Nome: </span>
          <span class="text-14px-black-regular">{{ storeSubcategory.subcategoryGetters.name }}</span>
          <div v-if="storeSubcategory.subcategoryGetters.is_favorite"
               class="container-is-favorite text-14px-secondary-regular">Preferiti
          </div>
        </div>
        <div class="container-content-left-head-detail">
          <span class="text-14px-dark-grey-medium">Data creazione: </span>
          <span class="text-14px-black-regular">{{ returnDate(storeSubcategory.subcategoryGetters.created_at) }}</span>
        </div>
        <div class="container-content-left-head-detail">
          <span class="text-14px-dark-grey-medium">Proprietario: </span>
          <span v-if="storeSubcategory.subcategoryGetters.owner.user_detail" class="text-14px-secondary-regular">{{
              storeSubcategory.subcategoryGetters.owner.user_detail.first_name
            }} {{ storeSubcategory.subcategoryGetters.owner.user_detail.last_name }}</span>
          <span v-else class="text-14px-secondary-regular">{{ storeSubcategory.subcategoryGetters.owner.email }}</span>
        </div>
        <div class="container-content-left-head-detail">
          <span class="text-14px-dark-grey-medium">Descrizione: </span>
          <span v-b-popover="storeSubcategory.subcategoryGetters.description"
                v-if="storeSubcategory.subcategoryGetters.description"
                class="text-14px-black-regular text-overflow-custom">{{
              storeSubcategory.subcategoryGetters.description
            }}</span>
          <span v-else class="text-14px-black-regular">ND</span>
        </div>
        <div class="container-content-left-head-detail container-content-left-head-detail-tag">
          <span class="text-14px-dark-grey-medium">Tags: </span>
          <div v-if="storeSubcategory.subcategoryGetters.tag.length > 0" style="display: flex;flex-wrap: wrap">
            <span class="text-14px-secondary-regular text-tags"
                  v-for="(tag,i) in storeSubcategory.subcategoryGetters.tag"
                  :key="i">{{
                tag.name
              }}</span>
          </div>
          <span v-else class="text-14px-secondary-regular">Nessun tag presente</span>
        </div>
      </div>
      <ComponentDynamicForm v-if="isEditing"
                            @close-form-without-update="isEditing = false"
                            @closeForm="closeModal"
                            :currentObject="{0: currentObj}"
                            :options="{
                                  endpoints: [
                                      {
                                        'endpoint': 'subcategories',
                                        'type': TYPE_OF_OPTION.UPDATE,
                                        'path': [
                                          'subcategories',
                                           {'objectID':  storeSubcategory.subcategoryGetters.id}
                                        ]
                                      }
                                  ]
                              }"
      ></ComponentDynamicForm>
      <div v-if="!showTabs" class="container-content-right-head-detail">
        <ComponentText v-if="storeSubcategory.subcategoryGetters && !isEditing"
                       :button-disabled="storeSubcategory.subcategoryGetters.description === null || storeSubcategory.subcategoryGetters.description === ''"
                       :currentObject="storeSubcategory.subcategoryGetters"
                       :fields="arrayFieldsToTextMining"
                       :model="'subcategory'" :id="storeSubcategory.subcategoryGetters.id"></ComponentText>
        <div style="display: flex;flex-direction: column;margin-left: 25px">
          <div class="container-content-top-right-head-detail">
            <span class="text-14px-dark-grey-regular">Gestisci la sottocategoria</span>
            <div class="container-buttons">
            <span v-if="storeSubcategory.subcategoryGetters && storeAuth.userGetters && storeAuth.userGetters.user_type === 'ad'" @click="isEditing = true,currentObj = Object.assign({},storeSubcategory.subcategoryGetters)"
                  class="text-14px-primary-medium">Modifica</span>
              <div :class="{'disable-pointer-custom': storeFavorite.debounceBooleanGetters}"
                   v-if="storeSubcategory.subcategoryGetters" v-show="!storeSubcategory.subcategoryGetters.is_favorite"
                   @click="storeFavorite.setStatusFavorites({params: storeSubcategory.subcategoryGetters.id,action: storeSubcategory.getSubcategory,object: storeSubcategory.subcategoryGetters,entity: 'subcategory'})"
                   class="container-favorites">
                <img src="../../assets/shared/bookmarks.svg" alt="">
                <span class="text-14px-secondary-medium">Salva tra i preferiti</span>
              </div>
              <div v-if="storeSubcategory.subcategoryGetters" @click="deleteFavorite"
                   v-show="storeSubcategory.subcategoryGetters.is_favorite" class="container-favorites">
                <img src="../../assets/shared/bookmarks-dark-grey.svg" alt="">
                <span class="text-14px-dark-grey-medium">Rimuovi dai preferiti</span>
              </div>
              <button v-if="storeSubcategory.subcategoryGetters && storeAuth.userGetters && storeAuth.userGetters.user_type === 'ad'" @click="deleteSubcategory" class="btn btn-danger-custom">Elimina</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex;align-items: center"
         v-if="!showTabs && !isEditing || (showManage && showTabs && !isEditing)">
      <div
          @click="showWiki = true,currentObj = Object.assign({},storeSubcategory.subcategoryGetters)"
          class="div-container-wiki">
        <div class="div-container-img-wiki">
          <img src="../../assets/shared/question-circle-secondary.svg" alt="">
        </div>
        <span class="text-16px-black-medium">Wiki</span>
      </div>
      <ComponentText v-if="storeSubcategory.subcategoryGetters && !isEditing && showTabs"
                     :button-disabled="storeSubcategory.subcategoryGetters.description === null || storeSubcategory.subcategoryGetters.description === ''"
                     :currentObject="storeSubcategory.subcategoryGetters"
                     :fields="arrayFieldsToTextMining"
                     :model="'subcategory'" :id="storeSubcategory.subcategoryGetters.id"></ComponentText>
    </div>

    <div v-if="!showTabs" class="container-buttons container-buttons-wrap">
      <div class="text-16px-dark-grey-medium">
        Progetti in
        <span v-if="storeSubcategory.subcategoryGetters"
              class="text-16px-primary-medium">{{ storeSubcategory.subcategoryGetters.name }}</span>
      </div>
      <button v-if="storeAuth.userGetters && storeAuth.userGetters.user_type === 'ad'" @click="setProjectInfo" class="btn btn-primary-custom btn-primary-custom-child">Crea un progetto</button>
    </div>
    <ComponentProjects :actionsProjects="actionsProjects" @setBoolModal="showAddProject = true" v-if="!showManage"
                       :from-entity="'subcategory'"></ComponentProjects>
    <!--    <ComponentSubCategoriess></ComponentSubCategoriess>-->
  </div>
  <b-modal :hide-footer="true" :hide-header="true" @hide="closeModal" v-model="showWiki">
    <ComponentDetailWiki :entity="'subcategory'" :id-prop="storeSubcategory.subcategoryGetters.wiki" v-if="showWiki"
                         @closeModal="closeModal"></ComponentDetailWiki>
  </b-modal>

  <b-modal :hide-footer="true" :hide-header="true" @hide="closeModal" v-model="showAddProject">
    <div class="container-head-form">
      <div class="container-content-head-form">
        <div v-if="storeSubcategory.subcategoryGetters" class="text-16px-black-bold">Aggiungi progetto in <span
            class="text-16px-primary-bold">{{ storeSubcategory.subcategoryGetters.name }}</span></div>
      </div>
      <img @click="showAddProject = false" class="img-close" src="../../assets/shared/close.svg" alt="">
    </div>
    <ComponentDynamicForm v-if="showAddProject"
                          @close-form-without-update="showAddProject = false"
                          @closeForm="closeModal" :options="{
      endpoints:
      [
          {
            'endpoint': 'projects',
            'type': TYPE_OF_OPTION.CREATE,
            'path': [
              'projects'
            ]
          }
      ]
      }"></ComponentDynamicForm>
  </b-modal>

  <ComponentFloatingActionButton @setBooleanModal="setBooleanModal"
                                 v-if="actions.length > 0 && showManage && innerWidth <= 991.98"
                                 :actions="actions"></ComponentFloatingActionButton>
</template>

<script>
import ComponentProjects from "@/components/project/ComponentProjects";
import {useSubcategoryStore} from "@/stores/subcategories";
import moment from "moment/moment";
import ComponentDynamicForm from "@/components/form/ComponentDynamicForm";
import ComponentDetailWiki from "@/components/wiki/ComponentDetailWiki";
import {TYPE_OF_OPTION} from "@/globals";
import {useUtilsStore} from "@/stores/utils";
import {useCategoryStore} from "@/stores/categories";
import {useProjectStore} from "@/stores/projects";
import {useFavoriteStore} from "@/stores/favorites";
import ComponentFloatingActionButton from "@/components/floatingActionButton/ComponentFloatingActionButton";
import {pages} from "../../../vue.config";
import ComponentText from "@/components/shared/ComponentText";
import {useAuthStore} from "@/stores/auth";

export default {
  name: "ComponentDetailSubCategory",
  components: {
    ComponentText,
    ComponentFloatingActionButton,
    ComponentDetailWiki, ComponentDynamicForm, ComponentProjects
  },
  setup() {
    const storeProject = useProjectStore()
    const storeFavorite = useFavoriteStore()
    const storeAuth = useAuthStore()
    const storeSubcategory = useSubcategoryStore()
    return {
      storeProject,
      storeAuth,
      storeFavorite,
      storeSubcategory,
      TYPE_OF_OPTION
    }
  },
  data() {
    return {
      arrayFieldsToTextMining: [],
      actions: [],
      actionsProjects: [],
      innerWidth: window.innerWidth,
      showProjects: false,
      showTabs: false,
      showManage: false,
      showShare: false,
      showAddProject: false,
      currentObj: null,
      isEditing: false,
      showWiki: false
    }
  },
  async created() {
    await this.loadDetail()
  },
  watch: {
    showAddProject: {
      handler: function (value) {
        if (!value)
          useProjectStore().getProjects({
            queryParams: {
              subcategory__in: this.storeSubcategory.subcategoryGetters.id
            }
          })
              .then(() => {
              })
              .catch(() => {
              })
      }
    },
    subcategory: {
      handler: function (val) {
        /*** costruisco le actions della sottocategoria da passare al floating button in base ai permessi ***/
        this.actions = []

        if (this.storeAuth.userGetters && this.storeAuth.userGetters.user_type === 'ad')
          this.actions.push({action: 'edit', entity: 'subcategory'})

        this.actions.push({action: 'favorite', entity: 'subcategory', is_favorite: val.is_favorite})

        if (this.storeAuth.userGetters && this.storeAuth.userGetters.user_type === 'ad')
          this.actions.push({action: 'delete', entity: 'subcategory'})
        /*** ***/

        /*** costruisco le actions del progetto da passare al floating button in base ai permessi ***/
        this.actionsProjects = []

        if (this.storeAuth.userGetters && this.storeAuth.userGetters.user_type === 'ad')
          this.actionsProjects.push({action: 'filter', entity: 'project'})
        if (this.storeAuth.userGetters && this.storeAuth.userGetters.user_type === 'ad')
          this.actionsProjects.push({action: 'delete', type: 'bulk', entity: 'project'})
        if (this.storeAuth.userGetters && this.storeAuth.userGetters.user_type === 'ad')
          this.actionsProjects.push({action: 'add', entity: 'project'})
        /*** ***/

      }
    }
  },
  computed: {
    subcategory() {
      return useSubcategoryStore().subcategoryGetters
    }
  },
  mounted() {
    document.title = 'Dettaglio sottocategoria' + ' | ' + pages.index.title
    if (window.innerWidth < 991.98) {
      this.showTabs = true
      this.showManage = true
      this.showProjects = false

    } else {
      this.showTabs = false
      this.showManage = false
      this.showProjects = false
    }
    window.addEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.innerWidth = window.innerWidth
      if (window.innerWidth < 991.98) {
        this.showTabs = true
      } else {
        this.showTabs = false
        this.showManage = false
        this.showSubcategories = true
        this.showProjects = false
      }
    },
    setBooleanModal(event) {
      if (event.action === 'favorite' && event.entity === 'subcategory' && event.is_favorite) {
        this.deleteFavorite()
      }
      if (event.action === 'favorite' && event.entity === 'subcategory' && !event.is_favorite)
        this.storeFavorite.setStatusFavorites({
          params: this.storeSubcategory.subcategoryGetters.id,
          action: this.storeSubcategory.getSubcategory,
          object: this.storeSubcategory.subcategoryGetters,
          entity: 'subcategory'
        })

      if (event.action === 'add' && event.entity === 'subcategory')
        this.showAddSubcategory = true
      if (event.action === 'edit' && event.entity === 'subcategory')
        this.isEditing = true
      if (event.action === 'delete' && event.entity === 'subcategory')
        this.deleteSubcategory()
    },
    deleteFavorite() {
      let item = {
        id: this.storeSubcategory.subcategoryGetters.favorite_id
      }
      useFavoriteStore().currentKeyDeleting = this.keyFavorite
      useUtilsStore().infoForToDelete['currentPosition'] = 'detail'
      useUtilsStore().infoForToDelete['confirmDelete'] = true
      useUtilsStore().infoForToDelete['action'] = this.storeFavorite.deleteFavorite
      useUtilsStore().infoForToDelete['actionAfterDelete']['action'] = this.storeSubcategory.getSubcategory
      useUtilsStore().infoForToDelete['actionAfterDelete']['payload'] = this.storeSubcategory.subcategoryGetters.id

      useUtilsStore().infoForToDelete['typeObjectToDelete'] = 'single'
      useUtilsStore().infoForToDelete['entity'] = 'favorite'
      useUtilsStore().infoForToDelete['name'] = 'preferito'
      useUtilsStore().infoForToDelete['objectToDelete'] = item
      useUtilsStore().infoForToDelete['messageToDelete']['is'] = 'div'
      useUtilsStore().infoForToDelete['messageToDelete']['innerHTML'] = "Sei sicuro di voler rimuovere dai preferiti la sottocategoria " +
          "<span style='color: var(--primary-color)'>" + this.storeSubcategory.subcategoryGetters.name +
          "</span>" + "?"
    },
    setProjectInfo() {
      this.showAddProject = true
      this.storeProject.fromEntity.name = 'subcategory'
      this.storeProject.fromEntity.id = this.storeSubcategory.subcategoryGetters.id
    },
    deleteSubcategory() {
      let item = useSubcategoryStore().subcategoryGetters
      useUtilsStore().infoForToDelete['currentPosition'] = 'detail'
      useUtilsStore().infoForToDelete['confirmDelete'] = true
      useUtilsStore().infoForToDelete['action'] = this.storeSubcategory.deleteSubcategory
      useUtilsStore().infoForToDelete['typeObjectToDelete'] = 'single'
      useUtilsStore().infoForToDelete['entity'] = 'subcategories'
      useUtilsStore().infoForToDelete['name'] = 'sottocategoria'
      useUtilsStore().infoForToDelete['objectToDelete'] = item
      useUtilsStore().infoForToDelete['messageToDelete']['is'] = 'div'
      useUtilsStore().infoForToDelete['messageToDelete']['innerHTML'] = "Sei sicuro di voler eliminare definitivamente la sottocategoria  " +
          "<span style='color: var(--primary-color)'>" + useSubcategoryStore().subcategoryGetters.name +
          "</span>" + "? L’operazione non è reversibile."
    },
    async loadDetail() {
      await useSubcategoryStore().getSubcategory(parseInt(this.$route.params.subcategoryId))
          .then(res => {
            // this.showShare = false

            /*** costruisco array di fields da analizzare per il text mining ***/
            this.arrayFieldsToTextMining = []
            if (res.data.description !== null)
              this.arrayFieldsToTextMining.push({
                name_db: 'description',
                value: 'Descrizione sottocategoria'
              })
            /*** ***/


            if ('categoryId' in this.$route.params) {
              useCategoryStore().getCategory(this.$route.params.categoryId)
            }
            /*** costruisco le actions della sottocategoria da passare al floating button in base ai permessi ***/
            this.actions = []

            if (this.storeAuth.userGetters && this.storeAuth.userGetters.user_type === 'ad')
              this.actions.push({action: 'edit', entity: 'subcategory'})

            this.actions.push({action: 'favorite', entity: 'subcategory', is_favorite: res.data.is_favorite})

            if (this.storeAuth.userGetters && this.storeAuth.userGetters.user_type === 'ad')
              this.actions.push({action: 'delete', entity: 'subcategory'})
            /*** ***/

            /*** costruisco le actions del progetto da passare al floating button in base ai permessi ***/
            this.actionsProjects = []

            if (this.storeAuth.userGetters && this.storeAuth.userGetters.user_type === 'ad')
              this.actionsProjects.push({action: 'filter', entity: 'project'})
            if (this.storeAuth.userGetters && this.storeAuth.userGetters.user_type === 'ad')
              this.actionsProjects.push({action: 'delete', type: 'bulk', entity: 'project'})
            if (this.storeAuth.userGetters && this.storeAuth.userGetters.user_type === 'ad')
              this.actionsProjects.push({action: 'add', entity: 'project'})
            /*** ***/

            useUtilsStore().chronology = []
            useUtilsStore().chronologyGetters.push({
              route_name: 'archive',
              route_id: null,
              name: 'Archivio',
              display_name: 'Archivio'
            })
            if (useCategoryStore().categoryGetters) {
              useUtilsStore().chronologyGetters.push({
                route_name: 'detailCategory',
                route_id: useCategoryStore().categoryGetters.id,
                name: 'Dettaglio categoria',
                display_name: useCategoryStore().categoryGetters.name + ' [' + useCategoryStore().categoryGetters.id + ']'
              })
              useUtilsStore().chronologyGetters.push({
                route_name: 'detailSubcategory',
                route_id: res.data.id,
                catgoryId: useCategoryStore().categoryGetters.id,
                name: 'Dettaglio sottocategoria',
                display_name: res.data.name + ' [' + this.$route.params.subcategoryId + ']'
              })
            } else {
              useCategoryStore().getCategory(this.$route.params.categoryId)
                  .then(resp => {
                    useUtilsStore().chronologyGetters.push({
                      route_name: 'detailCategory',
                      route_id: resp.data.id,
                      name: 'Dettaglio categoria',
                      display_name: resp.data.name + ' [' + resp.data.id + ']'
                    })
                    useUtilsStore().chronologyGetters.push({
                      route_name: 'detailSubcategory',
                      route_id: res.data.id,
                      categoryId: resp.data.id,
                      name: 'Dettaglio sottocategoria',
                      display_name: res.data.name + ' [' + this.$route.params.subcategoryId + ']'
                    })
                  })
            }
            useUtilsStore().currentNamePage = res.data.name
            this.currentObj = Object.assign({}, res.data)
            this.isEditing = false
            this.showShare = false
          })
          .catch(() => {
          })
    },
    returnDate(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    closeModal() {
      if (this.isEditing)
        this.loadDetail()

      this.showWiki = false
      this.showAddProject = false
      this.currentObj = Object.assign({}, null)
    }
  },
  updated() {
  },
  beforeUnmount() {
    if (useSubcategoryStore().controllerGetSubcategoryGetters)
      useSubcategoryStore().controllerGetSubcategoryGetters.abort()
    useSubcategoryStore().subcategory = null
    window.removeEventListener('resize', this.handleResize)
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style scoped>
.div-container-wiki {
  margin-right: 20px;
}

.container_tabs.container-tabs span {
  margin: 0px auto;
}

.container-single-child-detail {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#container-buttons-categories {
  background: var(--white-color);
  position: sticky;
  z-index: 1;
}

.container-buttons-wrap div {
  margin-right: 10px;
}

.container-buttons-wrap {
  margin: 10px 0px;
  flex-wrap: wrap;
}

.btn-primary-custom-child.btn-primary-custom {
  margin-left: 10px;
}

.text-tags {
  margin-right: 5px;
  margin-bottom: 5px;
}

.container-favorites span {
  white-space: nowrap;
}

.container-favorites img {
  margin-right: 5px;
}

.container-shared img {
  margin-right: 5px;
}

.container-shared {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.container-favorites {
  display: flex;
  cursor: pointer;
  align-items: center;
  margin: 0px 10px;
}

.container_favorites.container-favorites {
  margin-left: 0px;
}

.container-buttons span {
}

.container-buttons {
  display: flex;
  align-items: center;
}

.container-tabs span {
  margin-right: 25px;
  cursor: pointer;
}

.container-tabs {
  margin: 10px 0px;
}


.container-content-detail-sub-category {
  display: flex;
}

.container-content-bottom-right-head-detail {
  display: flex;
  margin-top: 25px;
  flex-direction: column;
}

.container-content-top-right-head-detail {
  display: flex;
  flex-direction: column;
}

.container-content-right-head-detail {
  display: flex;
  align-items: flex-start;
  /*flex-direction: column;*/
  margin-left: auto;
}

.text-14px-primary-medium {
  cursor: pointer;
}

.container-content-left-head-detail {
  display: grid;
  margin: 0px 0px 5px 0px;
  column-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
}

.container-head-detail {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
}


.container-detail-sub-category {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px 15px;
}


@media (max-width: 575.98px) {
}

/*// Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
  .container-content-right-head-detail {
    margin-left: 0px;
  }

  .container-content-detail-sub-category {
    flex-direction: column;
  }
}

/*// Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {

}

/*// Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {
}

/*// X-Large devices (large desktops, less than 1400px)*/
@media (max-width: 1399.98px) {
}
</style>