<template>
  <div class="container-account">
    <div class="container-content-account" v-if="storeUser.userGetters && allowed === endpoints.length">
      <div class="container-head-content-account">
        <span class="text-24px-black-bold">Account</span>
      </div>
      <div class="container-head-section">
      <span
          class="text-16px-dark-grey-medium text-16px-dark-grey-medium-margin" style="margin-right: auto">Informazioni account</span>

        <div v-if="dictWithOnlyField[0]['is_active']" class="container-status-success">
          Attivo
        </div>
        <div v-else class="container-status-light-grey container-status-light-grey-margin">Inattivo</div>
      </div>
      <div v-if="!showAccountUser" id="container-section-account" class="container-section container-section-border">
        <div
            v-show="!genericOptionLocal[0][key].read_only && genericOptionLocal[0][key].is_visible"
            :key="index" v-for="(key,index) in Object.keys(dictWithOnlyField[0])" class="container-content-section"
            :style="{'grid-row': genericOptionLocal[0][key].position.row + 1, 'grid-column': genericOptionLocal[0][key].position.col + 1 }">
          <span v-if="key !== 'is_active'" class="text-14px-grey-regular">{{ genericOptionLocal[0][key].label }}</span>

          <span v-if="dictWithOnlyField[0][key] && key !== 'is_active'"
                class="text-14px-black-regular">{{ dictWithOnlyField[0][key] }}</span>
          <span v-else-if="!dictWithOnlyField[0][key] && key !== 'is_active'" class="text-14px-black-regular">ND</span>
        </div>
      </div>

      <div class="container-head-section">
        <span class="text-16px-dark-grey-medium text-16px-dark-grey-medium-margin">Informazioni personali</span>
        <span v-if="!showDetailUser" @click="showDetailUser = true,showAccountUser = false"
              class="text-14px-primary-medium text-14px-primary-medium-margin">Modifica</span>
      </div>
      <div v-if="!showDetailUser" id="container-section-detail" class="container-section container-section-border">
        <div
            v-show="!genericOptionLocal[1][key].read_only && genericOptionLocal[1][key].is_visible"
            :key="index" v-for="(key,index) in Object.keys(dictWithOnlyField[1])" class="container-content-section"
            :style="{'grid-row': genericOptionLocal[1][key].position.row + 1, 'grid-column': genericOptionLocal[1][key].position.col + 1 }">
          <span class="text-14px-grey-regular">{{ genericOptionLocal[1][key].label }}</span>
          <span v-if="dictWithOnlyField[1][key]"
                class="text-14px-black-regular">{{ dictWithOnlyField[1][key] }}</span>
          <span v-else class="text-14px-black-regular">ND</span>
        </div>
        <!--      <div class="container-content-section">-->
        <!--        <span class="text-14px-grey-regular">Cognome</span>-->
        <!--        <span v-if="storeUser.userGetters.user_detail && storeUser.userGetters.user_detail"-->
        <!--              class="text-14px-black-regular">{{ storeUser.userGetters.user_detail.last_name }}</span>-->
        <!--        <span v-else class="text-14px-black-regular">ND</span>-->
        <!--      </div>-->
        <!--      <div class="container-content-section">-->
        <!--        <span class="text-14px-grey-regular">Email</span>-->
        <!--        <span v-if="storeUser.userGetters.email" class="text-14px-black-regular">{{-->
        <!--            storeUser.userGetters.email-->
        <!--          }}</span>-->
        <!--        <span v-else class="text-14px-black-regular">ND</span>-->
        <!--      </div>-->
        <!--      <div class="container-content-section">-->
        <!--        <span class="text-14px-grey-regular">Telefono</span>-->
        <!--        <span v-if="storeUser.userGetters.user_detail && storeUser.userGetters.user_detail.phone_number"-->
        <!--              class="text-14px-black-regular">{{ storeUser.userGetters.user_detail.phone_number }}</span>-->
        <!--        <span v-else class="text-14px-black-regular">ND</span>-->
        <!--      </div>-->
      </div>
      <ComponentDynamicForm @close-form-without-update="showDetailUser = false" @closeForm="resetVariables"
                            :currentObject="{0: currentObject.user_detail}"
                            class="form-custom"
                            v-else
                            :options="{
                            endpoints:
                            [
                              {
                                'endpoint': 'users-details',
                                'type': TYPE_OF_OPTION.UPDATE,
                                'path': [
                                  'users-details'
                                ]
                              },
                            ]
                          }"></ComponentDynamicForm>
    </div>
    <b-spinner v-else></b-spinner>
  </div>

</template>

<script>
import {useUserStore} from "@/stores/users";
import {useAuthStore} from "@/stores/auth";
import ComponentDynamicForm from "@/components/form/ComponentDynamicForm";
import {useUtilsStore} from "@/stores/utils";
import {TYPE_OF_OPTION} from "@/globals";
import {useApiSettingStore} from "@/stores/apiSettings";

export default {
  name: "ComponentAccount",
  components: {ComponentDynamicForm},
  setup() {
    const storeUser = useUserStore()
    const storeApisetting = useApiSettingStore()
    const storeAuth = useAuthStore()
    const storeUtils = useUtilsStore()
    return {
      storeApisetting,
      storeUser,
      TYPE_OF_OPTION,
      storeAuth,
      storeUtils
    }
  },
  data() {
    return {
      currentObject: null,
      currentObjectWithDetail: null,
      dictMaxCol: {},
      step: 0,
      allowed: 0,
      endpoints: [
        {
          'endpoint': 'users',
          'type': TYPE_OF_OPTION.UPDATE,
          'path': [
            'users',
            {'objectID': useAuthStore().userGetters.user_id}
          ]
        },
        {
          'endpoint': 'users-details',
          'type': TYPE_OF_OPTION.UPDATE,
          'path': [
            'users-details'
          ]
        }
      ],
      disabledButton: false,
      dictWithOnlyField: {},
      genericOptionLocal: {},
      showAccountUser: false,
      showEditGroup: false,
      showEditPermession: false,
      showDetailUser: false
    }
  },
  async created() {
    this.genericOptionLocal = {}
    await this.loadDetail()
  },
  computed: {
    user() {
      return useUserStore().userGetters
    }
  },
  watch: {},
  mounted() {
    let container_section_detail = document.getElementById('container-section-detail')
    if (container_section_detail) {
      container_section_detail.style.gridTemplateColumns = 'repeat(' + this.dictMaxCol[1].max + ', 1fr)'
    }
    let container_section_account = document.getElementById('container-section-account')
    if (container_section_account) {
      container_section_account.style.gridTemplateColumns = 'repeat(' + this.dictMaxCol[0].max + ', 1fr)'
    }
  },
  methods: {
    hideModal() {
      useUtilsStore().resetDictionaryWidgetList()
      this.showEditPermession = false
      this.showEditGroup = false
    },
    async loadDetail() {
      await useUserStore().getUser(useAuthStore().userGetters.user_id)
          .then(res => {
            this.currentObject = res.data

            useUtilsStore().chronology = []
            useUtilsStore().chronology.push({
              route_name: 'account',
              route_id: null,
              name: 'Account',
              display_name: 'Account'
            })
            this.allowed = 0
            this.buildObjectsDynamic()
          })
          .catch(() => {
          })
    },
    clickAnnulla() {
      useUtilsStore().resetDictionaryWidgetList()
      this.showEditGroup = false
      this.showEditPermession = false
    },
    updateGroups() {
      this.disabledButton = true
      let count = 0
      let obj = null
      obj = Object.assign({}, useUserStore().userGetters)
      useUtilsStore().dictionaryGenericWidgetListGetters['userGroups'].itemsAdded.forEach(group => {
        if (!(obj.groups.includes(group.id))) {
          obj.groups = obj.groups.map(el => el.id)
          obj.groups.push(group.id)
          obj.user_permissions = obj.user_permissions.map(el => el.id)
          useUserStore().editUser(obj)
              .then(() => {
                count++
                if (count === useUtilsStore().dictionaryGenericWidgetListGetters['userGroups'].itemsAdded.length)
                  useUserStore().getUser(useUserStore().userGetters.id)
                      .then(() => {
                        this.showEditGroup = false
                        this.disabledButton = false
                        useUtilsStore().resetDictionaryWidgetList()
                      })
                      .catch(() => {
                        this.disabledButton = false
                      })
              })
              .catch(() => {
                this.disabledButton = false
              })
        } else
          count++
      })
      if (useUtilsStore().dictionaryGenericWidgetListGetters['userGroups'].itemsRemoved.length > 0) {
        let obj1 = null
        obj1 = Object.assign({}, useUserStore().userGetters)
        useUtilsStore().dictionaryGenericWidgetListGetters['userGroups'].itemsRemoved.forEach(group => {
          if (Array.isArray(obj1.groups)) {
            let index = obj1.groups.findIndex(p => p === group)
            if (index !== -1) {
              obj1.groups.splice(index, 1);
            }
          }
        })
        obj1.groups = obj1.groups.map(el => el.id)
        obj1.user_permissions = obj1.user_permissions.map(el => el.id)
        useUserStore().editUser(obj1)
            .then(() => {
              useUserStore().getUser(useUserStore().userGetters.id)
                  .then(() => {
                    this.showEditGroup = false
                    this.disabledButton = false
                    useUtilsStore().resetDictionaryWidgetList()
                  })
                  .catch(() => {
                    this.disabledButton = false
                  })
            })
            .catch(() => {
              this.disabledButton = false
            })
      }
    },
    updatePermissions() {
      this.disabledButton = true
      let count = 0
      let obj = null
      obj = Object.assign({}, useUserStore().userGetters)
      useUtilsStore().dictionaryGenericWidgetListGetters['userPermissions'].itemsAdded.forEach(permission => {
        if (!(obj.user_permissions.includes(permission.id))) {
          obj.user_permissions = obj.user_permissions.map(el => el.id)
          obj.user_permissions.push(permission.id)
          obj.groups = obj.groups.map(el => el.id)
          useUserStore().editUser(obj)
              .then(() => {
                count++
                if (count === useUtilsStore().dictionaryGenericWidgetListGetters['userPermissions'].itemsAdded.length)
                  useUserStore().getUser(useUserStore().userGetters.id)
                      .then(() => {
                        this.showEditPermession = false
                        this.disabledButton = false
                        useUtilsStore().resetDictionaryWidgetList()
                      })
                      .catch(() => {
                        this.disabledButton = false
                      })
              })
              .catch(() => {
                this.disabledButton = false
              })
        } else
          count++
      })
      if (useUtilsStore().dictionaryGenericWidgetListGetters['userPermissions'].itemsRemoved.length > 0) {
        let obj1 = null
        obj1 = Object.assign({}, useUserStore().userGetters)
        useUtilsStore().dictionaryGenericWidgetListGetters['userPermissions'].itemsRemoved.forEach(permission => {
          if (Array.isArray(obj1.user_permissions)) {
            let index = obj1.user_permissions.findIndex(p => p === permission)
            if (index !== -1) {
              obj1.user_permissions.splice(index, 1);
            }
          }
        })
        obj1.groups = obj1.groups.map(el => el.id)
        obj1.user_permissions = obj1.user_permissions.map(el => el.id)
        useUserStore().editUser(obj1)
            .then(() => {
              useUserStore().getUser(useUserStore().userGetters.id)
                  .then(() => {
                    this.showEditPermession = false
                    this.disabledButton = false
                    useUtilsStore().resetDictionaryWidgetList()
                  })
                  .catch(() => {
                    this.disabledButton = false
                  })
            })
            .catch(() => {

              this.disabledButton = false
            })
      }
    },
    resetVariables() {
      useUserStore().getUser(useAuthStore().userGetters.user_id)
          .then(() => {
            this.showAccountUser = false
            this.showDetailUser = false
            this.allowed = 0
            this.buildObjectsDynamic()
          })
          .catch(() => {

          })
    },
    buildObjectsDynamic() {
      this.allowed = 0
      this.endpoints.forEach((endpoint, index) => {
        this.dictWithOnlyField[index] = {}
        this.dictMaxCol[index] = {}
        this.dictMaxCol[index]['max'] = 0
        useUtilsStore().getGenericOptionCycle(endpoint.path)
            .then(res => {
              if ('PATCH' in res.data.actions) {
                this.typeActionsFromOptions = 'PATCH'
              } else if ('POST' in res.data.actions) {
                this.typeActionsFromOptions = 'POST'
              }
              delete res.data.actions[this.typeActionsFromOptions].serializer_name
              this.genericOptionLocal[index] = {}
              this.genericOptionLocal[index] = res.data.actions[this.typeActionsFromOptions]
              for (let key in res.data.actions[this.typeActionsFromOptions]) {
                if (res.data.actions[this.typeActionsFromOptions][key].required && this.typeActionsFromOptions === 'POST') {
                  if (index === 0) {
                    this.dictWithOnlyField[index][key] = useUserStore().userGetters[key]
                  } else if (index === 1) {
                    this.dictWithOnlyField[index][key] = useUserStore().userGetters.user_detail[key]
                  }
                } else {
                  if (index === 0) {
                    this.dictWithOnlyField[index][key] = useUserStore().userGetters[key]
                  } else if (index === 1) {
                    this.dictWithOnlyField[index][key] = useUserStore().userGetters.user_detail[key]
                  }
                }

                if (res.data.actions[this.typeActionsFromOptions][key].position.col > this.dictMaxCol[index].max) {
                  this.dictMaxCol[index].max = res.data.actions[this.typeActionsFromOptions][key].position.col
                }
              }
              this.dictMaxCol[index].max = this.dictMaxCol[index].max + 1
              this.allowed++
            })
            .catch(() => {
            })
      })
    },
    submitEvent() {
      this.emitter.emit('submitEvent')
    }
  },
  updated() {
    let container_section_detail = document.getElementById('container-section-detail')
    if (container_section_detail) {
      container_section_detail.style.gridTemplateColumns = 'repeat(' + this.dictMaxCol[1].max + ', 1fr)'
    }
    let container_section_account = document.getElementById('container-section-account')
    if (container_section_account) {
      container_section_account.style.gridTemplateColumns = 'repeat(' + this.dictMaxCol[0].max + ', 1fr)'
    }
  },
  beforeUnmount() {
  },
  unmounted() {
  }
}
</script>

<style scoped>
.b-spinner {
  margin: auto;
}

.btn-light-grey-custom-margin {
  margin-left: auto;
}

.btn-primary-custom-margin {
  margin-right: 15px
}

.btn-primary-custom {
  margin-left: 15px;
}

.text-14px-black-regular {
  margin-left: auto;
}

.container-section-groups-permissions {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  padding: 10px 0px;
}

.text-14px-primary-medium-margin {
  margin-left: auto;
  cursor: pointer;
}

.container-head-content-account {
  display: flex;
  flex-direction: column;
}

.container-head-section {
  width: 100%;
  margin: 0px 0px 0px 0px;
  padding: 20px 0px 0px 0px;
  display: flex;
  align-items: center;
}

.container-status-success {
  width: auto;
  height: auto;
  margin: 0px 0px auto auto !important;
}

.text-permission {
  margin: 2.5px 0px;
}

.container-permissions {
  display: flex;
  flex-direction: column;
}

.container-section-border {
  border-bottom: 1px solid var(--light-grey-color);
}

.container-content-section {
  display: grid;
  width: 100%;
  margin-right: auto;
  grid-template-columns: repeat(2, 1fr);
}

.container-buttons {
  display: flex;
  margin-left: auto;
}

.container-section {
  display: grid;
  width: 100%;
  row-gap: 20px;
  column-gap: 20px;
  padding: 20px 0px 5px 0px;
  grid-template-columns: calc(50% - 10px) calc(50% - 10px);
}

.container-content-account {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.container-account {
  display: flex;
  width: 100%;
  padding: 10px 0px 5px 15px !important;
  flex-direction: column;
  align-items: center;
}


/*// X-Small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
}

/*// Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
  .container-head-content-account {
    display: none;
  }

  .text-14px-black-regular {
    margin-left: 0px;
  }

  .container-content-section {
    display: flex;
    flex-direction: column;
  }
}

/*// Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {
  .container-section {
    display: flex;
    flex-direction: column;
  }
}

/*// Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {
}

/*// X-Large devices (large desktops, less than 1400px)*/
@media (max-width: 1399.98px) {
}

</style>