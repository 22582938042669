<template>
  <div class="container-horizontal-list">
    <div style="position: relative;width: 100%;display: flex;align-items: center">
      <Transition>
        <div style="position:absolute;left: 0" v-if="items.length === 0 && !isLoading" class="">
          Non ci sono&nbsp;<span
            style="text-transform: lowercase">{{ currentObject[keyFavorite].name }}</span>
        </div>
        <div v-else-if="items.length > 0 && !isLoading" class="grid-list" ref="list">
          <div :id="'container_single_generic_mini_detail_' + index" @click="goToDetail($event,obj)"
               v-for="(obj,index) in items"
               :key="obj.id"
               class="container-single-generic-mini-detail">
            <div id="container_img" class="container-img">
              <!--          img for category-->
              <svg v-if="currentObject[keyFavorite].key === 'category'" width="22" height="22"
                   viewBox="0 0 22 22" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <rect x="4.12109" y="13.2919" width="4.5862" height="4.5862" rx="1.5"
                      :stroke=" 'content_object' in obj ? obj.content_object.color : obj.color"
                      stroke-width="1.5"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M8.70703 5.61951C8.70703 4.79108 9.3786 4.11951 10.207 4.11951H11.7932C12.6217 4.11951 13.2932 4.79108 13.2932 5.61951V7.2057C13.2932 8.03413 12.6217 8.70571 11.7932 8.70571H10.207C9.3786 8.70571 8.70703 8.03413 8.70703 7.2057V5.61951Z"
                      :stroke=" 'content_object' in obj ? obj.content_object.color : obj.color" stroke-width="1.5"/>
                <rect x="13.2939" y="13.2919" width="4.5862" height="4.5862" rx="1.5"
                      :stroke=" 'content_object' in obj ? obj.content_object.color : obj.color"
                      stroke-width="1.5"/>
                <path
                    d="M6.41406 13.2919V12.3746C6.41406 11.8681 6.82472 11.4574 7.3313 11.4574H14.6692C15.1758 11.4574 15.5865 11.8681 15.5865 12.3746V13.2919"
                    :stroke=" 'content_object' in obj ? obj.content_object.color : obj.color" stroke-width="1.5"/>
                <path d="M11 8.70569V11.4574" :stroke=" 'content_object' in obj ? obj.content_object.color : obj.color"
                      stroke-width="1.5"/>
              </svg>
              <!---->

              <!--          img for project -->
              <svg v-if="currentObject[keyFavorite].key === 'project'" width="22" height="22"
                   viewBox="0 0 22 22" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M17.5008 12.7611L12.8351 17.4268L10.542 17.8854L11.0006 15.5923L15.6669 10.9266C16.172 10.4216 16.9908 10.4216 17.4959 10.9266L17.5014 10.9321C18.0063 11.4374 18.006 12.2562 17.5008 12.7611Z"
                      :stroke=" 'content_object' in obj ? obj.content_object.color : obj.color" stroke-width="1.5"/>
                <path d="M6.87256 10.0889H12.376"
                      :stroke=" 'content_object' in obj ? obj.content_object.color : obj.color"
                      stroke-width="1.5"/>
                <path d="M6.87256 12.382H10.5415"
                      :stroke=" 'content_object' in obj ? obj.content_object.color : obj.color"
                      stroke-width="1.5"/>
                <path d="M6.87256 14.675H8.70704"
                      :stroke=" 'content_object' in obj ? obj.content_object.color : obj.color"
                      stroke-width="1.5"/>
                <path
                    d="M8.7068 17.8854H5.03785C4.53127 17.8854 4.12061 17.4748 4.12061 16.9682V7.33717C4.12061 6.83059 4.53127 6.41993 5.03785 6.41993H7.33156C7.33156 5.15349 8.35821 4.12683 9.62465 4.12683C10.8911 4.12683 11.9178 5.15349 11.9178 6.41993H14.2109C14.7174 6.41993 15.1281 6.83059 15.1281 7.33717V8.71303"
                    :stroke=" 'content_object' in obj ? obj.content_object.color : obj.color" stroke-width="1.5"/>
                <path
                    d="M9.62433 5.9613C9.75097 5.9613 9.85364 6.06397 9.85364 6.19061C9.85364 6.31726 9.75097 6.41992 9.62433 6.41992C9.49769 6.41992 9.39502 6.31726 9.39502 6.19061C9.39486 6.12975 9.41896 6.07133 9.462 6.02829C9.50504 5.98525 9.56346 5.96114 9.62433 5.9613"
                    :stroke=" 'content_object' in obj ? obj.content_object.color : obj.color" stroke-width="1.5"/>
              </svg>
              <!---->

              <!-- img for document -->
              <svg v-if="currentObject[keyFavorite].key === 'document'" width="24" height="24"
                   viewBox="0 0 24 24" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M13.0867 19.5047H5.49599C4.94336 19.5047 4.49536 19.0567 4.49536 18.5041V5.49599C4.49536 4.94336 4.94336 4.49536 5.49599 4.49536H18.5041C19.0567 4.49536 19.5047 4.94336 19.5047 5.49599V13.0867C19.5047 13.3519 19.3993 13.6063 19.2119 13.7938L13.7938 19.2119C13.6063 19.3993 13.3519 19.5047 13.0867 19.5047Z"
                      :stroke=" 'content_object' in obj ? obj.content_object.color : obj.color" stroke-width="1.5"/>
                <path d="M13.501 19.4147V14.5016C13.501 13.949 13.949 13.501 14.5016 13.501H19.4147"
                      :stroke=" 'content_object' in obj ? obj.content_object.color : obj.color"
                      stroke-width="1.5"/>
              </svg>
              <!---->

              <!-- img for subcategory -->
              <svg v-if="currentObject[keyFavorite].key === 'subcategory'" width="22" height="22"
                   viewBox="0 0 22 22" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M17.9015 11.2648C17.9368 11.0856 17.8901 10.9 17.7742 10.7589C17.6584 10.6177 17.4855 10.5357 17.3029 10.5353H10.0701C9.77997 10.5348 9.53012 10.3304 9.47209 10.0461L9.30271 9.19549C9.24468 8.91119 8.99483 8.70682 8.70467 8.7063H4.76298C4.58079 8.70743 4.40859 8.78974 4.29329 8.93082C4.17799 9.0719 4.13161 9.25704 4.16678 9.43581L5.75238 17.3883C5.81041 17.6726 6.06026 17.877 6.35042 17.8775H16.0836C16.3737 17.877 16.6236 17.6726 16.6816 17.3883C16.9317 16.1304 17.6043 12.7538 17.9015 11.2648Z"
                      :stroke=" 'content_object' in obj ? obj.content_object.color : obj.color" stroke-width="1.5"/>
                <path
                    d="M6.44775 6.87123L6.45998 4.731C6.45998 4.39328 6.73376 4.11951 7.07148 4.11951H14.9976C15.3354 4.11951 15.6091 4.39328 15.6091 4.731L15.622 8.70571"
                    :stroke=" 'content_object' in obj ? obj.content_object.color : obj.color" stroke-width="1.5"/>
                <path d="M8.29004 5.9491L13.3275 5.95399"
                      :stroke=" 'content_object' in obj ? obj.content_object.color : obj.color" stroke-width="1.5"/>
                <path d="M11.0347 7.78845H13.3278"
                      :stroke=" 'content_object' in obj ? obj.content_object.color : obj.color"
                      stroke-width="1.5"/>
              </svg>
              <!---->

              <!-- img for wiki -->
              <svg v-if="currentObject[keyFavorite].key === 'wiki'" width="24" height="24" viewBox="0 0 24 24"
                   fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9.99902 9.99874C9.99922 8.98106 10.7631 8.12558 11.7743 8.01067C12.7855 7.89576 13.7219 8.55803 13.9504 9.54972C14.1789 10.5414 13.6268 11.5467 12.6674 11.8859C12.2674 12.0273 12.0001 12.4056 12.0003 12.8298V13.5009"
                    :stroke=" 'content_object' in obj ? obj.content_object.color : obj.color" stroke-width="1.5"
                    stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M12.0002 15.5021C11.862 15.5021 11.75 15.6141 11.75 15.7523C11.75 15.8904 11.862 16.0024 12.0002 16.0024C12.1383 16.0024 12.2503 15.8904 12.2503 15.7523C12.2503 15.6141 12.1383 15.5021 12.0002 15.5021"
                    :stroke=" 'content_object' in obj ? obj.content_object.color : obj.color" stroke-width="1.5"
                    stroke-linecap="round" stroke-linejoin="round"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M11.9998 19.5047C16.1445 19.5047 19.5045 16.1447 19.5045 12C19.5045 7.85526 16.1445 4.4953 11.9998 4.4953C7.85508 4.4953 4.49512 7.85526 4.49512 12C4.49512 16.1447 7.85508 19.5047 11.9998 19.5047Z"
                      :stroke=" 'content_object' in obj ? obj.content_object.color : obj.color" stroke-width="1.5"/>
              </svg>
              <!---->

              <!-- img for tag -->
              <svg v-if="currentObject[keyFavorite].key === 'tag'" width="22" height="22" viewBox="0 0 22 22"
                   fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M6.4165 9.16675H18.3332" stroke="#CF3092"
                      stroke-width="1.5"/>
                <path d="M4.5835 14.6667H16.5002" stroke="#CF3092"
                      stroke-width="1.5"/>
                <path d="M11.4588 4.58337L6.87549 18.3334"
                      stroke="#CF3092" stroke-width="1.5"/>
                <path d="M16.0418 4.58337L11.4585 18.3334"
                      stroke="#CF3092" stroke-width="1.5"/>
              </svg>


              <!---->
            </div>
            <div id="container_name_generic_item" class="container-name-generic-item text-16px-black-regular">
              <span>
                {{ 'content_object' in obj ? obj.content_object.display_name : obj.display_name }}
              </span>
            </div>
            <div id="container_img_delete" @click="deleteFavorite($event,obj)"
                 class="container-img container-img-delete">
              <img src="../../assets/shared/delete-red.svg" alt="">
            </div>
          </div>
        </div>
        <div v-else-if="isLoading"
             style="width: 100%;position: absolute;z-index: 10;display: flex;align-items: center;justify-content: center">
          <b-spinner></b-spinner>
        </div>
      </Transition>
    </div>
    <b-modal @hide="hidedModal" :hide-footer="true" :hide-header="true" v-model="showModal">
      <ComponentDetailWiki @closeModal="showModal = false" :id-prop="currentId"
                           v-if="showModal"></ComponentDetailWiki>
    </b-modal>
  </div>
</template>

<script>
import {useUtilsStore} from "@/stores/utils";
import {useFavoriteStore} from "@/stores/favorites";
import {useCategoryStore} from "@/stores/categories";
import {computed} from "vue";
import ComponentDetailWiki from "@/components/wiki/ComponentDetailWiki";

export default {
  name: "ComponentGenericListMiniDetails",
  components: {ComponentDetailWiki},
  props: {
    keyFavorite: {
      type: String
    },
    currentObjectProp: {
      type: Object
    },
    searchProp: {
      type: String
    },
    showTabs: {
      type: Boolean
    }
  },
  setup(props) {
    const search = computed(() => {
      return props.searchProp
    })

    const storeFavorite = useFavoriteStore()
    return {
      search,
      storeFavorite
    }
  },
  data() {
    return {
      controller: null,
      currentObject: this.currentObjectProp,
      colors: [],
      items: [],
      page: 1,
      isLoading: false,
      showModal: false,
      response: null,
      currentId: 0
    }
  },

  async created() {
    this.controller = new AbortController()

    // carica i primi dati al caricamento del componente
    await this.loadMore()
  },
  watch: {
    infoForToDelete: {
      handler: function (value) {
        if (!value.confirmDelete) {
          if (value && this.keyFavorite === useFavoriteStore().currentKeyDeletingGetters) {
            this.items = []
            this.page = 1
            this.loadMore()
          }
        }
      }, deep: true
    },
    globalSearch: {
      handler: function () {
        // this.search = e
        this.items = []
        this.page = 1
        this.loadMore()
      }
    },
    isDeleted: {
      handler: function (value) {
        if (value && this.keyFavorite === useFavoriteStore().currentKeyDeletingGetters) {
          this.items = []
          this.page = 1
          this.loadMore()
        }
      }
    }
  },
  computed: {
    infoForToDelete() {
      return useUtilsStore().infoForToDeleteGetters
    },
    globalSearch() {
      return useUtilsStore().globalSearchGetters
    }
  },
  mounted() {
  },
  methods: {
    hidedModal() {
      this.currentId = 0
      this.showModal = false
    },
    deleteFavorite(e, obj) {
      e.stopPropagation()
      let item = obj
      useFavoriteStore().currentKeyDeleting = this.keyFavorite
      useUtilsStore().infoForToDelete['currentPosition'] = 'list'
      useUtilsStore().infoForToDelete['confirmDelete'] = true
      useUtilsStore().infoForToDelete['action'] = this.storeFavorite.deleteFavorite
      useUtilsStore().infoForToDelete['actionAfterDelete']['action'] = this.storeFavorite.getFavorites
      useUtilsStore().infoForToDelete['actionAfterDelete']['queryParams'] = {
        content_type__model: this.keyFavorite
      }
      useUtilsStore().infoForToDelete['typeObjectToDelete'] = 'single'
      useUtilsStore().infoForToDelete['entity'] = 'favorite'
      useUtilsStore().infoForToDelete['name'] = 'preferito'
      useUtilsStore().infoForToDelete['objectToDelete'] = item
      useUtilsStore().infoForToDelete['messageToDelete']['is'] = 'div'
      useUtilsStore().infoForToDelete['messageToDelete']['innerHTML'] = "Sei sicuro di voler eliminare la " +
          this.currentObject[this.keyFavorite].single_name + " dai preferiti?"
    },
    goToDetail(e, obj) {
      if (obj.content_object && 'color' in obj.content_object)
        useCategoryStore().currentColor = obj.content_object.color
      switch (this.currentObject[this.keyFavorite].key) {
        case 'category':
          this.$router.push({
            name: 'detailCategory',
            params: {categoryId: obj.content_object ? obj.content_object.id : obj.id}
          }).catch(() => {
          })
          break;
        case 'subcategory':
          this.$router.push({
            name: 'detailSubcategory',
            params: {
              categoryId: obj.content_object ? obj.content_object.category.id : obj.category.id,
              subcategoryId: obj.content_object ? obj.content_object.id : obj.id
            }
          }).catch(() => {
          })
          break;
        case 'project':
          if ((obj.content_object && obj.content_object.category) || obj.category)
            this.$router.push({
              name: 'detailProjectFromCategory',
              params: {
                categoryId: obj.content_object ? obj.content_object.category.id : obj.category.id,
                projectId: obj.content_object ? obj.content_object.id : obj.id
              }
            }).catch(() => {
            })
          if ((obj.content_object && obj.content_object.subcategory) || obj.subcategory)
            this.$router.push({
              name: 'detailProjectFromSubcategory',
              params: {
                categoryId: obj.content_object ? obj.content_object.subcategory.category.id : obj.subcategory.category.id,
                subcategoryId: obj.content_object ? obj.content_object.subcategory.id : obj.subcategory.id,
                projectId: obj.content_object ? obj.content_object.id : obj.id
              }
            }).catch(() => {
            })
          break;
        case 'document':
          if ((obj.content_object && obj.content_object.project.category && obj.content_object.project.subcategory === null) || obj.project && obj.project.category) {
            this.$router.push({
              name: 'detailProjectFromCategory',
              params: {
                categoryId: obj.content_object ? obj.content_object.project.category.id : obj.project.category.id,
                projectId: obj.content_object ? obj.content_object.project.id : obj.project.id
              }
            }).catch(() => {
            })
          } else if ((obj.content_object && obj.content_object.project.subcategory && obj.content_object.project.category === null) || obj.project && obj.project.subcategory) {
            this.$router.push({
              name: 'detailProjectFromSubcategory',
              params: {
                categoryId: obj.content_object ? obj.content_object.project.subcategory.category.id : obj.project.subcategory.category.id,
                subcategoryId: obj.content_object ? obj.content_object.project.subcategory.id : obj.project.subcategory.id,
                projectId: obj.content_object ? obj.content_object.project.id : obj.project.id
              }
            }).catch(() => {
            })
          }
          break;
        case 'wiki':
          this.currentId = obj.content_object ? obj.content_object.id : obj.id
          this.showModal = true
          break;
        case 'tag':
          break;
      }
      if (this.currentObject[this.keyFavorite].key !== 'tag')
        useUtilsStore().globalSearch = ''
    },
    handleScroll() {
      const list = this.$refs.list
      const scrollWidth = list.scrollWidth
      const clientWidth = list.clientWidth
      const scrollLeft = list.scrollLeft

      if (scrollWidth - clientWidth - scrollLeft < 50) {
        // carica nuovi dati quando l'utente raggiunge la fine della lista
        if (this.response.data.next) {
          this.loadMore()
        }
      }
    },
    async loadMore() {
      if (this.isLoading) return // evita di caricare nuovi dati se un caricamento è già in corso
      this.isLoading = true // indica che un caricamento è in corso

      if (useUtilsStore().globalSearchGetters === '') {
        let queryParams = {
          page: this.page,
          pagination: false,
          content_type__model: this.currentObject[this.keyFavorite].key
        }

        for (let key in useUtilsStore().dictionaryFilterFinalGetters) {
          queryParams[key] = useUtilsStore().dictionaryFilterFinalGetters[key]
        }

        await useFavoriteStore().getFavorites({queryParams: queryParams, controller: this.controller})
            .then(response => {

              this.currentObject[this.keyFavorite].count = response.data.length
              this.items = response.data
              this.isLoading = false // indica che il caricamento è stato completato
            })
            .catch(() => {

            })
      } else {
        let queryParams = {
          page: this.page,
          pagination: false,
          search: useUtilsStore().globalSearchGetters
        }

        for (let key in useUtilsStore().dictionaryFilterFinalGetters) {
          queryParams[key] = useUtilsStore().dictionaryFilterFinalGetters[key]
        }

        useUtilsStore().getGlobalSearch({queryParams: queryParams, key: this.keyFavorite})
            .then(res => {
              this.response = res.data
              this.currentObject[this.keyFavorite].count = this.response.length
              this.items = this.response
              this.page++
            })
            .catch(() => {

            })
            .finally(() => {

              this.isLoading = false
            })
      }
    }
  },
  updated() {
  },
  beforeUnmount() {
    if (this.controller)
      this.controller.abort()
  },
  unmounted() {
  }
}
</script>

<style scoped>
.container-name-generic-item:hover {
  background: var(--light-grey-color);
}

.text-16px-black-regular {
  line-height: unset;
}

.container-horizontal-list {
  display: flex;
  min-height: 85px;
  height: 100%;
}

.container-name-generic-item span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.container-name-generic-item {
  overflow: hidden;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px;
  height: 100%;
  background: var(--white-color);
  border: 1px solid var(--light-grey-color);
  border-radius: 0px;
}

.grid-list {
  width: 100%;
  display: grid;
  grid-template-columns: calc(33% - 5px) calc(33% - 5px) calc(33% - 5px);
  column-gap: 15px;
  row-gap: 15px;
  padding: 10px 10px 10px 0px;
  flex-wrap: wrap;
}

.container-img svg {
  width: 1.5rem;
  height: 1.5rem;
}

.container-img-delete.container-img {
  border-radius: 0px 4px 4px 0px;
}

.container-img {
  height: 100%;
  padding: 9.5px;
  display: flex;
  align-items: center;
  background: var(--dark-white-color);
  border-radius: 4px 0px 0px 4px;
}

.container-single-generic-mini-detail {
  display: flex;
  width: 100%;
  align-items: center;
  margin: 0px 0px 0px 0px;
  cursor: pointer;
}

.v-enter-active,
.v-leave-active {
  position: relative;
  transition: opacity 0.5s ease-out;
}

.v-enter-from,
.v-leave-to {
  position: relative;
  opacity: 0;
}


/*// X-Small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
  .grid-list {
    display: flex !important;
    flex-direction: column !important;
  }
}

/*// Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
  .container-single-category {
    min-width: 50%;
  }
}

/*// Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {
  .grid-list {
    display: grid;
    column-gap: 20px;
    row-gap: 20px;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  }
}

/*// Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {
}

/*// X-Large devices (large desktops, less than 1400px)*/
@media (max-width: 1399.98px) {
}

</style>