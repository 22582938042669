<template>
  <ComponentAccount v-if="$route.name === 'account'"></ComponentAccount>
</template>

<script>
import ComponentAccount from "@/components/settings/ComponentAccount.vue";
import {pages} from "../../vue.config";

export default {
  name: "AcconutView",
  components: {ComponentAccount},
  created() {
  },
  mounted() {
    document.title = 'Account' + ' | ' + pages.index.title
  }
}
</script>

<style scoped>

</style>