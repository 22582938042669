<template>
  <div id="floating_action_button" class="floating-action-button" :class="{ 'is-open': isOpen }">
    <div :class="{'floating-action-button__icon-no-background': isOpen}" @click="isOpen = true"
         class="floating-action-button__icon"></div>
    <div v-show="isOpen" class="floating-action-button__options">
      <div class="container-overflow-custom">
        <div
            :class="{'disabled_cursor_custom': 'type' in option && option.type === 'bulk' && selected.length === 0}"
            class="floating-action-button__option"
            v-for="(option, index) in actions" :key="index"
            @click="handleClick(option)">

          <div class="image-container"
               v-show="option.entity === 'group' && option.action === 'add' && ('type' in option && option.type === 'bulk')">
            <img
                src="../../assets/shared/group_white.svg" alt="">
            <sup v-if="'type' in option && option.type === 'bulk'">{{ selected.length }}</sup>
          </div>

          <img v-show="option.entity === 'group' && option.action === 'add' && !('type' in option)"
               src="../../assets/shared/group_white.svg" alt="">
          <img v-show="option.entity === 'category' && option.action === 'add'"
               src="../../assets/shared/hierarchy_white.svg" alt="">
          <img v-show="option.entity === 'privateWalls' && option.action === 'add'"
               src="../../assets/interaction_cc/earth_lucchetto_white.svg" alt="">
          <img v-show="option.entity === 'document' && option.action === 'add'"
               src="../../assets/shared/notes-paper_white.svg" alt="">
          <img v-show="option.entity === 'subcategory' && option.action === 'add'"
               src="../../assets/shared/folder_white.svg" alt="">
          <img v-show="option.entity === 'project' && option.action === 'add'"
               src="../../assets/shared/task-list_white.svg" alt="">
          <img v-show="option.entity === 'user' && option.action === 'add'"
               src="../../assets/shared/single-man-white.svg" alt="">
          <div class="image-container" v-show="option.action === 'delete'">
            <img
                src="../../assets/shared/delete_white.svg" alt="">
            <sup v-if="'type' in option && option.type === 'bulk'">{{ selected.length }}</sup>
          </div>
          <img
              v-show="(option.entity === 'category' || option.entity === 'subcategory' || option.entity === 'project') && option.action === 'favorite'"
              src="../../assets/shared/bookmarks_white.svg" alt="">
          <img
              v-show="(option.entity === 'category' || option.entity === 'subcategory' || option.entity === 'project' || option.entity === 'public-walls') && option.action === 'edit'"
              src="../../assets/shared/edit_white.svg" alt="">
          <img
              v-show="(option.entity === 'category' || option.entity === 'subcategory' || option.entity === 'project') && option.action === 'share'"
              src="../../assets/shared/share_white.svg" alt="">
          <img v-show="option.action === 'filter'"
               src="../../assets/shared/settings-filter_white.svg" alt="">
        </div>
      </div>

      <div style="width: 64px!important;padding: 0px 16px!important;" @click="closeFloatingButton"
           class="floating-action-button__option">
        <img class="img-close-floating" src="../../assets/shared/close_white.svg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ComponentFloatingActionButton",
  props: {
    actions: {
      type: Array
    },
    selected: {
      type: Array
    }
  },
  setup() {
    return {}
  },
  data() {
    return {
      isOpen: false,
    };
  },
  mounted() {
    this.setWidth()
  },
  methods: {
    closeFloatingButton() {
      this.isOpen = false
    },
    setWidth() {
      let floating_action_button = document.getElementById('floating_action_button')
      if (floating_action_button)
        if (this.isOpen)
          floating_action_button.style.width = 64 + (64 * this.actions.length) + 'px'
        else
          floating_action_button.style.width = '64px'
    },
    handleClick(option) {
      this.$emit('setBooleanModal', option)
      this.isOpen = false
    }
  },
  updated() {
    this.setWidth()
  }
}
</script>

<style>
#floating_action_button {
  z-index: 10000 !important;
}

.disabled_cursor_custom {
  cursor: not-allowed;
  pointer-events: none;
}

.img-close-floating {
}

.floating-action-button {
  position: fixed;
  bottom: 24px;
  right: 24px;
  width: 64px;
  height: 64px;
  border-radius: 64px;
  background-color: var(--primary-color);
  color: var(--white-color);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.floating-action-button.is-open {
  max-width: calc(100vw - 48px);
  height: 64px;
  border-radius: 64px;
  background-color: var(--primary-color);
  color: var(--white-color);
}

.floating-action-button__icon-no-background.floating-action-button__icon {
  background: unset;
}

.floating-action-button__icon {
  height: 100%;
  width: 64px;
  border-radius: 64px;
  background-position: center;
  background-image: url('../../assets/shared/navigation-menu-horizontal.svg');
  background-repeat: no-repeat;
}

.floating-action-button__options img {
  width: 32px
}

.floating-action-button__options {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  opacity: 0;
  transition: all 0.3s ease;
}

.floating-action-button.is-open .floating-action-button__options {
  opacity: 1;
}

.floating-action-button__option:hover {
  background: var(--primary-color-hover);
}

.floating-action-button__option {
  display: flex;
  justify-content: center;
  background: transparent;
  width: 64px;
  height: 100%;
  border-radius: 64px;
  align-items: center;
  padding: 0px 13px;
  opacity: 0;
  transform: translateX(100%);
  transition: all 0.3s ease;
}


.container-overflow-custom::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  padding-right: 15px;
  margin-right: 15px;
}

/*.container-overflow-custom::-webkit-scrollbar-thumb {*/
/*    background: var(--dark-grey-color);*/
/*    border-radius: 10px;*/
/*    padding-right: 15px;*/
/*    margin-right: 15px;*/
/*}*/

/*.container-overflow-custom::-webkit-scrollbar-track {*/
/*    box-shadow: inset 0 0 5px grey;*/
/*    border-radius: 10px;*/
/*    margin-right: 15px;*/
/*    padding-right: 15px;*/
/*}*/

.container-overflow-custom {
  max-width: calc(100vw - 48px);
  overflow-x: auto;
  display: flex;
  height: 95%;
  width: 100%;
}

.floating-action-button.is-open .floating-action-button__option {
  opacity: 1;
  transform: translateX(0);
}

.floating-action-button__option-icon {
  width: 24px;
  height: 24px;
  /*background-image: url('../../assets/shared/navigation-menu-horizontal.svg');*/
  margin-bottom: 8px;
  background-repeat: no-repeat;
  background-size: contain;
}

.floating-action-button__option-label {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.image-container {
  position: relative;
}

.image-container img {
  display: block;
}

.image-container sup {
  display: flex;
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 13px;
  color: white;
  background-color: var(--primary-color-hover);
  padding: 2px 8px;
  border-radius: 20px;
  height: 20px;
  min-width: 20px;
  align-items: center;
}
</style>