<template>
  <div class="container-detail-project" v-if="storeProject.projectGetters">
    <div v-if="showTabs" :class="{'container_tabs': showTabs, 'order_tabs': showTabs}" class="container-tabs">
      <span
          @click="showDocuments = false,showManage = true"
          :class="{'text-14px-secondary-medium': showManage && !showDocuments}"
          class="text-14px-grey-regular">Gestisci</span>
      <span @click="showDocuments = true, showManage = false,isEditing = false"
            :class="{'text-14px-secondary-medium': showDocuments && !showManage}"
            class="text-14px-grey-regular">Documenti</span>
    </div>
    <div class="container-content-detail-project">
      <div v-if="!isEditing && (!showTabs) || (showManage && showTabs && !isEditing)" v-show="!isEditing"
           class="container-head-detail">
        <div class="container-content-left-head-detail">
          <span class="text-14px-dark-grey-medium">Nome: </span>
          <span class="text-14px-black-regular">{{
              storeProject.projectGetters ? storeProject.projectGetters.name : 'ND'
            }}</span>
          <div v-if="storeProject.projectGetters.is_favorite" class="container-is-favorite text-14px-secondary-regular">
            Preferiti
          </div>
        </div>
        <div class="container-content-left-head-detail">
          <span class="text-14px-dark-grey-medium">Data creazione: </span>
          <span class="text-14px-black-regular">{{ returnDate(storeProject.projectGetters.created_at) }}</span>
        </div>
        <div class="container-content-left-head-detail">
          <span class="text-14px-dark-grey-medium">Proprietario: </span>
          <span v-if="storeProject.projectGetters.owner.user_detail" class="text-14px-secondary-regular">{{
              storeProject.projectGetters.owner.user_detail.first_name
            }} {{ storeProject.projectGetters.owner.user_detail.last_name }}</span>
          <span v-else class="text-14px-secondary-regular">{{ storeProject.projectGetters.owner.email }}</span>
        </div>
        <div class="container-content-left-head-detail">
          <span class="text-14px-dark-grey-medium">Descrizione: </span>
          <span v-b-popover="storeProject.projectGetters.description"
                v-if="storeProject.projectGetters.description"
                class="text-14px-black-regular text-overflow-custom">{{
              storeProject.projectGetters.description
            }}</span>
          <span v-else class="text-14px-black-regular">ND</span>
        </div>
        <div class="container-content-left-head-detail container-content-left-head-detail-tag">
          <span class="text-14px-dark-grey-medium">Tags: </span>
          <div v-if="storeProject.projectGetters.tag.length > 0" style="display: flex;flex-wrap: wrap">
            <span class="text-14px-secondary-regular text-tags"
                  v-for="(tag,i) in storeProject.projectGetters.tag"
                  :key="i">{{
                tag.name
              }}</span>
          </div>
          <span v-else class="text-14px-secondary-regular">Nessun tag presente</span>
        </div>
      </div>
      <ComponentDynamicForm v-if="isEditing"
                            @close-form-without-update="isEditing = false"
                            @closeForm="closeModal"
                            :currentObject="{0: currentObj}"
                            :options="{
                                  endpoints: [
                                      {
                                        'endpoint': 'projects',
                                        'type': TYPE_OF_OPTION.UPDATE,
                                        'path': [
                                          'projects',
                                           {'objectID':  storeProject.projectGetters.id}
                                        ]
                                      }
                                  ]
                              }"
      ></ComponentDynamicForm>
      <div v-if="!showTabs" class="container-content-right-head-detail">
        <ComponentText v-if="storeProject.projectGetters && !isEditing"
                       :button-disabled="storeProject.projectGetters.description === null || storeProject.projectGetters.description === ''"
                       :currentObject="storeProject.projectGetters"
                       :fields="arrayFieldsToTextMining"
                       :model="'category'" :id="storeProject.projectGetters.id"></ComponentText>
        <div style="display: flex;flex-direction: column;margin-left: 25px">
          <div class="container-content-top-right-head-detail">
            <span class="text-14px-dark-grey-regular">Gestisci il progetto</span>
            <div class="container-buttons">
            <span
                v-if="storeProject.projectGetters && storeAuth.userGetters && storeAuth.userGetters.user_type === 'ad'"
                @click="isEditing = true,currentObj = Object.assign({},storeProject.projectGetters)"
                class="text-14px-primary-medium">Modifica</span>
              <div v-if="storeProject.projectGetters"
                   @click="storeFavorite.setStatusFavorites({params: storeProject.projectGetters.id,action: storeProject.getProject,object: storeProject.projectGetters,entity: 'project'})"
                   v-show="!storeProject.projectGetters.is_favorite"
                   :class="{'container_favorites': isEditing,'disable-pointer-custom': storeFavorite.debounceBooleanGetters}"
                   class="container-favorites">
                <img src="../../assets/shared/bookmarks.svg" alt="">
                <span class="text-14px-secondary-medium">Salva tra i preferiti</span>
              </div>
              <div v-if="storeProject.projectGetters" @click="deleteFavorite"
                   v-show="storeProject.projectGetters.is_favorite" class="container-favorites">
                <img src="../../assets/shared/bookmarks-dark-grey.svg" alt="">
                <span class="text-14px-dark-grey-medium">Rimuovi dai preferiti</span>
              </div>
              <button
                  v-if="storeProject.projectGetters && storeAuth.userGetters && storeAuth.userGetters.user_type === 'ad'"
                  @click="deleteProject" class="btn btn-danger-custom">Elimina
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!showTabs || (showManage && showTabs && !isEditing)" style="display: flex;align-items: center">
      <div
          @click="showWiki = true,currentObj = Object.assign({},storeProject.projectGetters)"
          class="div-container-wiki">
        <div class="div-container-img-wiki">
          <img src="../../assets/shared/question-circle-secondary.svg" alt="">
        </div>
        <span class="text-16px-black-medium">Wiki</span>
      </div>
      <ComponentText v-if="storeProject.projectGetters && !isEditing && showTabs"
                     :button-disabled="storeProject.projectGetters.description === null || storeProject.projectGetters.description === ''"
                     :currentObject="storeProject.projectGetters"
                     :fields="arrayFieldsToTextMining"
                     :model="'category'" :id="storeProject.projectGetters.id"></ComponentText>
    </div>
  </div>
  <div :class="{'container_sub_detail': showTabs}" id="container-sub-detail" class="container-sub-detail">
    <div v-if="!showTabs" class="text-16px-dark-grey-medium">
      {{ textDynamic }}
      <span v-if="storeProject.projectGetters"
            class="text-16px-primary-medium">{{ storeProject.projectGetters.name }}</span>
      <button v-if="storeProject.projectGetters && storeAuth.userGetters && storeAuth.userGetters.user_type === 'ad'"
              @click="showAddDocument = true" class="btn btn-primary-custom btn-primary-custom-child">Carica
        documento
      </button>
    </div>
    <div :class="{'container_buttons': showTabs}" v-if="!showTabs || showDocuments"
         class="container-buttons container-buttons-documents">
      <div class="container-folders">
        <div @click="setFolder('d')" class="container-single-folder">
          <div class="container-img">
            <svg v-if="storeProject.projectGetters" width="22" height="22" viewBox="0 0 22 22" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M18.1545 8.24823C18.1545 7.64034 17.6617 7.14754 17.0538 7.14754H9.89936L8.57854 5.38644C8.37067 5.10928 8.04444 4.94617 7.69799 4.94617H4.94627C4.33838 4.94617 3.84558 5.43896 3.84558 6.04685V15.953C3.84558 16.5609 4.33838 17.0537 4.94627 17.0537H17.0538C17.6617 17.0537 18.1545 16.5609 18.1545 15.953V8.24823Z"
                    :stroke="storeProject.projectGetters.color" stroke-width="1.5"/>
            </svg>

          </div>
          <div :class="{'container_name_folder': currentFolder === 'd'}"
               class="container-name-folder text-16px-black-regular">Documentazione
          </div>
        </div>
        <div @click="setFolder('e')" class="container-single-folder">
          <div class="container-img">
            <svg v-if="storeProject.projectGetters" width="22" height="22" viewBox="0 0 22 22" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M18.1545 8.24823C18.1545 7.64034 17.6617 7.14754 17.0538 7.14754H9.89936L8.57854 5.38644C8.37067 5.10928 8.04444 4.94617 7.69799 4.94617H4.94627C4.33838 4.94617 3.84558 5.43896 3.84558 6.04685V15.953C3.84558 16.5609 4.33838 17.0537 4.94627 17.0537H17.0538C17.6617 17.0537 18.1545 16.5609 18.1545 15.953V8.24823Z"
                    :stroke="storeProject.projectGetters.color" stroke-width="1.5"/>
            </svg>

          </div>
          <div :class="{'container_name_folder': currentFolder === 'e'}"
               class="container-name-folder text-16px-black-regular">Preventivi
          </div>
        </div>
        <div @click="setFolder('s')" class="container-single-folder">
          <div class="container-img">
            <svg v-if="storeProject.projectGetters" width="22" height="22" viewBox="0 0 22 22" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M18.1545 8.24823C18.1545 7.64034 17.6617 7.14754 17.0538 7.14754H9.89936L8.57854 5.38644C8.37067 5.10928 8.04444 4.94617 7.69799 4.94617H4.94627C4.33838 4.94617 3.84558 5.43896 3.84558 6.04685V15.953C3.84558 16.5609 4.33838 17.0537 4.94627 17.0537H17.0538C17.6617 17.0537 18.1545 16.5609 18.1545 15.953V8.24823Z"
                    :stroke="storeProject.projectGetters.color" stroke-width="1.5"/>
            </svg>

          </div>
          <div :class="{'container_name_folder': currentFolder === 's'}"
               class="container-name-folder text-16px-black-regular">Doc. Scientifica
          </div>
        </div>


        <div v-click-outside="closeDropdownMenu" class="container-single-folder" ref="container"
             v-if="storeDocument.projectHasProjectSheetGetters && !isLoadingProjectSheet"
             @click="showMenu = !showMenu">
          <div class="container-img">
            <svg v-if="storeProject.projectGetters" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M13.0867 19.5047H5.49599C4.94336 19.5047 4.49536 19.0567 4.49536 18.5041V5.49599C4.49536 4.94336 4.94336 4.49536 5.49599 4.49536H18.5041C19.0567 4.49536 19.5047 4.94336 19.5047 5.49599V13.0867C19.5047 13.3519 19.3993 13.6063 19.2119 13.7938L13.7938 19.2119C13.6063 19.3993 13.3519 19.5047 13.0867 19.5047Z"
                    :stroke="storeProject.projectGetters.color" stroke-width="1.5"/>
              <path d="M13.501 19.4147V14.5016C13.501 13.949 13.949 13.501 14.5016 13.501H19.4147"
                    :stroke="storeProject.projectGetters.color"
                    stroke-width="1.5"/>
            </svg>

          </div>
          <div class="container-name-folder text-16px-black-regular">
            {{ storeDocument.projectHasProjectSheetGetters.name }}
          </div>
          <div :class="{menu_container: !showMenu}" class="menu-container"
               ref="menuContainer">
            <div v-show="showMenu" :style="{ top: '-20px', left: '5px' }" class="menu" ref="menu">
              <div class="container-content-menu">
                <div class="container-single-content-menu text-16px-primary-bold"
                     @click="editProjectSheet = true, closeDropdownMenu">
                  Modifica scheda
                </div>
                <div class="container-single-content-menu text-16px-primary-bold" @click="downloadProjectSheet">
                  Scarica
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--          <div class="container-single-folder">-->
        <!--            -->
        <!--          </div>-->


        <div v-if="!storeDocument.projectHasProjectSheetGetters && !isLoadingProjectSheet"
             v-b-popover.hover="{ customClass: 'my-popover-class', content: 'È necessario caricare la Scheda Progettuale' }"
             class="container-single-folder">
          <div class="container-img">
            <img src="../../assets/shared/notes-paper.svg" alt="">
          </div>
          <div class="container-name-folder text-16px-black-danger">Scheda progettuale assente</div>
        </div>
      </div>
    </div>
    <span v-if="!showTabs || showDocuments" class="text-16px-black-bold">{{ returnName() }}</span>
  </div>
  <b-modal :hide-footer="true" :hide-header="true" @hide="closeModal" v-model="showWiki">
    <ComponentDetailWiki :id-prop="storeProject.projectGetters.wiki" :entity="'project'" v-if="showWiki"
                         @closeModal="closeModal"></ComponentDetailWiki>
  </b-modal>
  <b-modal :hide-footer="true" :hide-header="true" @hide="closeModal" v-model="showAddDocument">
    <div class="container-head-form">
      <div class="container-content-head-form">
        <div v-if="storeProject.projectGetters" class="text-16px-black-bold">Aggiungi documento in <span
            class="text-16px-primary-bold">{{ storeProject.projectGetters.name }}</span></div>
      </div>
      <img @click="showAddDocument = false" class="img-close" src="../../assets/shared/close.svg" alt="">
    </div>
    <ComponentDynamicForm @close-form-without-update="showAddDocument = false" v-if="showAddDocument"
                          @closeForm="closeModal" :options="{
      endpoints:
      [
          {
            'endpoint': 'documents',
            'type': TYPE_OF_OPTION.CREATE,
            'path': [
              'documents'
            ]
          }
      ]
      }"></ComponentDynamicForm>
  </b-modal>
  <b-modal :hide-footer="true" :hide-header="true" @hide="editProjectSheet = false" v-model="editProjectSheet">
    <div class="container-head-form">
      <div class="container-content-head-form">
        <div v-if="storeProject.projectGetters && storeDocument.projectHasProjectSheetGetters"
             class="text-16px-black-bold">Modifica scheda <span
            class="text-16px-primary-bold">{{ storeDocument.projectHasProjectSheetGetters.name }}</span></div>
      </div>
      <img @click="editProjectSheet = false" class="img-close" src="../../assets/shared/close.svg" alt="">
    </div>
    <ComponentDynamicForm @close-form-without-update="editProjectSheet = false"
                          v-if="editProjectSheet && storeDocument.projectHasProjectSheetGetters" @closeForm="closeModal"
                          :currentObject="{0: storeDocument.projectHasProjectSheetGetters}" :options="{
      endpoints:
      [
          {
            'endpoint': 'documents',
            'type': TYPE_OF_OPTION.UPDATE,
            'path': [
              'documents',
              {'objectID': storeDocument.projectHasProjectSheetGetters.id}

            ]
          }
      ]
      }"></ComponentDynamicForm>
  </b-modal>

  <ComponentListDocuments @setAddDocument="showAddDocument = true"
                          v-if="storeProject.projectGetters && (showDocuments && !showManage) || (!showTabs && storeProject.projectGetters)"
                          :currentFolder="currentFolder"></ComponentListDocuments>


  <ComponentFloatingActionButton @setBooleanModal="setBooleanModal" v-if="showManage && innerWidth <= 991.98"
                                 :actions="actions"></ComponentFloatingActionButton>
</template>

<script>
import {useProjectStore} from "@/stores/projects";
import moment from "moment/moment";
import ComponentDynamicForm from "@/components/form/ComponentDynamicForm";
import ComponentDetailWiki from "@/components/wiki/ComponentDetailWiki";
import {TYPE_OF_OPTION} from "@/globals";
import {useUtilsStore} from "@/stores/utils";
import {useCategoryStore} from "@/stores/categories";
import {useSubcategoryStore} from "@/stores/subcategories";
import ComponentListDocuments from "@/components/document/ComponentListDocuments";
import {useDocumentStore} from "@/stores/documents";
import {useAuthStore} from "@/stores/auth";
import {useFavoriteStore} from "@/stores/favorites";
import ComponentFloatingActionButton from "@/components/floatingActionButton/ComponentFloatingActionButton";
import {pages} from "../../../vue.config";
import ComponentText from "@/components/shared/ComponentText";

export default {
  name: "ComponentDetailProject",
  components: {
    ComponentText,
    ComponentFloatingActionButton, ComponentListDocuments, ComponentDetailWiki, ComponentDynamicForm
  },
  setup() {
    const storeProject = useProjectStore()
    const storeAuth = useAuthStore()
    const storeFavorite = useFavoriteStore()
    const storeDocument = useDocumentStore()
    return {
      storeProject,
      storeAuth,
      storeFavorite,
      storeDocument,
      TYPE_OF_OPTION
    }
  },
  data() {
    return {
      arrayFieldsToTextMining: [],
      actions: [],
      innerWidth: window.innerWidth,
      showManage: false,
      showTabs: false,
      showShare: false,
      showDocuments: false,
      showMenu: false,
      posX: 0,
      posY: 0,
      currentFolder: '',
      textDynamic: 'Cartelle e file in ',
      currentObj: null,
      showAddDocument: false,
      isEditing: false,
      editProjectSheet: false,
      showWiki: false,
      isLoadingProjectSheet: false
    }
  },
  async created() {
    await this.loadDetail()
    await this.checkProjectSheet()
  },
  watch: {
    editProjectSheet: {
      handler: function () {
        //if (!e)
        // useDocumentStore().getDocuments({
        //   queryParams: {
        //     ordering: '-created_at',
        //     project__in: this.storeProject.projectGetters.id
        //   }
        // })
        //     .then(() => {
        //       this.checkProjectSheet()
        //       this.setFolder('')
        //     })
        //     .catch(() => {
        //     })
      }
    },
    infoForToDelete: {
      handler: function (e) {
        if (!e.confirmDelete && e.actionAfterDelete.action)
          this.checkProjectSheet()
      }, deep: true
    },
    showAddDocument: {
      handler: function (value) {
        if (!value)
          useDocumentStore().getDocuments({
            queryParams: {
              ordering: '-created_at',
              project__in: this.storeProject.projectGetters.id
            }
          })
              .then(() => {
                this.checkProjectSheet()
                this.setFolder('')
              })
              .catch(() => {
              })
      }
    },
    project: {
      handler: function (val) {
        /*** costruisco le actions da passare al floating button in base ai permessi ***/
        this.actions = []

        if (this.storeAuth.userGetters && this.storeAuth.userGetters.user_type === 'ad')
          this.actions.push({action: 'edit', entity: 'project'})

        this.actions.push({
          action: 'favorite',
          entity: 'project',
          is_favorite: val.is_favorite
        })

        if (this.storeAuth.userGetters && this.storeAuth.userGetters.user_type === 'ad')
          this.actions.push({action: 'delete', entity: 'project'})
        /*** ***/
      }
    }
  },
  computed: {
    documents() {
      return useDocumentStore().documentsGetters
    },
    project() {
      return useProjectStore().projectGetters
    },
    infoForToDelete() {
      return useUtilsStore().infoForToDeleteGetters
    }
  },
  mounted() {
    document.title = 'Dettaglio progetto' + ' | ' + pages.index.title
    if (window.innerWidth < 991.98) {
      this.showTabs = true
      this.showManage = true
      this.showDocuments = false

    } else {
      this.showTabs = false
      this.showManage = false
      this.showDocuments = false
    }

    this.setStickyPositions()
    window.addEventListener("resize", this.handleResize)
    window.addEventListener("scroll", this.setStickyPositions)

    if (this.$refs.container)
      this.$refs.container.addEventListener('contextmenu', (event) => {
        event.preventDefault();
        if (this.$refs.menuContainer.contains(event.target)) {
          this.posX = event.pageX;
          this.posY = event.pageY;
          this.showMenu = true;
        }
      });
  },
  methods: {
    closeDropdownMenu() {
      this.showMenu = false
    },
    handleResize() {
      this.innerWidth = window.innerWidth
      if (window.innerWidth < 991.98) {
        this.showTabs = true
      } else {
        this.showTabs = false
        this.showManage = false
        this.showDocuments = false
      }
      if (useAuthStore().authenticatedGetters)
        this.setStickyPositions()
    },
    setBooleanModal(event) {
      if (event.action === 'favorite' && event.entity === 'project' && event.is_favorite) {
        this.deleteFavorite()
      }
      if (event.action === 'favorite' && event.entity === 'project' && !event.is_favorite)
        this.storeFavorite.setStatusFavorites({
          params: this.storeProject.projectGetters.id,
          action: this.storeProject.getProject,
          object: this.storeProject.projectGetters,
          entity: 'project'
        })

      if (event.action === 'edit' && event.entity === 'project')
        this.isEditing = true
      if (event.action === 'delete' && event.entity === 'project')
        this.deleteProject()
    },
    deleteFavorite() {
      let item = {
        id: this.storeProject.projectGetters.favorite_id
      }
      useFavoriteStore().currentKeyDeleting = this.keyFavorite
      useUtilsStore().infoForToDelete['currentPosition'] = 'detail'
      useUtilsStore().infoForToDelete['confirmDelete'] = true
      useUtilsStore().infoForToDelete['action'] = this.storeFavorite.deleteFavorite
      useUtilsStore().infoForToDelete['actionAfterDelete']['action'] = this.storeProject.getProject
      useUtilsStore().infoForToDelete['actionAfterDelete']['payload'] = this.storeProject.projectGetters.id
      useUtilsStore().infoForToDelete['typeObjectToDelete'] = 'single'
      useUtilsStore().infoForToDelete['entity'] = 'favorite'
      useUtilsStore().infoForToDelete['name'] = 'preferito'
      useUtilsStore().infoForToDelete['objectToDelete'] = item
      useUtilsStore().infoForToDelete['messageToDelete']['is'] = 'div'
      useUtilsStore().infoForToDelete['messageToDelete']['innerHTML'] = "Sei sicuro di voler rimuovere dai preferiti il progetto " +
          "<span style='color: var(--primary-color)'>" + this.storeProject.projectGetters.name +
          "</span>" + "?"
    },
    setStickyPositions() {
      let container_content_header
      if (document.getElementById('container-content-header') && document.getElementById('container-content-header-mobile') && window.getComputedStyle(document.getElementById('container-content-header')).display === 'none')
        container_content_header = document.getElementById('container-content-header-mobile')
      else
        container_content_header = document.getElementById('container-content-header')
      let container_sub_detail = document.getElementById('container-sub-detail')
      if (container_content_header) {
        if (container_sub_detail) {
          container_sub_detail.style.top = container_content_header.getBoundingClientRect().height + 'px'
        }
      }
    },
    downloadProjectSheet() {
      useDocumentStore().currentContentType = null;
      useDocumentStore().downloadDocument(useDocumentStore().projectHasProjectSheetGetters.document_file)
          .then(res => {
            const url = URL.createObjectURL(res.data);
            const link = document.createElement('a');
            link.href = url;
            link.download = useDocumentStore().projectHasProjectSheetGetters.name; // Replace with your desired file name
            link.click();
            this.closeDropdownMenu()
          })
          .catch(() => {
            // handle error
          });
    },
    hideMenu() {
      if (!this.$refs.menuContainer.contains(event.target)) {
        this.showMenu = false;
      }
    },
    async checkProjectSheet() {
      if (this.isLoadingProjectSheet) return // evita di caricare nuovi dati se un caricamento è già in corso
      this.isLoadingProjectSheet = true // indica che un caricamento è in corso

      let queryParams = {
        project__in: useProjectStore().projectGetters ? useProjectStore().projectGetters.id : null,
        type: 'p'
      }
      await useDocumentStore().checkProjectSheet({queryParams: queryParams})
          .then(() => {
            this.isLoadingProjectSheet = false
          })
          .catch(() => {
            this.isLoadingProjectSheet = false
          })
    },
    returnName() {
      let name = 'Ultimi caricamenti'
      if (this.currentFolder === 's')
        name = 'Documentazione scientifica'
      else if (this.currentFolder === 'd')
        name = 'Documentazione'
      else if (this.currentFolder === 'e')
        name = 'Preventivi'
      return name
    },
    setFolder(type) {
      this.showMenu = false
      if (type === 's')
        this.textDynamic = 'File nella cartella Doc. scientifica del '
      if (type === 'd')
        this.textDynamic = 'File nella cartella Documentazione del '
      if (type === 'e')
        this.textDynamic = 'File nella cartella Preventivi del '
      if (this.currentFolder !== type) {
        this.currentFolder = type
      } else {
        this.textDynamic = 'Cartelle e file in '
        this.currentFolder = ''
      }
    },
    deleteProject() {
      let item = useProjectStore().projectGetters
      useUtilsStore().infoForToDelete['currentPosition'] = 'detail'
      useUtilsStore().infoForToDelete['confirmDelete'] = true
      useUtilsStore().infoForToDelete['action'] = this.storeProject.deleteProject
      useUtilsStore().infoForToDelete['actionAfterDelete'].action = null
      useUtilsStore().infoForToDelete['typeObjectToDelete'] = 'single'
      useUtilsStore().infoForToDelete['entity'] = 'projects'
      useUtilsStore().infoForToDelete['name'] = 'progetto'
      useUtilsStore().infoForToDelete['objectToDelete'] = item
      useUtilsStore().infoForToDelete['messageToDelete']['is'] = 'div'
      useUtilsStore().infoForToDelete['messageToDelete']['innerHTML'] = "Sei sicuro di voler eliminare definitivamente il progetto  " +
          "<span style='color: var(--primary-color)'>" + useProjectStore().projectGetters.name +
          "</span>" + "? L’operazione non è reversibile."
    },
    async loadDetail() {
      await useProjectStore().getProject(parseInt(this.$route.params.projectId))
          .then(async res => {
            this.showShare = false
            /*** costruisco array di fields da analizzare per il text mining ***/
            this.arrayFieldsToTextMining = []
            if (res.data.description !== null)
              this.arrayFieldsToTextMining.push({
                name_db: 'description',
                value: 'Descrizione progetto'
              })
            /*** ***/

            if ('categoryId' in this.$route.params) {
              await useCategoryStore().getCategory(this.$route.params.categoryId)
            }
            if ('subcategoryId' in this.$route.params) {
              await useSubcategoryStore().getSubcategory(this.$route.params.subcategoryId)
            }
            /*** costruisco le actions da passare al floating button in base ai permessi ***/
            this.actions = []

            if (this.storeAuth.userGetters && this.storeAuth.userGetters.user_type === 'ad')
              this.actions.push({action: 'edit', entity: 'project'})

            this.actions.push({
              action: 'favorite',
              entity: 'project',
              is_favorite: res.data.is_favorite
            })

            if (this.storeAuth.userGetters && this.storeAuth.userGetters.user_type === 'ad')
              this.actions.push({action: 'delete', entity: 'project'})
            /*** ***/

            useUtilsStore().chronology = []
            useUtilsStore().chronologyGetters.push({
              route_name: 'archive',
              route_id: null,
              name: 'Archivio',
              display_name: 'Archivio'
            })
            if (useProjectStore().fromEntityGetters.name === 'subcategory' || this.$route.params.subcategoryId) {
              if (useCategoryStore().categoryGetters) {
                useUtilsStore().chronologyGetters.push({
                  route_name: 'detailCategory',
                  route_id: useCategoryStore().categoryGetters.id,
                  name: 'Dettaglio categoria',
                  display_name: useCategoryStore().categoryGetters.name + ' [' + useCategoryStore().categoryGetters.id + ']'
                })
                if ('subcategoryId' in this.$route.params) {
                  if (useSubcategoryStore().subcategoryGetters) {
                    useUtilsStore().chronologyGetters.push({
                      route_name: 'detailSubcategory',
                      categoryId: useCategoryStore().categoryGetters.id,
                      route_id: useSubcategoryStore().subcategoryGetters.id,
                      name: 'Dettaglio sottocategoria',
                      display_name: useSubcategoryStore().subcategoryGetters.name + ' [' + useSubcategoryStore().subcategoryGetters.id + ']'
                    })
                    useUtilsStore().chronologyGetters.push({
                      route_name: 'detailProjectFromSubcategory',
                      categoryId: useCategoryStore().categoryGetters.id,
                      subcategoryId: useSubcategoryStore().subcategoryGetters.id,
                      route_id: this.$route.params.projectId,
                      name: 'Dettaglio progetto',
                      display_name: useProjectStore().projectGetters.name + ' [' + this.$route.params.projectId + ']'
                    })
                  } else {
                    useSubcategoryStore().getSubcategory(this.$route.params.subcategoryId)
                        .then(res => {
                          useUtilsStore().chronologyGetters.push({
                            route_name: 'detailSubcategory',
                            categoryId: useCategoryStore().categoryGetters.id,
                            route_id: res.data.id,
                            name: 'Dettaglio sottocategoria',
                            display_name: res.data.name + ' [' + res.data.id + ']'
                          })
                        })
                  }
                }

              } else {
                useCategoryStore().getCategory(this.$route.params.categoryId)
                    .then(resp => {
                      useUtilsStore().chronologyGetters.push({
                        route_name: 'detailCategory',
                        route_id: resp.data.id,
                        name: 'Dettaglio categoria',
                        display_name: resp.data.name + ' [' + resp.data.id + ']'
                      })
                      if ('subcategoryId' in this.$route.params) {
                        if (useSubcategoryStore().subcategoryGetters) {
                          useUtilsStore().chronologyGetters.push({
                            route_name: 'detailSubcategory',
                            categoryId: resp.data.id,
                            route_id: useSubcategoryStore().subcategoryGetters.id,
                            name: 'Dettaglio sottocategoria',
                            display_name: useSubcategoryStore().subcategoryGetters.name + ' [' + useSubcategoryStore().subcategoryGetters.id + ']'
                          })

                          useUtilsStore().chronologyGetters.push({
                            route_name: 'detailProjectFromSubcategory',
                            categoryId: resp.data.id,
                            subcategoryId: useSubcategoryStore().subcategoryGetters.id,
                            route_id: this.$route.params.projectId,
                            name: 'Dettaglio progetto',
                            display_name: useProjectStore().projectGetters.name + ' [' + this.$route.params.projectId + ']'
                          })
                        } else {
                          useSubcategoryStore().getSubcategory(this.$route.params.subcategoryId)
                              .then(respp => {
                                useUtilsStore().chronologyGetters.push({
                                  route_name: 'detailSubcategory',
                                  categoryId: resp.data.id,
                                  route_id: respp.data.id,
                                  name: 'Dettaglio sottocategoria',
                                  display_name: respp.data.name + ' [' + respp.data.id + ']'
                                })

                                useUtilsStore().chronologyGetters.push({
                                  route_name: 'detailProjectFromSubcategory',
                                  categoryId: resp.data.id,
                                  subcategoryId: respp.data.id,
                                  route_id: this.$route.params.projectId,
                                  name: 'Dettaglio progetto',
                                  display_name: useProjectStore().projectGetters.name + ' [' + this.$route.params.projectId + ']'
                                })
                              })
                        }
                      }
                    })
              }
            } else if (useProjectStore().fromEntityGetters.name === 'category' || this.$route.params.categoryId) {
              if (useCategoryStore().categoryGetters) {
                useUtilsStore().chronologyGetters.push({
                  route_name: 'detailCategory',
                  route_id: useCategoryStore().categoryGetters.id,
                  name: 'Dettaglio categoria',
                  display_name: useCategoryStore().categoryGetters.name + ' [' + useCategoryStore().categoryGetters.id + ']'
                })
                useUtilsStore().chronologyGetters.push({
                  route_name: 'detailProjectFromCategory',
                  route_id: this.$route.params.projectId,
                  categoryId: useCategoryStore().categoryGetters.id,
                  name: 'Dettaglio progetto',
                  display_name: useProjectStore().projectGetters.name + ' [' + this.$route.params.projectId + ']'
                })
              } else {
                useCategoryStore().getCategory(this.$route.params.categoryId)
                    .then(resp => {
                      useUtilsStore().chronologyGetters.push({
                        route_name: 'detailCategory',
                        route_id: resp.data.id,
                        name: 'Dettaglio categoria',
                        display_name: resp.data.name + ' [' + resp.data.id + ']'
                      })
                      useUtilsStore().chronologyGetters.push({
                        route_name: 'detailProjectFromCategory',
                        categoryId: resp.data.id,
                        route_id: res.data.id,
                        name: 'Dettaglio progetto',
                        display_name: useProjectStore().projectGetters.name + ' [' + this.$route.params.projectId + ']'
                      })
                    })
              }
            }
            useUtilsStore().currentNamePage = res.data.name
            this.currentObj = Object.assign({}, res.data)
            this.isEditing = false

            await this.checkProjectSheet()
          })
          .catch(() => {
          })
    },
    returnDate(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    closeModal() {
      if (this.isEditing)
        this.loadDetail()
      this.checkProjectSheet()

      this.showWiki = false
      this.storeDocument.projectHasProjectSheet = null
      this.editProjectSheet = false
      this.showAddDocument = false
      this.currentObj = Object.assign({}, null)
    }
  },
  updated() {
  },
  beforeUnmount() {
    if (useProjectStore().controllerGetProjectGetters)
      useProjectStore().controllerGetProjectGetters.abort()
    useProjectStore().project = null
    window.removeEventListener("scroll", this.setStickyPositions)
    window.removeEventListener("resize", this.handleResize)
    if (this.$refs.container)
      this.$refs.container.removeEventListener('contextmenu', () => {
      })
  },
  unmounted() {
    window.removeEventListener("scroll", this.setStickyPositions)
    window.removeEventListener("resize", this.handleResize)
  }
}
</script>

<style scoped>
.div-container-wiki {
  margin-right: 20px;
}

.container_tabs.container-tabs span {
  margin: 0px auto;
}

.menu {
  position: absolute;
  background: var(--white-color);
  border: 1px solid var(--light-grey-color);
  box-shadow: 0px 2px 15px rgba(198, 198, 198, 0.25);
  border-radius: 4px;
  padding: 10px 15px;
  width: auto;
  z-index: 1000;
}

.text-16px-primary-bold.container-single-content-menu {
  color: var(--primary-color) !important;
}

.container-single-content-menu {
  display: flex;
  white-space: nowrap;
  align-items: center;
  margin: 5px 0px;
}

.container-content-menu {
  display: flex;
  flex-direction: column;
}

.menu_container.menu-container {
  min-width: 0px;
}

.menu-container {
  display: flex;
  position: relative;
}

.container_sub_detail.container-sub-detail {
  border-top: unset;
  margin: 0px 15px 10px 15px;
  padding-top: 0px;
}

.container-sub-detail {
  position: sticky;
  display: flex;
  background: var(--white-color);
  margin: 10px 15px;
  padding-top: 10px;
  z-index: 2;
  border-top: 1px solid var(--light-grey-color);
  flex-direction: column;
}

.container-img {
  height: 100%;
  padding: 9.5px;
  display: flex;
  align-items: center;
  background: var(--dark-white-color);
  border-radius: 4px 0px 0px 4px;
}

.container_name_folder.container-name-folder {
  background: var(--primary-color-light);
}

.container-single-folder {
  display: flex;
  align-items: center;
  margin: 0px 10px 10px 0px;
  cursor: pointer;
}

.container-folders {
  display: flex;
  flex-wrap: wrap;
}

.container-single-child-detail {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#container-buttons-projects {
  background: var(--white-color);
  position: sticky;
  z-index: 1;
}

.btn-primary-custom-child.btn-primary-custom {
  margin-left: 10px;
}

.text-tags {
  margin-right: 5px;
  margin-bottom: 5px;
}

.container-favorites span {
  white-space: nowrap;
}

.container-favorites img {
  margin-right: 5px;
}

.container-shared img {
  margin-right: 5px;
}

.container-shared {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.container-favorites {
  display: flex;
  cursor: pointer;
  align-items: center;
  margin: 0px 10px;
}

.container_favorites.container-favorites {
  margin-left: 0px;
}

.container-buttons span {
  cursor: pointer;
}

.container-name-folder {
  display: flex;
  height: 100%;
  align-items: center;
  white-space: nowrap;
  padding: 10px;
  background: var(--white-color);
  border: 1px solid var(--light-grey-color);
  border-radius: 0px 4px 4px 0px;
}


.container_buttons.container-buttons {
  margin: 0px 0px 10px 0px;
}

.container-buttons-documents {
  margin: 15px 0px;
}

.container-buttons {
  display: flex;
  align-items: center;
}

.container-tabs span {
  margin-right: 25px;
  cursor: pointer;
}

.container-tabs {
  margin: 10px 0px;
}


.container-content-detail-project {
  display: flex;
}

.container-content-bottom-right-head-detail {
  display: flex;
  margin-top: 25px;
  flex-direction: column;
}

.container-content-top-right-head-detail {
  display: flex;
  flex-direction: column;
}

.container-content-right-head-detail {
  display: flex;
  align-items: flex-start;
  margin-left: auto;
}

.container-content-left-head-detail {
  display: grid;
  margin: 0px 0px 5px 0px;
  column-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
}

.container-head-detail {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
}


.container-detail-project {
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
}


@media (max-width: 575.98px) {
}

/*// Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
  .container-content-right-head-detail {
    margin-left: 0px;
  }

  .container-content-detail-project {
    flex-direction: column;
  }
}

/*// Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {

}

/*// Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {
}

/*// X-Large devices (large desktops, less than 1400px)*/
@media (max-width: 1399.98px) {
}
</style>