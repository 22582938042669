<template>
  <div class="container-users">
    <div id="container-head-settings-right" class="container-content-right-settings-custom">
      <div class="container-head-utenti">
        <span class="text-24px-black-bold">Utenti</span>
        <div style="display: flex; width: 100%;align-items: center">
          <div v-if="storeUser.usersGetters"
               class="container-single-buttons container-single-buttons-dark-grey text-14px-dark-grey-regular">
            {{ storeUtils.genericCountListGetters }} utenti
          </div>
          <div v-if="innerWidth <= 991.98" class="container-filter container-filter-no-background">
            <img v-show="!storeUtils.checkIfThereAreActiveFilters('users')"
                 src="../../assets/shared/settings-filter.svg" alt="">
            <img v-show="storeUtils.checkIfThereAreActiveFilters('users')"
                 src="../../assets/shared/settings-filter-active.svg" alt="">
            <span :class="{'text-active-filters': storeUtils.checkIfThereAreActiveFilters('users')}"
                  class="text-12px-dark-grey-medium">Filtri</span>
          </div>
        </div>
        <!--          <span class="text-16px-black-regular text-overflow-custom">Lorem ipsum dolor sit amet consectetur. Cursus nunc quam magna vulputate convallis. Massa libero est-->
        <!--        pharetra integer nisl. Amet laoreet aliquet nascetur risus nulla viverra arcu congue. Vel enim non tempus.</span>-->

      </div>

    </div>
    <div class="container-content-users">
      <div id="container-head-user" class="container-head-user">

        <b-form-input v-on:input="debounce(research)" v-model="storeUtils.search" class="form-control search-bar"
                      placeholder="Cerca"></b-form-input>
        <div v-if="innerWidth > 991.98 && storeAuth.userGetters && storeAuth.userGetters.user_type === 'ad'"
             class="container-all-filter">
          <button @click="showConfirmDelete($event,null,'bulk')" v-if="selectedUsers.length > 0"
                  class="btn btn-danger-custom">Elimina
          </button>
          <div @click="showFilters = true" class="container-filter">
            <span :class="{'text-active-filters': storeUtils.checkIfThereAreActiveFilters('users')}"
                  class="text-12px-dark-grey-medium">Filtri</span>
            <img v-show="!storeUtils.checkIfThereAreActiveFilters('users')"
                 src="../../assets/shared/settings-filter.svg" alt="">
            <img v-show="storeUtils.checkIfThereAreActiveFilters('users')"
                 src="../../assets/shared/settings-filter-active.svg" alt="">
          </div>
        </div>
      </div>
      <div style="width: 100%" v-if="storeUser.usersGetters && !isLoading"
           :class="{'table-responsive': innerWidth < 991.98}">
        <table>
          <thead id="thead">
          <!--          <tr>-->
          <th @change="changeSelectedAll">
            <b-form-checkbox @change="changeSelectedAll" v-model="selectedAll"></b-form-checkbox>
          </th>
          <th>

            <div class="container-th">
              ID
              <img v-show="id === ''" @click="ordering('id','th_id')" class="img-arrow-ordering"
                   src="../../assets/shared/arrow-drop-down-ordering.svg" alt="">

              <img @click="ordering('-id','th_id')" v-show="id === 'id'" class="img-arrow-ordering"
                   src="../../assets/shared/arrow-drop-down-ordering-primary.svg" alt="">
              <img class="img-arrow-ordering img-arrow-ordering-rotate" @click="ordering('','th_id')"
                   v-show="id === '-id'"
                   src="../../assets/shared/arrow-drop-down-ordering-primary.svg" alt="">
            </div>
          </th>
          <th>
            <div class="container-th">
              Nome
              <img v-if="user_detail__first_name === ''"
                   @click="ordering('user_detail__first_name','th_user_detail__first_name')" class="img-arrow-ordering"
                   src="../../assets/shared/arrow-drop-down-ordering.svg" alt="">

              <img @click="ordering('-user_detail__first_name','th_user_detail__first_name')"
                   v-show="user_detail__first_name === 'user_detail__first_name'" class="img-arrow-ordering"
                   src="../../assets/shared/arrow-drop-down-ordering-primary.svg" alt="">
              <img class="img-arrow-ordering img-arrow-ordering-rotate"
                   @click="ordering('','th_user_detail__first_name')"
                   v-show="user_detail__first_name === '-user_detail__first_name'"
                   src="../../assets/shared/arrow-drop-down-ordering-primary.svg" alt="">
            </div>
          </th>
          <th>
            <div class="container-th">
              Cognome
              <img v-show="user_detail__last_name === ''"
                   @click="ordering('user_detail__last_name','th_user_detail__last_name')" class="img-arrow-ordering"
                   src="../../assets/shared/arrow-drop-down-ordering.svg" alt="">

              <img @click="ordering('-user_detail__last_name','th_user_detail__last_name')"
                   v-show="user_detail__last_name === 'user_detail__last_name'" class="img-arrow-ordering"
                   src="../../assets/shared/arrow-drop-down-ordering-primary.svg" alt="">
              <img class="img-arrow-ordering img-arrow-ordering-rotate"
                   @click="ordering('','th_user_detail__last_name')"
                   v-show="user_detail__last_name === '-user_detail__last_name'"
                   src="../../assets/shared/arrow-drop-down-ordering-primary.svg" alt="">
            </div>
          </th>
          <th>Email</th>
          <th>Stato</th>
          <th></th>
          <!--          </tr>-->
          </thead>
          <tbody>
          <tr :class="{'tr-active': selectedUsers.includes(user)}" @click="goToDetail($event,user)"
              v-for="(user,index) in storeUser.usersGetters.results" :key="index">
            <td>
              <div class="form-check cursor-disabled">
                <!--                TODO CONTROLLARE LA NUOVA CONDIZIONE-->
                <input
                    :disabled="user.id === storeAuth.userGetters.user_id"
                    class="form-check-input" type="checkbox"
                    @click="stopPropagation" :value="user"
                    v-model="selectedUsers">
              </div>
            </td>
            <td>{{ user.id }}</td>
            <td v-if="user.user_detail">{{ user.user_detail.first_name }}</td>
            <td v-else></td>
            <td v-if="user.user_detail">{{ user.user_detail.last_name }}</td>
            <td v-else></td>
            <td>{{ user.email }}</td>
            <td class="td-flex">
              <div v-if="user.is_active" class="container-status-success">
                Attivo
              </div>
              <div v-else class="container-status-light-grey">
                Inattivo
              </div>
            </td>
            <td>
              <img
                  v-if="storeAuth.userGetters && storeAuth.userGetters.user_type === 'ad' && user.id !== storeAuth.userGetters.user_id"
                  @click="showConfirmDelete($event,user,'single')"
                  src="../../assets/shared/delete-red.svg" alt="">
              <img class="cursor-disabled" v-else src="../../assets/shared/delete-grey.svg" alt="">
              <!--                 @mouseover="user['is_hovered'] = true"-->
              <!--                 @mouseout="user['is_hovered'] = false"-->
              <!--            <img v-else @mouseover="user['is_hovered'] = true" @mouseout="user['is_hovered'] = false"-->
              <!--                 @click="showConfirmDelete($event,user,'single')"-->
              <!--                 src="../../assets/shared/delete-red.svg" alt="">-->
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <b-spinner v-if="isLoading"></b-spinner>
    </div>
    <div v-if="storeApi.apiSettingsGetters && storeUser.usersGetters" class="container-footer-table">
      <span class="text-14px-grey-regular">{{ storeUser.usersGetters.count }} risultati</span>
      <b-pagination
          v-if="storeUser.usersGetters"
          v-model="storeUtils.page"
          :total-rows="storeUser.usersGetters.count"
          :per-page="storeApi.apiSettingsGetters.pagination.page_size"
          last-number
      ></b-pagination>
    </div>
    <b-modal v-model="showFilters" @hide="closeModalFilters" :hide-header="true" :hide-footer="true">
      <ComponentFilters @resetDictFilter="resetDictFilter" @closeWithoutUpdate="showFilters = false"
                        @closeModalFilters="closeModalFilters"
                        :myFilter="'users'"></ComponentFilters>
    </b-modal>
  </div>

  <ComponentFloatingActionButton :selected="selectedUsers" @setBooleanModal="setBooleanModal"
                                 v-if="innerWidth <= 991.98" :actions="actions"></ComponentFloatingActionButton>

  <b-modal :hide-footer="true" :hide-header="true" @hide="showAddGroup = false" v-model="showAddGroup">
    <div class="container-head-form">
      <div class="container-content-head-form">
        <span class="text-16px-black-bold">Aggiungi un nuovo gruppo per gli utenti selezionati</span>
      </div>
      <img @click="showAddGroup = false" class="img-close" src="../../assets/shared/close.svg" alt="">
    </div>
    <ComponentDynamicForm @close-form-without-update="showAddGroup = false" :current-object="null" v-if="showAddGroup"
                          @closeForm="showAddGroup = false" :options="{
      endpoints:
      [
          {
            'endpoint': 'groups',
            'type': TYPE_OF_OPTION.CREATE,
            'path': [
              'groups'
            ]
          }
      ]
      }"></ComponentDynamicForm>
  </b-modal>
</template>

<script>
import {useApiSettingStore} from "@/stores/apiSettings";
import {useUtilsStore} from "@/stores/utils";
import {useUserStore} from "@/stores/users";
import ComponentFilters from "@/components/filters/ComponentFilters";
import ComponentDynamicForm from "@/components/form/ComponentDynamicForm";
import {useAuthStore} from "@/stores/auth";
import {TYPE_OF_OPTION} from "@/globals"
import ComponentFloatingActionButton from "@/components/floatingActionButton/ComponentFloatingActionButton";

export default {
  name: "ComponentUsers",
  components: {ComponentFloatingActionButton, ComponentDynamicForm, ComponentFilters},

  setup() {
    const storeApi = useApiSettingStore()
    const storeAuth = useAuthStore()
    const storeUser = useUserStore()
    const storeUtils = useUtilsStore()
    return {
      storeApi,
      TYPE_OF_OPTION,
      storeAuth,
      storeUtils,
      storeUser
    }
  },
  data() {
    return {
      actions: [],
      showAddGroup: false,
      currentId: 0,
      currentObject: null,
      innerWidth: window.innerWidth,
      debounce: this.createInputTextDebounce(),
      search: '',
      selectedAll: false,
      isLoading: false,
      showFilters: false,
      selectedUsers: [],
      id: '',
      user_detail__last_name: '',
      user_detail__first_name: ''
    }
  },
  async created() {
    useUtilsStore().page = 1
    useUtilsStore().resetFilterAndOtherInfo()
    useUtilsStore().dictionaryFilterFinal['page'] = 1

    useUtilsStore().chronology = []
    useUtilsStore().chronology.push({
      route_name: 'users',
      route_id: null,
      name: 'Utenti',
      display_name: 'Utenti'
    })
    this.actions.push({
      action: 'filter',
      entity: 'user'
    })
    if (this.storeAuth.userGetters && this.storeAuth.userGetters.user_type === 'ad') {
      this.actions.push({
        type: 'bulk',
        action: 'delete',
        entity: 'user'
      })
    }
    if (this.storeAuth.userGetters && this.storeAuth.userGetters.user_type === 'ad')
      this.actions.push({
        action: 'add',
        entity: 'user'
      })

    await this.loadUsers()
  },
  watch: {
    users: {
      handler: function () {
        this.selectedUsers = []
      },
    },
    page: {
      handler: function (page) {
        this.storeUtils.dictionaryFilterFinal['page'] = page
        useUtilsStore().getInfoFromFilterOrSearchFromGenericAction({
          action: this.storeUser.getUsers,
          queryParams: this.storeUtils.dictionaryFilterFinalGetters
        })
      }
    }
  },
  computed: {
    page() {
      return useUtilsStore().pageGetters
    },
    users() {
      return useUserStore().usersGetters
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  methods: {
    setNewGroup() {
      this.showAddGroup = true
      useUtilsStore().dictionaryGenericWidgetListGetters['users'].itemsAdded = this.selectedUsers
    },
    handleResize() {
      this.innerWidth = window.innerWidth
    },
    setBooleanModal(event) {
      if (event.action === 'filter' && event.entity === 'user')
        this.showFilters = true
      if (event.action === 'delete' && event.entity === 'user')
        this.showConfirmDelete(null, null, 'bulk')
      if (event.action === 'add' && event.entity === 'group')
        this.setNewGroup()
    },
    async loadUsers() {
      if (this.isLoading) return // evita di caricare nuovi dati se un caricamento è già in corso
      this.isLoading = true // indica che un caricamento è in corso

      await useUserStore().getUsers({
        queryParams: null
      })
          .then(res => {
            this.isLoading = false
            this.showEditUser = false
            useUtilsStore().genericCountList = res.data.count
          })
          .catch(() => {
          })
    },
    research() {
      useUtilsStore().page = 1
      if (useUtilsStore().searchGetters !== '') {
        useUtilsStore().dictionaryFilterFinal['search'] = useUtilsStore().searchGetters
        this.storeUtils.getInfoFromFilterOrSearchFromGenericAction({
          queryParams: useUtilsStore().dictionaryFilterFinalGetters,
          action: this.storeUser.getUsers
        })
      } else {
        delete useUtilsStore().dictionaryFilterFinalGetters['search']
        this.storeUtils.getInfoFromFilterOrSearchFromGenericAction({
          action: this.storeUser.getUsers, queryParams: useUtilsStore().dictionaryFilterFinalGetters
        })
      }
    },
    closeModal() {
      this.showNewUser = false
      this.showEditUser = false
      this.loadUsers()
    },
    resetDictFilter() {
      this.showFilters = false
      useUtilsStore().resetFilterAndOtherInfo()
      useUtilsStore().dictionaryFilterFinal['page'] = useUtilsStore().pageGetters
      this.loadUsers()
    },
    createInputTextDebounce() {
      let timeout = null;
      return function (fnc, delayMs) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          fnc();
        }, delayMs || 500);
      };
    },
    ordering(type, th) {
      if (th === 'th_id') {
        if (type === 'id') {
          this.id = type
          this.storeUtils.ordering.delete('')
          this.storeUtils.ordering.add(this.id)
        } else if (type === '-id') {
          this.id = type
          this.storeUtils.ordering.delete('id')
          this.storeUtils.ordering.add(this.id)
        } else {
          this.id = type
          this.storeUtils.ordering.delete('-id')
          this.storeUtils.ordering.add(this.id)
        }
      }
      if (th === 'th_user_detail__first_name') {
        if (type === 'user_detail__first_name') {
          this.user_detail__first_name = type
          this.storeUtils.ordering.delete('')
          this.storeUtils.ordering.add(this.user_detail__first_name)
        } else if (type === '-user_detail__first_name') {
          this.user_detail__first_name = type
          this.storeUtils.ordering.delete('user_detail__first_name')
          this.storeUtils.ordering.add(this.user_detail__first_name)
        } else {
          this.user_detail__first_name = type
          this.storeUtils.ordering.delete('-user_detail__first_name')
          this.storeUtils.ordering.add(this.user_detail__first_name)
        }
      }
      if (th === 'th_user_detail__last_name') {
        if (type === 'user_detail__last_name') {
          this.user_detail__last_name = type
          this.storeUtils.ordering.delete('')
          this.storeUtils.ordering.add(this.user_detail__last_name)
        } else if (type === '-user_detail__last_name') {
          this.user_detail__last_name = type
          this.storeUtils.ordering.delete('user_detail__last_name')
          this.storeUtils.ordering.add(this.user_detail__last_name)
        } else {
          this.user_detail__last_name = type
          this.storeUtils.ordering.delete('-user_detail__last_name')
          this.storeUtils.ordering.add(this.user_detail__last_name)
        }
      }

      if (this.storeUtils.orderingGetters.size > 0)
        this.storeUtils.dictionaryFilterFinal['ordering'] = Array.from(this.storeUtils.orderingGetters).toString()

      useUtilsStore().getInfoFromFilterOrSearchFromGenericAction({
        action: this.storeUser.getUsers,
        queryParams: this.storeUtils.dictionaryFilterFinalGetters
      })

    },
    changeSelectedAll() {
      if (this.selectedAll)
        this.selectedUsers = useUserStore().usersGetters.results.map(user => {
          if (user.id !== this.storeAuth.userGetters.user_id)
            return user
        })
      else
        this.selectedUsers = []
    },
    closeModalFilters() {
      this.showFilters = false
    },
    showConfirmDelete(event, user, quantity) {
      if (event)
        event.stopPropagation()
      // delete custom and temporary data in single user in template
      // if (user)
      //   delete user['is_hovered']
      if (quantity === 'single') {
        useUtilsStore().infoForToDelete['currentPosition'] = ''
        useUtilsStore().infoForToDelete['confirmDelete'] = true
        useUtilsStore().infoForToDelete['typeObjectToDelete'] = 'single'
        useUtilsStore().infoForToDelete['entity'] = 'users'
        useUtilsStore().infoForToDelete['name'] = 'utente'
        useUtilsStore().infoForToDelete['action'] = this.storeUser.deleteUser
        useUtilsStore().infoForToDelete['actionAfterDelete']['action'] = this.storeUser.getUsers
        useUtilsStore().infoForToDelete['actionAfterDelete']['queryParams'] = {}
        useUtilsStore().infoForToDelete['objectToDelete'] = user
        useUtilsStore().infoForToDelete['listIds'] = []
        useUtilsStore().infoForToDelete['messageToDelete']['is'] = 'div'
        useUtilsStore().infoForToDelete['messageToDelete']['innerHTML'] = "Sei sicuro di voler eliminare definitivamente l'utente " +
            "<span style='color: var(--primary-color)'>" + user.user_detail.first_name + ' ' + user.user_detail.last_name +
            "</span>" + "? L’operazione non è reversibile."
      } else if (quantity === 'bulk') {
        useUtilsStore().infoForToDelete['currentPosition'] = ''
        useUtilsStore().infoForToDelete['confirmDelete'] = true
        useUtilsStore().infoForToDelete['typeObjectToDelete'] = 'bulk'
        useUtilsStore().infoForToDelete['entity'] = 'users'
        useUtilsStore().infoForToDelete['name'] = ''
        useUtilsStore().infoForToDelete['action'] = this.storeUtils.bulkDeleteFromList
        useUtilsStore().infoForToDelete['actionAfterDelete']['action'] = this.storeUser.getUsers
        useUtilsStore().infoForToDelete['actionAfterDelete']['queryParams'] = {}
        useUtilsStore().infoForToDelete['objectToDelete'] = null
        useUtilsStore().infoForToDelete['listIds'] = this.selectedUsers.map(el => el.id)
        useUtilsStore().infoForToDelete['messageToDelete']['is'] = 'div'
        useUtilsStore().infoForToDelete['messageToDelete']['innerHTML'] = "Sei sicuro di voler eliminare definitivamente gli " +
            "<span style='color: var(--primary-color)'>" + "utenti selezionati" + "</span>" + "? L’operazione non è reversibile."
      }
    },
    // eslint-disable-next-line no-unused-vars
    stopPropagation(event) {
      event.stopPropagation()
    },
    // eslint-disable-next-line no-unused-vars
    goToDetail(event, user) {
      event.stopPropagation()
      if (useAuthStore().userGetters && (useAuthStore().userGetters.is_superuser || useAuthStore().userGetters.user_type === 'ad' || useAuthStore().userGetters.user_id === user.id))
        this.$router.push({name: 'detailUser', params: {id: user.id}}).catch(() => {
        })
    }
  },
  updated() {
  },
  beforeUnmount() {
    useUserStore().users = null
    if (useUserStore().controllerGetUsersGetters)
      useUserStore().controllerGetUsersGetters.abort()
    window.removeEventListener('resize', this.handleResize)
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style scoped>
.spinner-border {
  margin: 10px auto !important;
}

.justify-content-start.pagination {
  margin: 0px 0px 0px auto;
}

.container-status-success, .container-status-light-grey {
  margin-right: auto;
}

.btn-danger-custom {
  margin: 0px 10px;
}

.container-filter {
  height: 100%;
}

.text-14px-primary-medium {
  cursor: pointer;
  margin: 0px 10px;
  white-space: nowrap;
}

.container-head-utenti {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.container-all-filter {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.search-bar {
  height: 100%;
}

.container-users {
  display: flex;
  flex-direction: column;
  /*padding: 10px 0px 0px 15px !important;*/
  border-radius: 4px;
  width: 100% !important;
  height: 100%;
}

.container-content-right-settings-custom {
  display: flex;
  position: sticky;
  z-index: 1;
  width: 100%;
  background: var(--white-color);
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 15px;
}

.btn-primary-custom {
  margin-left: auto;
}

.container-content-users {
  height: 100%;
  align-items: center;
  margin: 15px 10px;
  background: var(--white-color);
  box-shadow: 0px 2px 15px rgba(198, 198, 198, 0.25);
  display: flex;
  width: 100%;
  flex-direction: column;
}

.container-head-user {
  border-bottom: 1px solid #E7EAED;
  width: 100%;
  display: flex;
  align-items: center;
  position: sticky;
  z-index: 1;
  background: var(--white-color);
  padding: 10px 15px;
}

@media (max-width: 575.98px) {
}

/*// Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
  .container-users {
    padding: 0px !important;
  }

  .container-filter {
    height: auto;
    margin-top: 5px;
  }

  .container-all-filter {
    margin-top: 5px;
    flex-wrap: wrap;
  }


  .table-responsive {

  }
}

/*// Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {
  .container-content-users {
    margin: 15px 0px;
  }

  .container-numbers-secondary {
    min-width: 100%;
  }

  .container-footer-table {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

}

/*// Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {
}

/*// X-Large devices (large desktops, less than 1400px)*/
@media (max-width: 1399.98px) {
}
</style>