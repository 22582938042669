<template>
  <div class="container-projects">
    <div class="container-content-projects">
      <div id="container-head-table" class="container-head-table">
        <b-form-input v-on:input="debounce(research)" v-model="storeUtils.search"
                      class="form-control search-bar"
                      placeholder="Cerca"></b-form-input>
        <div class="container-all-filter">
          <button @click="showConfirmDelete($event,null,'bulk')"
                  v-if="selectedProjects.length > 0 && innerWidth > 991.98 && storeAuth.userGetters && storeAuth.userGetters.user_type === 'ad'"
                  class="btn btn-danger-custom">Elimina
          </button>
          <!--          <span class="text-14px-primary-medium">azione</span>-->
          <div v-if="innerWidth > 991.98" @click="showFilters = true" class="container-filter">
            <span :class="{'text-active-filters': storeUtils.checkIfThereAreActiveFilters('projects')}"
                  class="text-12px-dark-grey-medium">Filtri</span>
            <img v-show="!storeUtils.checkIfThereAreActiveFilters('projects')"
                 src="../../assets/shared/settings-filter.svg" alt="">
            <img v-show="storeUtils.checkIfThereAreActiveFilters('projects')"
                 src="../../assets/shared/settings-filter-active.svg" alt="">
          </div>
          <div v-else class="container-filter container-filter-no-background">
            <img v-show="!storeUtils.checkIfThereAreActiveFilters('projects')"
                 src="../../assets/shared/settings-filter.svg" alt="">
            <img v-show="storeUtils.checkIfThereAreActiveFilters('projects')"
                 src="../../assets/shared/settings-filter-active.svg" alt="">
            <span :class="{'text-active-filters': storeUtils.checkIfThereAreActiveFilters('projects')}"
                  class="text-12px-dark-grey-medium">Filtri</span>
          </div>
        </div>
      </div>
      <div style="width: 100%" v-if="storeProjects.projectsGetters && !isLoading"
           :class="{'table-responsive': innerWidth < 767.98}">
        <table>
          <thead>
          <!--          <tr>-->
          <th>
            <b-form-checkbox @change="changeSelectedAll" v-model="selectedAll"></b-form-checkbox>
          </th>
          <th>
            <div class="container-th">
              ID
              <img v-show="id === ''" @click="ordering('id','th_id')" class="img-arrow-ordering"
                   src="../../assets/shared/arrow-drop-down-ordering.svg" alt="">

              <img @click="ordering('-id','th_id')" v-show="id === 'id'" class="img-arrow-ordering"
                   src="../../assets/shared/arrow-drop-down-ordering-primary.svg" alt="">
              <img class="img-arrow-ordering img-arrow-ordering-rotate" @click="ordering('','th_id')"
                   v-show="id === '-id'"
                   src="../../assets/shared/arrow-drop-down-ordering-primary.svg" alt="">
            </div>
          </th>
          <th>
            <div class="container-th">
              Nome
              <img v-show="name === ''"
                   @click="ordering('name','th_name')" class="img-arrow-ordering"
                   src="../../assets/shared/arrow-drop-down-ordering.svg" alt="">

              <img @click="ordering('-name','th_name')"
                   v-show="name === 'name'" class="img-arrow-ordering"
                   src="../../assets/shared/arrow-drop-down-ordering-primary.svg" alt="">
              <img class="img-arrow-ordering img-arrow-ordering-rotate" @click="ordering('','th_name')"
                   v-show="name === '-name'"
                   src="../../assets/shared/arrow-drop-down-ordering-primary.svg" alt="">
            </div>
          </th>
          <th>
            Descrizione
          </th>
          <th>Proprietario</th>
          <th class="th-width"></th>
          <th class="th-width"></th>
          <th class="th-width"></th>
          <!--          </tr>-->
          </thead>
          <tbody>
          <tr :class="{'tr-active': selectedProjects.includes(project)}"
              @click="goToDetail($event,project)"
              v-for="(project,index) in storeProjects.projectsGetters.results" :key="index">
            <td>
              <input class="form-check-input" type="checkbox" @click="stopPropagation" :value="project"
                     v-model="selectedProjects">
            </td>
            <td>{{ project.id }}</td>
            <td>{{ project.name }}</td>
            <td style="max-width: 250px" v-if="project.description" class="text-14px-grey-regular">
              <div v-b-popover="project.description" class="text-description-overflow">{{
                  project.description
                }}
              </div>
            </td>
            <td v-else class="text-14px-grey-regular">ND</td>
            <td v-if="project.owner.user_detail" class="text-14px-grey-regular">
              {{ project.owner.user_detail.first_name }} {{ project.owner.user_detail.last_name }}
            </td>
            <td v-else class="text-14px-grey-regular">
              {{ project.owner.email }}
            </td>
            <td>
              <img @click="deleteFavorite($event,project)" v-show="project.is_favorite"
                   src="../../assets/shared/bookmarks.svg" alt="">
              <img
                  @click="$event.stopPropagation(),storeFavorite.setStatusFavorites({params: {'queryParams': queryParams},action: storeProjects.getProjects,object: project,entity: 'project'})"
                  v-show="!project.is_favorite" src="../../assets/shared/bookmarks-grey.svg" alt="">
            </td>
            <td>
              <img v-if="storeAuth.userGetters && storeAuth.userGetters.user_type === 'ad'" @click="showedEditProject($event,project)" src="../../assets/shared/edit-primary.svg" alt="">
            </td>
            <td>
              <img v-if="storeAuth.userGetters && storeAuth.userGetters.user_type === 'ad'" @click="showConfirmDelete($event,project,'single')" src="../../assets/shared/delete-red.svg"
                   alt="">
              <!--                 @mouseover="user['is_hovered'] = true"-->
              <!--                 @mouseout="user['is_hovered'] = false"-->
              <!--            <img v-else @mouseover="user['is_hovered'] = true" @mouseout="user['is_hovered'] = false"-->
              <!--                 @click="showConfirmDelete($event,user,'single')"-->
              <!--                 src="../../assets/shared/delete-red.svg" alt="">-->
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <b-spinner v-if="isLoading"></b-spinner>
    </div>
    <div v-if="storeProjects.projectsGetters" class="container-footer-projects">
      <span class="text-14px-grey-regular">{{ storeProjects.projectsGetters.count }} risultati</span>
      <b-pagination
          v-if="storeProjects.projectsGetters"
          v-model="storeUtils.page"
          :total-rows="storeProjects.projectsGetters.count"
          :per-page="storeApi.apiSettingsGetters.pagination.page_size"
          last-number
      ></b-pagination>
    </div>
    <b-modal v-model="showEditProject" @hide="closeModalFilters" :hide-header="true" :hide-footer="true">
      <div class="container-head-form">
        <div class="container-content-head-form">
          <div v-if="currentObj" class="text-16px-black-bold">Modifica <span
              class="text-16px-primary-bold">{{ currentObj.name }}</span></div>
        </div>
        <img @click="showEditProject = false" class="img-close" src="../../assets/shared/close.svg" alt="">
      </div>
      <ComponentDynamicForm v-if="showEditProject"
                            @close-form-without-update="showEditProject = false"
                            @closeForm="closeModal"
                            :currentObject="{0: currentObj}"
                            :options="{
                                  endpoints: [
                                      {
                                        'endpoint': 'projects',
                                        'type': TYPE_OF_OPTION.UPDATE,
                                        'path': [
                                          'projects',
                                           {'objectID':  currentObj.id}
                                        ]
                                      }
                                  ]
                              }"
      ></ComponentDynamicForm>
    </b-modal>
    <b-modal v-model="showFilters" @hide="closeModalFilters" :hide-header="true" :hide-footer="true">
      <ComponentFilters @resetDictFilter="resetDictFilter" @closeWithoutUpdate="showFilters = false"
                        @closeModalFilters="closeModalFilters"
                        :myFilter="'projects'"></ComponentFilters>
    </b-modal>
  </div>
  <ComponentFloatingActionButton :selected="selectedProjects" @setBooleanModal="setBooleanModal"
                                 v-if="innerWidth <= 991.98"
                                 :actions="actionsProjects"></ComponentFloatingActionButton>
</template>

<script>
import {useApiSettingStore} from "@/stores/apiSettings";
import {useUtilsStore} from "@/stores/utils";
import ComponentFilters from "@/components/filters/ComponentFilters";
import {useProjectStore} from "@/stores/projects";
import ComponentDynamicForm from "@/components/form/ComponentDynamicForm";
import {TYPE_OF_OPTION} from "@/globals"
import {useFavoriteStore} from "@/stores/favorites";
import ComponentFloatingActionButton from "@/components/floatingActionButton/ComponentFloatingActionButton";
import {useAuthStore} from "@/stores/auth";

export default {
  name: "ComponentProjects",
  emits: ['setBoolModal'],
  components: {ComponentFloatingActionButton, ComponentDynamicForm, ComponentFilters},
  props: {
    fromEntity: {
      type: String
    },
    actionsProjects: {
      type: Array
    }
  },
  setup() {
    const storeAuth = useAuthStore()
    const storeFavorite = useFavoriteStore()
    const storeApi = useApiSettingStore()
    const storeProjects = useProjectStore()
    const storeUtils = useUtilsStore()
    return {
      storeFavorite,
      storeAuth,
      storeApi,
      storeUtils,
      TYPE_OF_OPTION,
      storeProjects
    }
  },
  data() {
    return {
      innerWidth: window.innerWidth,
      debounce: this.createInputTextDebounce(),
      currentIndex: -1,
      currentObj: null,
      selectedAll: false,
      showEditProject: false,
      imageDeleteUrl: require("@/assets/shared/delete-grey.svg"),
      showFilters: false,
      selectedProjects: [],
      id: '',
      name: '',
      isLoading: false,
      response: null,
      fromEntity__in: '',
      queryParams: {}
    }
  },
  async created() {
    useUtilsStore().resetFilterAndOtherInfo()
    this.queryParams[this.fromEntity + '__in'] = this.$route.params[this.fromEntity + 'Id']
    useUtilsStore().dictionaryFilterFinal[this.fromEntity + '__in'] = this.$route.params[this.fromEntity + 'Id']
    useUtilsStore().dictionaryFilterFinal['page'] = useUtilsStore().pageGetters
    await this.loadProjects()
  },
  watch: {
    projects: {
      handler: function () {
        this.selectedProjects = []
      },
    },
    page: {
      handler: function (page) {
        this.storeUtils.dictionaryFilterFinal['page'] = page
        useUtilsStore().getInfoFromFilterOrSearchFromGenericAction({
          action: this.storeProjects.getProjects,
          queryParams: this.storeUtils.dictionaryFilterFinalGetters
        })
      }
    }
  },
  computed: {
    projects() {
      return useProjectStore().projectsGetters
    },
    search() {
      return useUtilsStore().searchGetters
    },
    page() {
      return useUtilsStore().pageGetters
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.innerWidth = window.innerWidth
    },
    setBooleanModal(event) {
      if (event.action === 'add' && event.entity === 'project')
        this.$emit('setBoolModal', true)
      if (event.action === 'filter' && event.entity === 'project')
        this.showFilters = true
      if (event.action === 'delete' && event.entity === 'project')
        this.showConfirmDelete(null, null, 'bulk')
    },
    research() {
      useUtilsStore().page = 1
      if (useUtilsStore().searchGetters !== '') {
        useUtilsStore().dictionaryFilterFinal['search'] = useUtilsStore().searchGetters
        this.storeUtils.getInfoFromFilterOrSearchFromGenericAction({
          queryParams: useUtilsStore().dictionaryFilterFinalGetters,
          action: this.storeProjects.getProjects
        })
      } else {
        delete useUtilsStore().dictionaryFilterFinalGetters['search']
        this.storeUtils.getInfoFromFilterOrSearchFromGenericAction({
          action: this.storeProjects.getProjects, queryParams: useUtilsStore().dictionaryFilterFinalGetters
        })
      }
    },
    deleteFavorite(e, obj) {
      e.stopPropagation()
      let item = {
        id: obj.favorite_id
      }
      useFavoriteStore().currentKeyDeleting = this.keyFavorite
      useUtilsStore().infoForToDelete['currentPosition'] = 'list'
      useUtilsStore().infoForToDelete['confirmDelete'] = true
      useUtilsStore().infoForToDelete['action'] = this.storeFavorite.deleteFavorite
      useUtilsStore().infoForToDelete['actionAfterDelete']['action'] = this.storeProjects.getProjects
      useUtilsStore().infoForToDelete['actionAfterDelete']['queryParams'] = this.queryParams
      useUtilsStore().infoForToDelete['typeObjectToDelete'] = 'single'
      useUtilsStore().infoForToDelete['entity'] = 'favorite'
      useUtilsStore().infoForToDelete['name'] = 'preferito'
      useUtilsStore().infoForToDelete['objectToDelete'] = item
      useUtilsStore().infoForToDelete['messageToDelete']['is'] = 'div'
      useUtilsStore().infoForToDelete['messageToDelete']['innerHTML'] = "Sei sicuro di voler rimuovere dai preferiti il progetto " +
          "<span style='color: var(--primary-color)'>" + obj.name +
          "</span>" + "?"
    },
    closeModal() {
      this.loadProjects()
    },
    showedEditProject(e, project) {
      e.stopPropagation()
      useProjectStore().project = project
      this.currentObj = project
      this.showEditProject = true
    },
    resetDictFilter() {
      this.showFilters = false
      useUtilsStore().resetFilterAndOtherInfo()
      useUtilsStore().dictionaryFilterFinal[this.fromEntity + '__in'] = this.$route.params[this.fromEntity + 'Id']
      useUtilsStore().dictionaryFilterFinal['page'] = useUtilsStore().pageGetters
      this.loadProjects()
    },
    async loadProjects() {
      if (this.isLoading) return // evita di caricare nuovi dati se un caricamento è già in corso
      this.isLoading = true // indica che un caricamento è in corso

      await useProjectStore().getProjects({queryParams: this.queryParams})
          .then(() => {
            this.isLoading = false
            this.showEditProject = false
          })
          .catch(() => {
          })
          .finally(() => {
          })

    },
    createInputTextDebounce() {
      let timeout = null;
      return function (fnc, delayMs) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          fnc();
        }, delayMs || 500);
      };
    },
    ordering(type, th) {
      if (th === 'th_id') {
        if (type === 'id') {
          this.id = type
          this.storeUtils.ordering.delete('')
          this.storeUtils.ordering.add(this.id)
        } else if (type === '-id') {
          this.id = type
          this.storeUtils.ordering.delete('id')
          this.storeUtils.ordering.add(this.id)
        } else {
          this.id = type
          this.storeUtils.ordering.delete('-id')
          this.storeUtils.ordering.add(this.id)
        }
      }
      if (th === 'th_name') {
        if (type === 'name') {
          this.name = type
          this.storeUtils.ordering.delete('')
          this.storeUtils.ordering.add(this.name)
        } else if (type === '-name') {
          this.name = type
          this.storeUtils.ordering.delete('name')
          this.storeUtils.ordering.add(this.name)
        } else {
          this.name = type
          this.storeUtils.ordering.delete('-name')
          this.storeUtils.ordering.add(this.name)
        }
      }
      if (this.storeUtils.orderingGetters.size > 0)
        this.storeUtils.dictionaryFilterFinal['ordering'] = Array.from(this.storeUtils.orderingGetters).toString()

      useUtilsStore().getInfoFromFilterOrSearchFromGenericAction({
        action: this.storeProjects.getProjects,
        queryParams: this.storeUtils.dictionaryFilterFinalGetters
      })
    },
    changeSelectedAll() {
      if (this.selectedAll)
        this.selectedProjects = useProjectStore().projectsGetters.results.map(project => project)
      else
        this.selectedProjects = []
    },
    closeModalFilters() {
      this.showFilters = false
    },
    showConfirmDelete(event, project, quantity) {
      if (event)
        event.stopPropagation()
      // delete custom and temporary data in single user in template
      // if (user)
      //   delete user['is_hovered']
      if (quantity === 'single') {
        useUtilsStore().infoForToDelete['currentPosition'] = ''
        useUtilsStore().infoForToDelete['confirmDelete'] = true
        useUtilsStore().infoForToDelete['typeObjectToDelete'] = 'single'
        useUtilsStore().infoForToDelete['entity'] = 'projects'
        useUtilsStore().infoForToDelete['name'] = 'sottocategoria'
        useUtilsStore().infoForToDelete['action'] = this.storeProjects.deleteProject
        useUtilsStore().infoForToDelete['actionAfterDelete']['action'] = this.storeProjects.getProjects
        useUtilsStore().infoForToDelete['actionAfterDelete']['queryParams'] = {
          [useProjectStore().fromEntityGetters.name + '__in']: useProjectStore().fromEntityGetters.id
        }
        useUtilsStore().infoForToDelete['objectToDelete'] = project
        useUtilsStore().infoForToDelete['listIds'] = []
        useUtilsStore().infoForToDelete['messageToDelete']['is'] = 'div'
        useUtilsStore().infoForToDelete['messageToDelete']['innerHTML'] = "Sei sicuro di voler eliminare definitivamente la sottocategoria " +
            "<span style='color: var(--primary-color)'>" + project.name +
            "</span>" + "? L’operazione non è reversibile."

      } else if (quantity === 'bulk') {
        useUtilsStore().infoForToDelete['currentPosition'] = ''
        useUtilsStore().infoForToDelete['confirmDelete'] = true
        useUtilsStore().infoForToDelete['typeObjectToDelete'] = 'bulk'
        useUtilsStore().infoForToDelete['entity'] = 'projects'
        useUtilsStore().infoForToDelete['name'] = ''
        useUtilsStore().infoForToDelete['action'] = this.storeUtils.bulkDeleteFromList
        useUtilsStore().infoForToDelete['actionAfterDelete']['action'] = this.storeProjects.getProjects
        useUtilsStore().infoForToDelete['actionAfterDelete']['queryParams'] = {
          [useProjectStore().fromEntityGetters.name + '__in']: useProjectStore().fromEntityGetters.id
        }
        useUtilsStore().infoForToDelete['objectToDelete'] = null
        useUtilsStore().infoForToDelete['listIds'] = this.selectedProjects.map(el => el.id)
        useUtilsStore().infoForToDelete['messageToDelete']['is'] = 'div'
        useUtilsStore().infoForToDelete['messageToDelete']['innerHTML'] = "Sei sicuro di voler eliminare definitivamente le " +
            "<span style='color: var(--primary-color)'>" + "sottocategorie selezionate" + "</span>" + "? L’operazione non è reversibile."
      }
    },
    stopPropagation(event) {
      event.stopPropagation()
    },
    // eslint-disable-next-line no-unused-vars
    goToDetail(event, object) {
      event.stopPropagation()
      useUtilsStore().currentNamePage = object.name
      if (this.fromEntity === 'subcategory')
        this.$router.push({name: 'detailProjectFromSubcategory', params: {projectId: object.id}}).catch(() => {
        })
      else if (this.fromEntity === 'category')
        this.$router.push({name: 'detailProjectFromCategory', params: {projectId: object.id}}).catch(() => {
        })

    }
  },
  updated() {
  },
  beforeUnmount() {
    if (useProjectStore().controllerGetProjectsGetters)
      useProjectStore().controllerGetProjectsGetters.abort()
    window.removeEventListener('resize', this.handleResize)
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style scoped>
.spinner-border {
  padding: 12.5px;
  margin: auto 0px !important;
}

.justify-content-start.pagination {
  margin: 0px 0px 0px auto;
}

.container-footer-projects {
  display: flex;
  width: 100%;
  margin: 10px 0px 10px 0px;
  align-items: center;
}

.container-status-success, .container-status-light-grey {
  margin-right: auto;
}

.container-numbers-secondary {
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background: var(--secondary-color);
  padding: 5px 10px;
}

.container-filter {
  height: 100%;
  margin-left: 10px;
}

.text-14px-primary-medium {
  cursor: pointer;
  margin: 0px 10px;
  white-space: nowrap;
}

.container-all-filter {
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: auto;
}

.search-bar {
}

.container-projects {
  display: flex;
  flex-direction: column;
  padding: 0px !important;
  margin: 0px !important;
  border-radius: 4px;
  width: 100% !important;
  height: 100%;
}

.container-content-projects {
  height: 100%;
  background: var(--white-color);
  box-shadow: 0px 2px 15px rgba(198, 198, 198, 0.25);
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.container-head-table {
  border-bottom: 1px solid #E7EAED;
  width: 100%;
  display: flex;
  position: sticky;
  z-index: 1;
  align-items: center;
  background: var(--white-color);
  padding: 10px 15px;
}

@media (max-width: 575.98px) {
}

/*// Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
  .container-filter {
    height: auto;
  }

  .container-all-filter {
    flex-wrap: wrap;
  }

  .table-responsive {

  }
}

/*// Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {
  .container-footer-projects {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

}

/*// Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {
}

/*// X-Large devices (large desktops, less than 1400px)*/
@media (max-width: 1399.98px) {
}
</style>