import {defineStore} from 'pinia'
import axiosInstance from "@/services/api";
import {useAuthStore} from "@/stores/auth";
import {useApiSettingStore} from "@/stores/apiSettings";

export const useCategoryStore = defineStore(
    "category",
    {
        state: () => ({
            /*** controller ***/
            controllerGetCategories: null,
            controllerGetCategory: null,
            /*** ***/

            currentColor: '#E7EAED',
            categories: null,
            infiniteHorizontalArray: [],
            arrayCategoriesInfinity: [],
            optionsCategory: null,
            optionsCategoryDetail: null,
            category: null,
            allCategories: null,
            oldCategory: null,
            errorMimeTypeImage: false,
            spinnerCategory: false
        }),
        actions: {
            getOptionsCategory() {
                return new Promise((resolve, reject) => {

                    axiosInstance.options('api/categories/')
                        .then(res => {
                            this.optionsCategory = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                })
            },
            getCategory(id) {
                return new Promise((resolve, reject) => {
                    this.controllerGetCategory = new AbortController()
                    const config = {
                        signal: useCategoryStore().controllerGetCategoryGetters.signal
                    }
                    axiosInstance.get('api/categories/' + id + '/', config)
                        .then(res => {
                            this.category = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                })
            },
            getCategories(payload) {
                return new Promise((resolve, reject) => {
                    this.controllerGetCategories = new AbortController()
                    const config = {
                        signal: useCategoryStore().controllerGetCategoriesGetters.signal
                    }

                    let url = new URL(axiosInstance.defaults.baseURL + 'api/categories/')

                    this.spinnerCategory = true
                    if (payload.queryParams !== null) {
                        let params = new URLSearchParams()
                        for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                            params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                        }
                        url.search = params
                        axiosInstance.get(url.pathname + url.search, config)
                            .then(res => {
                                const newItems = res.data.results
                                this.infiniteHorizontalArray = [...useCategoryStore().infiniteHorizontalArrayGetters, ...newItems]

                                this.categories = res.data

                                if (payload.queryParams.scroll) {
                                    this.arrayCategoriesInfinity.push(...res.data.results)
                                } else {
                                    this.arrayCategoriesInfinity = res.data.results
                                }
                                useCategoryStore().infiniteHorizontalArrayGetters.forEach((el, i) => {
                                    fetch(el.thumbnail_img, {
                                        headers: {
                                            Authorization: 'Bearer ' + useAuthStore().keycloakUserGetters.token
                                        }
                                    })
                                        .then(response => response.blob())
                                        .then(blob => {
                                            let img = document.getElementById('category_' + i);
                                            let url = URL.createObjectURL(blob);
                                            if (img)
                                                img.style.backgroundImage = `url(${url})`;
                                        })
                                })
                                this.spinnerCategory = false
                                resolve(res)
                            })
                            .catch(err => {
                                this.spinnerCategory = false
                                if (err.response && err.response.status === 500) {
                                    useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                                }
                                reject(err)
                            })
                    } else {
                        axiosInstance.get(url.pathname, config)
                            .then(res => {
                                this.spinnerCategory = false

                                const newItems = res.data.results
                                this.infiniteHorizontalArray = [...useCategoryStore().infiniteHorizontalArrayGetters, ...newItems]

                                this.categories = res.data
                                let a = []
                                a.push(...res.data.results);
                                this.arrayCategoriesInfinity = a
                                useCategoryStore().infiniteHorizontalArrayGetters.forEach((el, i) => {
                                    fetch(el.thumbnail_img, {
                                        headers: {
                                            Authorization: 'Bearer ' + useAuthStore().keycloakUserGetters.token
                                        }
                                    })
                                        .then(response => response.blob())
                                        .then(blob => {
                                            let img = document.getElementById('category_' + i);
                                            let url = URL.createObjectURL(blob);
                                            if (img)
                                                img.style.backgroundImage = `url(${url})`;
                                        })
                                })
                                resolve(res)
                            })
                            .catch(err => {
                                this.spinnerCategory = false
                                if (err.response && err.response.status === 500) {
                                    useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                                }
                                reject(err)
                            })
                    }

                })
            },
            getCategoriesWithoutSetState(payload) {
                return new Promise((resolve, reject) => {
                    let url = new URL(axiosInstance.defaults.baseURL + 'api/categories/')

                    this.spinnerCategory = true
                    if (payload.queryParams !== null) {
                        let params = new URLSearchParams()
                        for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                            params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                        }
                        url.search = params
                        axiosInstance.get(url.pathname + url.search)
                            .then(res => {
                                resolve(res)
                            })
                            .catch(err => {
                                this.spinnerCategory = false
                                if (err.response && err.response.status === 500) {
                                    useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                                }
                                reject(err)
                            })
                    } else {
                        axiosInstance.get(url.pathname)
                            .then(res => {
                                resolve(res)
                            })
                            .catch(err => {
                                this.spinnerCategory = false
                                if (err.response && err.response.status === 500) {
                                    useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                                }
                                reject(err)
                            })
                    }

                })
            },
            createCategory(payload) {
                return new Promise((resolve, reject) => {
                    this.errorMimeTypeImage = false

                    if (payload instanceof FormData) {
                        payload.set('owner', useAuthStore().userGetters.user_id)
                    } else {
                        payload['owner'] = useAuthStore().userGetters.user_id
                    }
                    axiosInstance.post('/api/categories/', payload)
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            reject(err)
                        })
                })
            },
            getAllCategories(payload) {
                return new Promise((resolve, reject) => {
                    let url = new URL(axiosInstance.defaults.baseURL + 'api/categories/')

                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axiosInstance.get(url.pathname + url.search)
                        .then(res => {
                            this.allCategories = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })

                })
            },
            editCategory(payload) {
                return new Promise((resolve, reject) => {
                    if (payload.get('thumbnail_img') === 'null')
                        payload.delete('thumbnail_img')
                    axiosInstance.patch('api/categories/' + payload.get('id') + '/', payload)
                        .then(res => {
                            this.currentColor = res.data.color
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                })
            },
            deleteCategory(id) {
                return new Promise((resolve, reject) => {
                    axiosInstance.delete('/api/categories/' + id + '/')
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                })
            }
        },
        getters: {
            categoriesGetters: state => {
                return state.categories
            },
            allCategoriesGetters: state => {
                return state.allCategories
            },
            oldCategoryGetters: state => {
                return state.oldCategory
            },
            categoryGetters: state => {
                return state.category
            },
            arrayCategoriesInfinityGetters: state => {
                return state.arrayCategoriesInfinity
            },
            spinnerCategoryGetters: state => {
                return state.spinnerCategory
            },
            errorMimeTypeImageGetters: state => {
                return state.errorMimeTypeImage
            },
            optionsCategoryGetters: state => {
                return state.optionsCategory
            },
            optionsCategoryDetailGetters: state => {
                return state.optionsCategoryDetail
            },
            currentColorGetters: state => {
                return state.currentColor
            },
            infiniteHorizontalArrayGetters: state => {
                return state.infiniteHorizontalArray
            },
            controllerGetCategoriesGetters: state => {
                return state.controllerGetCategories
            },
            controllerGetCategoryGetters: state => {
                return state.controllerGetCategory
            }
        }
    })