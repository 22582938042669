<template>
  <table>
    <thead>
    <th class=""></th>
    <th class="text-16px-black-regular">Punteggio</th>
    <th class="text-16px-black-regular">Importanza</th>
    </thead>
    <tbody>
    <tr>
      <td class="text-16px-black-medium">Testo completo</td>
      <td>
        <div class="container-status-success">
          {{ storeUtils.textAnalysisGetters.Sentiment.document_sentiment.score.toFixed(2) }}
        </div>
      </td>
      <td>
        <div class="container-status-danger">
          {{ storeUtils.textAnalysisGetters.Sentiment.document_sentiment.magnitude.toFixed(2) }}
        </div>
      </td>


    </tr>
    <tr v-for="(sentence,i) in storeUtils.textAnalysisGetters.Sentiment.sentences" :key="i">
      <td class="text-14px-dark-grey-regular">
        <div style="cursor: auto" v-bind="{innerHTML: sentence.text.content}"></div>
      </td>
      <td>
        <div class="container-status-success">
          {{ sentence.sentiment.score.toFixed(2) }}
        </div>
      </td>
      <td>
        <div class="container-status-danger">
          {{ sentence.sentiment.magnitude.toFixed(2) }}
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import {useUtilsStore} from "@/stores/utils";

export default {
  name: "ComponentTextMiningSentiment",
  props: {},
  setup() {
    const storeUtils = useUtilsStore()
    return {
      storeUtils
    }
  },
  data() {
    return {}
  },
  created() {
  },
  watch: {},
  computed: {},
  mounted() {
  },
  methods: {},
  updated() {
  },
  beforeUnmount() {
  },
  unmounted() {
  }

}
</script>

<style scoped>
td {
  cursor: auto !important;
}

thead th {
  position: relative !important;
}

thead {
  position: relative !important;
}

.container-single-grid-sentiment {
  display: flex;
  padding: 10px 15px;
  width: 100%;
  background: var(--white-color);
}

.container-grid-sentiment {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 48% 48%;
  gap: 4%;
}

table {
  border-radius: 4px !important;
}
</style>