<template>
  <div id="container-content-header" class="container-content-header">
    <div class="container-content-left-header">
      <div class="container-chronology">
        <span class="text-14px-secondary-medium">{{ returnTextFromPath() }}</span>
        <div class="container-chronologies">
          <div class="container-single-chronology" :key="index"
               v-for="(chronology,index) in storeUtils.chronologyGetters">
            <img src="../../assets/shared/arrow-right.svg" alt="">
            <span @click="goToNamePath(chronology)"
                  :class="{'text-14px-grey-medium': index + 1 === storeUtils.chronologyGetters.length}"
                  class="text-14px-dark-grey-medium">{{ chronology.display_name }}</span>
          </div>
        </div>
      </div>
      <span class="text-24px-primary-medium">{{ storeUtils.currentNamePageGetters }}</span>
    </div>

    <div @click="goToChat" class="container-info-chat">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M18.5041 9.49824H10.4991L7.49724 12.5001V9.49824H5.49599C4.94336 9.49824 4.49536 9.05025 4.49536 8.49762V5.49574C4.49536 4.94311 4.94336 4.49512 5.49599 4.49512H18.5041C19.0567 4.49512 19.5047 4.94311 19.5047 5.49574V8.49762C19.5047 9.05025 19.0567 9.49824 18.5041 9.49824Z"
              :stroke="$route.name === 'chat' ? '#CF3092' : '#111827'" stroke-width="1.5"/>
        <path
            d="M5.49599 11.4995C4.94336 11.4995 4.49536 11.9475 4.49536 12.5001V15.502C4.49536 16.0546 4.94336 16.5026 5.49599 16.5026H13.501L16.5029 19.5045V16.5026H18.5041C19.0567 16.5026 19.5047 16.0546 19.5047 15.502V12.5001C19.5047 11.9475 19.0567 11.4995 18.5041 11.4995H11.4997"
            :stroke="$route.name === 'chat' ? '#CF3092' : '#111827'" stroke-width="1.5"/>
      </svg>
      <div class="container-info-chat-messages">
        {{ storeThread.threadsUnreadGetters }}
      </div>
    </div>

    <img @click="showedFormReport = true" style="margin-left: 20px;cursor:pointer;margin-right: 5px"
         src="../../assets/shared/annotation-question.svg" alt="">

    <div v-click-outside="closeDropdownMenu" @click="openDropdownMenu" :class="{container_user: toggleMenu}"
         class="container-user" id="container-user">
      <img v-show="!toggleMenu" src="../../assets/shared/user-secondary.svg" alt="">
      <img v-show="toggleMenu" src="../../assets/shared/user-white.svg" alt="">
      <span v-if="user" :class="{color_active: toggleMenu}"
            class="text-14px-secondary-medium">{{ user.email }}</span>
    </div>
    <div v-show="toggleMenu" class="container-dropdown-menu-header-bar">
      <div v-if="user && !user.is_superuser" @click="goToAccount"
           class="container-single-dropdown-menu-header-bar">
        <img src="../../assets/shared/user-black.svg" alt="">
        <span class="text-14px-black-regular">Account</span>
      </div>
      <div @click="logout" class="container-single-dropdown-menu-header-bar">
        <img src="../../assets/shared/logout.svg" alt="">
        <span class="text-14px-black-regular">Logout</span>
      </div>
    </div>
  </div>
  <div id="container-content-header-mobile" class="container-content-header-mobile">
    <div class="container-content-header-mobile-search-bar">
      <div class="container-search-bar">
        <img @click="sendEmitToHamburger" src="../../assets/shared/navigation-menu.svg" alt="">
        <b-form-input id="search_mobile" type="search" v-on:input="debounce(setSearchFinal)" v-model="search"
                      placeholder="Cerca" class="form-control form-control-wihtout-border"></b-form-input>
      </div>
      <div @click="showedFormReport = true" class="container-img-annotation">
        <img src="../../assets/shared/annotation-question.svg" alt="">
      </div>
      <div v-click-outside="closeDropdownMenuMobile" :class="{'container_img_profile': toggleMenuMobile}"
           class="container-img-profile">
        <img v-show="!toggleMenuMobile" @click="openDropdownMenu" src="../../assets/shared/user-secondary.svg" alt="">
        <img v-show="toggleMenuMobile" @click="openDropdownMenu" src="../../assets/shared/user-white.svg" alt="">
        <div v-show="toggleMenuMobile" id="container-dropdown-menu-header-bar"
             class="container-dropdown-menu-header-bar">
          <div v-if="user && !user.is_superuser" @click="goToAccount"
               class="container-single-dropdown-menu-header-bar">
            <img src="../../assets/shared/user-black.svg" alt="">
            <span class="text-14px-black-regular">Account</span>
          </div>
          <div @click="logout" class="container-single-dropdown-menu-header-bar">
            <img src="../../assets/shared/logout.svg" alt="">
            <span class="text-14px-black-regular">Logout</span>
          </div>
        </div>
      </div>

    </div>
    <div id="container-content-left-header" class="container-content-left-header">
      <div class="container-chronology container-chronology-imgs">
        <span class="text-14px-secondary-medium">{{ returnTextFromPath() }}</span>
        <div class="container-chronologies">
          <div class="container-single-chronology" :key="index"
               v-for="(chronology,index) in storeUtils.chronologyGetters">
            <img src="../../assets/shared/arrow-right.svg" alt="">
            <img @click="goToNamePath(chronology)" v-show="index + 1 !== storeUtils.chronologyGetters.length "
                 :src="returnSrc(chronology,index)" alt="">
            <span class="text-14px-grey-medium"
                  v-show="index + 1 === storeUtils.chronologyGetters.length ">{{ chronology.display_name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <b-modal v-if="showedFormReport" v-model="showedFormReport" :hide-footer="true" :hide-header="true">
    <div class="container-head-form">
      <div class="container-content-head-form">
        <div>Segnalazione anonima</div>
      </div>
      <img @click="showedFormReport = false" class="img-close" src="../../assets/shared/close.svg" alt="">
    </div>
    <ComponentAnonymousReport @closeModal="showedFormReport = false"></ComponentAnonymousReport>
  </b-modal>

  <b-modal style="z-index: 20000;" :noCloseOnEsc="true" :noCloseOnBackdrop="true"
           :hide-footer="true" v-if="showModalInterceptorCount"
           :hide-header="true" v-model="showModalInterceptorCount">
    <div class="text-warning" style="padding: 30px;display: flex;align-items: center;justify-content: center">
      Errore di autenticazione, riprova più tardi oppure ricarica la pagina.
    </div>
  </b-modal>
</template>

<script>
import {useUtilsStore} from "@/stores/utils";
import {useAuthStore} from "@/stores/auth";
import {useUserStore} from "@/stores/users";
import {useProjectStore} from "@/stores/projects";
import {useCategoryStore} from "@/stores/categories";
import {useThreadsStore} from "@/stores/threads";
import ComponentAnonymousReport from "@/components/anonymousReport/ComponentAnonymousReport.vue";

export default {
  name: "ComponentHeader",
  components: {ComponentAnonymousReport},
  setup() {
    const storeAuth = useAuthStore()
    const storeThread = useThreadsStore()
    const storeUtils = useUtilsStore()
    const storeProject = useProjectStore()
    const storeUser = useUserStore()
    const storeCategory = useCategoryStore()
    return {
      storeCategory,
      storeThread,
      storeProject,
      storeUtils,
      storeAuth,
      storeUser
    }
  },
  data() {
    return {
      showModalInterceptorCount: false,
      showedFormReport: false,
      innerWidth: window.innerWidth,
      search: '',
      debounce: this.createInputTextDebounce(),
      toggleMenu: false,
      toggleMenuMobile: false,
      user: null,
      interval: null
    }
  },
  created() {
    this.interval = setInterval(this.getThreadsUnread, 5000)
  },
  watch: {
    userGetters: {
      handler: function (value) {
        if (value.id === useAuthStore().userGetters.user_id)
          this.user = value
      }
    },
    retry401Counter: {
      handler: function (value) {
        if (value >= process.env.VUE_APP_RETRY_401_LIMIT) {
          this.showModalInterceptorCount = true
          clearInterval(this.interval)
          useUtilsStore().retry401Counter = 0
        }
      }
    },
  },
  computed: {
    userGetters() {
      return useAuthStore().userGetters
    },
    retry401Counter() {
      return useUtilsStore().retry401CounterGetters
    }
  },
  mounted() {
    this.calculateStylesDropdownMenu()
    this.setBorderBottom()
    window.addEventListener('resize', this.handleResize)
  },
  methods: {
    returnTextFromPath() {
      let text
      switch (this.$route.name) {
          // Knowledge
        case 'dashboard':
          text = 'Knowledge'
          break;
        case 'archive':
          text = 'Knowledge'
          break;
        case 'detailCategory':
          text = 'Knowledge'
          break;
        case 'detailSubcategory':
          text = 'Knowledge'
          break;
        case 'detailProjectFromSubcategory':
          text = 'Knowledge'
          break;
        case 'detailProjectFromCategory':
          text = 'Knowledge'
          break;
        case 'wiki':
          text = 'Knowledge'
          break;
          //

          //Interazione Cooperativa
        case 'publicWall':
          text = this.innerWidth >= 991.98 ? 'Interazione Cooperativa' : 'Interazione Cooperativa'
          break;
        case 'detailPublicWall':
          text = this.innerWidth >= 991.98 ? 'Interazione Cooperativa' : 'Interazione Cooperativa'
          break;
        case 'privateWall':
          text = this.innerWidth >= 991.98 ? 'Interazione Cooperativa' : 'Interazione Cooperativa'
          break;
        case 'detailPrivateWall':
          text = this.innerWidth >= 991.98 ? 'Interazione Cooperativa' : 'Interazione Cooperativa'
          break;
        case 'chat':
          text = this.innerWidth >= 991.98 ? 'Interazione Cooperativa' : 'Interazione Cooperativa'
          break;
        case 'calendar':
          text = this.innerWidth >= 991.98 ? 'Interazione Cooperativa' : 'Interazione Cooperativa'
          break;
        case 'suggestions':
          text = this.innerWidth >= 991.98 ? 'Interazione Cooperativa' : 'Interazione Cooperativa'
          break;
        case 'video-call':
          text = this.innerWidth >= 991.98 ? 'Interazione Cooperativa' : 'Interazione Cooperativa'
          break;
          //

          // Impostazioni
        case 'users':
          text = 'Impostazioni'
          break;
        case 'detailUser':
          text = 'Impostazioni'
          break;
        case 'groups':
          text = 'Impostazioni'
          break;
        case 'detailGroup':
          text = 'Impostazioni'
          break;
        case 'account':
          text = 'Impostazioni'
          break;
          //
      }
      return text
    },
    getThreadsUnread() {
      useThreadsStore().getThreadsUnread()
          .then(() => {
          })
          .catch(() => {
          })
    },
    goToChat() {
      this.$router.push({name: 'chat'}).catch(() => {
      })
    },
    goToNamePath(chronology) {
      switch (chronology.name) {
        case 'Dashboard':
          if (chronology.route_id === null)
            this.$router.push({name: chronology.route_name}).catch(() => {
            })
          break;
        case 'Archivio':
          if (chronology.route_id === null)
            this.$router.push({name: chronology.route_name}).catch(() => {
            })
          break;
        case 'Wiki':
          if (chronology.route_id === null)
            this.$router.push({name: chronology.route_name}).catch(() => {
            })
          break;
        case 'Account':
          if (chronology.route_id === null)
            this.$router.push({name: chronology.route_name}).catch(() => {
            })
          break;
        case 'Gruppi':
          if (chronology.route_id === null)
            this.$router.push({name: chronology.route_name}).catch(() => {
            })
          break;
        case 'Utenti':
          if (chronology.route_id === null)
            this.$router.push({name: chronology.route_name}).catch(() => {
            })
          break;
        case 'Dettaglio categoria':
          if (chronology.route_id)
            this.$router.push({name: chronology.route_name, params: {categoryId: chronology.route_id}}).catch(() => {
            })
          break;
        case 'Dettaglio Gruppo':
          if (chronology.route_id)
            this.$router.push({name: chronology.route_name, params: {id: chronology.route_id}}).catch(() => {
            })
          break;
        case 'Dettaglio Utente':
          if (chronology.route_id)
            this.$router.push({name: chronology.route_name, params: {id: chronology.route_id}}).catch(() => {
            })
          break;
        case 'Dettaglio sottocategoria':
          if (chronology.route_id)
            this.$router.push({
              name: chronology.route_name,
              params: {categoryId: chronology.categoryId, subcategoryId: chronology.route_id}
            }).catch(() => {
            })
          break;
        case 'Dettaglio progetto':
          if (chronology.route_id)
            this.$router.push({
              name: chronology.route_name,
              params: {
                categoryId: chronology.categoryId,
                subcategoryId: chronology.subcategoryId,
                projectId: chronology.route_id
              }
            }).catch(() => {
            })
          break;
        case 'Dettaglio Private Wall':
          this.$router.push({
            name: chronology.route_name,
            params: {
              id: chronology.route_id
            }
          }).catch(() => {
          })
          break;
        case 'Private Wall':
          this.$router.push({
            name: chronology.route_name,
          }).catch(() => {
          })
          break;
      }
    },
    handleResize() {
      this.setBorderBottom()
      this.innerWidth = window.innerWidth
      if (window.innerWidth > 991.98)
        this.toggleMenuMobile = false
      else {
        let search_mobile = document.getElementById('search_mobile')
        if (search_mobile && useUtilsStore().globalSearchGetters !== '') {
          search_mobile.focus()
          search_mobile.value = useUtilsStore().globalSearchGetters
        }
        this.toggleMenu = false
      }
      this.calculateStylesDropdownMenu()
    },
    returnSrc(chronology, index) {
      let src
      switch (chronology.name) {
        case "Archivio":
          if (index + 1 !== this.storeUtils.chronologyGetters.length)
            src = require('../../assets/shared/archive-dark-grey.svg')
          break;
        case "Dettaglio categoria":
          if (index + 1 !== this.storeUtils.chronologyGetters.length)
            src = require('../../assets/shared/hierarchy-dark-grey.svg')
          break;
        case "Dettaglio sottocategoria":
          if (index + 1 !== this.storeUtils.chronologyGetters.length)
            src = require('../../assets/shared/folder-dark-grey.svg')
          break;
        case "Dettaglio progetto":
          if (index + 1 !== this.storeUtils.chronologyGetters.length)
            src = require('../../assets/shared/folder-dark-grey.svg')
          break;
        case "Impostazioni":
          if (index + 1 !== this.storeUtils.chronologyGetters.length)
            src = require('../../assets/shared/settings-dark-grey.svg')
          break;
        case "Account":
          if (index + 1 !== this.storeUtils.chronologyGetters.length)
            src = require('../../assets/shared/single-man-dark-grey.svg')
          break;
        case "Utenti":
          if (index + 1 !== this.storeUtils.chronologyGetters.length)
            src = require('../../assets/shared/single-man-dark-grey.svg')
          break;
        case "Gruppi":
          if (index + 1 !== this.storeUtils.chronologyGetters.length)
            src = require('../../assets/shared/group-dark-grey.svg')
          break;
        case "Interazione Cooperativa":
          if (index + 1 !== this.storeUtils.chronologyGetters.length)
            src = require('../../assets/shared/group-dark-grey.svg')
          break;
        case "Public Wall":
          if (index + 1 !== this.storeUtils.chronologyGetters.length)
            src = require('../../assets/interaction_cc/earth.svg')
          break;
        case "Private Wall":
          if (index + 1 !== this.storeUtils.chronologyGetters.length)
            src = require('../../assets/interaction_cc/earth_lucchetto.svg')
          break;
        case "Videochiamata":
          if (index + 1 !== this.storeUtils.chronologyGetters.length)
            src = require('../../assets/interaction_cc/video.svg')
          break;
      }
      return src
    },
    createInputTextDebounce() {
      let timeout = null;
      return function (fnc, delayMs) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          fnc();
        }, delayMs || 500);
      };
    },
    setSearchFinal() {
      useUtilsStore().globalSearch = this.search
    },
    closeDropdownMenu() {
      this.toggleMenu = false
    },
    closeDropdownMenuMobile() {
      this.toggleMenuMobile = false
    },
    goToAccount() {
      this.$router.push({name: 'account'}).then(() => {
        this.toggleMenuMobile = false
        this.toggleMenu = false
      }).catch(() => {
      })
    },
    sendEmitToHamburger() {
      useUtilsStore().toggleHamburgerMenu = true
      this.emitter.emit('toggleHamburger', true)
    },
    calculateStylesDropdownMenu() {
      let container_user = document.getElementById('container-user')
      let container_dropdown_menu_header_bar = document.getElementsByClassName('container-dropdown-menu-header-bar')[0]
      if (container_dropdown_menu_header_bar) {
        container_dropdown_menu_header_bar.style.width = container_user.getBoundingClientRect().width + 'px'
      }
    },
    openDropdownMenu() {
      // funzione per calcolare la posizione esatta per aprire il menu rispetto al nome dell'utente
      let container_user = document.getElementById('container-user')
      let container_dropdown_menu_header_bar = document.getElementsByClassName('container-dropdown-menu-header-bar')[0]
      if (this.innerWidth > 991.98) {
        if (container_user && container_dropdown_menu_header_bar) {
          container_dropdown_menu_header_bar.style.top = container_user.getBoundingClientRect().top + container_user.getBoundingClientRect().height + 5 + 'px'
          container_dropdown_menu_header_bar.style.width = container_user.getBoundingClientRect().width + 'px'
        }
      } else {
        let container_content_header_mobile = document.getElementById('container-content-header-mobile')
        let container_dropdown_menu_header_bar = document.getElementById('container-dropdown-menu-header-bar')
        let container_content_left_header = document.getElementById('container-content-left-header')
        if (container_content_header_mobile && container_dropdown_menu_header_bar) {
          container_dropdown_menu_header_bar.style.top = container_content_header_mobile.getBoundingClientRect().height - container_content_left_header.getBoundingClientRect().height + 5 + 'px'
        }
        this.toggleMenuMobile = !this.toggleMenuMobile
      }
      this.toggleMenu = !this.toggleMenu
      // this.emitter.emit('checkStateDropdownMenu', this.showDropdownMenu)
    },
    logout() {
      useAuthStore().logout()
      // this.$router.push({path: '/'}).catch(() => {
      // })
    },
    setBorderBottom() {
      let container_content_header
      if (document.getElementById('container-content-header') && window.getComputedStyle(document.getElementById('container-content-header-mobile')).display === 'none') {
        container_content_header = document.getElementById('container-content-header')
      } else if (document.getElementById('container-content-header-mobile') && window.getComputedStyle(document.getElementById('container-content-header')).display === 'none') {
        container_content_header = document.getElementById('container-content-header-mobile')
      }
      if (container_content_header)
        if (this.$route.path.includes('category') ||
            this.$route.path.includes('subcategory') ||
            this.$route.path.includes('project'))
          container_content_header.style.borderBottom = '1px solid ' + useCategoryStore().currentColorGetters
        else
          container_content_header.style.borderBottom = '1px solid #E7EAED'
    }
  },
  updated() {
    this.setBorderBottom()
  },
  beforeUnmount() {
    clearInterval(this.interval)
    window.removeEventListener('resize', this.handleResize)
  },
  unmounted() {
    clearInterval(this.interval)
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style scoped>
.container_img_profile.container-img-profile {
  background: var(--secondary-color);
  border: 1px solid var(--secondary-color);
}

.container-img-profile {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /*height: 100%;*/
  border-top: 1px solid var(--grey-color);
  border-right: 1px solid var(--grey-color);
  border-bottom: 1px solid var(--grey-color);
  border-radius: 0px 4px 4px 0px;
  background: var(--dark-white-color);
}

.container-img-annotation {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /*height: 100%;*/
  border-top: 1px solid var(--grey-color);
  border-bottom: 1px solid var(--grey-color);
  background: var(--dark-white-color);
}

.container-content-header-mobile {
  display: none;
  position: sticky;
  background: var(--white-color);
  top: 0;
  padding: 10px 15px;
  z-index: 5;
  width: 100%;
}

.container-search-bar img {
  margin: 0px 5px;
  cursor: pointer;
}

.container-search-bar {
  width: 100%;
  display: none;
  align-items: center;
  background: var(--white-color);
  border-top: 1px solid var(--grey-color);
  border-left: 1px solid var(--grey-color);
  border-bottom: 1px solid var(--grey-color);
  border-radius: 4px 0px 0px 4px;
}

.container-single-dropdown-menu-header-bar img {
  margin-right: 15px;
}

.container-single-dropdown-menu-header-bar:hover {
  background: var(--light-grey-color);
}

.container-single-dropdown-menu-header-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  width: 100%;
  margin: 5px auto;
}

.container-dropdown-menu-header-bar {
  position: absolute;
  right: 0;
  z-index: 3;
  background: var(--white-color);
  box-shadow: 0px 2px 15px rgba(198, 198, 198, 0.25);
  border-radius: 8px;
  height: auto;
  min-width: 150px;
  display: flex;
  align-items: center;
  margin-right: 15px;
  flex-direction: column;
  cursor: pointer;
  padding: 5px 0px;
}

.text-14px-secondary-medium {
  line-height: 133%;
}

.color_active.text-14px-secondary-medium {
  color: var(--white-color);
}

.container-chronology {
  display: flex;
  align-items: flex-start;
}

.text-24px-primary-medium {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.container-content-left-header {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  max-width: 700px;
}

.container-user img {
  margin-right: 10px;
}

.container-content-header-mobile-search-bar {
  display: flex;
}

.container_user.container-user {
  background: var(--secondary-color);
  border: 1px solid var(--secondary-color-hover);
  color: var(--white-color);
}

.container-user {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 15px;
  background: var(--white-color);
  border: 1px solid var(--light-grey-color);
  border-radius: 4px;
  padding: 10px;
}

.container-info-chat-messages {
  background: var(--secondary-color);
  color: var(--white-color);
  padding: 5px;
  border-radius: 26px;
  min-width: 35px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-info-chat {
  cursor: pointer;
  display: flex;
  margin-left: auto;
  align-items: center;
}

.text-14px-dark-grey-medium:hover {
  text-decoration: underline;
}

.container-single-chronology img {
  width: 18px !important;
  cursor: pointer;
}

.container-single-chronology span {
  cursor: pointer;
}

.container-single-chronology {
  display: flex;
  align-items: center;
}

.container-content-header {
  display: flex;
  position: sticky;
  z-index: 3;
  top: 0;
  width: 100%;
  max-height: 12vh;
  min-height: 12vh;
  background: var(--dark-white-color);
  border-top: 1px solid var(--light-grey-color);
  border-right: 1px solid var(--light-grey-color);
  border-left: 1px solid var(--light-grey-color);
  border-bottom: 1px solid var(--light-grey-color);
  padding: 10px 15px;
  align-items: center;
}

.text-14px-grey-medium {
  cursor: auto !important;
  text-decoration: unset !important;
  line-height: 129% !important;
}

.container-chronologies {
  display: flex;
  flex-wrap: wrap;
}


/*// X-Small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
}

/*// Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
}

/*// Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {
  .container-content-header {
    display: none;
  }

  .container-search-bar {
    display: flex;
  }

  .container-chronology-imgs.container-chronology {
    align-items: flex-start;
  }

  .container-chronology {
    padding: 15px 0px 0px 0px;
  }

  .container-content-header-mobile {
    display: flex;
    flex-direction: column;
  }

  .container-single-chronology {
    margin: 2.5px 0px;
  }
}

/*// Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {
}

/*// X-Large devices (large desktops, less than 1400px)*/
@media (max-width: 1399.98px) {
}


</style>