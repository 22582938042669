<template>
  <div v-if="storeWiki.wikiGetters" class="container-detail-wiki">
    <div class="container-head-detail">
      <div class="container-content-head-detail">
        <span class="text-16px-black-bold">Wiki</span>
        <span class="text-16px-primary-bold">&nbsp;{{ returnNameEntity() }}</span>
      </div>
      <img @click="$emit('closeModal')" class="img-close" src="../../assets/shared/close.svg" alt="">
    </div>

    <div class="container-content-detail">
      <strong class="" v-if="!isEditing && storeWiki.wikiGetters.wiki.innerHTML === ''">Wiki vuota!</strong>
      <div v-show="!isEditing && storeWiki.wikiGetters" v-bind="storeWiki.wikiGetters.wiki"></div>
      <ckeditor v-if="isEditing && storeWiki.wikiGetters" v-model="storeWiki.wikiGetters.wiki.innerHTML"
                :editor="editor" :config="editorConfig"/>
    </div>
    <span v-if="lastReason">{{ lastReason.wiki }}</span>

    <div class="container-footer-wiki">
      <!--      <span class="text-14px-dark-grey-regular">Ultima modifica il {{ returnDate(currentObj.updated_at) }}</span>-->
      <div class="container-content-right-footer">
        <!--        <img src="../../assets/shared/bookmarks.svg" alt="">-->

        <ComponentText :buttonDisabled="storeWiki.wikiGetters.wiki.innerHTML === ''" v-if="!isEditing"
                       :currentObject="storeWiki.wikiGetters" :fields="[{name_db: 'wiki', value: 'wiki'}]"
                       :model="'wiki'" :id="idProp"></ComponentText>

        <button v-if="storeAuth.userGetters && storeAuth.userGetters.user_type === 'ad'" v-show="!isEditing" class="btn btn-light-grey-custom" @click="isEditing = true">
          <span class="text-16px-black-medium">Modifica</span>
        </button>
        <button @click="$emit('closeModal')" v-show="isEditing" class="btn btn-light-grey-custom">Annulla</button>
        <button @click="save" v-show="isEditing" class="btn btn-primary-custom">Salva modifiche</button>
      </div>
    </div>
  </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from "@ckeditor/ckeditor5-vue";
import moment from "moment";
import {useWikiStore} from "@/stores/wiki";
import ComponentText from "@/components/shared/ComponentText";
import {useUtilsStore} from "@/stores/utils";
import {useAuthStore} from "@/stores/auth";

export default {
  name: "ComponentDetailWiki",
  components: {
    ComponentText,
    ckeditor: CKEditor.component,
  },
  props: {
    idProp: {
      type: Number
    },
    entity: {
      type: String
    },
    id: {
      type: Number
    }
  },
  setup() {
    const storeAuth = useAuthStore()
    const storeWiki = useWikiStore()
    return {
      storeWiki,storeAuth
    }
  },
  data() {
    return {
      lastReason: null,
      isEditing: false,
      currentObj: null,
      content: '',
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            '|',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'indent',
            'outdent',
            '|',
            'blockQuote',
            'insertTable',
            'undo',
            'redo'
          ]
        },
        language: 'it'
      }
    }
  },
  created() {
    useWikiStore().getWiki(this.idProp)
        .then(() => {
        })
        .catch(() => {
        })
  },
  watch: {},
  computed: {},
  mounted() {
  },
  methods: {
    returnNameEntity() {
      let name = ''
      if (this.storeWiki.wikiGetters.category)
        name = this.storeWiki.wikiGetters.category.name
      if (this.storeWiki.wikiGetters.subcategory)
        name = this.storeWiki.wikiGetters.subcategory.name
      if (this.storeWiki.wikiGetters.project)
        name = this.storeWiki.wikiGetters.project.name
      return name
    },
    save() {
      this.lastReason = null
      useWikiStore().editWiki(this.storeWiki.wikiGetters)
          .then(res => {
            useWikiStore().wiki = res.data
            this.$emit('closeModal')
          })
          .catch(() => {
          })
    },
    returnDate(date) {
      return moment(date).format('DD/MM/YYYY HH:mm:ss')
    }
  },
  updated() {
  },
  beforeUnmount() {
    if (useWikiStore().controllerGetWikiGetters)
      useWikiStore().controllerGetWikiGetters.abort()
    useUtilsStore().textAnalysis = null
    useWikiStore().wiki = null
  },
  unmounted() {
  }
}
</script>

<style scoped>
:deep(a) {
  color: var(--primary-color) !important;
  text-decoration: underline !important;
}

.btn-light-grey-custom {
  margin-left: 15px;
}

:deep(.table table td) {
  border-width: 1px !important;
}

.btn-primary-custom {
  margin-left: 15px;
}

.container-content-right-footer img {
  margin-right: 10px;
}

.container-content-right-footer {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.container-footer-wiki {
  display: flex;
  padding: 10px 15px;
  align-items: center;
}

.img-drop-down {
  width: 30px;
  display: flex;
  cursor: pointer;
  margin-left: auto;
}

.container-top-footer {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-top: 1px solid var(--light-grey-color);
  border-bottom: 1px solid var(--light-grey-color);
}

.container-content-detail {
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
}

.img-close {
  display: flex;
  margin-left: auto;
}

.container-content-head-detail {
  display: flex;
}

.container-head-detail {
  background: var(--dark-white-color);
  border-radius: 2px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
}
</style>