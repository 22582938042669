import {createApp} from 'vue'
import App from './App.vue'
import router from "@/router";
import BootstrapVueNext from 'bootstrap-vue-next'
import mitt from 'mitt';
import {createPinia} from 'pinia'
import {createPersistedStatePlugin} from 'pinia-plugin-persistedstate-2'
import {vBTooltip, vBPopover} from "bootstrap-vue-next";
import './assets/css/main.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import moment from "moment";
import 'chartjs-adapter-moment';
import vClickOutside from "click-outside-vue3"
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import AuthPlugin from '@/plugins/auth';
import keycloakService from '@/services/keycloak';
// import instance from "@/services/api";


const emitter = mitt();
const pinia = createPinia()

moment.locale('it')

// instance

// instance.defaults.baseURL = process.env.VUE_APP_BACKEND_HOST

const renderApp = () => {
    const app = createApp(App)
    app.directive('b-tooltip', vBTooltip)
    app.directive('b-popover', vBPopover)
    app.config.globalProperties.emitter = emitter
    app.use(router)
    app.component('VueDatePicker', VueDatePicker);
    app.use(vClickOutside)
    app.use(BootstrapVueNext)
    app.use(AuthPlugin, { pinia });
    app.use(pinia)
    pinia.use(createPersistedStatePlugin())
    app.mount('#app')
}

// renderApp()
keycloakService.CallInit(renderApp);