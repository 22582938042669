import {defineStore} from 'pinia'
import axiosInstance from "@/services/api";
import {useApiSettingStore} from "@/stores/apiSettings";
import {useFavoriteStore} from "@/stores/favorites";

export const useUtilsStore = defineStore({
    id: 'utils',

    state: () => ({
        // counter token interceptor
        retry401Counter: 0,

        /*** debounce updated filters ***/
        isLoadingConfirmFilters: false,
        spinnerFilterUpdatedLists: false,
        /*** fine ***/

        // text-mining
        textAnalysis: null,

        // mapping entity text-mining
        dictionaryEntityTextMining: {
            0: '#4285f4',
            1: '#34a853',
            2: '#f538a0',
            3: '#185abc',
            4: '#a142f4',
            5: '#b31412',
            6: '#f29900',
            7: '#ea4335',
            8: '#9aa0a6',
            9: '#A9D5F7',
            10: '#15803D',
            11: '#9C246E',
            12: '#47402E',
        },

        // controllers
        controllerGetGenericOptionCycle: null,
        // controllers

        // v-model del global search
        globalSearch: '',

        /*** inizio: filters shared ***/
        search: '',
        ordering: new Set(),
        page: 1,
        dictionaryFilterFinal: {},
        dictionaryFilterCategoriesInArchiveFinal: {},
        /*** fine ***/

        /*** inizio: mapping contenttype ***/
        dictionarySearch: {
            category: {
                count: 0,
                endpoint: 'categories',
                single_name: 'categoria',
                key: 'category',
                name: 'Categorie'
            },
            subcategory: {
                count: 0,
                endpoint: 'subcategories',
                key: 'subcategory',
                single_name: 'sottocategoria',
                name: 'Sottocategorie'
            },
            project: {
                count: 0,
                endpoint: 'projects',
                key: 'project',
                single_name: 'progetto',
                name: 'Progetti'
            },
            document: {
                count: 0,
                endpoint: 'documents',
                key: 'document',
                single_name: 'documento',
                name: 'Documenti'
            },
            wiki: {
                count: 0,
                endpoint: 'wikis',
                key: 'wiki',
                single_name: 'wiki',
                name: 'Wiki'
            },
            tag: {
                count: 0,
                endpoint: 'tags',
                key: 'tag',
                single_name: 'tag',
                name: 'Tag'
            }
        },
        /*** fine ***/

        /*** inizio: informazioni necessarie per la cronologia nel ComponentHeader ***/
        chronology: [],
        currentNamePage: '',
        /*** fine ***/

        /*** inizio: informazioni necessarie per il component generico ComponentGenericWidgetLists ***/
        dictionaryGenericWidgetList: {
            'groupUsers': {
                selectedAvailables: [],
                selectedAdded: [],
                itemsAdded: [],
                itemsRemoved: []
            },
            'groupPermissions': {
                selectedAvailables: [],
                selectedAdded: [],
                itemsAdded: [],
                itemsRemoved: []
            },
            'userPermissions': {
                selectedAvailables: [],
                selectedAdded: [],
                itemsAdded: [],
                itemsRemoved: []
            },
            'userGroups': {
                selectedAvailables: [],
                selectedAdded: [],
                itemsAdded: [],
                itemsRemoved: []
            },
            'permissions': {
                selectedAvailables: [],
                selectedAdded: [],
                itemsAdded: [],
                itemsRemoved: []
            },
            'users': {
                selectedAvailables: [],
                selectedAdded: [],
                itemsAdded: [],
                itemsRemoved: []
            },
            'categories': {
                selectedAvailables: [],
                selectedAdded: [],
                itemsAdded: [],
                itemsRemoved: []
            },
            'subcategories': {
                selectedAvailables: [],
                selectedAdded: [],
                itemsAdded: [],
                itemsRemoved: []
            },
            'projects': {
                selectedAvailables: [],
                selectedAdded: [],
                itemsAdded: [],
                itemsRemoved: []
            },
            'documents': {
                selectedAvailables: [],
                selectedAdded: [],
                itemsAdded: [],
                itemsRemoved: []
            },
            'groups': {
                selectedAvailables: [],
                selectedAdded: [],
                itemsAdded: [],
                itemsRemoved: []
            }
        },
        /*** fine ***/

        // option dinamiche per i form
        genericOption: {},
        // option dei filtri dinamici per i filtri
        genericFiltersetFields: {
            'log-entries': {},
            'users': {},
            'categories': {},
            'favorites': {},
            'subcategories': {},
            'projects': {},
            'documents': {},
            'wikis': {},
            'public-walls': {},
            'suggestions': {},
        },
        // booleano per mostrare l'hamburger menu
        toggleHamburgerMenu: false,
        // serve per la gestione del componente all'interno di ComponentSettings
        showedDetailUser: false,
        // id dell'utente da visualizzare
        currentIdToShowDetail: false,
        /*** inizio: variabili comuni per le quando si vuole eliminare un'entità ***/
        infoForToDelete: {
            listIds: [],
            messageToDelete: {},
            typeObjectToDelete: '',
            currentPosition: '',
            entity: '',
            name: '',
            objectToDelete: null,
            confirmDelete: false,
            action: {},
            actionAfterDelete: {},
        },
        spinnerDeleting: false,
        /*** fine ***/

        // count generico usato per indicare quanti elementi ci sono alla creazione del componente
        genericCountList: 0,
        // lista di id che vengono eliminati dal bulk delete
        listIds: [],
        maxCol: 0,
        lastResponse: null,
        // AZURE_CONNECTION_STRING
        azureConnectionString: null,
        azureConnectionUrl: null,
        azureConnectionChat: null,
    }),
    actions: {
        resetDictionaryWidgetList() {
            this.dictionaryGenericWidgetList = {
                'groupUsers': {
                    selectedAvailables: [],
                    selectedAdded: [],
                    itemsAdded: [],
                    itemsRemoved: []
                },
                'groupPermissions': {
                    selectedAvailables: [],
                    selectedAdded: [],
                    itemsAdded: [],
                    itemsRemoved: []
                },
                'userPermissions': {
                    selectedAvailables: [],
                    selectedAdded: [],
                    itemsAdded: [],
                    itemsRemoved: []
                },
                'userGroups': {
                    selectedAvailables: [],
                    selectedAdded: [],
                    itemsAdded: [],
                    itemsRemoved: []
                },
                'permissions': {
                    selectedAvailables: [],
                    selectedAdded: [],
                    itemsAdded: [],
                    itemsRemoved: []
                },
                'users': {
                    selectedAvailables: [],
                    selectedAdded: [],
                    itemsAdded: [],
                    itemsRemoved: []
                },
                'categories': {
                    selectedAvailables: [],
                    selectedAdded: [],
                    itemsAdded: [],
                    itemsRemoved: []
                },
                'subcategories': {
                    selectedAvailables: [],
                    selectedAdded: [],
                    itemsAdded: [],
                    itemsRemoved: []
                },
                'projects': {
                    selectedAvailables: [],
                    selectedAdded: [],
                    itemsAdded: [],
                    itemsRemoved: []
                },
                'documents': {
                    selectedAvailables: [],
                    selectedAdded: [],
                    itemsAdded: [],
                    itemsRemoved: []
                },
                'groups': {
                    selectedAvailables: [],
                    selectedAdded: [],
                    itemsAdded: [],
                    itemsRemoved: []
                }
            }
        },
        checkIfThereAreActiveFilters(entity) {
            let bool = false
            for (let key in useUtilsStore().genericFiltersetFieldsGetters[entity]) {
                if (Array.isArray(useUtilsStore().genericFiltersetFieldsGetters[entity][key].value)) {
                    if (useUtilsStore().genericFiltersetFieldsGetters[entity][key].value.length > 0)
                        bool = true
                } else if (useUtilsStore().genericFiltersetFieldsGetters[entity][key].value !== null && useUtilsStore().genericFiltersetFieldsGetters[entity][key].value !== 0 && useUtilsStore().genericFiltersetFieldsGetters[entity][key].value !== undefined && useUtilsStore().genericFiltersetFieldsGetters[entity][key].value !== '')
                    bool = true
            }
            return bool
        },
        resetFilterAndOtherInfo() {
            this.search = ''
            this.ordering = new Set()
            this.page = 1
            this.dictionaryFilterFinal = {}
        },
        getTextAnalysis(payload) {
            return new Promise((resolve, reject) => {
                axiosInstance.post('api/utils/text-analysis/', payload)
                    .then(res => {
                        this.textAnalysis = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        bulkDeleteFromList(payload) {
            return new Promise((resolve, reject) => {
                axiosInstance.post('api/' + payload.entity + '/bulk-destroy/', {ids: payload.list_ids})
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        resetConfirmDelete() {
            useFavoriteStore().isDeleted = false
            this.infoForToDelete['objectToDelete'] = null
            this.infoForToDelete['listIds'] = []
            this.infoForToDelete['entity'] = ''
            this.infoForToDelete['name'] = ''
            this.infoForToDelete['currentPosition'] = ''
            this.infoForToDelete['action'] = {}
            this.infoForToDelete['actionAfterDelete'] = {}
            this.infoForToDelete['confirmDelete'] = false
            this.infoForToDelete['messageToDelete'] = {}
            this.infoForToDelete['typeObjectToDelete'] = ''
            this.infoForToDelete['currentPosition'] = ''
        },
        getGenericOptionCycle(payload) {
            return new Promise((resolve, reject) => {
                this.controllerGetGenericOptionCycle = new AbortController()

                const config = {
                    signal: useUtilsStore().controllerGetGenericOptionCycleGetters.signal
                }

                let payloadFinal = ''
                payload.forEach(item => {
                    if (typeof item !== 'object')
                        payloadFinal += item + '/'
                    else if (typeof item === 'object') {
                        if ('objectID' in item) {
                            payloadFinal += item.objectID + '/'
                        }
                    }
                })

                axiosInstance.options('api/' + payloadFinal, config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getGlobalSearch(payload) {
            return new Promise((resolve, reject) => {

                let url = new URL(axiosInstance.defaults.baseURL + 'api/' + useUtilsStore().dictionarySearchGetters[payload.key].endpoint)

                let params = new URLSearchParams()
                for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                    params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                }
                url.search = params
                axiosInstance.get(url.pathname + url.search)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        getAzureConnectionString() {
            return new Promise((resolve, reject) => {

                axiosInstance.get('api/interactions/get-connection-string/')
                    .then(res => {
                        this.azureConnectionString = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        getAzureConnectionUrl() {
            return new Promise((resolve, reject) => {

                axiosInstance.get('api/interactions/get-connection-url/')
                    .then(res => {
                        this.azureConnectionUrl = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        getAzureConnectionChat() {
            return new Promise((resolve, reject) => {

                axiosInstance.get('api/interactions/get-connection-chat/')
                    .then(res => {
                        this.azureConnectionChat = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        getListFromModelName(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    signal: payload.controller.signal
                }
                let url
                if (payload.key === 'permissions' || payload.key === 'groups')
                    url = new URL(axiosInstance.defaults.baseURL + 'api/' + payload.key)
                else
                    url = new URL(axiosInstance.defaults.baseURL + 'api/' + payload.key + 's')

                let params = new URLSearchParams()
                for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                    params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                }
                url.search = params
                axiosInstance.get(url.pathname + url.search, config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },

        getGenericOption(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    signal: payload.controller.signal
                }
                axiosInstance.options('api/' + payload.myFilter + '/', config)
                    .then(res => {
                        this.genericFiltersetFields[payload.myFilter] = {}
                        this.genericFiltersetFields[payload.myFilter] = res.data.filterset_fields
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getInfoFromFilterOrSearchFromGenericAction(payload) {
            return new Promise((resolve, reject) => {
                this.spinnerFilterUpdatedLists = true
                payload.action(payload)
                    .then(() => {
                        this.spinnerFilterUpdatedLists = false
                        resolve()
                    })
                    .catch(() => {
                        this.spinnerFilterUpdatedLists = true
                        reject()
                    })

            })
        }
    },
    getters: {
        chronologyGetters: state => {
            return state.chronology
        },
        showedDetailUserGetters: state => {
            return state.showedDetailUser
        },
        toggleHamburgerMenuGetters: state => {
            return state.toggleHamburgerMenu
        },
        currentNamePageGetters: state => {
            return state.currentNamePage
        },
        genericOptionGetters: state => {
            return state.genericOption
        },
        infoForToDeleteGetters: state => {
            return state.infoForToDelete
        },
        genericCountListGetters: state => {
            return state.genericCountList
        },
        currentIdToShowDetailGetters: state => {
            return state.currentIdToShowDetail
        },
        maxColGetters: state => {
            return state.maxCol
        },
        genericFiltersetFieldsGetters: state => {
            return state.genericFiltersetFields
        },
        listIdsGetters: state => {
            return state.listIds
        },
        lastResponseGetters: state => {
            return state.lastResponse
        },
        spinnerDeletingGetters: state => {
            return state.spinnerDeleting
        },
        dictionarySearchGetters: state => {
            return state.dictionarySearch
        },
        globalSearchGetters: state => {
            return state.globalSearch
        },
        dictionaryFilterFinalGetters: state => {
            return state.dictionaryFilterFinal
        },
        dictionaryFilterCategoriesInArchiveFinalGetters: state => {
            return state.dictionaryFilterCategoriesInArchiveFinal
        },
        searchGetters: state => {
            return state.search
        },
        pageGetters: state => {
            return state.page
        },
        orderingGetters: state => {
            return state.ordering
        },
        dictionaryGenericWidgetListGetters: state => {
            return state.dictionaryGenericWidgetList
        },
        isLoadingConfirmFiltersGetters: state => {
            return state.isLoadingConfirmFilters
        },
        spinnerFilterUpdatedListsGetters: state => {
            return state.spinnerFilterUpdatedLists
        },
        textAnalysisGetters: state => {
            return state.textAnalysis
        },
        dictionaryEntityTextMiningGetters: state => {
            return state.dictionaryEntityTextMining
        },
        azureConnectionStringGetters: state => {
            return state.azureConnectionString
        },
        azureConnectionUrlGetters: state => {
            return state.azureConnectionUrl
        },
        azureConnectionChatGetters: state => {
            return state.azureConnectionChat
        },
        controllerGetGenericOptionCycleGetters: state => {
            return state.controllerGetGenericOptionCycle
        },
        retry401CounterGetters: state => {
            return state.retry401Counter
        }
    }
})
