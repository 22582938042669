<template>
  <div class="table-responsive" v-if="storeUtils.textAnalysisGetters.Syntax.tokens.length">
    <table>
      <thead>
      <th></th>
      <th :key="i" v-for="(field,i) in Object.keys(ths)">
        {{ths[field].field_key_translated}}
      </th>
      </thead>
      <tbody>
      <tr v-for="(token,i) in storeUtils.textAnalysisGetters.Syntax.tokens" :key="i">
        <td class="td-text-no-border">{{ token.text.content }}</td>
        <td>{{ token.part_of_speech.tag.value_verbose_translated }}</td>
        <td>{{ token.part_of_speech.aspect.value_verbose_translated }}</td>
        <td>{{ token.part_of_speech.case.value_verbose_translated }}</td>
        <td>{{ token.part_of_speech.form.value_verbose_translated }}</td>
        <td>{{ token.part_of_speech.gender.value_verbose_translated }}</td>
        <td>{{ token.part_of_speech.mood.value_verbose_translated }}</td>
        <td>{{ token.part_of_speech.number.value_verbose_translated }}</td>
        <td>{{ token.part_of_speech.person.value_verbose_translated }}</td>
        <td>{{ token.part_of_speech.proper.value_verbose_translated }}</td>
        <td>{{ token.part_of_speech.reciprocity.value_verbose_translated }}</td>
        <td>{{ token.part_of_speech.tense.value_verbose_translated }}</td>
        <td>{{ token.part_of_speech.voice.value_verbose_translated }}</td>
      </tr>
      </tbody>
    </table>
  </div>
  <div v-else>Non ci sono dati da analizzare</div>
</template>

<script>
import {useUtilsStore} from "@/stores/utils";

export default {
  name: "ComponentTextMiningSyntax",
  props: {},
  setup() {
    const storeUtils = useUtilsStore()
    return {
      storeUtils
    }
  },
  data() {
    return {
      ths: {}
    }
  },
  created() {
    for(const field in this.storeUtils.textAnalysisGetters.Syntax.tokens[0].part_of_speech){
      this.ths[field] = {
        value: this.storeUtils.textAnalysisGetters.Syntax.tokens[0].part_of_speech[field].value,
        field_key_translated: this.storeUtils.textAnalysisGetters.Syntax.tokens[0].part_of_speech[field].field_key_translated,
      }
    }
  },
  watch: {},
  computed: {},
  mounted() {
  },
  methods: {},
  updated() {
  },
  beforeUnmount() {
  },
  unmounted() {
  }
}
</script>

<style scoped>
/* Imposta lo sfondo della riga pari */
tr:nth-child(even) {
  background-color: #E7EAED;
}

th {
  color: var(--secondary-color) !important;
  font-size: 16px !important;
}

.td-text-no-border {
  border: unset !important;
  font-size: 16px !important;
  color: var(--primary-color) !important;
}

td {
  min-width: 200px!important;
  font-size: 14px;
  color: var(--dark-grey-color);
  border-collapse: collapse !important;
}

thead, th {
  position: relative !important;
}
</style>