<template>
  <ComponentArchive v-if="$route.path === '/archive'"></ComponentArchive>
  <router-view v-else></router-view>
</template>

<script>
import ComponentArchive from "@/components/archive/ComponentArchive.vue";
import {pages} from "../../vue.config";

export default {
  name: "ArchiveView",
  components: {ComponentArchive},
  setup() {
    return {}
  },
  data() {
    return {}
  },
  created() {
  },
  watch: {},
  computed: {},
  mounted() {
    document.title = 'Archivio | ' + pages.index.title
  },
  methods: {},
  updated() {
  },
  beforeUnmount() {
  },
  unmounted() {
  }
}
</script>

<style scoped>

</style>