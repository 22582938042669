<template>
  <ComponentDetailUser></ComponentDetailUser>
</template>

<script>
import ComponentDetailUser from "@/components/settings/ComponentDetailUser.vue";
import {pages} from "../../vue.config";

export default {
  name: "DetailUserView",
  components: {ComponentDetailUser},
  created() {
  },
  mounted() {
    document.title = 'Dettaglio utente' + ' | ' + pages.index.title
  }
}
</script>

<style scoped>

</style>