<template>
  <div :class="{container_horizontal_list: showTabs}" id="container-horizontal-list" class="container-horizontal-list">
    <div v-show="!showTabs && isInOverflow" class="container-arrow-horizontal-list" @click="scrollLeft">
      <svg style="transform: rotate(180deg)" width="24" height="24" viewBox="0 0 24 24" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path d="M4.49512 12H19.5045" stroke="#CF3092" stroke-width="1.5"/>
        <path d="M12.5 19.0044L19.5044 12L12.5 4.99561" stroke="#CF3092" stroke-width="1.5"/>
      </svg>
    </div>
    <div :class="{horizontal_list: showTabs}" id="horizontal-list" class="horizontal-list" ref="list">
      <div @click="goToCategory(category)" v-for="(category,index) in storeCategory.infiniteHorizontalArrayGetters"
           :key="index" :id="'category_' + index" @mouseover="mouseOverCategory(index)"
           @mouseleave="mouseLeaveCategory(index)"
           :class="{container_single_category: showTabs}"
           class="container-single-category">
        <div :style="{background: category.color}" class="circle-color"></div>
        <div :id="'container_footer_' + index" class="container-footer">
          <span class="text-16px-white-medium">{{ category.name }}</span>
        </div>
      </div>
      <span class="text-16px-dark-grey-medium"
            v-if="!isLoading && storeCategory.infiniteHorizontalArrayGetters.length === 0">Non ci sono categorie presenti</span>
    </div>
    <b-spinner
        v-if="isLoading" style="position: absolute; top: 50%;right: 50%;margin: 50px"></b-spinner>

    <div v-show="!showTabs && isInOverflow" class="container-arrow-horizontal-list" @click="scrollRight">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.49512 12H19.5045" stroke="#CF3092" stroke-width="1.5"/>
        <path d="M12.5 19.0044L19.5044 12L12.5 4.99561" stroke="#CF3092" stroke-width="1.5"/>
      </svg>
    </div>
  </div>
  <b-modal :hide-footer="true" :hide-header="true" @hide="closeModal" v-model="showNewCategory">
    <div class="container-head-form">
      <div class="container-content-head-form">
        <span class="text-16px-black-bold">Crea una categoria</span>
      </div>
      <img @click="closeModal" class="img-close" src="../../assets/shared/close.svg" alt="">
    </div>
    <ComponentDynamicForm @close-form-without-update="showNewCategory = false" :current-object="null"
                          v-if="showNewCategory" @closeForm="closeModal" :options="{
      endpoints:
      [
          {
            'endpoint': 'categories',
            'type': TYPE_OF_OPTION.CREATE,
            'path': [
              'categories'
            ]
          }
      ]
      }"></ComponentDynamicForm>
  </b-modal>

</template>

<script>
import {useUtilsStore} from "@/stores/utils";
import {useCategoryStore} from "@/stores/categories";
import ComponentDynamicForm from "@/components/form/ComponentDynamicForm";
import {TYPE_OF_OPTION} from "@/globals";
import {useAuthStore} from "@/stores/auth";

export default {
  name: 'ComponentListCategories',
  components: {ComponentDynamicForm},
  emits: ['resetModalEmit', 'closeModalFilters'],
  setup() {
    const storeCategory = useCategoryStore()
    return {
      TYPE_OF_OPTION,
      storeCategory
    }
  },
  props: {
    showTabs: {
      type: Boolean
    },
    showNewCategoryProp: {
      type: Boolean
    }
  },
  data() {
    return {
      controller: null,
      currentPage: 1,
      isInOverflow: false,
      showNewCategory: this.showNewCategoryProp,
      isLoading: false
    }
  },
  async created() {
    this.emitter.on('updatedListCategories', async () => {
      await this.loadCategories()
    })

    useUtilsStore().resetFilterAndOtherInfo()
    useCategoryStore().infiniteHorizontalArray = []
    await this.loadCategories()
  },
  watch: {
    showNewCategoryProp: {
      handler: function (value) {
        this.showNewCategory = value
      }
    }
  },
  async mounted() {
    // rileva lo scorrimento dell'utente sulla lista
    let container_single_section = document.getElementById('container-single-section')

    if (container_single_section) {
      container_single_section.addEventListener('scroll', () => {
        const scrollable = container_single_section;
        const {scrollHeight, clientHeight, scrollTop} = scrollable;
        const isAtBottom = scrollHeight - parseInt(scrollTop) === clientHeight;
        if (isAtBottom && useCategoryStore().categoriesGetters.next) {
          this.loadCategories()
        }
      })
    }

    this.$refs.list.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.checkIfDivIsInOverflow)

    let isScrolling = false;
    this.$refs.list.addEventListener('wheel', (event) => {
      if (!isScrolling) {
        isScrolling = true;
        setTimeout(() => {
          isScrolling = false;
          if (event.deltaY > 0) {
            this.scrollRight()
          } else {
            this.scrollLeft()
          }
        }, 100);
      }
      this.checkIfDivIsInOverflow()
    }, {passive: true})
    // carica i primi dati al caricamento del componente
    await this.loadCategories()

    if (window.innerWidth > 991.98)
      this.checkIfDivIsInOverflow()
  },
  methods: {
    closeModalFilters() {
      this.showFilters = false
      this.$emit('closeModalFilters')
    },
    resetDictFilter() {
      this.showFilters = false
      this.currentPage = 1
      this.loadCategories()
    },
    closeModal() {
      this.$emit('resetModalEmit')
      this.$emit('closeModalFilters')
      this.currentPage = 1
      this.storeCategory.infiniteHorizontalArray = []
      this.loadCategories()
    },
    checkIfDivIsInOverflow() {
      const container = this.$refs.list
      if (window.innerWidth > 991.98) {
        if (container) {
          if (container.scrollHeight > container.clientHeight || container.scrollWidth > container.clientWidth) {
            this.isInOverflow = true
          } else {
            this.isInOverflow = false
          }
        }
      }
    },
    scrollLeft() {
      const list = this.$refs.list
      list.scrollLeft -= 500
    },
    scrollRight() {
      const list = this.$refs.list
      list.scrollLeft += 500
    },
    mouseOverCategory(i) {
      let container_footer = document.getElementById('container_footer_' + i)
      if (container_footer) {
        container_footer.style.transition = 'max-height 0.5s cubic-bezier(0, 1, 0, 1)'
        container_footer.style.maxHeight = '100%'
      }
    },
    mouseLeaveCategory(i) {
      let container_footer = document.getElementById('container_footer_' + i)
      if (container_footer) {
        container_footer.style.transition = 'max-height 0.5s ease-in-out'
        container_footer.style.maxHeight = '40%'
      }
    },
    // eslint-disable-next-line no-unused-vars
    goToCategory(category) {
      useCategoryStore().currentColor = category.color
      this.$router.push({name: 'detailCategory', params: {categoryId: category.id}})
          .then(() => {
            useUtilsStore().currentNamePage = category.name
          })
          .catch(() => {
          })
    },
    handleScroll() {
      const list = this.$refs.list
      const scrollWidth = list.scrollWidth
      const clientWidth = list.clientWidth
      const scrollLeft = list.scrollLeft

      if (scrollWidth - clientWidth - scrollLeft < 50) {
        // carica nuovi dati quando l'utente raggiunge la fine della lista
        if (useCategoryStore().categoriesGetters.next) {
          this.loadCategories()
        }
      }
    },
    async loadCategories() {
      if (this.isLoading) return // evita di caricare nuovi dati se un caricamento è già in corso
      this.isLoading = true // indica che un caricamento è in corso
      let queryParams = {
        page: this.currentPage
      }

      if (useUtilsStore().dictionaryFilterCategoriesInArchiveFinalGetters)
        if (useUtilsStore().dictionaryFilterCategoriesInArchiveFinalGetters !== {} && Object.keys(useUtilsStore().dictionaryFilterCategoriesInArchiveFinalGetters).length > 0) {
          for (let key in useUtilsStore().dictionaryFilterCategoriesInArchiveFinalGetters) {
            queryParams[key] = useUtilsStore().dictionaryFilterCategoriesInArchiveFinalGetters[key]
          }
        }

      await useCategoryStore().getCategories({queryParams: queryParams})
          .then(() => {
            this.currentPage++

            this.controller = new AbortController();
            const signal = this.controller.signal;
            useCategoryStore().infiniteHorizontalArrayGetters.forEach((el, i) => {
              fetch(el.thumbnail_img, {
                headers: {
                  Authorization: 'Bearer ' + useAuthStore().keycloakUserGetters.token
                },
                signal
              })
                  .then(response => response.blob())
                  .then(blob => {
                    let img = document.getElementById('category_' + i);
                    let url = URL.createObjectURL(blob);
                    if (img)
                      img.style.backgroundImage = `url(${url})`;
                  })
                  .catch(error => {
                    if (error.name === 'AbortError') {
                      console.log('Richiesta interrotta');
                    } else {
                      console.error(error);
                    }
                  });
            })
            this.isLoading = false // indica che il caricamento è stato completato
          })
          .catch(() => {
          })
    }
  },
  updated() {
    if (window.innerWidth > 991.98)
      this.checkIfDivIsInOverflow()
  },
  beforeUnmount() {
    if (this.controller)
      this.controller.abort();

    if (useCategoryStore().controllerGetCategoriesGetters)
      useCategoryStore().controllerGetCategoriesGetters.abort()
    this.emitter.off('updatedListCategories')
    let container_single_section = document.getElementById('container-single-section')
    container_single_section.removeEventListener('scroll', () => {
    })

    window.removeEventListener('resize', this.checkIfDivIsInOverflow)
    // rimuove l'evento di scroll alla distruzione del componente
    this.$refs.list.removeEventListener('scroll', this.handleScroll)
    this.$refs.list.removeEventListener('wheel', () => {
    })
  },
  unmounted() {
    this.emitter.off('updatedListCategories')
    window.removeEventListener('resize', this.checkIfDivIsInOverflow)
  }
}
</script>

<style>
.container_single_category.container-single-category {
  max-width: 100%;
  margin-right: 0px;
  min-height: 160px;
  background-size: cover;
  /*height: 50vh;*/
}

.horizontal_list.horizontal-list {
  display: flex;
  overflow: unset;
  flex-direction: column;
}

.container_horizontal_list.container-horizontal-list {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.img-section {
  margin-right: 10px;
}

.container-filter {
  margin-left: auto;
}

.spinner-border {
  padding: 12.5px;
  margin: auto 0px !important;
}

.container-horizontal-list {
  position: relative;
  display: flex;
  min-height: 80px;
  align-items: center;
}

.container-footer {
  padding: 5px 10px;
  display: flex;
  max-height: 40%;
  height: 100%;
  align-items: flex-start;
  background: rgba(17, 24, 39, 0.7);
  border-radius: 0px 0px 4px 4px;
  margin-top: auto;
  width: 100%;
}

.container-head-section {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.circle-color {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background: red;
}

.horizontal-list {
  width: 100%;
  align-items: center;
  display: flex;
  padding: 10px 10px 10px 0px;
  flex-wrap: nowrap;
  scroll-behavior: smooth;
  overflow: auto;
  scroll-snap-type: x proximity;
  -webkit-overflow-scrolling: touch; /* abilita lo scrolling fluido su iOS */
}

.container-single-category {
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 0px 0px 4px 4px;
  display: flex;
  min-width: 22%;
  max-width: 22%;
  width: 100%;
  height: 160px;
  position: relative;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.horizontal-list::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.horizontal-list::-webkit-scrollbar-thumb {
  background: var(--dark-grey-color);
  border-radius: 10px;
}

.horizontal-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.loading {
  text-align: center;
  margin-top: 16px;
}


/*// X-Small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
  .horizontal-list {
    display: flex !important;
    flex-direction: column !important;
  }
}

/*// Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
  .container-single-category {
    min-width: 50%;
  }
}

/*// Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {
  .horizontal_list.horizontal-list {
    align-items: center;
    padding: 10px 15px;
    display: grid;
    column-gap: 20px;
    row-gap: 20px;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  }
}

/*// Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {
}

/*// X-Large devices (large desktops, less than 1400px)*/
@media (max-width: 1399.98px) {
}

</style>