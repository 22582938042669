module.exports = {
    pages: {
        index: {
            // entry for the page
            entry: 'src/main.js',
            title: 'Coopservice',
        }
    },
    configureWebpack: {
        module: {
            rules: [
                {
                    test: /\.mjs$/,
                    include: /node_modules/,
                    type: "javascript/auto"
                }
            ]
        }
    }
}