import {defineStore} from 'pinia'
import axiosInstance from "@/services/api";
import {useAuthStore} from "@/stores/auth";
import {useApiSettingStore} from "@/stores/apiSettings";
import {useUtilsStore} from "@/stores/utils";
import router from "@/router";

export const useUserStore = defineStore("user", {
    state: () => ({
        /*** controller ***/
        controllerGetUserWithoutState: null,
        controllerGetUsers: null,
        controllerGetUser: null,
        /*** ***/
        users: null,
        arrayUsersInfinity: [],
        optionsUser: null,
        optionsUserDetail: null,
        user: null,
        allUsers: null,
        oldUser: null,
        spinnerUser: false
    }),
    actions: {
        getOptionsUser() {
            return new Promise((resolve, reject) => {
                axiosInstance.options('api/users/')
                    .then(res => {
                        this.optionsUser = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        getOptionsUserDetail() {
            return new Promise((resolve, reject) => {
                axiosInstance.options('api/users-details/')
                    .then(res => {
                        this.optionsUserDetail = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        getUser(id) {
            return new Promise((resolve, reject) => {
                axiosInstance.get('api/users/' + id + '/')
                    .then(res => {
                        this.user = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        getUserWithoutState(id) {
            return new Promise((resolve, reject) => {
                this.controllerGetUserWithoutState = new AbortController()
                const config = {
                    signal: useUserStore().controllerGetUserWithoutStateGetters.signal
                }

                axiosInstance.get('api/users/' + id + '/', config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        getUsers(payload) {
            return new Promise((resolve, reject) => {
                this.controllerGetUsers = new AbortController()
                const config = {
                    signal: useUserStore().controllerGetUsersGetters.signal
                }

                let url = new URL(axiosInstance.defaults.baseURL + 'api/users/')

                this.spinnerUser = true
                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axiosInstance.get(url.pathname + url.search, config)
                        .then(res => {
                            this.users = res.data
                            this.spinnerUser = false
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerUser = false
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                } else {
                    axiosInstance.get(url.pathname, config)
                        .then(res => {
                            this.spinnerUser = false
                            this.users = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerUser = false
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                }

            })
        },
        getUsersWithoutSetState(payload) {
            return new Promise((resolve, reject) => {
                let url = new URL(axiosInstance.defaults.baseURL + 'api/users/')

                this.spinnerUser = true
                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axiosInstance.get(url.pathname + url.search)
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerUser = false
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                } else {
                    axiosInstance.get(url.pathname)
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerUser = false
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                }

            })
        },
        getAllUsers(payload) {
            return new Promise((resolve, reject) => {
                let url = new URL(axiosInstance.defaults.baseURL + 'api/users/')

                let params = new URLSearchParams()
                for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                    params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                }
                url.search = params
                axiosInstance.get(url.pathname + url.search)
                    .then(res => {
                        this.allUsers = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })

            })
        },
        registerUser(payload) {
            return new Promise((resolve, reject) => {
                const config = {}
                if (useAuthStore().keycloakUserGetters) {
                    config['headers'] = {
                        Authorization: 'Bearer ' + useAuthStore().keycloakUserGetters.token
                    }
                }
                axiosInstance.post('/api/users/', payload, config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        registerUserDetail(payload) {
            return new Promise((resolve, reject) => {
                const config = {}
                if (useAuthStore().keycloakUserGetters) {
                    config['headers'] = {
                        Authorization: 'Bearer ' + useAuthStore().keycloakUserGetters.token
                    }
                }
                if (useUtilsStore().lastResponseGetters) {
                    if (payload instanceof FormData)
                        payload.set('custom_user', useUtilsStore().lastResponseGetters.id)
                    else
                        payload['custom_user'] = useUtilsStore().lastResponseGetters.id
                }
                axiosInstance.post('/api/users-details/', payload, config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        if (useUtilsStore().lastResponseGetters) {
                            useUserStore().deleteUser(useUtilsStore().lastResponseGetters.id)
                                .then(() => {
                                })
                                .catch(() => {
                                })
                        }
                        reject(err)
                    })
            })
        },
        getUsersByCUI(payload) {
            return new Promise((resolve, reject) => {
                axiosInstance.post('/api/users/get-user-by-cui/', payload)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        if (useUtilsStore().lastResponseGetters) {
                            useUserStore().deleteUser(useUtilsStore().lastResponseGetters.id)
                                .then(() => {
                                })
                                .catch(() => {
                                })
                        }
                        reject(err)
                    })
            })
        },
        register(payload) {
            return new Promise((resolve, reject) => {

                axiosInstance.post('/api/users/', {
                    username: payload.values.username,
                    email: payload.values.email,
                    password: payload.values.password,
                    confirmation: payload.values.confirmation,
                    user_type: payload.user_type
                })
                    .then(res => {
                        axiosInstance.post('/api/users-details/', {
                            first_name: payload.values.first_name,
                            last_name: payload.values.last_name,
                            entity_name: payload.entity_name,
                            custom_user: res.data.id
                        })
                            .then(() => {
                                res.data.registration_completed = true
                                useUserStore().editUser(res.data)
                                    .then(res => {
                                        resolve(res)
                                    })
                                    .catch(() => {
                                    })
                            })
                            .catch(() => {
                                useUserStore().deleteUser(res.data.id)
                            })
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        if (useUtilsStore().lastResponseGetters) {
                            useUserStore().deleteUser(useUtilsStore().lastResponseGetters.id)
                                .then(() => {
                                })
                                .catch(() => {
                                })
                        }
                        reject(err)
                    })
            })

        },
        editUser(payload) {
            return new Promise((resolve, reject) => {
                axiosInstance.patch('api/users/' + payload.id + '/', payload)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        editUserDetail(payload) {
            return new Promise((resolve, reject) => {
                axiosInstance.patch('api/users-details/' + payload.id + '/', payload)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        deleteUser(id) {
            return new Promise((resolve, reject) => {
                axiosInstance.delete('/api/users/' + id + '/')
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        changePassword(payload) {
            return new Promise((resolve, reject) => {
                if (payload.old_password !== '') {
                    axiosInstance.patch('api/users/change-password/', payload)
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                } else {
                    resolve()
                }
            })
        },
        setPassword(payload) {
            return new Promise((resolve, reject) => {
                if (payload.password !== '') {
                    axiosInstance.patch('api/users/' + useUtilsStore().lastResponseGetters.id + '/set-password/', payload)
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                } else {
                    resolve()
                }
            })
        },
        requestResetPassword(payload) {
            return new Promise((resolve, reject) => {
                axiosInstance.post('api/users/request-reset-password/', {
                    email: payload
                })
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        checkRequestResetPassword(payload) {
            return new Promise((resolve, reject) => {
                axiosInstance.get('api/users/check-request-reset-password/' + payload.uidb64 + '/' + payload.token)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            router.push('/login').catch(() => {
                            })
                        }
                        reject(err)
                    })
            })
        },
        resetPassword(payload) {
            return new Promise((resolve, reject) => {
                axiosInstance.patch('api/users/reset-password/', {
                    password: payload.password,
                    token: payload.token,
                    uidb64: payload.uidb64
                })
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        deactivateUser(payload) {
            return new Promise((resolve, reject) => {
                axiosInstance.get('api/users/' + payload + '/deactivate/')
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        activateUser(payload) {
            return new Promise((resolve, reject) => {
                axiosInstance.get('api/users/' + payload + '/activate/')
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        }
    },
    getters: {
        usersGetters: state => {
            return state.users
        },
        allUsersGetters: state => {
            return state.allUsers
        },
        oldUserGetters: state => {
            return state.oldUser
        },
        userGetters: state => {
            return state.user
        },
        arrayUsersInfinityGetters: state => {
            return state.arrayUsersInfinity
        },
        spinnerUserGetters: state => {
            return state.spinnerUser
        },
        optionsUserGetters: state => {
            return state.optionsUser
        },
        optionsUserDetailGetters: state => {
            return state.optionsUserDetail
        },
        controllerGetUsersGetters: state => {
            return state.controllerGetUsers
        },
        controllerGetUserGetters: state => {
            return state.controllerGetUser
        },
        controllerGetUserWithoutStateGetters: state => {
            return state.controllerGetUserWithoutState
        }
    }
})