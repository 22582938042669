import {defineStore} from 'pinia'
import axiosInstance from "@/services/api";
import {useApiSettingStore} from "@/stores/apiSettings";

export const useTagStore = defineStore(
    "tag",
    {
        state: () => ({
            tags: null,
            arrayTagInfinity: [],
            optionsTag: null,
            tag: null,
            allTag: null,
            oldTag: null,
            spinnerTag: false
        }),
        actions: {
            getOptionsTag() {
                return new Promise((resolve, reject) => {
                    
                    axiosInstance.options('api/tags/')
                        .then(res => {
                            this.optionsTag = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                })
            },
            getTag(id) {
                return new Promise((resolve, reject) => {
                    
                    axiosInstance.get('api/tags/' + id + '/')
                        .then(res => {
                            this.tag = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                })
            },
            getTags(payload) {
                return new Promise((resolve, reject) => {
                    

                    let url = new URL(axiosInstance.defaults.baseURL + 'api/tags/')

                    this.spinnerTag = true
                    if (payload.queryParams !== null) {
                        let params = new URLSearchParams()
                        for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                            params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                        }
                        url.search = params
                        axiosInstance.get(url.pathname + url.search)
                            .then(res => {
                                this.tags = res.data
                                if (payload.queryParams.scroll) {
                                    this.arrayTagInfinity.push(...res.data.results)
                                } else {
                                    this.arrayTagInfinity = res.data.results
                                }
                                this.spinnerTag = false
                                resolve(res)
                            })
                            .catch(err => {
                                this.spinnerTag = false
                                if (err.response && err.response.status === 500) {
                                    useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                                }
                                reject(err)
                            })
                    } else {
                        axiosInstance.get(url.pathname)
                            .then(res => {
                                this.spinnerTag = false
                                this.tags = res.data
                                let a = []
                                a.push(...res.data.results);
                                this.arrayTagInfinity = a
                                resolve(res)
                            })
                            .catch(err => {
                                this.spinnerTag = false
                                if (err.response && err.response.status === 500) {
                                    useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                                }
                                reject(err)
                            })
                    }

                })
            },
            createTag(payload) {
                return new Promise((resolve, reject) => {
                    
                    axiosInstance.post('/api/tags/', payload)
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            reject(err)
                        })
                })
            },
            getAllTag(payload) {
                return new Promise((resolve, reject) => {
                    

                    let url = new URL(axiosInstance.defaults.baseURL + 'api/tags/')

                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axiosInstance.get(url.pathname + url.search)
                        .then(res => {
                            this.allTag = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })

                })
            },
            editTag(payload) {
                return new Promise((resolve, reject) => {
                    if (payload.get('thumbnail_img') === 'null')
                        payload.delete('thumbnail_img')
                    axiosInstance.patch('api/tags/' + payload.get('id') + '/', payload)
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                })
            },
            deleteTag(id) {
                return new Promise((resolve, reject) => {
                    
                    axiosInstance.delete('/api/tags/' + id + '/')
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                })
            }
        },
        getters: {
            tagsGetters: state => {
                return state.tags
            },
            allTagGetters: state => {
                return state.allTag
            },
            oldTagGetters: state => {
                return state.oldTag
            },
            tagGetters: state => {
                return state.tag
            },
            arrayTagInfinityGetters: state => {
                return state.arrayTagInfinity
            },
            spinnerTagGetters: state => {
                return state.spinnerTag
            },
            optionsTagGetters: state => {
                return state.optionsTag
            }
        }
    })