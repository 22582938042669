<template>
  <div class="container-detail-user" v-if="storeUser.userGetters && allowed === endpoints.length && !isLoading">
    <div class="container-head-detail-user">
      <span class="text-24px-black-bold">Dettaglio Utente</span>
      <button
          v-if="storeAuth.userGetters && storeAuth.userGetters.user_type === 'ad' && storeAuth.userGetters && storeUser.userGetters.id !== storeAuth.userGetters.user_id"
          @click="deleteUser"
          class="btn btn-danger-custom">Elimina utente
      </button>
    </div>
    <div class="container-sub-head-detail-user">
      <span class="text-14px-grey-medium text-14px-grey-medium-margin">Utente registrato dal {{
          returnDate(storeUser.userGetters.created_at)
        }}</span>
      <div v-if="storeUser.userGetters.is_active" class="container-status-success container-status-success-margin">
        Attivo
      </div>
      <div v-else class="container-status-light-grey container-status-light-grey-margin">Inattivo</div>
    </div>

    <div class="container-head-section">
      <span
          class="text-16px-dark-grey-medium text-16px-dark-grey-medium-margin">Informazioni account</span>
    </div>
    <div id="container-section-account" class="container-section container-section-border">
      <div
          v-show="!genericOptionLocal[0][key].read_only && genericOptionLocal[0][key].is_visible && key !== 'is_active'"
          :key="index" v-for="(key,index) in Object.keys(dictWithOnlyField[0])" class="container-content-section"
          :style="{'grid-row': genericOptionLocal[0][key].position.row + 1, 'grid-column': genericOptionLocal[0][key].position.col + 1 }">
        <span class="text-14px-grey-regular">{{ genericOptionLocal[0][key].label }}</span>
        <span v-if="dictWithOnlyField[0][key] || genericOptionLocal[0][key].type === 'boolean'"
              class="text-14px-black-regular">{{ dictWithOnlyField[0][key] }}</span>
        <span v-else class="text-14px-black-regular">ND</span>
      </div>
    </div>

    <div class="container-head-section">
      <span
          class="text-16px-dark-grey-medium text-16px-dark-grey-medium-margin">Informazioni personali</span>
      <span
          v-if="!showDetailUser && (storeAuth.userGetters && storeAuth.userGetters.user_type === 'ad' || storeAuth.userGetters.user_id === currentObject.id )"
          @click=" showDetailUser = true"
          class="text-14px-primary-medium text-14px-primary-medium-margin">Modifica</span>
    </div>
    <div v-if="!showDetailUser" id="container-section-detail" class="container-section container-section-border">
      <div
          v-show="!genericOptionLocal[1][key].read_only && genericOptionLocal[1][key].is_visible"
          :key="index" v-for="(key,index) in Object.keys(dictWithOnlyField[1])" class="container-content-section"
          :style="{'grid-row': genericOptionLocal[1][key].position.row + 1, 'grid-column': genericOptionLocal[1][key].position.col + 1 }">
        <span class="text-14px-grey-regular">{{ genericOptionLocal[1][key].label }}</span>
        <span v-if="dictWithOnlyField[1][key]"
              class="text-14px-black-regular">{{ dictWithOnlyField[1][key] }}</span>
        <span v-else class="text-14px-black-regular">ND</span>
      </div>
    </div>
    <ComponentDynamicForm v-else
                          @closeForm="resetVariables"
                          @closeFormWithoutUpdate="showDetailUser = false"
                          :currentObject="{0: storeUser.userGetters.user_detail}"
                          :options="{
                            endpoints:
                            [
                              {
                                'endpoint': 'users-details',
                                'type': TYPE_OF_OPTION.UPDATE,
                                'path': [
                                    'users-details'
                                  ]
                              }
                            ]
                          }"></ComponentDynamicForm>

  </div>
  <b-spinner style="margin: auto" v-if="isLoading"></b-spinner>
</template>

<script>
import {useUtilsStore} from "@/stores/utils";
import ComponentDynamicForm from "@/components/form/ComponentDynamicForm";
import {useUserStore} from "@/stores/users";
import moment from "moment";
import {TYPE_OF_OPTION} from "@/globals";
import {useAuthStore} from "@/stores/auth";
import {useApiSettingStore} from "@/stores/apiSettings";

export default {
  name: "ComponentDetailUser",
  components: {ComponentDynamicForm},

  setup() {
    const storeApisettings = useApiSettingStore()
    const storeAuth = useAuthStore()
    const storeUser = useUserStore()
    const storeUtil = useUtilsStore()
    return {
      storeApisettings,
      storeAuth,
      storeUser,
      TYPE_OF_OPTION,
      storeUtil
    }
  },
  data() {
    return {
      isLoading: false,
      currentObject: {},
      maxCol: 0,
      allowed: 0,
      endpoints: [
        {
          'endpoint': 'users',
          'type': TYPE_OF_OPTION.UPDATE,
          'path': [
            'users',
            {'objectID': this.$route.params.id}
          ]
        },
        {
          'endpoint': 'users-details',
          'type': TYPE_OF_OPTION.UPDATE,
          'path': [
            'users-details'
          ]
        }
      ],
      dictWithOnlyField: {},
      dictMaxCol: {},
      genericOptionLocal: {},
      showEditGroup: false,
      showEditPermession: false,
      disabledButton: false,
      showDetailUser: false
    }
  },
  async created() {
    useUtilsStore().dictionaryFilterFinal = {}
    this.genericOptionLocal = {}
    await this.loadDetail()
  },
  watch: {
    user: {
      handler: function () {
      }
    }
  },
  computed: {
    user() {
      return useUserStore().userGetters
    }
  },
  mounted() {
    let container_section_detail = document.getElementById('container-section-detail')
    if (container_section_detail) {
      container_section_detail.style.gridTemplateColumns = 'repeat(' + this.dictMaxCol[1].max + ', 1fr)'
    }
    let container_section_account = document.getElementById('container-section-account')
    if (container_section_account) {
      container_section_account.style.gridTemplateColumns = 'repeat(' + this.dictMaxCol[0].max + ', 1fr)'
    }
  },
  methods: {
    async loadDetail() {
      if (this.isLoading) return
      this.isLoading = true
      await useUserStore().getUser(this.$route.params.id)
          .then(res => {
            this.allowed = 0
            this.currentObject = res.data
            useUtilsStore().chronology = []
            useUtilsStore().chronology.push({
              name: 'Impostazioni',
              display_name: 'Impostazioni'
            })
            useUtilsStore().chronology.push({
              route_name: 'users',
              route_id: null,
              name: 'Utenti',
              display_name: 'Utenti'
            })
            useUtilsStore().chronology.push({
              route_name: 'detailUser',
              route_id: res.data.id,
              name: 'Dettaglio Utente',
              display_name: res.data.user_detail.first_name + ' ' + res.data.user_detail.last_name
            })
            useUtilsStore().currentNamePage = res.data.user_detail.first_name + ' ' + res.data.user_detail.last_name
            this.buildObjectsDynamic()
          })
          .catch(() => {

          })
          .finally(() => {
            this.isLoading = false
          })
    },
    resetVariables() {
      this.showDetailUser = false
      this.storeUser.controllerGetUser = null
      this.loadDetail()
    },
    buildObjectsDynamic() {
      this.endpoints.forEach((enpoint, index) => {
        this.dictWithOnlyField[index] = {}
        this.dictMaxCol[index] = {}
        this.dictMaxCol[index]['max'] = 0
        useUtilsStore().getGenericOptionCycle(enpoint.path)
            .then(res => {
              if ('PATCH' in res.data.actions) {
                this.typeActionsFromOptions = 'PATCH'
              } else if ('POST' in res.data.actions) {
                this.typeActionsFromOptions = 'POST'
              }
              delete res.data.actions[this.typeActionsFromOptions].serializer_name
              this.genericOptionLocal[index] = {}
              this.genericOptionLocal[index] = res.data.actions[this.typeActionsFromOptions]

              for (let key in res.data.actions[this.typeActionsFromOptions]) {
                if (res.data.actions[this.typeActionsFromOptions][key].required && this.typeActionsFromOptions === 'POST') {
                  if (index === 0) {
                    this.dictWithOnlyField[index][key] = useUserStore().userGetters[key]
                  } else if (index === 1) {
                    this.dictWithOnlyField[index][key] = useUserStore().userGetters.user_detail[key]
                  }
                } else {
                  if (index === 0) {
                    this.dictWithOnlyField[index][key] = useUserStore().userGetters[key]
                  } else if (index === 1) {
                    this.dictWithOnlyField[index][key] = useUserStore().userGetters.user_detail[key]
                  }
                }
                if (res.data.actions[this.typeActionsFromOptions][key].position.col > this.dictMaxCol[index].max) {
                  this.dictMaxCol[index].max = res.data.actions[this.typeActionsFromOptions][key].position.col
                }
              }
              this.allowed++
              this.dictMaxCol[index].max = this.dictMaxCol[index].max + 1
            })
            .catch(() => {
            })
      })
      this.isLoading = false
    },
    deleteUser() {

      let item = useUserStore().userGetters
      useUtilsStore().infoForToDelete['currentPosition'] = 'detail'
      useUtilsStore().infoForToDelete['confirmDelete'] = true
      useUtilsStore().infoForToDelete['action'] = this.storeUser.deleteUser
      useUtilsStore().infoForToDelete['typeObjectToDelete'] = 'single'
      useUtilsStore().infoForToDelete['entity'] = 'users'
      useUtilsStore().infoForToDelete['name'] = 'utente'
      useUtilsStore().infoForToDelete['objectToDelete'] = item
      useUtilsStore().infoForToDelete['messageToDelete']['is'] = 'div'
      useUtilsStore().infoForToDelete['messageToDelete']['innerHTML'] = "Sei sicuro di voler eliminare definitivamente l'utente  " +
          "<span style='color: var(--primary-color)'>" + item.user_detail.first_name + ' ' + item.user_detail.last_name +
          "</span>" + "? L’operazione non è reversibile."
    },
    submitEvent() {
      this.emitter.emit('submitEvent')
    },
    returnDate(date) {
      return moment(date).format('DD/MM/YYYY')
    }
  },
  updated() {
    let container_section_detail = document.getElementById('container-section-detail')
    if (container_section_detail) {
      container_section_detail.style.gridTemplateColumns = 'repeat(' + this.dictMaxCol[1].max + ', 1fr)'
    }
    let container_section_account = document.getElementById('container-section-account')
    if (container_section_account) {
      container_section_account.style.gridTemplateColumns = 'repeat(' + this.dictMaxCol[0].max + ', 1fr)'
    }
  },
  beforeUnmount() {
    // useUtilsStore().showedDetailUser = false
  },
  unmounted() {
    // useUtilsStore().showedDetailUser = false
  }
}
</script>

<style scoped>
.btn-light-grey-custom-margin {
  margin-left: auto;
}

.btn-primary-custom-margin {
  margin-right: 15px
}

.container-section-groups-permissions {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  padding: 10px 0px;
}

.btn-primary-custom {
  margin-left: 15px;
}

.text-permission {
  margin: 2.5px 0px;
}

.container-permissions {
  display: flex;
  flex-direction: column;
}

#container-section-detail {
  display: grid;
  width: 100% !important;
  padding: 25px 0px !important;
  column-gap: 25px !important;
}

.container-section {
  display: grid;
  width: 100%;
  row-gap: 20px;
  column-gap: 20px;
  padding: 25px 0px;
  grid-template-columns: calc(50% - 10px) calc(50% - 10px);
}

.container-content-section {
  display: grid;
  width: 100%;
  margin-right: auto;
  grid-template-columns: repeat(2, 1fr);
}

.text-14px-primary-medium-margin {
  cursor: pointer;
  margin-left: auto;
}

.container-head-section {
  width: 100%;
  margin: 0px 0px 0px 0px;
  display: flex;
  align-items: center;
  padding-top: 20px;
}

.container-section-border {
  border-bottom: 1px solid var(--light-grey-color);
}


.container-status-success {
  margin-right: auto;
  margin-bottom: auto;
}

.container-head-detail-user button {
  margin-left: auto;
}

.container-buttons {
  display: flex;
  margin-left: auto;
}

.container-status-success-margin.container-status-success {
  margin: 0px 0px 0px 20px;
}

.container-status-light-grey-margin.container-status-light-grey {
  margin: 0px 0px 0px 20px;
}

.container-sub-head-detail-user {
  display: flex;
  align-items: center;
}

.container-head-detail-user {
  display: flex;
  margin-bottom: 10px;
  width: 100%;
  align-items: flex-start;
}

.btn-danger-custom {
  display: flex;
  margin-left: auto;
}

.container-detail-user {
  width: 100%;
  display: flex;
  padding: 10px 0px 10px 15px;
  flex-direction: column;
}

.text-14px-black-regular {
  margin-left: auto;
}

@media (max-width: 575.98px) {
}

/*// Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
  .container-section {
    display: flex !important;
    flex-wrap: wrap;
  }

  .text-14px-black-regular {
    margin-left: 0px;
  }

  .container-content-section {
    display: flex;
    flex-direction: column;
  }

  .container-content-right-settings.container-detail-user {
    padding-left: 0px;
  }
}

/*// Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {
  .container-detail-user {
    padding: 0px;
  }
}

/*// Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {
}

/*// X-Large devices (large desktops, less than 1400px)*/
@media (max-width: 1399.98px) {
}
</style>