import {defineStore} from 'pinia'
import axiosInstance from "@/services/api";
import {useApiSettingStore} from "@/stores/apiSettings";
import router from "@/router";

export const useTestStore = defineStore("test", {
    state: () => ({
        tests: null,
        arrayTestsInfinity: [],
        optionsTest: null,
        optionsTestDetail: null,
        test: null,
        allTests: null,
        oldTest: null,
        spinnerTest: false
    }),
    actions: {
        getOptionsTest() {
            return new Promise((resolve, reject) => {
                
                axiosInstance.options('api/tests/')
                    .then(res => {
                        this.optionsTest = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        getOptionsTestDetail() {
            return new Promise((resolve, reject) => {
                
                axiosInstance.options('api/tests-details/')
                    .then(res => {
                        this.optionsTestDetail = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        getTest(id) {
            return new Promise((resolve, reject) => {
                
                axiosInstance.get('api/tests/' + id + '/')
                    .then(res => {
                        this.test = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        getTests(payload) {
            return new Promise((resolve, reject) => {
                

                let url = new URL(axiosInstance.defaults.baseURL + 'api/tests/')

                this.spinnerTest = true
                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axiosInstance.get(url.pathname + url.search)
                        .then(res => {
                            this.tests = res.data
                            if (payload.queryParams.scroll) {
                                this.arrayTestsInfinity.push(...res.data.results)
                            } else {
                                this.arrayTestsInfinity = res.data.results
                            }
                            this.spinnerTest = false
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerTest = false
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                } else {
                    axiosInstance.get(url.pathname)
                        .then(res => {
                            this.spinnerTest = false
                            this.tests = res.data
                            let a = []
                            a.push(...res.data.results);
                            this.arrayTestsInfinity = a
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerTest = false
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                }

            })
        },

        createTest(payload) {
            return new Promise((resolve, reject) => {
                

                axiosInstance.post('/api/tests/', payload)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getAllTests(payload) {
            return new Promise((resolve, reject) => {
                

                let url = new URL(axiosInstance.defaults.baseURL + 'api/tests/')

                let params = new URLSearchParams()
                for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                    params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                }
                url.search = params
                axiosInstance.get(url.pathname + url.search)
                    .then(res => {
                        this.allTests = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })

            })
        },
        registerTest(payload) {
            return new Promise((resolve, reject) => {
                

                axiosInstance.post('/api/tests/', payload)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        registerTestDetail(payload) {
            return new Promise((resolve, reject) => {
                
                axiosInstance.post('/api/tests-details/', payload)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        register(payload) {
            return new Promise((resolve, reject) => {
                
                axiosInstance.post('/api/tests/', {
                    testname: payload.values.testname,
                    email: payload.values.email,
                    password: payload.values.password,
                    confirmation: payload.values.confirmation,
                    test_type: payload.test_type
                })
                    .then(res => {
                        axiosInstance.post('/api/tests-details/', {
                            first_name: payload.values.first_name,
                            last_name: payload.values.last_name,
                            entity_name: payload.entity_name,
                            custom_test: res.data.id
                        })
                            .then(() => {
                                res.data.registration_completed = true
                                useTestStore().editTest(res.data)
                                    .then(res => {
                                        resolve(res)
                                    })
                                    .catch(() => {
                                    })
                            })
                            .catch(() => {
                                useTestStore().deleteTest(res.data.id)
                            })
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })

        },
        editTest(payload) {
            return new Promise((resolve, reject) => {
                
                axiosInstance.patch('api/tests/' + payload.id + '/', payload)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        editTestDetail(payload) {
            return new Promise((resolve, reject) => {
                
                axiosInstance.patch('api/tests-details/' + payload.id + '/', payload)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        deleteTest(id) {
            return new Promise((resolve, reject) => {
                
                axiosInstance.delete('/api/tests/' + id + '/')
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        changePassword(payload) {
            return new Promise((resolve, reject) => {
                
                axiosInstance.patch('api/tests/change-password/', payload)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        setPassword(payload) {
            return new Promise((resolve, reject) => {
                
                axiosInstance.patch('api/tests/' + payload.id + '/set-password/', {
                    password: payload.password,
                    confirmed_password: payload.confirmed_password
                })
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        requestResetPassword(payload) {
            return new Promise((resolve, reject) => {
                axiosInstance.post('api/tests/request-reset-password/', {
                    email: payload
                })
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        checkRequestResetPassword(payload) {
            return new Promise((resolve, reject) => {
                axiosInstance.get('api/tests/check-request-reset-password/' + payload.uidb64 + '/' + payload.token)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            router.push('/login').catch(() => {
                            })
                        }
                        reject(err)
                    })
            })
        },
        resetPassword(payload) {
            return new Promise((resolve, reject) => {
                axiosInstance.patch('api/tests/reset-password/', {
                    password: payload.password,
                    token: payload.token,
                    uidb64: payload.uidb64
                })
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        deactivateTest(payload) {
            return new Promise((resolve, reject) => {
                
                axiosInstance.get('api/tests/' + payload + '/deactivate/')
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        activateTest(payload) {
            return new Promise((resolve, reject) => {
                
                axiosInstance.get('api/tests/' + payload + '/activate/')
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        }
    },
    getters: {
        testsGetters: state => {
            return state.tests
        },
        allTestsGetters: state => {
            return state.allTests
        },
        oldTestGetters: state => {
            return state.oldTest
        },
        testGetters: state => {
            return state.test
        },
        arrayTestsInfinityGetters: state => {
            return state.arrayTestsInfinity
        },
        spinnerTestGetters: state => {
            return state.spinnerTest
        },
        optionsTestGetters: state => {
            return state.optionsTest
        },
        optionsTestDetailGetters: state => {
            return state.optionsTestDetail
        }
    }
})