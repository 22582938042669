<template>
  <div :class="{'form-no-padding': options.endpoints[step].endpoint === 'auth'}"
       class="container-form"
       v-if="allowed === options.endpoints.length">
    <Form @submit="onSubmit"
          class="forms" ref="form"
          v-slot="{}">
      <div :class="{'container-content-form-login': options.endpoints[step].endpoint === 'auth'}"
           id="container-content-form"
           class="container-content-form">
        <div
            v-show="!storeUtils.genericOptionGetters[step][key].read_only && storeUtils.genericOptionGetters[step][key].is_visible"
            v-for="(key) in Object.keys(storeUtils.genericOptionGetters[step])" :key="key"
            class="form-group"
            :style="{'grid-row': storeUtils.genericOptionGetters[step][key].position.row + 1, 'grid-column': storeUtils.genericOptionGetters[step][key].position.col + 1 }">
          <label class="label-form">{{ storeUtils.genericOptionGetters[step][key].label }} </label>
          <div
              v-if="storeUtils.genericOptionGetters[step][key].type !== 'json' && storeUtils.genericOptionGetters[step][key].type !== 'color' && storeUtils.genericOptionGetters[step][key].type !== 'choice' && storeUtils.genericOptionGetters[step][key].type !== 'boolean' && storeUtils.genericOptionGetters[step][key].type !== 'multiple choice' && (key in dictWithOnlyField[step] && dictWithOnlyField[step][key].widget === null)"
              class="container-field">
            <Field v-model="dictWithOnlyField[step][key].value" v-slot="{field}" :name="key">
              <div class="container-content-field">
                <input :name="key"
                       :disabled="currentSerializerName === 'UserDetailSerializer' && (key === 'first_name' || key === 'last_name')"
                       :maxlength="storeUtils.genericOptionGetters[step][key].max_length"
                       :min="returnMin(storeUtils.genericOptionGetters[step][key].type,storeUtils.genericOptionGetters[step][key].min_value,key)"
                       :placeholder="storeUtils.genericOptionGetters[step][key].label" class="form-control"
                       autocomplete="new-password username email"
                       :step="1/10 ** storeUtils.genericOptionGetters[step][key].decimal_places"
                       :class="{ 'is-invalid': lastReason[key],'form-control-password': key === 'password' || key === 'confirmation' || key === 'old_password' || key === 'confirmed_password'}"
                       v-bind="field"
                       :max="returnMax(storeUtils.genericOptionGetters[step][key].type, storeUtils.genericOptionGetters[step][key].max_value,key)"
                       :type="getInputType(key,storeUtils.genericOptionGetters[step][key].type)">
                <img @click="showedPassword('password')" v-if="key === 'password' && !showPassword"
                     class="eye-password" src="../../assets/form/eye-close.svg" alt="">
                <img @click="hidedPassword('password')" v-if="key === 'password' && showPassword"
                     class="eye-password" src="../../assets/form/eye-open.svg" alt="">
                <img @click="showedPassword('confirmation')" v-if="key === 'confirmation' && !showConfirmationPassword"
                     class="eye-password" src="../../assets/form/eye-close.svg" alt="">
                <img @click="hidedPassword('confirmation')" v-if="key === 'confirmation' && showConfirmationPassword"
                     class="eye-password" src="../../assets/form/eye-open.svg" alt="">
                <img @click="showedPassword('confirmed_password')"
                     v-if="key === 'confirmed_password' && !showConfirmedPassword"
                     class="eye-password" src="../../assets/form/eye-close.svg" alt="">
                <img @click="hidedPassword('confirmed_password')"
                     v-if="key === 'confirmed_password' && showConfirmedPassword"
                     class="eye-password" src="../../assets/form/eye-open.svg" alt="">
                <img @click="showedPassword('old_password')"
                     v-if="key === 'old_password' && !showOldPassword"
                     class="eye-password" src="../../assets/form/eye-close.svg" alt="">
                <img @click="hidedPassword('old_password')"
                     v-if="key === 'old_password' && showOldPassword"
                     class="eye-password" src="../../assets/form/eye-open.svg" alt="">
              </div>
            </Field>
          </div>
          <textarea rows="5" :placeholder="storeUtils.genericOptionGetters[step][key].label" class="form-control"
                    v-else-if="storeUtils.genericOptionGetters[step][key].type !== 'json' && storeUtils.genericOptionGetters[step][key].type !== 'color' && storeUtils.genericOptionGetters[step][key].type !== 'choice' && storeUtils.genericOptionGetters[step][key].type !== 'boolean' && storeUtils.genericOptionGetters[step][key].type !== 'multiple choice' && (key in dictWithOnlyField[step] && dictWithOnlyField[step][key].widget === 'textarea')"></textarea>
          <b-dropdown
              v-else-if="storeUtils.genericOptionGetters[step][key].type === 'choice' || storeUtils.genericOptionGetters[step][key].type === 'multiple choice' || (key in dictWithOnlyField[step] && dictWithOnlyField[step][key].widget === 'dropdown')"
              v-model="storeUtils.genericOptionGetters[step][key].show"
              :text="returnTextDropdownDynamic(key)"
              block
              class="text-14px-dark-grey-medium"
              menu-class="dropdown-custom"
          >
            <b-form-input v-if="storeUtils.genericOptionGetters[step][key].type === 'field'" style="margin-bottom: 5px"
                          @input="handleInput($event,key)" @click="$event.stopPropagation()"
                          v-model="dictWithOnlyField[step][key].search" type="text" placeholder="Cerca"></b-form-input>
            <div class="container-list-types"
                 v-if="key in dictWithOnlyField[step] && storeUtils.genericOptionGetters[step][key].type === 'choice' || key in dictWithOnlyField[step] && storeUtils.genericOptionGetters[step][key].type === 'field' && !storeUtils.genericOptionGetters[step][key].is_many">
              <b-form-radio v-if="!storeUtils.genericOptionGetters[step][key].required" :value="null"
                            v-model="dictWithOnlyField[step][key].value">
                nessuna scelta
              </b-form-radio>
              <div style="display: flex;flex-direction: column;padding: 0px 10px">
                <b-form-radio :key="i"
                              v-for="(choice,i) in storeUtils.genericOptionGetters[step][key].choices"
                              :value="choice.value"
                              v-model="dictWithOnlyField[step][key].value">
                  {{ choice.display_name }}
                </b-form-radio>
              </div>
            </div>
            <div
                class="container-checkboxes-form"
                v-else-if="storeUtils.genericOptionGetters[step][key].type === 'multiple choice' || key in dictWithOnlyField[step] && storeUtils.genericOptionGetters[step][key].type === 'field' && storeUtils.genericOptionGetters[step][key].is_many">
              <div :key="i" v-for="(choice,i) in storeUtils.genericOptionGetters[step][key].choices"
                   class="form-check"
                   @click="toggleSelection($event,choice.value,dictWithOnlyField[step][key].value)">
                <input class="form-check-input" type="checkbox"
                       v-model="dictWithOnlyField[step][key].value" :value="choice.value">
                <label class="form-check-label">{{ choice.display_name }}</label>
              </div>
            </div>
          </b-dropdown>
          <div
              v-if="storeUtils.genericOptionGetters && storeUtils.genericOptionGetters[step][key].type === 'file upload'"
              class="form-group">

            <div class="container-file">
              <div @dragover.prevent @drop="handleDrop($event,key)"
                   class="container-drag-and-drop">
                <img src="../../assets/shared/upload.svg">
              </div>
              <div class="container-button-file">
                <label class="btn btn-light-custom" :for="key">Carica file</label>
                <input :id="key" type="file" ref="fileInput" style="display:none;"
                       @change="handleFileInputChange($event,key)">
                <ul v-if="options.endpoints[step].endpoint === 'categories'" class="ul-file">
                  <li class="text-12px-dark-grey-regular">formati accettati: png, jpeg, jpg, svg</li>
                </ul>
              </div>
            </div>
            <span v-if="dictWithOnlyField[step][key].file"
                  class="text-14px-primary-medium">{{ dictWithOnlyField[step][key].file.name }}</span>
          </div>
          <div class="container-boolean"
               v-if="(key in dictWithOnlyField[step] && storeUtils.genericOptionGetters[step][key].type === 'boolean' && storeUtils.genericOptionGetters[step][key].required) ||
                      key in dictWithOnlyField[step] && storeUtils.genericOptionGetters[step][key].type === 'boolean' && dictWithOnlyField[step][key].widget === 'switch'">
            <b-form-checkbox
                switch
                v-model="dictWithOnlyField[step][key].value">
              {{
                dictWithOnlyField[step][key].value ? 'Attivo' : 'Non attivo'
              }}
            </b-form-checkbox>
          </div>
          <div
              v-else-if="key in dictWithOnlyField[step] && storeUtils.genericOptionGetters[step][key].type === 'boolean' && !storeUtils.genericOptionGetters[step][key].required"
              class="container-boolean">
            <b-form-radio @change="changeBoolean($event,key)" :value="null"
                          v-model="dictWithOnlyField[step][key].value">
              nessuna scelta
            </b-form-radio>
            <b-form-radio :value="true"
                          v-model="dictWithOnlyField[step][key].value">
              Attivo
            </b-form-radio>
            <b-form-radio :value="false"
                          v-model="dictWithOnlyField[step][key].value">
              Non attivo
            </b-form-radio>
          </div>

          <div v-if="storeUtils.genericOptionGetters[step][key].type === 'color'" class="form-group">
            <input @change="setColor($event,key)" type="color" id="input-color">
          </div>
          <div
              v-if="storeUtils.genericOptionGetters[step][key].is_visible && storeUtils.genericOptionGetters[step][key].type === 'json' && dictWithOnlyField[step][key].widget !== 'editor'"
              class="form-group">
            <Vue3JsonEditor
                v-model="dictWithOnlyField[step][key].value"
                @json-change="onJsonChange($event,key)"
            />
          </div>
          <ckeditor
              v-else-if="storeUtils.genericOptionGetters[step][key].is_visible && storeUtils.genericOptionGetters[step][key].type === 'json' && dictWithOnlyField[step][key].widget === 'editor'"
              :editor="editor" v-model="dictWithOnlyField[step][key].value.innerHTML" :config="editorConfig"/>

          <div ref="target" id="container-tags-custom" class="container-tags-custom"
               v-else-if="storeUtils.genericOptionGetters[step][key].is_visible && storeUtils.genericOptionGetters[step][key].type === 'field' && dictWithOnlyField[step][key].widget === 'tag'">
            <div id="container-content-tags-custom" class="container-content-tags-custom">
              <div class="container-list-content-tags-custom">
                <div :key="i"
                     v-for="(name,i) in dictWithOnlyField[step][key].names"
                     class="container-single-content-tags-custom text-14px-black-regular">
                  {{ name }}
                  <img @click="removeTag($event,i,key)" class="remove-tag" src="../../assets/shared/close-black.svg"
                       alt="">
                </div>

                <input pattern="^[a-z0-9]+(_[a-zA-Z0-9]+)*$" id="input-tags" class="form-control input-tags"
                       placeholder="Aggiungi tag" type="text" v-model="newTag"
                       @keyup="addTag($event,key)" title="Inserire un valore valido">
              </div>


              <img @click="copyTags($event,key)" v-b-tooltip="'Copia tag'" class="img-copy"
                   src="../../assets/shared/copy.svg"
                   alt="">
            </div>
            <span class="text-danger-custom" v-show="isDuplicated">Tag già inseriti</span>
            <span class="text-danger-custom" v-show="errorFormat">Tag non corretto</span>
            <span v-if="objCopy.text"
                  :class="{'text-success': objCopy.bool, 'text-danger-custom': !objCopy.bool}">{{ objCopy.text }}</span>
            <ul v-click-outside="closeUl" v-if="storeTag.tagsGetters" id="list-tag-suggestion"
                class="list-group position-absolute"
                v-show="listVisible">
              <li :key="tag.id" @click="clickOnSuggestionTag($event,tag,key)"
                  v-for="tag in storeTag.tagsGetters.results"
                  class="list-group-item list-single-tag-suggestion">
                {{ tag.name }}
              </li>
            </ul>
          </div>

          <div
              v-if="storeUtils.genericOptionGetters[step][key].type === 'field' && key === 'test_duration_field'"
              class="form-group form-group-dates">
            <input :name="key"
                   :placeholder="storeUtils.genericOptionGetters[step][key].label" class="form-control"
                   :class="{ 'is-invalid': lastReason[key]}"
                   v-model="dictWithOnlyField[step][key].min_date"
                   :max="today"
                   type="datetime-local">
            <input :name="key"
                   :min="dictWithOnlyField[step][key].min_date"
                   :placeholder="storeUtils.genericOptionGetters[step][key].label" class="form-control"
                   :class="{ 'is-invalid': lastReason[key]}"
                   v-model="dictWithOnlyField[step][key].max_date"
                   :max="today"
                   type="datetime-local">
          </div>
          <div v-if="lastReason && lastReason[key]" class="text-danger-custom">{{ lastReason[key][0] }}</div>
          <div v-if="lastReason && key === 'confirmation' && 'non_field_errors' in lastReason"
               class="text-danger-custom">{{ lastReason['non_field_errors'][0] }}
          </div>
        </div>
      </div>
      <span class="text-danger-custom" v-if="storeApi.error500Getters">{{ storeApi.error500Getters }}</span>
      <span v-if="storeAuth.errorTokenGetters" class="text-danger-custom">{{ storeAuth.errorTokenGetters }}</span>
      <div v-if="options.endpoints[step].endpoint !== 'auth'"
           :class="{'container_footer_form': options.endpoints[step].type === 'update'}"
           class="container-footer-form">
        <button type="button" @click="step--" v-if="step !== 0" class="btn btn-light-custom">Indietro</button>
        <button @click="$emit('closeFormWithoutUpdate')" type="button" class="btn btn-light-grey-custom">Annulla
        </button>
        <button type="submit" v-if="options.endpoints.length === 1 || options.endpoints.length === step + 1"
                class="btn btn-primary-custom btn-primary-custom-no-margin">
          <span :class="{'text-span-with-spinner':isLoading}">{{
              options.endpoints[step].type === 'create' ? 'Aggiungi' : 'Salva modifiche'
            }}</span>
          <b-spinner v-show="isLoading"></b-spinner>
        </button>
        <button v-else type="button" class="btn btn-light-custom" @click="step++">
          <!-- {{ returnTextButton() }}-->
          Avanti
        </button>
      </div>
      <button type="submit" v-else class="btn btn-primary-custom btn-primary-custom-login">
        <span :class="{'text-span-with-spinner': isLoading}">Accedi</span>
        <b-spinner v-show="isLoading"></b-spinner>
      </button>
    </Form>

  </div>

</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-vue';
// eslint-disable-next-line no-unused-vars
import {Field, Form} from "vee-validate";
import {useAuthStore} from "@/stores/auth";
import {useUtilsStore} from "@/stores/utils";
import {useUserStore} from "@/stores/users";
import moment from "moment/moment";
// eslint-disable-next-line no-unused-vars
import {useTestStore} from "@/stores/test";
// eslint-disable-next-line no-unused-vars
import {useApiSettingStore} from "@/stores/apiSettings";
// eslint-disable-next-line no-unused-vars
import {Vue3JsonEditor} from 'vue3-json-editor'
import {useCategoryStore} from "@/stores/categories";
import {useTagStore} from "@/stores/tags";
import {useSubcategoryStore} from "@/stores/subcategories";
import {useProjectStore} from "@/stores/projects";
import {useDocumentStore} from "@/stores/documents";

export default {
  name: "ComponentDynamicForm",
  emits: ['closeForm', 'closeFormWithoutUpdate'],
  components: {
    ckeditor: CKEditor.component,
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
    Vue3JsonEditor,
    Field
  },
  props: {
    currentObject: {
      type: Object
    },
    options: {
      type: Object
    }
  },
  setup() {
    const storeApi = useApiSettingStore()
    const storeTest = useTestStore()
    const storeTag = useTagStore()
    const storeUtils = useUtilsStore()
    const storeUser = useUserStore()
    const storeAuth = useAuthStore()
    const storeCategory = useCategoryStore()
    const storeSubcategory = useSubcategoryStore()
    const storeProject = useProjectStore()
    const storeDocument = useDocumentStore()
    return {
      storeApi,
      storeDocument,
      storeTest,
      storeProject,
      storeCategory,
      storeSubcategory,
      storeTag,
      storeAuth,
      storeUtils,
      storeUser
    }
  },
  data() {
    return {
      controller: null,
      innerWidth: window.innerWidth,
      isLoading: false,
      currentTypeObj: 'permissions',
      regexTag: '^[a-zA-Z0-9]+(_[a-zA-Z0-9]+)*$',
      isDuplicated: false,
      newTag: '',
      objCopy: {
        text: '',
        bool: true
      },
      listVisible: false,
      // eslint-disable-next-line no-undef
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            '|',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'indent',
            'outdent',
            '|',
            'blockQuote',
            'insertTable',
            'undo',
            'redo'
          ]
        },
        language: 'it'
      },
      listIdsCreatedTags: [],
      value1: [],
      currentSerializerName: '',
      objectVerboseName: {},
      today: moment().format('yyyy-MM-DDTHH:mm'),
      currentObjectLocal: Object.assign({}, this.currentObject),
      lastReason: {},
      allowed: 0,
      showConfirmationPassword: false,
      showConfirmedPassword: false,
      hideConfirmedPassword: false,
      showOldPassword: false,
      hideOldPassword: false,
      hideConfirmationPassword: false,
      showPassword: false,
      hidePassword: false,
      errorFormat: false,
      selectedRadio: null,
      selectedCheckboxes: [],
      arrayAllCol: [],
      dictMaxCol: {},
      step: 0,
      dictWithOnlyField: {},
      refForm: null,
      typeActionsFromOptions: '',
      endpointsActionsMapping: {
        'auth': {
          'create': {
            'action': this.storeAuth.login,
            'payload': null
          }
        },
        'tests': {
          'create': {
            'action': this.storeTest.registerTest,
            'payload': null
          }
        },
        'categories': {
          'create': {
            'action': this.storeCategory.createCategory,
            'options': this.storeUtils.getGenericOptionCycle,
            'payload': null
          },
          'update': {
            'action': this.storeCategory.editCategory,
            'options': this.storeUtils.getGenericOptionCycle,
            'payload': null
          }
        },
        'subcategories': {
          'create': {
            'action': this.storeSubcategory.createSubcategory,
            'options': this.storeUtils.getGenericOptionCycle,
            'payload': null
          },
          'update': {
            'action': this.storeSubcategory.editSubcategory,
            'options': this.storeUtils.getGenericOptionCycle,
            'payload': null
          }
        },
        'projects': {
          'create': {
            'action': this.storeProject.createProject,
            'options': this.storeUtils.getGenericOptionCycle,
            'payload': null
          },
          'update': {
            'action': this.storeProject.editProject,
            'options': this.storeUtils.getGenericOptionCycle,
            'payload': null
          }
        },
        'documents': {
          'create': {
            'action': this.storeDocument.createDocument,
            'options': this.storeUtils.getGenericOptionCycle,
            'payload': null
          },
          'update': {
            'action': this.storeDocument.editDocument,
            'options': this.storeUtils.getGenericOptionCycle,
            'payload': null
          }
        },
        'users': {
          'create': {
            'action': this.storeUser.registerUser,
            'options': this.storeUtils.getGenericOptionCycle,
            'payload': null
          },
          'update': {
            'action': this.storeUser.editUser,
            'options': this.storeUtils.getGenericOptionCycle,
            'payload': null
          },
          'change-password': {
            'action': this.storeUser.changePassword,
            'options': this.storeUtils.getGenericOptionCycle,
            'payload': null
          },
          'set-password': {
            'action': this.storeUser.setPassword,
            'options': this.storeUtils.getGenericOptionCycle,
            'payload': null
          }
          // 'update': useUserStore().$actions.editUser
        },
        'users-details': {
          'create': {
            'action': this.storeUser.registerUserDetail,
            'payload': null
          },
          'update': {
            'action': this.storeUser.editUserDetail,
            'payload': null
          }
        }
      },
      erroreFile: '',
      currentUser: null,
      arrayUserAdded: []
    }
  },
  created() {
    this.controller = new AbortController()
    useUtilsStore().resetDictionaryWidgetList()

    if (useAuthStore().authenticatedGetters)
      useUserStore().getUser(useAuthStore().userGetters.user_id)
          .then(res => {
            this.currentUser = res.data
            this.arrayUserAdded.push(res.data)
          })
          .catch(() => {
          })

    useCategoryStore().errorMimeTypeImage = false
    useApiSettingStore().error500 = ''
    this.emitter.on('submitEvent', () => {
      this.onSubmit()
    })

    this.options.endpoints.forEach((option, index) => {
      this.dictMaxCol[index] = {}
      this.dictMaxCol[index]['max'] = 0
      this.dictWithOnlyField[index] = {}

      useUtilsStore().getGenericOptionCycle(option.path)
          .then(res => {
            if ('PATCH' in res.data.actions) {
              this.typeActionsFromOptions = 'PATCH'
            } else if ('POST' in res.data.actions) {
              this.typeActionsFromOptions = 'POST'
            }
            useUtilsStore().genericOption[index] = {}
            this.currentSerializerName = res.data.actions[this.typeActionsFromOptions].serializer_name
            delete res.data.actions[this.typeActionsFromOptions].serializer_name
            useUtilsStore().genericOption[index] = res.data.actions[this.typeActionsFromOptions]

            for (let key in res.data.actions[this.typeActionsFromOptions]) {
              if (!res.data.actions[this.typeActionsFromOptions][key].read_only && res.data.actions[this.typeActionsFromOptions][key].is_visible) {
                this.dictWithOnlyField[index][key] = {}

                if (option.type === 'create') {
                  switch (res.data.actions[this.typeActionsFromOptions][key].type) {
                    case "integer":
                      this.dictWithOnlyField[index][key].value = 0
                      this.dictWithOnlyField[index][key]['widget'] = null
                      break;
                    case "color":
                      this.dictWithOnlyField[index][key].value = '#000000'
                      this.dictWithOnlyField[index][key]['widget'] = null
                      break;
                    case "email":
                      this.dictWithOnlyField[index][key].value = ''
                      this.dictWithOnlyField[index][key]['widget'] = null
                      break;
                    case "multiple choice":
                      this.dictWithOnlyField[index][key].value = []
                      this.dictWithOnlyField[index][key]['widget'] = null
                      this.dictWithOnlyField[index][key]['search'] = ''
                      this.dictWithOnlyField[index][key]['show'] = false
                      break;
                    case "choice":
                      this.dictWithOnlyField[index][key].value = null
                      this.dictWithOnlyField[index][key]['widget'] = null
                      this.dictWithOnlyField[index][key]['search'] = ''
                      this.dictWithOnlyField[index][key]['show'] = false
                      break;
                    case "string":
                      this.dictWithOnlyField[index][key].value = ''
                      // if ('max_length' in res.data.actions[this.typeActionsFromOptions][key])
                      this.dictWithOnlyField[index][key]['widget'] = null
                      // else
                      //   this.dictWithOnlyField[index][key]['widget'] = 'textarea'
                      break;
                    case "json":
                      if (key === 'wiki') {
                        this.dictWithOnlyField[index][key].value = {
                          innerHTML: ""
                        }
                        this.dictWithOnlyField[index][key]['widget'] = 'editor'
                      } else {
                        this.dictWithOnlyField[index][key].value = {}
                        this.dictWithOnlyField[index][key]['widget'] = null
                      }
                      break;
                    case "duration":
                      this.dictWithOnlyField[index][key].value = ''
                      this.dictWithOnlyField[index][key].min_date = null
                      this.dictWithOnlyField[index][key].max_date = null
                      this.dictWithOnlyField[index][key]['widget'] = null
                      break;
                    case "boolean":
                      this.dictWithOnlyField[index][key].value = useUtilsStore().genericOptionGetters[index][key].required ? false : null
                      this.dictWithOnlyField[index][key]['widget'] = null
                      break;
                    case "file upload":
                      this.dictWithOnlyField[index][key].file = null
                      this.dictWithOnlyField[index][key]['widget'] = null
                      break;
                    case "date":
                      this.dictWithOnlyField[index][key].value = null
                      this.dictWithOnlyField[index][key]['widget'] = null
                      break;
                    case "datetime":
                      this.dictWithOnlyField[index][key].value = null
                      this.dictWithOnlyField[index][key]['widget'] = null
                      break;
                    case "decimal":
                      this.dictWithOnlyField[index][key].value = 0
                      this.dictWithOnlyField[index][key]['widget'] = null
                      break;
                    case "field":
                      if (key === 'tag') {
                        this.dictWithOnlyField[index][key].value = []
                        this.dictWithOnlyField[index][key].names = []
                        this.dictWithOnlyField[index][key]['widget'] = 'tag'
                      } else {
                        this.dictWithOnlyField[index][key].value = useUtilsStore().genericOptionGetters[index][key].is_many ? [] : null
                        this.dictWithOnlyField[index][key]['widget'] = 'dropdown'
                        this.dictWithOnlyField[index][key]['search'] = ''
                        useUtilsStore().genericOptionGetters[index][key]['choices'] = this.returnList(key)
                      }
                      break;
                  }
                } else if (option.type === 'update') {
                  if (res.data.actions[this.typeActionsFromOptions][key].type === 'field' && key === 'tag') {
                    this.dictWithOnlyField[index][key]['value'] = this.currentObject[index]['tag'].map(el => el.id)
                    this.dictWithOnlyField[index][key]['names'] = this.currentObject[index]['tag'].map(el => el.name)
                    this.dictWithOnlyField[index][key]['widget'] = 'tag'
                  } else if (res.data.actions[this.typeActionsFromOptions][key].type === 'color' && key === 'color') {
                    this.dictWithOnlyField[index][key].value = this.currentObjectLocal[index][key]
                    // this.dictWithOnlyField[index][key].value = '#000000'
                  } else if (key === 'is_active' && res.data.actions[this.typeActionsFromOptions][key].type === 'boolean') {
                    this.dictWithOnlyField[index][key].value = this.currentObjectLocal[index][key]
                    this.dictWithOnlyField[index][key]['widget'] = 'switch'
                  } else {


                    // gestione file
                    if (useUtilsStore().genericOptionGetters[index][key].type !== 'file upload')
                      this.dictWithOnlyField[index][key].value = this.currentObjectLocal[index][key]
                    else
                      this.dictWithOnlyField[index][key].file = null
                    //

                    // gestione booleani
                    if (res.data.actions[this.typeActionsFromOptions][key].type === 'boolean') {
                      this.dictWithOnlyField[index][key]['widget'] = 'switch'
                    } else
                      this.dictWithOnlyField[index][key]['widget'] = null
                    //

                    // gestione textarea
                    // if (res.data.actions[this.typeActionsFromOptions][key].type === 'string' && 'max_length' in res.data.actions[this.typeActionsFromOptions][key])
                    this.dictWithOnlyField[index][key]['widget'] = null
                    // else
                    //   this.dictWithOnlyField[index][key]['widget'] = 'textarea'
                    //

                    if (!('id' in this.dictWithOnlyField[index])) {
                      this.dictWithOnlyField[index]['id'] = {}
                      this.dictWithOnlyField[index]['id'].value = this.currentObjectLocal[index]['id']
                    }
                  }
                } else {
                  this.dictWithOnlyField[index][key].value = ''
                  this.dictWithOnlyField[index][key].widget = null
                }
              }
              if (res.data.actions[this.typeActionsFromOptions][key].position.col > this.dictMaxCol[index].max) {
                this.dictMaxCol[index].max = res.data.actions[this.typeActionsFromOptions][key].position.col
              }
            }
            this.dictMaxCol[index].max = this.dictMaxCol[index].max + 1
            this.allowed++
          })
          .catch(() => {

          })
    })
  },
  watch: {},
  computed: {},
  mounted() {
    this.setCurrentTypeObj('permissions')
    window.addEventListener('resize', this.handleResize)
    for (let i = 0; i < this.options.endpoints.length; i++) {
      let container_content_form = document.getElementById('container-content-form')
      if (container_content_form) {
        container_content_form.style.gridTemplateColumns = 'repeat(' + this.dictMaxCol[this.step].max + ', 1fr)'
      }
    }
  },
  methods: {
    handleResize() {
      this.innerWidth = window.innerWidth
    },
    setCurrentTypeObj(str) {
      this.currentTypeObj = str
    },
    calculatePositionForListTags() {
      let list_tag_suggestion = document.getElementById('list-tag-suggestion')
      let container_content_tags_custom = document.getElementById('container-content-tags-custom')
      let input_tags = document.getElementById('input-tags')
      if (input_tags && list_tag_suggestion && container_content_tags_custom) {
        list_tag_suggestion.style.marginTop = container_content_tags_custom.getBoundingClientRect().height + 5 + 'px'
      }
    },
    searchTag(tag) {
      let queryParams = {
        search: tag
      }
      useTagStore().getTags({queryParams: queryParams})
          .then(res => {
            if (res.data.count > 0)
              this.listVisible = true
          })
          .catch(() => {
          })
    },
    debounce(func, delay) {
      let timeout;
      return function () {
        const context = this;
        const args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(context, args);
        }, delay);
      }
    },
    removeTag(e, index, key) {
      this.dictWithOnlyField[this.step][key].names.splice(index, 1);
      this.dictWithOnlyField[this.step][key].value.splice(index, 1);
      this.calculatePositionForListTags()
    },
    addTag(e, key) {
      this.isDuplicated = false
      this.errorFormat = false
      if ((e.code === 'Space' || e.key === 'Enter' || e.key === 'Unidentified' || e.code === 'NumpadEnter' || e.code === 'Comma')) {
        let splits = []
        if (e.code === 'Space') {
          if (this.newTag.endsWith(" ")) {
            this.newTag = this.newTag.trim().toLowerCase();
          }
          // this.newTag = this.newTag.substring(0, this.newTag.length-1)
        }
        splits = this.newTag.split(/[ ,;]+/)

        // string sanification - removing al unwanted chars
        splits = splits.map(split => {
          if (split.substring(0, 1) === '#')
            return split.substring(1, split.length)
          return split
        })

        // remove duplicates
        splits = [...new Set(splits)]
        splits.forEach(split => {

          if (split.substring(0, 1) !== '#')
            split = '#' + split
          let queryParams = {
            name: split
          }
          if (/^#[a-z0-9]+(_[a-z0-9]+)*$/.test(split)) {
            if (!(this.dictWithOnlyField[this.step][key].names.includes(split))) {
              useTagStore().getTags({queryParams: queryParams})
                  .then(res => {
                    if (res.data.count === 0) {
                      let obj = {
                        name: split
                      }
                      useTagStore().createTag(obj)
                          .then(resp => {
                            this.dictWithOnlyField[this.step][key].names.push(resp.data.name)
                            this.dictWithOnlyField[this.step][key].value.push(resp.data.id)
                            this.listIdsCreatedTags.push(resp.data.id)
                            this.newTag = ''
                          })
                          .catch(() => {
                          })
                    } else {
                      this.dictWithOnlyField[this.step][key].names.push(res.data.results[0].name)
                      this.dictWithOnlyField[this.step][key].value.push(res.data.results[0].id)
                      this.newTag = ''
                    }
                  })
                  .catch(() => {
                  })
            } else {
              this.newTag = ''
              this.isDuplicated = true
            }
          } else {
            this.errorFormat = true
          }
        })
      }
      this.calculatePositionForListTags()
    },
    clickOnSuggestionTag(e, tag, key) {
      e.stopPropagation()
      if (!(this.dictWithOnlyField[this.step][key].value.includes(tag.id))) {
        this.dictWithOnlyField[this.step][key].names.push(tag.name)
        this.dictWithOnlyField[this.step][key].value.push(tag.id)
      } else {
        this.isDuplicated = true
      }
      this.listVisible = false
      this.newTag = ''
    },
    copyTags(e, key) {
      if (this.dictWithOnlyField[this.step][key].names.length > 0) {
        const tagsString = this.dictWithOnlyField[this.step][key].names.join(', ');
        this.objCopy.text = 'Tag copiati con successo'
        navigator.clipboard.writeText(tagsString).then(() => {
          setTimeout(() => {
            this.objCopy.text = ''
          }, 3000)
        }).catch(() => {
          this.objCopy.text = 'Errore durante la copia dei tag'
          setTimeout(() => {
            this.objCopy.text = ''
          }, 3000)
        });
      }
    },
    handleInput(text, key) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId)
      }
      this.timeoutId = setTimeout(() => {
        let lista = []
        this.dictWithOnlyField[this.step][key].search = text
        let queryParams = {
          pagination: false
        }
        if (text !== '') {
          queryParams['search'] = text

          useUtilsStore().getListFromModelName({queryParams: queryParams, key: key, controller: this.controller})
              .then(res => {
                lista = []
                res.data.forEach(item => {
                  let obj = {
                    value: item.id,
                    display_name: item.display_name
                  }
                  lista.push(obj)
                })

                useUtilsStore().genericOptionGetters[this.step][key].choices = lista;
              })
              .catch(() => {
              })
        } else {
          useUtilsStore().getListFromModelName({queryParams: queryParams, key: key, controller: this.controller})
              .then(res => {
                lista = []
                res.data.forEach(item => {
                  let obj = {
                    value: item.id,
                    display_name: item.display_name
                  }
                  lista.push(obj)
                })
                useUtilsStore().genericOptionGetters[this.step][key].choices = lista;
              })
              .catch(() => {
              })
        }
      }, 500)
    },
    onJsonChange(e, key) {
      this.dictWithOnlyField[this.step][key].value = e
    },
    returnList(key) {
      let lista = []

      let queryParams = {
        pagination: false
      }
      useUtilsStore().getListFromModelName({queryParams: queryParams, key: key, controller: this.controller})
          .then(res => {
            res.data.forEach(item => {
              let obj = {
                value: item.id,
                display_name: item.display_name
              }
              lista.push(obj)
            })
          })
          .catch(() => {
          })
      return lista
    },
    changeBoolean(e, key) {
      if (!e)
        this.dictWithOnlyField[this.step][key].value = null
    },
    setColor(e, key) {
      this.dictWithOnlyField[this.step][key].value = e.target.value
    },
    handleFileInputChange(e, key) {
      this.dictWithOnlyField[this.step][key].file = e.target.files[0]
    },
    toggleSelection(event, value, obj) {
      const index = obj.indexOf(value);
      if (index === -1) {
        obj.push(value);
      } else {
        obj.splice(index, 1);
      }
      event.stopPropagation()
    },
    handleDrop(event, key) {
      event.preventDefault();
      this.dictWithOnlyField[this.step][key].file = event.dataTransfer.files[0];
    },
    // returnTextButton() {
    //   let text = ''
    //
    //   if (this.options.endpoints[this.step].type === 'change-password') {
    //    text = 'Vai al cambio password'
    //   } else if (this.options.endpoints[this.step].type === 'set-password') {
    //    text = 'Vai al settaggio password'
    //   } else {
    //     text = 'Avanti'
    //   }
    //   return text
    // },
    // eslint-disable-next-line no-unused-vars
    returnTextDropdownDynamic(key) {
      let text = ''
      if (useUtilsStore().genericOptionGetters[this.step][key].choices !== null) {
        if (key in this.dictWithOnlyField[this.step]) {
          if ((useUtilsStore().genericOptionGetters[this.step][key].type === 'choice' || useUtilsStore().genericOptionGetters[this.step][key].type === 'field') && this.dictWithOnlyField[this.step][key].value === null || this.dictWithOnlyField[this.step][key].value === '' || this.dictWithOnlyField[this.step][key].value === false) {
            text = 'Scegli un opzione'
            this.dictWithOnlyField[this.step][key].value = null
          } else if ((useUtilsStore().genericOptionGetters[this.step][key].type === 'choice' || useUtilsStore().genericOptionGetters[this.step][key].type === 'field') && this.dictWithOnlyField[this.step][key].value !== null && this.dictWithOnlyField[this.step][key].value !== '') {
            let objFinded = useUtilsStore().genericOptionGetters[this.step][key].choices.find(el => (el.value === this.dictWithOnlyField[this.step][key].value))
            if (objFinded)
              text = objFinded.display_name
          }
        }
      }
      if (useUtilsStore().genericOptionGetters[this.step][key].type === 'multiple choice' || (useUtilsStore().genericOptionGetters[this.step][key].type === 'field' && useUtilsStore().genericOptionGetters[this.step][key].is_many))
        text = 'Scegli una o più opzioni'
      return text
    },
    showedPassword(str) {
      if (str === 'password') {
        this.showPassword = true
        this.hidePassword = false
      } else if (str === 'confirmation') {
        this.showConfirmationPassword = true
        this.hideConfirmationPassword = false
      } else if (str === 'confirmed_password') {
        this.showConfirmedPassword = true
        this.hideConfirmedPassword = false
      } else if (str === 'old_password') {
        this.showOldPassword = true
        this.hideOldPassword = false
      }
    },
    hidedPassword(str) {
      if (str === 'password') {
        this.showPassword = false
        this.hidePassword = true
      } else if (str === 'confirmation') {
        this.showConfirmationPassword = false
        this.hideConfirmationPassword = true
      } else if (str === 'confirmed_password') {
        this.showConfirmedPassword = false
        this.hideConfirmedPassword = true
      } else if (str === 'old_password') {
        this.showOldPassword = false
        this.hideOldPassword = true
      }
    },
    async onSubmit() {
      let input_tags = document.getElementById('input-tags')
      this.isLoading = false
      if (document.activeElement !== input_tags) {
        useApiSettingStore().error500 = ''
        useCategoryStore().errorMimeTypeImage = false
        this.lastReason = {}
        let objFinal = {}
        let bool = false

        this.options.endpoints.forEach((endpoint, index) => {
          if (endpoint.type === 'create') {

            objFinal[index] = {}
            for (let key in useUtilsStore().genericOptionGetters[index]) {
              if (useUtilsStore().genericOptionGetters[index][key].type === 'file upload' && useUtilsStore().genericOptionGetters[index][key].required) {
                bool = true
                objFinal[index] = new FormData()
              }
            }

            for (let key in useUtilsStore().genericOptionGetters[index]) {
              if (key in this.dictWithOnlyField[index]) {
                if ('value' in this.dictWithOnlyField[index][key] && useUtilsStore().genericOptionGetters[index][key].type === 'duration' && this.dictWithOnlyField[index][key].max_date && this.dictWithOnlyField[index][key].min_date) {
                  let diff = moment(this.dictWithOnlyField[index][key].max_date).unix() - moment(this.dictWithOnlyField[index][key].min_date).unix();
                  this.dictWithOnlyField[index][key].value = moment.utc(diff).format("DD HH:mm:ss.SSSSSS");
                }
                // caso in cui c'è un file nel form corrente


                if (bool) {
                  if (useUtilsStore().genericOptionGetters[index][key].required ||
                      ('value' in this.dictWithOnlyField[index][key] && (this.dictWithOnlyField[index][key].value !== null &&
                          this.dictWithOnlyField[index][key].value !== undefined &&
                          this.dictWithOnlyField[index][key].value !== 0 &&
                          this.dictWithOnlyField[index][key].value !== '')) ||
                      ('file' in this.dictWithOnlyField[index][key] && (this.dictWithOnlyField[index][key].file !== null))
                  ) {
                    if ('value' in this.dictWithOnlyField[index][key]) {
                      if (useUtilsStore().genericOptionGetters[index][key].type === 'json')
                        objFinal[index].set(key, JSON.stringify(this.dictWithOnlyField[index][key].value))
                      else if (useUtilsStore().genericOptionGetters[index][key].type === 'field') {
                        objFinal[index].set(key, this.dictWithOnlyField[index][key].value)
                        // else
                        //   objFinal[index].set(key, '')
                      } else
                        objFinal[index].set(key, this.dictWithOnlyField[index][key].value)
                    }
                    if ('file' in this.dictWithOnlyField[index][key]) {
                      if (endpoint.endpoint === 'categories') {
                        if (this.dictWithOnlyField[index][key].file) {
                          objFinal[index].append(key, this.dictWithOnlyField[index][key].file)
                        }
                      } else {
                        objFinal[index].append(key, this.dictWithOnlyField[index][key].file)
                      }
                    }
                  }
                } else {
                  if (useUtilsStore().genericOptionGetters[index][key].required ||
                      ('value' in this.dictWithOnlyField[index][key] && (this.dictWithOnlyField[index][key].value !== null &&
                          this.dictWithOnlyField[index][key].value !== undefined &&
                          this.dictWithOnlyField[index][key].value !== 0 &&
                          this.dictWithOnlyField[index][key].value !== ''))
                  ) {
                    if (key === 'wiki') {
                      objFinal[index][key] = JSON.stringify(this.dictWithOnlyField[index][key].value)
                    } else {
                      objFinal[index][key] = this.dictWithOnlyField[index][key].value
                    }
                  }
                }
              }
            }
          } else if (endpoint.type === 'update') {
            objFinal[index] = {}
            for (let key in useUtilsStore().genericOptionGetters[index]) {
              if (useUtilsStore().genericOptionGetters[index][key].type === 'file upload' && useUtilsStore().genericOptionGetters[index][key].required) {
                bool = true
                objFinal[index] = new FormData()
              }
            }
            for (let key in useUtilsStore().genericOptionGetters[index]) {
              if (useUtilsStore().genericOptionGetters[index][key].is_visible || key === 'id') {
                if (bool) {
                  if ('value' in this.dictWithOnlyField[index][key]) {
                    if (useUtilsStore().genericOptionGetters[index][key].type === 'json')
                      objFinal[index].set(key, JSON.stringify(this.dictWithOnlyField[index][key].value))
                    else if (useUtilsStore().genericOptionGetters[index][key].type === 'field') {
                      objFinal[index].set(key, this.dictWithOnlyField[index][key].value)
                      // else
                      //   objFinal[index].set(key, '')
                    } else
                      objFinal[index].set(key, this.dictWithOnlyField[index][key].value)
                  }
                  if ('file' in this.dictWithOnlyField[index][key]) {
                    if (endpoint.endpoint === 'categories') {
                      if (this.dictWithOnlyField[index][key].file) {
                        objFinal[index].append(key, this.dictWithOnlyField[index][key].file)
                      }
                    } else {
                      objFinal[index].append(key, this.dictWithOnlyField[index][key].file)
                    }
                  }
                } else {
                  if (key === 'wiki') {
                    objFinal[index][key] = JSON.stringify(this.dictWithOnlyField[index][key].value)
                  } else {
                    objFinal[index][key] = this.dictWithOnlyField[index][key].value
                  }
                }
              }
            }
          } else {
            objFinal[index] = {}
            for (let key in useUtilsStore().genericOptionGetters[index]) {
              if (useUtilsStore().genericOptionGetters[index][key].is_visible || key === 'id') {
                objFinal[index][key] = this.dictWithOnlyField[index][key].value
              }
            }
          }
        })
        let result = null;
        if (this.isLoading) return // evita di caricare nuovi dati se un caricamento è già in corso
        this.isLoading = true // indica che un caricamento è in corso

        for (let i = 0; i < this.options.endpoints.length; i++) {
          const func = this.endpointsActionsMapping[this.options.endpoints[i].endpoint][this.options.endpoints[i].type].action;
          try {
            result = await func(objFinal[i]);
            if (result)
              useUtilsStore().lastResponse = result.data
            if (i + 1 === this.options.endpoints.length) {
              this.$emit('closeForm')
              this.isLoading = false
              this.listIdsCreatedTags = []
            }
          } catch (error) {
            this.isLoading = false
            this.step = i
            if (error && error.response)
              this.lastReason = error.response.data
            return
          }
        }
      }
    },
    returnMax(type, max, key) {
      let maxFinal
      if (type === 'integer')
        maxFinal = max
      else if (type === 'decimal')
        maxFinal = ((10 ** useUtilsStore().genericOptionGetters[this.step][key].max_digits) - 1) / (10 ** useUtilsStore().genericOptionGetters[this.step][key].decimal_places)
      else if (type === 'datetime')
        maxFinal = moment().format('yyyy-MM-DDTHH:mm')
      else if (type === 'float')
        maxFinal = (10 ** 38)
      else if (type === 'date')
        maxFinal = new Date().toISOString().split("T")[0]
      return maxFinal
    },
    returnMin(type, min, key) {
      let minFinal
      if (type === 'integer')
        minFinal = min
      else if (type === 'decimal')
        minFinal = -((10 ** useUtilsStore().genericOptionGetters[this.step][key].max_digits) - 1) / (10 ** useUtilsStore().genericOptionGetters[this.step][key].decimal_places)
      else if (type === 'float')
        minFinal = -(10 ** 38)
      // else if (type === 'datetime')
      //   minFinal = moment().format('yyyy-MM-DDTHH:mm')
      // else if (type === 'date')
      //   minFinal = new Date().toISOString().split("T")[0]
      return minFinal
    },
    getInputType(key, type) {
      let typeFinal = "";
      switch (type) {
        case "string":
          if (key === 'password' && !this.showPassword)
            typeFinal = "password";
          else if (key === 'confirmation' && !this.showConfirmationPassword)
            typeFinal = "password";
          else if (key === 'confirmed_password' && !this.showConfirmedPassword)
            typeFinal = "password";
          else if (key === 'old_password' && !this.showOldPassword)
            typeFinal = "password";
          else
            typeFinal = 'text'
          break;
        case "integer":
          typeFinal = "number";
          break;
        case "decimal":
          typeFinal = "number";
          break;
        case "email":
          typeFinal = "email";
          break;
        case "datetime":
          typeFinal = "datetime-local";
          break;
        case "date":
          typeFinal = "date";
          break;
        case "color":
          typeFinal = "color";
          break;
        case "file upload":
          typeFinal = "file";
          break;
        default:
      }
      return typeFinal;
    },
    closeUl() {
      this.listVisible = false
    }
  },
  updated() {
    let input_color = document.getElementById('input-color')
    if (input_color && this.dictWithOnlyField)
      input_color.value = this.dictWithOnlyField[this.step]['color'].value

    let input_tags = document.getElementById('input-tags')
    if (input_tags) {
      input_tags.addEventListener('input', this.debounce((event) => {
        this.newTag = event.target.value
        this.searchTag(event.target.value)
        this.calculatePositionForListTags()
      }, 500));
    }

    let container_content_form = document.getElementById('container-content-form')
    if (container_content_form) {
      container_content_form.style.gridTemplateColumns = 'repeat(' + this.dictMaxCol[this.step].max + ', 1fr)'
    }
  },
  beforeUnmount() {
    if (useUserStore().controllerGetUserGetters)
      useUserStore().controllerGetUserGetters.abort()
    if (useUtilsStore().controllerGetGenericOptionCycleGetters)
      useUtilsStore().controllerGetGenericOptionCycleGetters.abort()
    window.removeEventListener('resize', this.handleResize)
    let input_tags = document.getElementById('input-tags')
    if (input_tags)
      input_tags.removeEventListener('input', this.debounce())
    this.emitter.off('submitEvent')
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize)
    this.emitter.off('submitEvent')
  }

}
</script>

<style scoped>

.container-sub-head-group span {
  margin-right: auto;
}

.container-sub-head-group img {
  margin-right: auto;
}

.container-sub-head-group {
  cursor: pointer;
  flex-wrap: wrap;
  display: flex;
  margin-top: 20px;
  align-items: center;
}

.spinner-border {
  padding: 0px !important;
}

.text-16px-grey-regular-no-margin.text-16px-grey-regular {
  margin-right: 0px;
}

.container-components {
  display: flex;
}

.container-head-form-groups-custom span {
  margin-right: auto;
}

.container-head-form-groups-custom {
  cursor: pointer;
  display: flex;
  margin-top: 20px;
  align-items: center;
}

#lista {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  border: 1px solid #ccc;
  background-color: #fff;
}

.form-group-dates.form-group input {
  margin-right: 5px;

}

.form-group-dates.form-group {
  flex-direction: row;
  padding: 0px;
}

.form-check {
  margin-right: 10px !important;
}

.container-boolean {
  display: flex;
  flex-wrap: wrap;
}

.btn-primary-custom-login.btn-primary-custom {
  margin-top: 30px;
  margin-right: auto;
}

.btn-light-grey-custom {
  margin-left: auto;
  margin-right: 20px;
}

.container-content-field {
  position: relative;
}

.container-content-form-login {
  display: flex !important;
  flex-direction: column !important;
}

.container-content-form {
  display: grid;
  overflow: auto;
  padding: 5px 0px;
  column-gap: 4%;

}

.form-no-padding {
  padding: 10px 0px !important;
}

form {
  display: flex;
  flex-direction: column;
}


.container-form {
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  padding: 0px 10px;
  width: 100%;
}
</style>