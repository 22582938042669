import {defineStore} from 'pinia'
import axiosInstance from "@/services/api";
import {useApiSettingStore} from "@/stores/apiSettings";

export const usePublicWallStore = defineStore(
    "publicWall",
    {
        state: () => ({
            /*** controllers ***/
            controllerGetPublicWalls: null,
            controllerGetPublicWall: null,
            /*** ***/

            publicWalls: null,
            arrayPublicWallInfinity: [],
            optionsPublicWall: null,
            publicWall: null,
            allPublicWall: null,
            oldPublicWall: null,
            spinnerPublicWall: false
        }),
        actions: {
            getOptionsPublicWall() {
                return new Promise((resolve, reject) => {

                    axiosInstance.options('api/public-walls/')
                        .then(res => {
                            this.optionsPublicWall = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                })
            },
            getPublicWall(id) {
                return new Promise((resolve, reject) => {
                    this.controllerGetPublicWall = new AbortController()
                    const config = {
                        signal: usePublicWallStore().controllerGetPublicWallGetters.signal
                    }

                    axiosInstance.get('api/public-walls/' + id + '/', config)
                        .then(res => {
                            this.publicWall = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                })
            },
            getPublicWalls(payload) {
                return new Promise((resolve, reject) => {
                    this.controllerGetPublicWalls = new AbortController()
                    const config = {
                        signal: usePublicWallStore().controllerGetPublicWallsGetters.signal
                    }

                    let url = new URL(axiosInstance.defaults.baseURL + 'api/public-walls/')

                    this.spinnerPublicWall = true
                    if (payload.queryParams !== null) {
                        let params = new URLSearchParams()
                        for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                            params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                        }
                        url.search = params
                        axiosInstance.get(url.pathname + url.search, config)
                            .then(res => {
                                this.publicWalls = res.data
                                if (payload.queryParams.scroll) {
                                    this.arrayPublicWallInfinity.push(...res.data.results)
                                } else {
                                    this.arrayPublicWallInfinity = res.data.results
                                }
                                this.spinnerPublicWall = false
                                resolve(res)
                            })
                            .catch(err => {
                                this.spinnerPublicWall = false
                                if (err.response && err.response.status === 500) {
                                    useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                                }
                                reject(err)
                            })
                    } else {
                        axiosInstance.get(url.pathname, config)
                            .then(res => {
                                this.spinnerPublicWall = false
                                this.publicWalls = res.data
                                let a = []
                                a.push(...res.data.results);
                                this.arrayPublicWallInfinity = a
                                resolve(res)
                            })
                            .catch(err => {
                                this.spinnerPublicWall = false
                                if (err.response && err.response.status === 500) {
                                    useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                                }
                                reject(err)
                            })
                    }

                })
            },
            getPublicWallsWithoutSetState(payload) {
                return new Promise((resolve, reject) => {
                    let url = new URL(axiosInstance.defaults.baseURL + 'api/public-walls/')

                    this.spinnerPublicWall = true
                    if (payload.queryParams !== null) {
                        let params = new URLSearchParams()
                        for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                            params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                        }
                        url.search = params
                        axiosInstance.get(url.pathname + url.search)
                            .then(res => {
                                resolve(res)
                            })
                            .catch(err => {
                                this.spinnerPublicWall = false
                                if (err.response && err.response.status === 500) {
                                    useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                                }
                                reject(err)
                            })
                    } else {
                        axiosInstance.get(url.pathname)
                            .then(res => {
                                resolve(res)
                            })
                            .catch(err => {
                                this.spinnerPublicWall = false
                                if (err.response && err.response.status === 500) {
                                    useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                                }
                                reject(err)
                            })
                    }

                })
            },
            createPublicWall(payload) {
                return new Promise((resolve, reject) => {
                    axiosInstance.post('/api/public-walls/', payload)
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            reject(err)
                        })
                })
            },
            getAllPublicWall(payload) {
                return new Promise((resolve, reject) => {
                    let url = new URL(axiosInstance.defaults.baseURL + 'api/public-walls/')

                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axiosInstance.get(url.pathname + url.search)
                        .then(res => {
                            this.allPublicWall = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })

                })
            },
            editPublicWall(payload) {
                return new Promise((resolve, reject) => {
                    axiosInstance.patch('api/public-walls/' + payload.id + '/', payload)
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                })
            },
            deletePublicWall(id) {
                return new Promise((resolve, reject) => {
                    axiosInstance.delete('/api/public-walls/' + id + '/')
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                })
            }
        },
        getters: {
            publicWallsGetters: state => {
                return state.publicWalls
            },
            allPublicWallGetters: state => {
                return state.allPublicWall
            },
            oldPublicWallGetters: state => {
                return state.oldPublicWall
            },
            publicWallGetters: state => {
                return state.publicWall
            },
            arrayPublicWallInfinityGetters: state => {
                return state.arrayPublicWallInfinity
            },
            spinnerPublicWallGetters: state => {
                return state.spinnerPublicWall
            },
            optionsPublicWallGetters: state => {
                return state.optionsPublicWall
            },
            controllerGetPublicWallsGetters: state => {
                return state.controllerGetPublicWalls
            },
            controllerGetPublicWallGetters: state => {
                return state.controllerGetPublicWall
            }
        }
    })