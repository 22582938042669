import {defineStore} from 'pinia'
import axiosInstance from "@/services/api";


export const useApiSettingStore = defineStore({
    id: 'apiSettings',

    state: () => ({
        apiSettings: null,
        error500: '',
        errorNetwork: false,
    }),
    actions: {
        getApiSettings() {
            return new Promise((resolve, reject) => {

                axiosInstance.options('api/')
                    .then(res => {
                        this.apiSettings = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        }
    },

    getters: {
        apiSettingsGetters: state => {
            return state.apiSettings
        },
        error500Getters: state => {
            return state.error500
        },
        errorNetworkGetters: state => {
            return state.errorNetwork
        }
    }
})


