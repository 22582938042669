<template>
  <div class="container-text-mining">

    <b-dropdown v-if="fields.length > 1" :text="currentTextDropdown">
      <div @click="initTextMining(item)" class="container-single-item" :key="i" v-for="(item,i) in fields">
        {{ item.value }}
      </div>
    </b-dropdown>

    <div class="container-content-text-mining" v-if="storeUtils.textAnalysisGetters && !isLoading">
      <div class="container-head-text-mining">
        <div class="container-single-tab" @click="setCurrentTab($event,'sentiment')">
          <span class="text-16px-black-medium">Sentiment</span>
          <div :class="{'container_footer_single_tab': currentTab === 'sentiment'}"
               class="container-footer-single-tab"></div>
        </div>
        <div class="container-single-tab" @click="setCurrentTab($event,'entities')">
          <span class="text-16px-black-medium">Entità</span>
          <div :class="{'container_footer_single_tab': currentTab === 'entities'}"
               class="container-footer-single-tab"></div>
        </div>
        <div class="container-single-tab container-single-tab-last" @click="setCurrentTab($event,'syntax')">
          <span class="text-16px-black-medium">Sintassi</span>
          <div :class="{'container_footer_single_tab': currentTab === 'syntax'}"
               class="container-footer-single-tab"></div>
        </div>
      </div>
      <div class="container-body-text-mining">
        <ComponentTextMiningSentiment v-if="currentTab === 'sentiment'"></ComponentTextMiningSentiment>
        <ComponentTextMiningEntities :currentObject="currentObject" :currentField="currentField"
                                     v-if="currentTab === 'entities'"></ComponentTextMiningEntities>
        <ComponentTextMiningSyntax v-if="currentTab === 'syntax'"></ComponentTextMiningSyntax>
      </div>
    </div>
    <b-spinner v-if="isLoading"></b-spinner>
  </div>
</template>

<script>
import ComponentTextMiningEntities from "@/components/shared/ComponentTextMiningEntities";
import ComponentTextMiningSentiment from "@/components/shared/ComponentTextMiningSentiment";
import {useUtilsStore} from "@/stores/utils";
import ComponentTextMiningSyntax from "@/components/shared/ComponentTextMiningSyntax";

export default {
  name: "ComponentTextMining",
  components: {ComponentTextMiningSyntax, ComponentTextMiningSentiment, ComponentTextMiningEntities},
  props: {
    id: {
      type: Number
    },
    currentObject: {
      type: Object
    },
    model: {
      type: String
    },
    fields: {
      type: Array
    }
  },
  setup() {
    const storeUtils = useUtilsStore()
    return {
      storeUtils
    }
  },
  data() {
    return {
      currentTextDropdown: 'Scegli un dato da analizzare',
      isLoading: false,
      currentField: '',
      currentTab: 'sentiment'
    }
  },
  async created() {
    if (this.fields.length === 1) {
      this.currentField = this.fields[0].name_db
      await this.textMining()
    }

  },
  watch: {},
  computed: {},
  mounted() {
  },
  methods: {
    initTextMining(item) {
      this.currentTextDropdown = item.value
      this.currentField = item.name_db
      this.textMining()
    },
    async textMining() {
      if (this.isLoading) return // evita di caricare nuovi dati se un caricamento è già in corso
      this.isLoading = true // indica che un caricamento è in corso

      await useUtilsStore().getTextAnalysis({
        "model": this.model,
        "instance_id": this.id,
        "field": this.currentField
      })
          .then(() => {
            this.isLoading = false
          }, () => {
            this.isLoading = false
          })
          .catch(() => {
            this.isLoading = false
          })
    },
    setCurrentTab(e, tab) {
      this.currentTab = tab
    }
  },
  updated() {
  },
  beforeUnmount() {
    useUtilsStore().textAnalysis = null
  },
  unmounted() {
  }
}
</script>

<style scoped>
.container-single-item:hover {
  background: var(--light-grey-color);
}

.container-single-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 15px;
}

:deep(a) {
  color: var(--primary-color) !important;
  text-decoration: underline !important;
}

:deep(.show.dropdown-menu) {
  border-radius: 0px !important;
  width: 100% !important;
}

:deep(.btn-group) {
  width: 100%;
}

:deep(.dropdown-toggle.btn-secondary.btn-md.btn) {
  display: flex;
  align-items: center;
}

.container-body-text-mining {
  max-height: 650px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  background: var(--light-grey-color);
}

.container_footer_single_tab.container-footer-single-tab {
  border: 1px solid var(--primary-color);
}

.container-footer-single-tab {
  display: flex;
  width: 100%;
  border: 1px solid transparent;
}

.container-single-tab-last.container-single-tab {
  margin-right: 0px;
}

.container-single-tab {
  cursor: pointer;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}

.container-text-mining {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-content-text-mining {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.spinner-border {
  margin: 10px !important;
}

.container-head-text-mining {
  padding: 15px 20px;
  display: flex;
  align-items: center;
  background: var(--white-color);
}
</style>