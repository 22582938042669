<template>
  <button :disabled="buttonDisabled" class="btn btn-primary-custom container-text" @click="showTextMining = true">
    <img v-show="!buttonDisabled" src="../../assets/shared/text-style.svg" alt="">
    <img v-show="buttonDisabled" src="../../assets/shared/text-style-disabled.svg" alt="">
  </button>
  <b-modal content-class="content-class-text-mining" @hide="storeUtils.textAnalysis = null" :hide-footer="true"
           :hide-header="true" v-model="showTextMining">
    <ComponentTextMining v-if="showTextMining" :model="model" :currentObject="currentObject" :fields="fields"
                         :id="id"></ComponentTextMining>
  </b-modal>
</template>

<script>
import ComponentTextMining from "@/components/shared/ComponentTextMining";
import {useUtilsStore} from "@/stores/utils";

export default {
  name: "ComponentText",
  components: {ComponentTextMining},
  props: {
    id: {
      type: Number
    },
    currentObject: {
      type: Object
    },
    buttonDisabled: {
      type: Boolean
    },
    model: {
      type: String
    },
    fields: {
      type: Array
    }
  },
  setup() {
    const storeUtils = useUtilsStore()
    return {
      storeUtils
    }
  },
  data() {
    return {
      showTextMining: false,
    }
  },
  created() {
  },
  watch: {},
  computed: {},
  mounted() {
  },
  methods: {},
  updated() {
  },
  beforeUnmount() {
  },
  unmounted() {
  }
}
</script>

<style scoped>

:deep(.content-class-text-mining.modal-content) {
  max-width: 100% !important;
  width: 100% !important;
}

:deep(.modal-dialog) {
  max-width: 100% !important;
}


.container-text {
  padding: 10px;
  cursor: pointer;
  background: transparent !important;
  border: 1px solid var(--primary-color) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
</style>