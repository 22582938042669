<template>
  <div class="container-left-login">
    <img class="img-blue" src="../../assets/shared/blue.svg" alt="">
    <img class="img-yellow" src="../../assets/shared/yellow.svg" alt="">
    <img class="img-purple" src="../../assets/shared/purple.svg" alt="">
    <div class="container-content-left-login">
      <span class="text-64px-black-bold">Un nuovo metodo</span>
      <span class="text_dm_serif_display" id="text">comunicativo</span>
      <span class="text-24px-black-medium">per l’archiviazione dei contributi innovativi</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ComponentPresentationDynamic",
  setup() {
    return {}
  },
  data() {
    return {
      intervalId: null,
      arrayClassesCss: [
        'text_dm_serif_display',
        'text_glass_antiqua',
        'text_dotGothic16',
        'text-darker_grotesque',
      ]
    }
  },
  created() {
  },
  watch: {},
  computed: {},
  mounted() {
    const span = document.getElementById('text'); // seleziona tutti gli elementi span
    let currentFontIndex = 0; // definisce l'indice del font corrente

    clearInterval(this.intervalId)
    this.intervalId = setInterval(() => {
      for (let i = 0; i < this.arrayClassesCss.length; i++) { // loop su tutti gli elementi span
        span.classList.remove(...this.arrayClassesCss);
        // span.classList = []
        span.classList.add(this.arrayClassesCss[currentFontIndex]); // applica il font corrente ad ogni elemento
      }
      currentFontIndex = (currentFontIndex + 1) % this.arrayClassesCss.length; // passa al font successivo
    }, 1000); // cambia il font ogni secondo (1000 millisecondi)
  },
  methods: {},
  updated() {
  },
  beforeUnmount() {
    clearInterval(this.intervalId)
  },
  unmounted() {
  }
}
</script>

<style scoped>
.text_dm_serif_display {
  font-family: 'DM Serif Display' !important;
  font-style: normal;
  font-weight: var(--font-weight-regular);
  font-size: var(--x-large);
  line-height: 110%;
  /*word-break: break-all;*/
  color: var(--secondary-color);
}

.text_glass_antiqua {
  font-family: 'Glass Antiqua' !important;
  font-style: normal;
  font-weight: var(--font-weight-regular);
  font-size: var(--x-large);
  line-height: 110%;
  /*word-break: break-all;*/
  color: var(--secondary-color-hover)
}

.text_dotGothic16 {
  font-family: 'DotGothic16' !important;
  font-style: normal;
  font-weight: var(--font-weight-regular);
  font-size: var(--x-large);
  line-height: 110%;
  /*word-break: break-all;*/
  color: var(--secondary-color)
}

.text-darker_grotesque {
  font-family: 'Darker Grotesque' !important;
  font-style: normal;
  font-weight: var(--font-weight-regular);
  font-size: var(--x-large);
  line-height: 110%;
  /*word-break: break-all;*/
  color: var(--secondary-color-hover);
}

.img-yellow {
  position: absolute;
  top: 25px;
  right: 150px;
  z-index: 0;
  width: 15rem;
}

.img-purple {
  position: absolute;
  right: 50px;
  z-index: -1;
  top: 0;
  width: 20rem;
}

.img-blue {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 25rem;
}

.container-content-left-login {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  background: rgba(249, 250, 251, 0.3);
  border: 1px solid var(--dark-white-color);
  backdrop-filter: blur(50px);
  border-radius: 4px;
  padding: 50px 20px;
}

.container-left-login {
  background: linear-gradient(158.36deg, rgba(242, 243, 245, 0.1) 4.26%, rgba(169, 213, 247, 0.1) 90.12%);
  backdrop-filter: blur(25px);
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/*// X-Small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
}

/*// Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {

}

/*// Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {
  .container-left-login {
    display: none;
  }
}

/*// Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {
}

/*// X-Large devices (large desktops, less than 1400px)*/
@media (max-width: 1399.98px) {
}

</style>