<template>
  <div class="container-confirm-delete">
    <div class="container-head-form">
      <div class="container-content-head-form">
        <span
            class="text-16px-black-bold">{{
            storeUtils.infoForToDeleteGetters['typeObjectToDelete'] === 'leave' ? 'Abbandona' : 'Eliminazione'
          }} {{
            storeUtils.infoForToDeleteGetters['name']
          }}</span>
      </div>
      <img @click="storeUtils.resetConfirmDelete()" class="img-close" src="../../assets/shared/close.svg" alt="">
    </div>
    <div class="container-body-modal">
      <img src="../../assets/shared/cancel.svg" alt="">
      <div class="container-messagge-to-delete" v-bind="storeUtils.infoForToDeleteGetters['messageToDelete']"></div>
    </div>
    <div class="container-footer-form">
      <button :disabled="storeUtils.spinnerDeletingGetters" @click="storeUtils.resetConfirmDelete()"
              class="btn btn-light-grey-custom">Annulla
      </button>
      <button :disabled="storeUtils.spinnerDeletingGetters" @click="confirmDelete" class="btn btn-danger-custom">
        <span v-show="!storeUtils.spinnerDeletingGetters">{{
            storeUtils.infoForToDeleteGetters['typeObjectToDelete'] === 'leave' ? 'Abbandona' : 'Elimina'
          }}</span>
        <b-spinner v-show="storeUtils.spinnerDeletingGetters"></b-spinner>
      </button>
    </div>
  </div>
</template>

<script>
import {useUtilsStore} from "@/stores/utils";
import {useCategoryStore} from "@/stores/categories";
import {useFavoriteStore} from "@/stores/favorites";

export default {
  name: "ComponentConfirmDelete",
  setup() {
    const storeUtils = useUtilsStore()
    const storeCategory = useCategoryStore()
    const storeFavorite = useFavoriteStore()
    return {
      storeFavorite,
      storeCategory,
      storeUtils
    }
  },
  data() {
    return {}
  },
  created() {
    this.storeUtils.spinnerDeleting = false
  },
  methods: {
    confirmDelete() {
      this.storeUtils.spinnerDeleting = true
      switch (useUtilsStore().infoForToDeleteGetters['typeObjectToDelete']) {
        case 'single':
          useUtilsStore().infoForToDeleteGetters['action'](useUtilsStore().infoForToDeleteGetters['objectToDelete'].id)
              .then(() => {
                if ('action' in useUtilsStore().infoForToDeleteGetters.actionAfterDelete && useUtilsStore().infoForToDeleteGetters.actionAfterDelete.action !== null && 'queryParams' in useUtilsStore().infoForToDeleteGetters.actionAfterDelete) {
                  useUtilsStore().infoForToDeleteGetters.actionAfterDelete.action({queryParams: useUtilsStore().infoForToDeleteGetters.actionAfterDelete.queryParams})
                      .then(() => {
                        useUtilsStore().showedDetailUser = false
                        if (useUtilsStore().infoForToDeleteGetters.currentPosition === 'detail') {
                          this.$router.back()
                          this.storeUtils.spinnerDeleting = false
                        }
                        if (useUtilsStore().infoForToDeleteGetters.currentPosition === 'chat') {
                          this.emitter.emit('emitUpdateThreads')
                          this.storeUtils.spinnerDeleting = false
                        }
                        useUtilsStore().resetConfirmDelete()
                        this.storeUtils.spinnerDeleting = false
                        this.storeFavorite.isDeleted = true
                      })
                      .catch(() => {
                        this.storeUtils.spinnerDeleting = false
                      })
                } else if ('action' in useUtilsStore().infoForToDeleteGetters.actionAfterDelete && useUtilsStore().infoForToDeleteGetters.actionAfterDelete.action !== null && 'payload' in useUtilsStore().infoForToDeleteGetters.actionAfterDelete) {
                  useUtilsStore().infoForToDeleteGetters.actionAfterDelete.action(useUtilsStore().infoForToDeleteGetters.actionAfterDelete.payload)
                      .then(() => {
                        useUtilsStore().showedDetailUser = false
                        useUtilsStore().resetConfirmDelete()
                        this.storeUtils.spinnerDeleting = false
                        this.storeFavorite.isDeleted = true
                      })
                      .catch(() => {
                        this.storeUtils.spinnerDeleting = false
                      })
                } else {
                  if (useUtilsStore().infoForToDeleteGetters.currentPosition === 'detail') {
                    this.$router.back()
                    this.storeUtils.spinnerDeleting = false
                    useUtilsStore().resetConfirmDelete()
                  }
                  // caso specifico per eliminare dal DOM un messaggio
                  else if (useUtilsStore().infoForToDeleteGetters.currentPosition === 'chat') {
                    document.getElementById('chat_balloon_' + useUtilsStore().infoForToDeleteGetters['objectToDelete'].id).remove()
                    this.storeUtils.spinnerDeleting = false
                    useUtilsStore().resetConfirmDelete()
                  }
                }
              })
              .catch(() => {
                this.storeUtils.spinnerDeleting = false
              })
          break;
        case 'bulk':
          useUtilsStore().bulkDeleteFromList({
            entity: useUtilsStore().infoForToDeleteGetters.entity,
            list_ids: useUtilsStore().infoForToDeleteGetters.listIds
          })
              .then(() => {
                useUtilsStore().infoForToDeleteGetters.actionAfterDelete.action({queryParams: useUtilsStore().infoForToDeleteGetters.actionAfterDelete.queryParams})
                    .then(() => {
                      this.storeUtils.spinnerDeleting = false
                      useUtilsStore().resetConfirmDelete()

                    })
                    .catch(() => {
                      this.storeUtils.spinnerDeleting = false
                    })
              })
              .catch(() => {
                this.storeUtils.spinnerDeleting = false
              })
          break;
        case 'leave':
          useUtilsStore().infoForToDeleteGetters['action'](useUtilsStore().infoForToDeleteGetters['objectToDelete'].id)
              .then(() => {
                useUtilsStore().resetConfirmDelete()
                this.storeUtils.spinnerDeleting = false
                this.emitter.emit('emitUpdateThreads')
              })
              .catch(() => {

              })

          break;
        default:
      }
    }
  },
  beforeUnmount() {
    this.storeUtils.spinnerDeleting = false
    useUtilsStore().resetConfirmDelete()
  },
  unmounted() {
    this.storeUtils.spinnerDeleting = false
    useUtilsStore().resetConfirmDelete()
  }
}
</script>

<style scoped>
.spinner-border {
  padding: 0px !important;
}

.btn-danger-custom {
  display: flex;
}

.btn-light-grey-custom {
  margin-left: auto;
  margin-right: 20px;
}

img {
  width: auto !important;
}

.container-footer-form {
  margin-top: 0px;
  align-items: unset;
  padding: 10px 15px;
}

.container-head-form {
  background: var(--white-color);
}

.container-body-modal img {
  margin-right: 15px;
}

.container-body-modal {
  align-items: center;
  display: flex;
  background: var(--white-color);
  border-top: 1px solid var(--light-grey-color);
  padding: 20px;
}

.container-confirm-delete {
  display: flex;
  flex-direction: column;
}
</style>