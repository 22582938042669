import {defineStore} from 'pinia'
import axiosInstance from "@/services/api";
import {useAuthStore} from "@/stores/auth";
import {useApiSettingStore} from "@/stores/apiSettings";
import {useContentTypeStore} from "@/stores/contentType";

export const useFavoriteStore = defineStore("favorite", {
    state: () => ({
        /*** controller ***/
        controllerGetFavorites: null,
        /*** ***/
        currentKeyDeleting: '',
        debounceBoolean: false,
        isDeleted: false,
        dictonaryFavorites: {
            category: {
                controller: null,
                count: 0,
                single_name: 'categoria',
                key: 'category',
                name: 'Categorie'
            },
            subcategory: {
                controller: null,
                count: 0,
                key: 'subcategory',
                single_name: 'sottocategoria',
                name: 'Sottocategorie'
            },
            project: {
                controller: null,
                count: 0,
                key: 'project',
                single_name: 'progetto',
                name: 'Progetti'
            },
            document: {
                controller: null,
                count: 0,
                key: 'document',
                single_name: 'documento',
                name: 'Documenti'
            },
            wiki: {
                controller: null,
                count: 0,
                key: 'wiki',
                single_name: 'wiki',
                name: 'Wiki'
            }
        },
        favorites: null,
        arrayFavoritesInfinity: [],
        infiniteHorizontalArray: [],
        optionsFavorite: null,
        favorite: null,
        allFavorites: null,
        oldFavorite: null,
        spinnerFavorite: false
    }),
    actions: {
        setStatusFavorites(payload) {
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve, reject) => {
                if (payload.object.is_favorite) {
                    // è già nei preferiti, rimuovere
                    useFavoriteStore().deleteFavorite(payload.object.favorite_id)
                        .then(() => {
                            payload.action(payload.params)
                                .then(resp => {
                                    resolve(resp)
                                })
                                .catch(err => {
                                    reject(err)
                                })

                        })
                        .catch(() => {
                        })
                } else {
                    this.debounceBoolean = true
                    let objFavorite = {
                        content_type: useContentTypeStore().contentTypesGetters[payload.entity].id,
                        object_id: payload.object.id,
                        owner: useAuthStore().userGetters.user_id
                    }
                    // non è tra i preferiti, aggiungere
                    useFavoriteStore().createFavorite(objFavorite)
                        .then(() => {
                            payload.action(payload.params)
                                .then(resp => {
                                    this.debounceBoolean = false
                                    resolve(resp)
                                })
                                .catch(err => {
                                    this.debounceBoolean = false
                                    reject(err)
                                })
                        })
                        .catch(() => {
                            this.debounceBoolean = false
                        })
                }
            })
        },
        getOptionsFavorite() {
            return new Promise((resolve, reject) => {
                axiosInstance.options('api/favorites/')
                    .then(res => {
                        this.optionsFavorite = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        getFavorite(id) {
            return new Promise((resolve, reject) => {
                axiosInstance.get('api/favorites/' + id + '/')
                    .then(res => {
                        this.favorite = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        getFavorites(payload) {
            return new Promise((resolve, reject) => {
                this.controllerGetFavorites = new AbortController()
                const config = {
                    signal: useFavoriteStore().controllerGetFavoritesGetters.signal
                }

                let url = new URL(axiosInstance.defaults.baseURL + 'api/favorites/')

                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axiosInstance.get(url.pathname + url.search, config)
                        .then(res => {
                            this.favorites = res.data
                            if (payload.queryParams.scroll) {
                                this.arrayFavoritesInfinity.push(...res.data.results)
                            } else {
                                this.arrayFavoritesInfinity = res.data.results
                            }
                            this.spinnerFavorite = false
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                } else {
                    axiosInstance.get(url.pathname, config)
                        .then(res => {
                            this.favorites = res.data
                            let a = []
                            a.push(...res.data.results);
                            this.arrayFavoritesInfinity = a
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                }

            })
        },
        getFavoritesWithoutSetState(payload) {
            return new Promise((resolve, reject) => {
                let url = new URL(axiosInstance.defaults.baseURL + 'api/favorites/')
                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axiosInstance.get(url.pathname + url.search)
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                } else {
                    axiosInstance.get(url.pathname)
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                }

            })
        },
        createFavorite(payload) {
            return new Promise((resolve, reject) => {
                axiosInstance.post('/api/favorites/', payload)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getAllFavorites(payload) {
            return new Promise((resolve, reject) => {
                let url = new URL(axiosInstance.defaults.baseURL + 'api/favorites/')

                let params = new URLSearchParams()
                for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                    params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                }
                url.search = params
                axiosInstance.get(url.pathname + url.search)
                    .then(res => {
                        this.allFavorites = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })

            })
        },
        registerFavorite(payload) {
            return new Promise((resolve, reject) => {
                axiosInstance.post('/api/favorites/', payload)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        editFavorite(payload) {
            return new Promise((resolve, reject) => {
                axiosInstance.patch('api/favorites/' + payload.id + '/', payload)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        deleteFavorite(id) {
            return new Promise((resolve, reject) => {
                axiosInstance.delete('/api/favorites/' + id + '/')
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        }
    },
    getters: {
        favoritesGetters: state => {
            return state.favorites
        },
        allFavoritesGetters: state => {
            return state.allFavorites
        },
        oldFavoriteGetters: state => {
            return state.oldFavorite
        },
        favoriteGetters: state => {
            return state.favorite
        },
        arrayFavoritesInfinityGetters: state => {
            return state.arrayFavoritesInfinity
        },
        spinnerFavoriteGetters: state => {
            return state.spinnerFavorite
        },
        optionsFavoriteGetters: state => {
            return state.optionsFavorite
        },
        dictonaryFavoritesGetters: state => {
            return state.dictonaryFavorites
        },
        currentKeyDeletingGetters: state => {
            return state.currentKeyDeleting
        },
        isDeletedGetters: state => {
            return state.isDeleted
        },
        infiniteHorizontalArrayGetters: state => {
            return state.infiniteHorizontalArray
        },
        debounceBooleanGetters: state => {
            return state.debounceBoolean
        },
        controllerGetFavoritesGetters: state => {
            return state.controllerGetFavorites
        }
    }
})