<template>
  <div id="container-archive" class="container-archive">
    <div v-if="showTabs" id="container-tabs" class="container-tabs">
      <div @click="showCategories = true,showFavorites = false" class="container-content-tabs">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="4.4953" y="14.5002" width="5.00313" height="5.00313" rx="1.5"
                :stroke="showCategories && !showFavorites ? '#CF3092' : '#9CA3AF'" stroke-width="1.5"/>
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M9.49841 5.99399C9.49841 5.16556 10.17 4.49399 10.9984 4.49399H13.0015C13.83 4.49399 14.5015 5.16556 14.5015 5.99399V7.99711C14.5015 8.82554 13.83 9.49711 13.0015 9.49711H10.9984C10.17 9.49711 9.49841 8.82554 9.49841 7.99711V5.99399Z"
                :stroke="showCategories && !showFavorites ? '#CF3092' : '#9CA3AF'" stroke-width="1.5"/>
          <rect x="14.5016" y="14.5002" width="5.00313" height="5.00313" rx="1.5"
                :stroke="showCategories && !showFavorites ? '#CF3092' : '#9CA3AF'" stroke-width="1.5"/>
          <path
              d="M6.99686 14.5002V13.4996C6.99686 12.947 7.44485 12.499 7.99748 12.499H16.0025C16.5551 12.499 17.0031 12.947 17.0031 13.4996V14.5002"
              :stroke="showCategories && !showFavorites ? '#CF3092' : '#9CA3AF'" stroke-width="1.5"/>
          <path d="M12 9.4971V12.499" :stroke="showCategories && !showFavorites ? '#CF3092' : '#9CA3AF'"
                stroke-width="1.5"/>
        </svg>

        <span :class="{'text-14px-secondary-medium': showCategories && !showFavorites}" class="text-14px-grey-regular">Categorie</span>
      </div>
      <div @click="showedFavorites" class="container-content-tabs">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M14.0017 5.69668C13.4491 5.69668 13.0011 6.14468 13.0011 6.69731V17.8042C13.001 17.986 12.9024 18.1534 12.7434 18.2416C12.5844 18.3297 12.3902 18.3247 12.2359 18.2285L8.99859 16.2032L5.76124 18.2265C5.60733 18.3225 5.41349 18.3277 5.25466 18.2401C5.09583 18.1524 4.99689 17.9857 4.99609 17.8042V6.69731C4.99609 5.03942 6.34008 3.69543 7.99797 3.69543H14.0017C15.6596 3.69543 17.0036 5.03942 17.0036 6.69731V9.19887C17.0036 9.47519 16.7796 9.69918 16.5033 9.69918H13.0011"
              :stroke="!showCategories && showFavorites ? '#CF3092' : '#9CA3AF'" stroke-width="1.5"/>
        </svg>

        <span :class="{'text-14px-secondary-medium': !showCategories && showFavorites}" class="text-14px-grey-regular">Preferiti</span>
      </div>
      <div v-show="storeUtils.checkIfThereAreActiveFilters('categories') && showCategories"
           class="container-filter container-filter-no-background">
        <img v-show="!storeUtils.checkIfThereAreActiveFilters('categories')"
             src="../../assets/shared/settings-filter.svg" alt="">
        <img v-show="storeUtils.checkIfThereAreActiveFilters('categories')"
             src="../../assets/shared/settings-filter-active.svg" alt="">
        <span :class="{'text-active-filters': storeUtils.checkIfThereAreActiveFilters('categories')}"
              class="text-12px-dark-grey-medium">Filtri</span>
      </div>
      <div v-show="storeUtils.checkIfThereAreActiveFilters('favorites') && showFavorites"
           class="container-filter container-filter-no-background">
        <img v-show="!storeUtils.checkIfThereAreActiveFilters('favorites')"
             src="../../assets/shared/settings-filter.svg" alt="">
        <img v-show="storeUtils.checkIfThereAreActiveFilters('favorites')"
             src="../../assets/shared/settings-filter-active.svg" alt="">
        <span :class="{'text-active-filters': storeUtils.checkIfThereAreActiveFilters('favorites')}"
              class="text-12px-dark-grey-medium">Filtri</span>
      </div>
    </div>
    <div v-if="!showTabs" class="container-head">
      <div class="container-head-left">
        <b-form-input id="search_archive" type="search" v-on:input="debounce(setSearchFinal)" v-model="search"
                      placeholder="Cerca"
                      class="form-control search-bar"></b-form-input>
        <!--        <div class="container-results">-->
        <!--          <div v-for="i in 4" :key="i" class="container-single-result">-->
        <!--            <span class="text-12px-dark-grey-regular">Risultato 1</span>-->
        <!--            <img src="../../assets/shared/close-black.svg" alt="">-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
      <div v-if="storeAuth.userGetters && storeAuth.userGetters.user_type === 'ad'" class="container-head-right">
        <!--        <span class="text-14px-dark-grey-regular">Gestisci Archivio</span>-->
        <button
            @click="showNewCategory = true, storeUtils.genericOption = {}" class="btn btn-primary-custom">Crea una
          categoria
        </button>
      </div>
    </div>
    <div class="container-list-categories" id="container_list_categories" v-show="finalSearch === ''">
      <div id="container-single-section"
           v-show="(!showTabs) || (showCategories)"
           class="container-single-section">

        <div v-if="!showTabs" class="container-head-section">
          <img class="img-section" src="../../assets/shared/hierarchy-secondary.svg" alt="">
          <span class="text-24px-black-medium">Categorie</span>
          <div @click="showFiltersCategories = true" class="container-filter">
            <span :class="{'text-active-filters': storeUtils.checkIfThereAreActiveFilters('categories')}"
                  class="text-12px-dark-grey-medium">Filtri</span>
            <img v-show="!storeUtils.checkIfThereAreActiveFilters('categories')"
                 src="../../assets/shared/settings-filter.svg" alt="">
            <img v-show="storeUtils.checkIfThereAreActiveFilters('categories')"
                 src="../../assets/shared/settings-filter-active.svg" alt="">
          </div>
        </div>
        <ComponentListCategories @resetModalEmit="closeModal" v-if="finalSearch === '' && finalSearch !== null"
                                 :key="refreshCategories"
                                 @closeModalFilters="closeModalFilters" :showNewCategoryProp="showNewCategory"
                                 :showTabs="showTabs"></ComponentListCategories>
      </div>
      <div
          v-show="(!showTabs) || (showFavorites)"
          class="container-single-section container-single-section-no-border ">
        <div v-if="!showTabs" class="container-head-section">
          <img class="img-section" src="../../assets/shared/bookmarks.svg" alt="">
          <span class="text-24px-black-medium">Preferiti</span>
          <div @click="showFiltersFavorites = true" class="container-filter">
            <span :class="{'text-active-filters': storeUtils.checkIfThereAreActiveFilters('favorites')}"
                  class="text-12px-dark-grey-medium">Filtri</span>
            <img v-show="!storeUtils.checkIfThereAreActiveFilters('favorites')"
                 src="../../assets/shared/settings-filter.svg" alt="">
            <img v-show="storeUtils.checkIfThereAreActiveFilters('favorites')"
                 src="../../assets/shared/settings-filter-active.svg" alt="">
          </div>
        </div>
        <div class="container-list-generic-mini-detail">
          <div style="display: flex;flex-direction: column" class="container-single-generic-mini-detail" :key="i"
               v-for="(key,i) in Object.keys(storeFavorite.dictonaryFavoritesGetters)">
            <div @click="setKey(storeFavorite.dictonaryFavoritesGetters[key].key)"
                 class="container-head-single-generic-mini-detail">
              <div style="display: flex;align-items: center">
                <span class="text-16px-black-medium">{{
                    storeFavorite.dictonaryFavoritesGetters[key].name
                  }}&nbsp;</span>
                <span v-if="storeFavorite.dictonaryFavoritesGetters[key].count"
                      class="text-16px-black-medium">({{ storeFavorite.dictonaryFavoritesGetters[key].count }})</span>
              </div>
              <img
                  :class="{'img_arrow_up_rotate': !arrayKeyShowed.includes(storeFavorite.dictonaryFavoritesGetters[key].key)}"
                  class="img-arrow-up" src="../../assets/shared/arrow-drop-up.svg" alt="">
            </div>
            <ComponentGenericMiniDetail :ref="key" :showTabs="showTabs" :key="refreshFavorites"
                                        :currentObjectProp="storeFavorite.dictonaryFavoritesGetters"
                                        v-show="arrayKeyShowed.includes(storeFavorite.dictonaryFavoritesGetters[key].key)"
                                        v-if="finalSearch === '' && finalSearch !== null"
                                        :keyFavorite="key"></ComponentGenericMiniDetail>
          </div>
        </div>
      </div>
    </div>

    <b-modal :hide-footer="true" :hide-header="true" @hide="closeModalFilters" v-model="showFiltersCategories">
      <ComponentFilters @resetDictFilter="resetDictFilter" @closeWithoutUpdate="showFiltersCategories = false"
                        @closeModalFilters="closeModalFilters"
                        :myFilter="'categories'"></ComponentFilters>
    </b-modal>

    <b-modal :hide-footer="true" :hide-header="true" v-model="showFiltersFavorites">
      <ComponentFilters @resetDictFilterFavorites="resetDictFilterFavorites"
                        @closeWithoutUpdate="showFiltersFavorites = false"
                        @closeModalFiltersFavorites="closeModalFiltersFavorites"
                        :myFilter="'favorites'"></ComponentFilters>
    </b-modal>

    <ComponentFloatingActionButton @setBooleanModal="setBooleanModal" v-if="innerWidth <= 991.98 && showCategories"
                                   :actions="actions"></ComponentFloatingActionButton>
    <ComponentFloatingActionButton @setBooleanModal="setBooleanModal" v-if="innerWidth <= 991.98 && showFavorites"
                                   :actions="[
        {
          action: 'filter',
          entity: 'favorite'
        }
    ]"></ComponentFloatingActionButton>
  </div>
</template>

<script>
import {useUtilsStore} from "@/stores/utils";
import ComponentListCategories from "@/components/category/ComponentListCategories";
import ComponentGenericMiniDetail from "@/components/shared/ComponentGenericListMiniDetails.vue";
import {useAuthStore} from "@/stores/auth";
import {useCategoryStore} from "@/stores/categories";
import ComponentDynamicForm from "@/components/form/ComponentDynamicForm";
import {TYPE_OF_OPTION} from "@/globals";
import {useFavoriteStore} from "@/stores/favorites";
import ComponentFilters from "@/components/filters/ComponentFilters";
import ComponentFloatingActionButton from "@/components/floatingActionButton/ComponentFloatingActionButton";
import {pages} from "../../../vue.config";
import {useApiSettingStore} from "@/stores/apiSettings";

export default {
  name: "ComponentArchive",
  components: {
    ComponentFloatingActionButton,
    ComponentFilters,
    // eslint-disable-next-line vue/no-unused-components
    ComponentDynamicForm,
    // eslint-disable-next-line vue/no-unused-components
    ComponentGenericMiniDetail,
    ComponentListCategories
  },

  setup() {
    const storeApi = useApiSettingStore()
    const storeAuth = useAuthStore()
    const storeFavorite = useFavoriteStore()
    const storeUtils = useUtilsStore()
    const storeCategory = useCategoryStore()
    return {
      storeApi,
      storeAuth,
      TYPE_OF_OPTION,
      storeFavorite,
      storeUtils,
      storeCategory
    }
  },
  data() {
    return {
      queryParamsCategories: {},
      refreshCategories: false,
      refreshFavorites: false,
      actions: [],
      innerWidth: window.innerWidth,
      finalSearch: '',
      search: '',
      debounce: this.createInputTextDebounce(),
      arrayKeyShowed: [],
      showTabs: true,
      showFiltersCategories: false,
      showFiltersFavorites: false,
      showCategories: true,
      showFavorites: false,
      showNewCategory: false
    }
  },
  created() {
    useUtilsStore().currentNamePage = ''
    useUtilsStore().chronology = []
    useUtilsStore().currentNamePage = 'Archivio'
    useUtilsStore().chronology.push({
      route_name: 'archive',
      route_id: null,
      name: 'Archivio',
      display_name: 'Archivio'
    })
    useUtilsStore().dictionaryFilterCategoriesInArchiveFinal = {}
    for (let key in this.storeFavorite.dictonaryFavoritesGetters) {
      this.storeFavorite.dictonaryFavoritesGetters[key].controller = new AbortController()
      this.arrayKeyShowed.push(this.storeFavorite.dictonaryFavoritesGetters[key].key)
    }

    this.actions.push({
      action: 'filter',
      entity: 'category'
    })
    if (this.storeAuth.userGetters && this.storeAuth.userGetters.user_type === 'ad')
      this.actions.push({
        action: 'add',
        entity: 'category'
      })
  },
  computed: {
    infoForToDelete() {
      return useUtilsStore().infoForToDeleteGetters
    },
    globalSearch() {
      return useUtilsStore().globalSearchGetters
    }
  },
  watch: {
    finalSearch: {
      handler: function (value) {
        if (value === '' || value === null)
          for (let key in this.storeFavorite.dictonaryFavoritesGetters) {
            this.arrayKeyShowed.push(this.storeFavorite.dictonaryFavoritesGetters[key].key)
          }
        else
          for (let key in this.storeUtils.dictionarySearchGetters)
            this.arrayKeyShowed.push(this.storeUtils.dictionarySearchGetters[key].key)
      }
    }
  },
  mounted() {
    document.title = 'Archivio | ' + pages.index.title
    let search_archive = document.getElementById('search_archive')
    if (search_archive && !this.showFiltersFavorites && !this.showFiltersCategories)
      search_archive.focus()

    if (window.innerWidth < 991.98) {
      this.setVariables()
    } else {
      this.resetVariables()
    }
    this.setMaxHeight()
    window.addEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.setMaxHeight()
      this.innerWidth = window.innerWidth
      if (window.innerWidth < 991.98) {
        this.showTabs = true
      } else {
        let global_search = document.getElementById('global-search')
        if (global_search) {
          global_search.focus()
          global_search.value = useUtilsStore().globalSearchGetters
        }
        this.resetVariables()
      }
    },
    setMaxHeight() {
      let container_tabs = document.getElementById('container-tabs')
      let container_content_header
      if (document.getElementById('container-content-header') && document.getElementById('container-content-header-mobile') && window.getComputedStyle(document.getElementById('container-content-header')).display === 'none')
        container_content_header = document.getElementById('container-content-header-mobile')
      else
        container_content_header = document.getElementById('container-content-header')

      let container_list_categories = document.getElementById('container_list_categories')
      if (container_list_categories && container_tabs)
        if (window.innerWidth < 991.98)
          container_list_categories.style.maxHeight = 'calc(100vh - ' + (container_content_header.getBoundingClientRect().height + container_tabs.getBoundingClientRect().height) + 'px)'
        else
          container_list_categories.style.maxHeight = 'unset'
    },
    setBooleanModal(event) {
      if (event.action === 'add' && event.entity === 'category')
        this.showNewCategory = true
      if (event.action === 'filter' && event.entity === 'category')
        this.showFiltersCategories = true
      if (event.action === 'filter' && event.entity === 'favorite')
        this.showFiltersFavorites = true
    },
    showedFavorites() {
      this.showFavorites = true
      this.showCategories = false
    },
    setSearchFinal() {
      this.finalSearch = this.search
      useUtilsStore().globalSearch = this.search
    },
    createInputTextDebounce() {
      let timeout = null;
      return function (fnc, delayMs) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          fnc();
        }, delayMs || 500);
      };
    },
    resetDictFilter() {
      useUtilsStore().dictionaryFilterCategoriesInArchiveFinal = {}
      this.refreshCategories = !this.refreshCategories
      this.showFiltersCategories = false
    },
    resetDictFilterFavorites() {
      this.showFiltersFavorites = false
      this.refreshFavorites = !this.refreshFavorites
    },
    closeModalFilters() {
      this.refreshCategories = !this.refreshCategories
      this.showFiltersCategories = false
    },
    closeModalFiltersFavorites() {
      this.showFiltersFavorites = false
      this.refreshFavorites = !this.refreshFavorites
    },
    closeModal() {
      this.showNewCategory = false
    },
    resetVariables() {
      this.showFavorites = false
      this.showCategories = false
      this.showTabs = false
    },
    setVariables() {
      this.showFavorites = false
      this.showCategories = true
      this.showTabs = true
    },
    setKey(key) {
      if (this.arrayKeyShowed.includes(key)) {
        let indexOf = this.arrayKeyShowed.indexOf(key)
        this.arrayKeyShowed.splice(indexOf, 1)
      } else {
        this.arrayKeyShowed.push(key)
      }
    }
  },
  updated() {
    let search_archive = document.getElementById('search_archive')
    if (search_archive && !this.showFiltersFavorites && !this.showFiltersCategories)
      search_archive.focus()
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize)
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style scoped>
.container-single-generic-mini-detail {
  margin: 5px 0px;
}

.container-single-section-no-border.container-single-section {
  border-bottom: unset;
  overflow: unset;
}


.img-arrow-up {
  cursor: pointer;
}

.container-head-single-generic-mini-detail {
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-right: auto;
}

.container-list-favorites {
  display: flex;
  margin: 10px 0px;
  flex-direction: column;
}

.container-list-generic-mini-detail {
  display: flex;
  flex-direction: column;
}

.container-filter {
  margin-left: auto;
}

.container-filter-no-background.container-filter {
  background: transparent !important;
  cursor: auto;
  margin: 0px auto;
  padding: 0px !important;
}

.img-section {
  margin-right: 10px;
}

.container-head-section {
  width: 100%;
  display: flex;
  align-items: center;
}


.container-single-section::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.container-single-section::-webkit-scrollbar-thumb {
  background: var(--dark-grey-color);
  border-radius: 10px;
}

.container-single-section::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.container-list-categories {
  display: flex;
  flex-direction: column;
}

.container-single-section {
  display: flex;
  margin-bottom: 25px;
  overflow-x: auto;
  flex-direction: column;
}

.container-head-right button {
  margin: 0px 0px 0px 0px;
}

.container-head-right {
  margin-left: auto;
  display: flex;
  flex-direction: column;
}

.container-single-result img {
  margin-left: 10px;
  width: 15px;
  cursor: pointer;
}

#container-tabs {
  padding: 10px 0px;
}

.container-single-result {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  margin-bottom: 5px;
  margin-right: 10px;
  background: var(--light-grey-color);
  border-radius: 2px;
}

.container-results {
  margin: 10px 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.container-head-left {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 70%;
}

.container-head {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.container-archive {
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
}

#container-single-section::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

#container-single-section::-webkit-scrollbar-thumb {
  background: var(--dark-grey-color);
  border-radius: 10px;
}

#container-single-section::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

@media (max-width: 575.98px) {
}

/*// Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
  .container-filter {
    height: auto;
    margin-top: 5px;
  }

  .container-all-filter {
    margin-top: 5px;
    flex-wrap: wrap;
  }

  .table-responsive {

  }
}

/*// Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {
  .container-archive {
    padding: 0px;
  }

  .container-single-section {
    margin-bottom: 0px;
    padding: 10px 15px;
  }
}

/*// Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {
}

/*// X-Large devices (large desktops, less than 1400px)*/
@media (max-width: 1399.98px) {
}
</style>