<template>
  <Form v-slot="{errors}" @submit="sendEmail" class="form-report">
    <Field v-model="report" v-slot="{field}" name="text">
      <textarea :disabled="showText" v-bind="field" class="form-control" placeholder="Scrivi le tue considerazioni"
      ></textarea>
      <div class="text-danger-custom text-danger-custom-report">{{ errors.text }}</div>
    </Field>
    <span v-if="showText" class="text-success text-success-report">Segnalazione inviata con successo</span>
    <div class="container-footer-form">
      <button :disabled="isLoading || showText" type="button" @click="$emit('closeModal')" style="margin-left: auto"
              class="btn btn-light-grey-custom">Annulla
      </button>
      <button :disabled="isLoading || showText" type="submit" style="margin-left: 20px;margin-right: 20px"
              class="btn btn-primary-custom">
        <span v-if="!isLoading">Invia</span>
        <b-spinner class="spinner-border-report" v-else></b-spinner>
      </button>
    </div>
  </Form>
</template>

<script>
import {useAnonymousReport} from "@/stores/anonymousReport";
import {Form, Field} from "vee-validate";

export default {
  name: "ComponentAnonymousReport",
  emits: ['closeModal'],
  components: {
    Field,
    // eslint-disable-next-line vue/no-reserved-component-names
    Form
  },
  setup() {
    return {}
  },
  data() {
    return {
      showText: false,
      isLoading: false,
      report: ''
    }
  },
  created() {
  },
  watch: {},
  computed: {},
  mounted() {
  },
  methods: {
    sendEmail(values, actions) {
      this.isLoading = true
      useAnonymousReport().createAnonymousReport({text: this.report})
          .then(() => {
                this.isLoading = false
                this.showText = true
                setTimeout(() => {
                  this.$emit('closeModal')
                  this.showText = false
                }, 3000)
              },
              reason => {
                this.isLoading = false
                actions.setErrors(reason.response.data)
              })
          .catch(() => {
            this.isLoading = false
          })
    }
  },
  updated() {
  },
  beforeUnmount() {
  },
  unmounted() {
  }
}
</script>


<style>
.spinner-border-report.spinner-border {
  padding: 0px !important;
}

.text-danger-custom-report.text-danger-custom, .text-success-report.text-success {
  padding: 10px 20px;
}

textarea {
  min-height: 150px !important;
  margin: 10px 20px !important;
  width: calc(100% - 40px) !important;
}

.container-footer-form {
  margin-top: 20px !important;
}

</style>