<template>
  <div class="container-filter-custom">
    <div class="container-head-form">
      <div class="container-content-head-form">
        <span
            class="text-16px-black-bold">Filtri</span>
      </div>
      <img @click="$emit('closeWithoutUpdate')" class="img-close"
           src="../../assets/shared/close.svg" alt="">
    </div>
    <div v-if="storeUtils.genericFiltersetFieldsGetters[myFilter]" id="container-filters" class="container-filters">
      <div class="container-single-filter-custom"
           v-for="(filter, index) in Object.keys(storeUtils.genericFiltersetFieldsGetters[myFilter])"
           :key="index+'filter'+filter.name">
        <div class="form-group" v-if="storeUtils.genericFiltersetFieldsGetters[myFilter][filter].choices === null">
          <span class="label-filter">{{ storeUtils.genericFiltersetFieldsGetters[myFilter][filter].label }}</span>
          <input
              :name="storeUtils.genericFiltersetFieldsGetters[myFilter][filter].label"
              :max="storeUtils.genericFiltersetFieldsGetters[myFilter][filter].type === 'date' ? now : nowTimeLocal"
              :class="{'form-control': storeUtils.genericFiltersetFieldsGetters[myFilter][filter].type !== 'checkbox'}"
              :type="storeUtils.genericFiltersetFieldsGetters[myFilter][filter].type"
              v-model="storeUtils.genericFiltersetFieldsGetters[myFilter][filter].value"
              :placeholder="storeUtils.genericFiltersetFieldsGetters[myFilter][filter].label">
        </div>
        <div v-else-if="storeUtils.genericFiltersetFieldsGetters[myFilter][filter].choices"
             class="container-dropdown-filter">
          <span class="label-filter">{{ storeUtils.genericFiltersetFieldsGetters[myFilter][filter].label }}</span>
          <b-dropdown
              :text="storeUtils.genericFiltersetFieldsGetters[myFilter][filter].title">
            <div v-if="'search' in storeUtils.genericFiltersetFieldsGetters[myFilter][filter]"
                 class="container-search-bar">
              <b-form-input @input="handleInput($event,filter)"
                            v-model="storeUtils.genericFiltersetFieldsGetters[myFilter][filter].search"
                            class="form-control search-bar" placeholder="Cerca" @click="$event.stopPropagation()"
                            type="search"></b-form-input>
            </div>
            <div @click="resetSelections($event,filter)" class="container-reset-selections text-14px-error-medium">
              Resetta selezione
            </div>
            <div @scroll="onScrollDropdown($event,filter)" :id="'box_structure_' + index" class="container-list-types">
              <b-form-radio
                  v-if="!storeUtils.genericFiltersetFieldsGetters[myFilter][filter].is_multiple_choice && storeUtils.genericFiltersetFieldsGetters[myFilter][filter].type !== 'checkbox' && storeUtils.genericFiltersetFieldsGetters[myFilter][filter].choices !== null"
                  @change="storeUtils.genericFiltersetFieldsGetters[myFilter][filter].title = 'Scegli una opzione'"
                  v-model="storeUtils.genericFiltersetFieldsGetters[myFilter][filter].value" :value="null">
                nessuna scelta
              </b-form-radio>
              <div class="container-single-type"
                   v-for="(item,index) in storeUtils.genericFiltersetFieldsGetters[myFilter][filter].choices"
                   :key="index">
                <div class="form-check"
                     @click="toggleSelection($event,item.value,storeUtils.genericFiltersetFieldsGetters[myFilter][filter].value)"
                     v-if="storeUtils.genericFiltersetFieldsGetters[myFilter][filter].is_multiple_choice || storeUtils.genericFiltersetFieldsGetters[myFilter][filter].type === 'checkbox'">
                  <input :name="storeUtils.genericFiltersetFieldsGetters[myFilter][filter].label"
                         class="form-check-input"
                         type="checkbox"
                         v-model="storeUtils.genericFiltersetFieldsGetters[myFilter][filter].value" :value="item.value">
                  <span class="form-check-label">{{ item.display_name }}</span>
                </div>
                <b-form-radio v-else
                              @change="storeUtils.genericFiltersetFieldsGetters[myFilter][filter].title = item.display_name"
                              v-model="storeUtils.genericFiltersetFieldsGetters[myFilter][filter].value"
                              :value="item.value">
                  {{ item.display_name }}
                </b-form-radio>
              </div>
              <b-spinner v-if="filter in mappingKeys && mappingKeys[filter].isLoading"></b-spinner>
              <div
                  v-show="(filter in mappingKeys && !mappingKeys[filter].isLoading) && storeUtils.genericFiltersetFieldsGetters[myFilter][filter].choices && storeUtils.genericFiltersetFieldsGetters[myFilter][filter].choices.length === 0"
                  class="container-single-type">Nessun risultato
              </div>
            </div>
          </b-dropdown>
        </div>
      </div>
    </div>
    <div class="container-footer-form">
      <button type="button" @click="resetFilters" class="btn btn-danger-custom">Resetta e chiudi</button>
      <button type="button" @click="updateFilters" class="btn btn-primary-custom">Conferma filtri</button>
    </div>
  </div>
</template>

<script>
import {useUserStore} from "@/stores/users";
import {useUtilsStore} from "@/stores/utils";
import moment from "moment";
import {useChronologyStore} from "@/stores/chronology";
import {useCategoryStore} from "@/stores/categories";
import {useSubcategoryStore} from "@/stores/subcategories";
import {useProjectStore} from "@/stores/projects";
import {useWikiStore} from "@/stores/wiki";
import {useFavoriteStore} from "@/stores/favorites";
import {useDocumentStore} from "@/stores/documents";
import {usePublicWallStore} from "@/stores/publicWall";
import {useSuggestionStore} from "@/stores/suggestions";

export default {
  name: "ComponentFilters",
  emits: ['updateListSuggestion', 'closeModalFilters', 'resetDictFilter', 'resetDictFilterFavorites', 'closeModalFiltersFavorites', 'resetCurrentPage', 'closeWithoutUpdate'],
  props: {
    myFilter: {
      type: String
    }
  },
  setup() {
    const storeUtils = useUtilsStore()
    return {
      storeUtils
    }
  },
  data() {
    return {
      controller: null,
      mappingKeys: {},
      isLoading: false,
      now: new Date().toISOString().split("T")[0],
      nowTimeLocal: moment().format('yyyy-MM-DDTHH:mm'),
      maxCol: 0,
      timeoutId: null,
      queryParams: {
        pagination: false
      }
    }
  },
  created() {
    this.controller = new AbortController()
    this.getGenericOptionFilters()
  },
  watch: {},
  async mounted() {
    let container_filters = document.getElementById('container-filters')
    if (container_filters) {
      container_filters.style.gridTemplateColumns = 'repeat(' + this.maxCol + ', 1fr)'
    }
  },
  methods: {
    resetSelections(event, key) {
      event.stopPropagation()
      this.storeUtils.genericFiltersetFieldsGetters[this.myFilter][key].value = []
    },
    onScrollDropdown(e, key) {
      const scrollable = e.target;
      const {scrollHeight, clientHeight, scrollTop} = scrollable;
      const isAtBottom = scrollHeight - parseInt(scrollTop) - clientHeight < 50;
      if (isAtBottom && key in this.mappingKeys && this.mappingKeys[key].lastResponse.next) {
        this.calculateLists(this.myFilter, key)
      }
    },
    handleInput(text, key) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId)
      }
      this.timeoutId = setTimeout(() => {
        this.queryParams['search'] = text
        this.mappingKeys[key].page = 1
        this.mappingKeys[key].lista = []
        useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key]['choices'] = []
        this.calculateLists(this.myFilter, key)
      }, 500)

    },
    async calculateLists(myFilter, key) {
      if (this.mappingKeys[key].isLoading) return // evita di caricare nuovi dati se un caricamento è già in corso
      this.mappingKeys[key].isLoading = true // indica che un caricamento è in corso
      if (myFilter === 'users') {

        let queryParams = {
          page: this.mappingKeys[key].page
        }
        if (this.storeUtils.genericFiltersetFieldsGetters[myFilter][key].search)
          queryParams['search'] = this.storeUtils.genericFiltersetFieldsGetters[myFilter][key].search
        else
          delete queryParams['search']
        await useUserStore().getUsersWithoutSetState({
          queryParams: queryParams
        })
            .then(res => {
              this.mappingKeys[key].lastResponse = res.data
              const newItems = []
              res.data.results.forEach(item => {
                let obj = {
                  value: item.id,
                  display_name: item.display_name
                }
                newItems.push(obj)
              })
              this.mappingKeys[key].lista = [...this.mappingKeys[key].lista, ...newItems]

              this.mappingKeys[key].page++
              if (myFilter)
                useUtilsStore().genericFiltersetFieldsGetters[myFilter][key]['choices'] = this.mappingKeys[key].lista
              this.mappingKeys[key].isLoading = false
            })
            .catch(() => {
              this.mappingKeys[key].isLoading = false
            })

      }
      if (myFilter === 'public-walls') {
        let queryParams = {
          page: this.mappingKeys[key].page
        }
        if (this.storeUtils.genericFiltersetFieldsGetters[myFilter][key].search)
          queryParams['search'] = this.storeUtils.genericFiltersetFieldsGetters[myFilter][key].search
        else
          delete queryParams['search']

        await usePublicWallStore().getPublicWallsWithoutSetState({
          queryParams: queryParams
        })
            .then(res => {
              this.mappingKeys[key].lastResponse = res.data
              const newItems = []
              res.data.results.forEach(item => {
                let obj = {
                  value: item.id,
                  display_name: 'display_name' in item ? item.display_name : item.name
                }
                newItems.push(obj)
              })
              this.mappingKeys[key].lista = [...this.mappingKeys[key].lista, ...newItems]

              this.mappingKeys[key].page++
              if (myFilter)
                useUtilsStore().genericFiltersetFieldsGetters[myFilter][key]['choices'] = this.mappingKeys[key].lista
              this.mappingKeys[key].isLoading = false
            })
            .catch(() => {
              this.mappingKeys[key].isLoading = false
            })

      }
      if (myFilter === 'suggestions') {
        let queryParams = {
          page: this.mappingKeys[key].page
        }
        if (this.storeUtils.genericFiltersetFieldsGetters[myFilter][key].search)
          queryParams['search'] = this.storeUtils.genericFiltersetFieldsGetters[myFilter][key].search
        else
          delete queryParams['search']

        await useSuggestionStore().getSuggestionsWithoutSetState({
          queryParams: queryParams
        })
            .then(res => {
              this.mappingKeys[key].lastResponse = res.data
              const newItems = []
              res.data.results.forEach(item => {
                let obj = {
                  value: item.id,
                  display_name: 'display_name' in item ? item.display_name : item.name
                }
                newItems.push(obj)
              })
              this.mappingKeys[key].lista = [...this.mappingKeys[key].lista, ...newItems]

              this.mappingKeys[key].page++
              if (myFilter)
                useUtilsStore().genericFiltersetFieldsGetters[myFilter][key]['choices'] = this.mappingKeys[key].lista
              this.mappingKeys[key].isLoading = false
            })
            .catch(() => {
              this.mappingKeys[key].isLoading = false
            })

      }
      if (myFilter === 'log-entries') {

        let queryParams = {
          page: this.mappingKeys[key].page
        }
        if (this.storeUtils.genericFiltersetFieldsGetters[myFilter][key].search)
          queryParams['search'] = this.storeUtils.genericFiltersetFieldsGetters[myFilter][key].search
        else
          delete queryParams['search']
        await useChronologyStore().getChronologysWithoutSetState({
          queryParams: queryParams
        })
            .then(res => {
              this.mappingKeys[key].lastResponse = res.data
              const newItems = []
              res.data.results.forEach(item => {
                let obj = {
                  value: item.id,
                  display_name: item.content_object.display_name
                }
                newItems.push(obj)
              })
              this.mappingKeys[key].lista = [...this.mappingKeys[key].lista, ...newItems]

              this.mappingKeys[key].page++
              if (myFilter)
                useUtilsStore().genericFiltersetFieldsGetters[myFilter][key]['choices'] = this.mappingKeys[key].lista
              this.mappingKeys[key].isLoading = false
            })
            .catch(() => {
              this.mappingKeys[key].isLoading = false
            })

      }
      if (myFilter === 'documents') {
        let queryParams = {
          page: this.mappingKeys[key].page
        }

        if (this.storeUtils.genericFiltersetFieldsGetters[myFilter][key].search)
          queryParams['search'] = this.storeUtils.genericFiltersetFieldsGetters[myFilter][key].search
        else
          delete queryParams['search']


        if (this.$route.params.projectId)
          queryParams['project__in'] = this.$route.params.projectId
        await useDocumentStore().getDocumentsWithoutSetState({
          queryParams: queryParams
        })
            .then(res => {
              this.mappingKeys[key].lastResponse = res.data
              const newItems = []
              res.data.results.forEach(item => {
                let obj = {
                  value: item.id,
                  display_name: item.display_name
                }
                newItems.push(obj)
              })
              this.mappingKeys[key].lista = [...this.mappingKeys[key].lista, ...newItems]

              this.mappingKeys[key].page++
              if (myFilter)
                useUtilsStore().genericFiltersetFieldsGetters[myFilter][key]['choices'] = this.mappingKeys[key].lista
              this.mappingKeys[key].isLoading = false
            })
            .catch(() => {
              this.mappingKeys[key].isLoading = false
            })
      }
      if (myFilter === 'categories') {

        let queryParams = {
          page: this.mappingKeys[key].page
        }
        if (this.storeUtils.genericFiltersetFieldsGetters[myFilter][key].search)
          queryParams['search'] = this.storeUtils.genericFiltersetFieldsGetters[myFilter][key].search
        else
          delete queryParams['search']

        await useCategoryStore().getCategoriesWithoutSetState({
          queryParams: queryParams
        })
            .then(res => {
              this.mappingKeys[key].lastResponse = res.data
              const newItems = []
              res.data.results.forEach(item => {
                let obj = {
                  value: item.id,
                  display_name: item.display_name
                }
                newItems.push(obj)
              })
              this.mappingKeys[key].lista = [...this.mappingKeys[key].lista, ...newItems]

              this.mappingKeys[key].page++
              if (myFilter)
                useUtilsStore().genericFiltersetFieldsGetters[myFilter][key]['choices'] = this.mappingKeys[key].lista
              this.mappingKeys[key].isLoading = false
            })
            .catch(() => {
              this.mappingKeys[key].isLoading = false
            })
      }
      if (myFilter === 'subcategories') {
        let queryParams = {
          page: this.mappingKeys[key].page
        }

        if (this.storeUtils.genericFiltersetFieldsGetters[myFilter][key].search)
          queryParams['search'] = this.storeUtils.genericFiltersetFieldsGetters[myFilter][key].search
        else
          delete queryParams['search']

        if (this.$route.params.categoryId)
          queryParams['category__in'] = this.$route.params.categoryId
        await useSubcategoryStore().getSubcategoriesWithoutSetState({
          queryParams: queryParams
        })
            .then(res => {
              this.mappingKeys[key].lastResponse = res.data
              const newItems = []
              res.data.results.forEach(item => {
                let obj = {
                  value: item.id,
                  display_name: item.display_name
                }
                newItems.push(obj)
              })
              this.mappingKeys[key].lista = [...this.mappingKeys[key].lista, ...newItems]

              this.mappingKeys[key].page++
              if (myFilter)
                useUtilsStore().genericFiltersetFieldsGetters[myFilter][key]['choices'] = this.mappingKeys[key].lista
              this.mappingKeys[key].isLoading = false
            })
            .catch(() => {
              this.mappingKeys[key].isLoading = false
            })
      }
      if (myFilter === 'projects') {
        let queryParams = {
          page: this.mappingKeys[key].page
        }

        if (this.storeUtils.genericFiltersetFieldsGetters[myFilter][key].search)
          queryParams['search'] = this.storeUtils.genericFiltersetFieldsGetters[myFilter][key].search
        else
          delete queryParams['search']

        if (this.$route.params.categoryId && !this.$route.params.subcategoryId)
          queryParams['category__in'] = this.$route.params.categoryId
        if (this.$route.params.subcategoryId && this.$route.params.categoryId)
          queryParams['subcategory__in'] = this.$route.params.subcategoryId
        await useProjectStore().getProjectsWithoutSetState({
          queryParams: queryParams
        })
            .then(res => {
              this.mappingKeys[key].lastResponse = res.data
              const newItems = []
              res.data.results.forEach(item => {
                let obj = {
                  value: item.id,
                  display_name: item.display_name
                }
                newItems.push(obj)
              })
              this.mappingKeys[key].lista = [...this.mappingKeys[key].lista, ...newItems]

              this.mappingKeys[key].page++
              if (myFilter)
                useUtilsStore().genericFiltersetFieldsGetters[myFilter][key]['choices'] = this.mappingKeys[key].lista
              this.mappingKeys[key].isLoading = false
            })
            .catch(() => {
              this.mappingKeys[key].isLoading = false
            })
      }
      if (myFilter === 'wikis') {

        let queryParams = {
          page: this.mappingKeys[key].page
        }
        if (this.storeUtils.genericFiltersetFieldsGetters[myFilter][key].search)
          queryParams['search'] = this.storeUtils.genericFiltersetFieldsGetters[myFilter][key].search
        else
          delete queryParams['search']

        await useWikiStore().getWikisWithoutSetState({
          queryParams: queryParams
        })
            .then(res => {
              this.mappingKeys[key].lastResponse = res.data
              const newItems = []
              res.data.results.forEach(item => {
                let obj = {
                  value: item.id,
                  display_name: item.display_name
                }
                newItems.push(obj)
              })
              this.mappingKeys[key].lista = [...this.mappingKeys[key].lista, ...newItems]

              this.mappingKeys[key].page++
              if (myFilter)
                useUtilsStore().genericFiltersetFieldsGetters[myFilter][key]['choices'] = this.mappingKeys[key].lista
              this.mappingKeys[key].isLoading = false
            })
            .catch(() => {
              this.mappingKeys[key].isLoading = false
            })
      }
      if (myFilter === 'favorites') {

        let queryParams = {
          page: this.mappingKeys[key].page
        }
        if (this.storeUtils.genericFiltersetFieldsGetters[myFilter][key].search)
          queryParams['search'] = this.storeUtils.genericFiltersetFieldsGetters[myFilter][key].search
        else
          delete queryParams['search']
        await useFavoriteStore().getFavoritesWithoutSetState({
          queryParams: queryParams
        })
            .then(res => {
              this.mappingKeys[key].lastResponse = res.data
              const newItems = []
              res.data.results.forEach(item => {
                let obj = {
                  value: item.id,
                  display_name: item.display_name
                }
                newItems.push(obj)
              })
              this.mappingKeys[key].lista = [...this.mappingKeys[key].lista, ...newItems]
              this.mappingKeys[key].page++
              if (myFilter)
                useUtilsStore().genericFiltersetFieldsGetters[myFilter][key]['choices'] = this.mappingKeys[key].lista
              this.mappingKeys[key].isLoading = false
            })
            .catch(() => {
              this.mappingKeys[key].isLoading = false
            })
      }
      this.mappingKeys[key].isLoading = false
    },
    toggleSelection(event, value, obj) {
      event.stopPropagation()
      const index = obj.indexOf(value);
      if (index === -1) {
        obj.push(value);
      } else {
        obj.splice(index, 1);
      }
    },
    async getGenericOptionFilters() {
      await useUtilsStore().getGenericOption({myFilter: this.myFilter, controller: this.controller})
          .then(() => {
            for (let key in useUtilsStore().genericFiltersetFieldsGetters[this.myFilter]) {
              switch (useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key].type) {
                case "BigAutoField":
                  if (key.includes('__in')) {
                    this.mappingKeys[key] = {
                      page: 1,
                      lista: [],
                      lastResponse: null,
                      isLoading: false,
                      search: ''
                    }
                    this.calculateLists(this.myFilter, key)
                    useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key].type = null;
                    useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key]['title'] = 'Scegli una o più opzioni';
                    useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key]['is_multiple_choice'] = true;
                    useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key]['value'] = [];
                    useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key]['choices'] = this.mappingKeys[key].lista;
                    useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key]['search'] = '';
                  } else {
                    useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key].type = "number";
                    useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key]['value'] = 0;
                  }
                  break;
                case "AutoField":
                  if (key.includes('__in')) {
                    this.mappingKeys[key] = {
                      page: 1,
                      lista: [],
                      lastResponse: null,
                      isLoading: false,
                      search: ''
                    }
                    this.calculateLists(this.myFilter, key)
                    useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key].type = null;
                    useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key]['title'] = 'Scegli una o più opzioni';
                    useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key]['is_multiple_choice'] = true;
                    useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key]['value'] = [];
                    useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key]['choices'] = this.mappingKeys[key].lista;
                    useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key]['search'] = '';
                  } else {
                    useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key].type = "number";
                    useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key]['value'] = 0;
                  }
                  break;
                case "DateTimeField":
                  if (key.includes('__gte')) {
                    useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key].label = useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key].label + ' da'
                  } else if (key.includes('__lte')) {
                    useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key].label = useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key].label + ' a'
                  }
                  useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key].type = "datetime-local";
                  useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key]['value'] = null;
                  break;
                case "DateField":
                  if (key.includes('__gte')) {
                    useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key].label = useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key].label + ' da'
                  } else if (key.includes('__lte')) {
                    useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key].label = useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key].label + ' a'
                  }
                  useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key].type = "date";
                  useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key]['value'] = null;
                  break;
                case "CharField":
                  // choice !== null && key non finisce in '__in' -> radio button
                  // choice !== null && key finisce in '__in' -> checkbox
                  // choice === null && key finisce in '__icontains' -> text + modificare label
                  // choice === null -> text

                  if (useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key].choices !== null && !(key.includes('__in'))) {
                    useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key].type = null;
                    useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key]['value'] = null;
                    useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key]['title'] = 'Scegli una opzione';
                    useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key]['is_multiple_choice'] = false;
                  } else if (useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key].choices !== null && key.includes('__in')) {
                    useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key].type = null;
                    useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key]['value'] = [];
                    useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key]['title'] = 'Scegli una o più opzioni';
                    useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key]['is_multiple_choice'] = true;
                  } else if (useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key].choices === null && key.includes('__icontains')) {
                    useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key].type = "text";
                    useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key]['value'] = '';
                    useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key].label = 'Ricerca per ' + useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key].label
                  } else if (useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key].choices !== null) {
                    useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key].type = "text";
                    useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key]['value'] = '';
                  }
                  break;
                case "BooleanField":
                  useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key].type = "checkbox";
                  useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key]['value'] = [];
                  useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key]['title'] = 'Scegli una o più opzioni';
                  break;
                case "ForeignKey":
                  delete useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key]
                  break;
              }
            }
          })
          .catch(() => {
          })
    },
    resetFilters() {
      this.getGenericOptionFilters()
      if (this.myFilter === 'favorites') {
        useUtilsStore().dictionaryFilterFinal = {}
        this.$emit('resetDictFilterFavorites')
      } else
        this.$emit('resetDictFilter')
    },
    async updateFilters() {
      for (let key in useUtilsStore().genericFiltersetFieldsGetters[this.myFilter]) {
        if (Array.isArray(useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key].value)) {
          if (useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key].value.length > 0) {
            if (this.myFilter === 'categories')
              useUtilsStore().dictionaryFilterCategoriesInArchiveFinal[key] = useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key].value
            else
              useUtilsStore().dictionaryFilterFinal[key] = useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key].value
          } else
            delete useUtilsStore().dictionaryFilterFinalGetters[key]
        } else if (useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key].value !== 0 && useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key].value !== '' && useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key].value !== undefined && useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key].value !== null) {

          if (this.myFilter === 'categories')
            useUtilsStore().dictionaryFilterCategoriesInArchiveFinal[key] = useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key].value
          else
            useUtilsStore().dictionaryFilterFinal[key] = useUtilsStore().genericFiltersetFieldsGetters[this.myFilter][key].value
        } else
          delete useUtilsStore().dictionaryFilterFinalGetters[key]
      }

      if (useUtilsStore().searchGetters !== '')
        useUtilsStore().dictionaryFilterFinalGetters['search'] = useUtilsStore().searchGetters

      switch (this.myFilter) {
        case 'subcategories':
          await useUtilsStore().getInfoFromFilterOrSearchFromGenericAction({
            queryParams: useUtilsStore().dictionaryFilterFinalGetters,
            action: useSubcategoryStore().getSubcategories
          })
          break;
        case 'wikis':
          await useUtilsStore().getInfoFromFilterOrSearchFromGenericAction({
            queryParams: useUtilsStore().dictionaryFilterFinalGetters,
            action: useWikiStore().getWikis
          })
          break;
        case 'users':
          await useUtilsStore().getInfoFromFilterOrSearchFromGenericAction({
            queryParams: useUtilsStore().dictionaryFilterFinalGetters,
            action: useUserStore().getUsers
          })
          break;
          // case 'favorites':
          //   await useUtilsStore().getInfoFromFilterOrSearchFromGenericAction({
          //     queryParams: useUtilsStore().dictionaryFilterFinalGetters,
          //     action: useFavoriteStore().getFavorites
          //   })
          //   break;
          // case 'categories':
          //   useCategoryStore().infiniteHorizontalArray = []
          //   await useUtilsStore().getInfoFromFilterOrSearchFromGenericAction({
          //     queryParams: useUtilsStore().dictionaryFilterFinalGetters,
          //     action: useCategoryStore().getCategories
          //   })
          //   break;
          // case 'documents':
          //   await useUtilsStore().getInfoFromFilterOrSearchFromGenericAction({
          //     queryParams: useUtilsStore().dictionaryFilterFinalGetters,
          //     action: useDocumentStore().getDocuments
          //   })
          //   break;
        case 'log-entries':
          await useUtilsStore().getInfoFromFilterOrSearchFromGenericAction({
            queryParams: useUtilsStore().dictionaryFilterFinalGetters,
            action: useChronologyStore().getChronologys
          })
          break;
        case 'public-walls':
          await useUtilsStore().getInfoFromFilterOrSearchFromGenericAction({
            queryParams: useUtilsStore().dictionaryFilterFinalGetters,
            action: usePublicWallStore().getPublicWalls
          })
          break;
        case 'suggestions':
          this.$emit('updateListSuggestion')
          break;
      }
      if (this.myFilter === 'favorites')
        this.$emit('closeModalFiltersFavorites')
      else
        this.$emit('closeModalFilters')

    }
  },
  updated() {
    let container_filters = document.getElementById('container-filters')
    if (container_filters) {
      container_filters.style.gridTemplateColumns = 'repeat(' + this.maxCol + ', 1fr)'
    }
  },
  beforeUnmount() {
    if (this.controller)
      this.controller.abort()
  },
  unmounted() {
  }
}
</script>

<style scoped>
.container-filter-custom {
  display: flex;
  flex-direction: column;
}

.container-reset-selections {
  padding: 5px 10px;
  cursor: pointer;
  width: 100%;
  display: flex;
  border-bottom: 1px solid var(--light-grey-color);
}

.container-search-bar {
  border-bottom: 1px solid var(--light-grey-color);
}

.search-bar.form-control {
  width: calc(100% - 20px);
  margin: 10px auto 10px auto;
}

input[type="checkbox"] {
  cursor: pointer;
}

.form-group {
  align-items: flex-start;
}

.date-input-custom {
  margin-right: 10px;
}

.container-dropdown-filter {
  display: flex;
  flex-direction: column;
}

.container-single-filter-custom {
  margin: 0px 10px 10px 10px;
}

.btn-primary-custom {
  margin-left: auto;
}

.container-footer-form {
  margin-top: 0px;
  padding: 10px 15px !important;
}

.container-filters {
  padding: 10px 5px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}


/*// X-Small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
}

/*// Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
  .container-filters {
    display: flex !important;
    flex-direction: column !important;
  }
}

/*// Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {
}

/*// Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {
}

/*// X-Large devices (large desktops, less than 1400px)*/
@media (max-width: 1399.98px) {
}


</style>