import {defineStore} from 'pinia'
import axiosInstance from "@/services/api";
import {useAuthStore} from "@/stores/auth";
import {useApiSettingStore} from "@/stores/apiSettings";
import {useCategoryStore} from "@/stores/categories";

export const useProjectStore = defineStore(
    "project",
    {
        state: () => ({
            /*** controller ***/
            controllerGetProjects: null,
            controllerGetProject: null,
            /*** ***/

            fromEntity: {
                name: '',
                id: null
            },
            projects: null,
            arrayProjectsInfinity: [],
            optionsProject: null,
            optionsProjectDetail: null,
            project: null,
            allProjects: null,
            oldProject: null,
            spinnerProject: false
        }),
        actions: {
            getOptionsProject() {
                return new Promise((resolve, reject) => {
                    axiosInstance.options('api/projects/')
                        .then(res => {
                            this.optionsProject = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                })
            },
            getProject(id) {
                return new Promise((resolve, reject) => {
                    this.controllerGetProject = new AbortController()
                    const config = {
                        signal: useProjectStore().controllerGetProjectGetters.signal
                    }
                    axiosInstance.get('api/projects/' + id + '/', config)
                        .then(res => {
                            this.project = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                })
            },
            getProjects(payload) {
                return new Promise((resolve, reject) => {
                    this.controllerGetProjects = new AbortController()
                    const config = {
                        signal: useProjectStore().controllerGetProjectsGetters.signal
                    }

                    let url = new URL(axiosInstance.defaults.baseURL + 'api/projects/')

                    this.spinnerProject = true
                    if (payload.queryParams !== null) {
                        let params = new URLSearchParams()
                        for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                            params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                        }
                        if (params.get('subcategory__in') !== null)
                            params.set('subcategory__in', params.get('subcategory__in'))
                        if (params.get('category__in') !== null)
                            params.set('category__in', params.get('category__in'))
                        url.search = params
                        axiosInstance.get(url.pathname + url.search, config)
                            .then(res => {
                                this.projects = res.data
                                if (payload.queryParams.scroll) {
                                    this.arrayProjectsInfinity.push(...res.data.results)
                                } else {
                                    this.arrayProjectsInfinity = res.data.results
                                }
                                this.spinnerProject = false
                                resolve(res)
                            })
                            .catch(err => {
                                this.spinnerProject = false
                                if (err.response && err.response.status === 500) {
                                    useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                                }
                                reject(err)
                            })
                    } else {
                        axiosInstance.get(url.pathname, config)
                            .then(res => {
                                this.spinnerProject = false
                                this.projects = res.data
                                let a = []
                                a.push(...res.data.results);
                                this.arrayProjectsInfinity = a
                                resolve(res)
                            })
                            .catch(err => {
                                this.spinnerProject = false
                                if (err.response && err.response.status === 500) {
                                    useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                                }
                                reject(err)
                            })
                    }

                })
            },
            getProjectsWithoutSetState(payload) {
                return new Promise((resolve, reject) => {
                    let url = new URL(axiosInstance.defaults.baseURL + 'api/projects/')

                    this.spinnerProject = true
                    if (payload.queryParams !== null) {
                        let params = new URLSearchParams()
                        for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                            params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                        }
                        if (params.get('subcategory__in') !== null)
                            params.set('subcategory__in', params.get('subcategory__in'))
                        if (params.get('category__in') !== null)
                            params.set('category__in', params.get('category__in'))
                        url.search = params
                        axiosInstance.get(url.pathname + url.search)
                            .then(res => {
                                resolve(res)
                            })
                            .catch(err => {
                                this.spinnerProject = false
                                if (err.response && err.response.status === 500) {
                                    useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                                }
                                reject(err)
                            })
                    } else {
                        axiosInstance.get(url.pathname)
                            .then(res => {
                                resolve(res)
                            })
                            .catch(err => {
                                this.spinnerProject = false
                                if (err.response && err.response.status === 500) {
                                    useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                                }
                                reject(err)
                            })
                    }

                })
            },
            createProject(payload) {
                return new Promise((resolve, reject) => {
                    if (useProjectStore().fromEntityGetters.name === 'category') {
                        payload['category'] = useProjectStore().fromEntityGetters.id
                    } else if (useProjectStore().fromEntityGetters.name === 'subcategory') {
                        payload['subcategory'] = useProjectStore().fromEntityGetters.id
                    }
                    payload['owner'] = useAuthStore().userGetters.user_id
                    payload['color'] = useCategoryStore().categoryGetters.color

                    axiosInstance.post('/api/projects/', payload)
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            reject(err)
                        })
                })
            },
            getAllProjects(payload) {
                return new Promise((resolve, reject) => {
                    let url = new URL(axiosInstance.defaults.baseURL + 'api/projects/')

                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axiosInstance.get(url.pathname + url.search)
                        .then(res => {
                            this.allProjects = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })

                })
            },
            editProject(payload) {
                return new Promise((resolve, reject) => {
                    payload['owner'] = useProjectStore().projectGetters.owner.id
                    axiosInstance.patch('api/projects/' + payload.id + '/', payload)
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                })
            },
            deleteProject(id) {
                return new Promise((resolve, reject) => {
                    axiosInstance.delete('/api/projects/' + id + '/')
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                })
            }
        },
        getters: {
            fromEntityGetters: state => {
                return state.fromEntity
            },
            projectsGetters: state => {
                return state.projects
            },
            allProjectsGetters: state => {
                return state.allProjects
            },
            oldProjectGetters: state => {
                return state.oldProject
            },
            projectGetters: state => {
                return state.project
            },
            arrayProjectsInfinityGetters: state => {
                return state.arrayProjectsInfinity
            },
            spinnerProjectGetters: state => {
                return state.spinnerProject
            },
            optionsProjectGetters: state => {
                return state.optionsProject
            },
            optionsProjectDetailGetters: state => {
                return state.optionsProjectDetail
            },
            controllerGetProjectsGetters: state => {
                return state.controllerGetProjects
            },
            controllerGetProjectGetters: state => {
                return state.controllerGetProject
            }
        }
    })