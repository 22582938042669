import {defineStore} from 'pinia'
import axiosInstance from "@/services/api";
import {useApiSettingStore} from "@/stores/apiSettings";


export const useContentTypeStore = defineStore({
    id: 'contentType',

    state: () => ({
        contentTypes: {}
    }),
    actions: {
        getContentTypes(payload) {
            return new Promise((resolve, reject) => {
                
                let url = new URL(axiosInstance.defaults.baseURL + 'api/content-types')

                let params = new URLSearchParams()
                for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                    params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                }
                url.search = params
                axiosInstance.get(url.pathname + url.search)
                    .then(res => {
                         res.data.forEach(el => {
                             this.contentTypes[el.model] = el
                         })
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        }
    },

    getters: {
        contentTypesGetters: state => {
            return state.contentTypes
        }
    }
})


