import axiosInstance from "@/services/api";
import {defineStore} from 'pinia'
import {useApiSettingStore} from "./apiSettings";


export const useChronologyStore = defineStore({
    id: 'chronology',

    state: () => ({

        /*** controllers ***/
        controllerGetChronologys: null,
        /*** ***/

        chronologies: null,
        spinnerChronology: false,
        chronology: null,
        allChronology: null,
        arrayChronologysInfinity: [],
        optionsChronology: null,
    }),
    actions: {
        getChronologys(payload) {
            return new Promise((resolve, reject) => {
                this.controllerGetChronologys = new AbortController()
                const config = {
                    signal: useChronologyStore().controllerGetChronologysGetters.signal
                }

                let url = new URL(axiosInstance.defaults.baseURL + 'api/log-entries/')

                this.spinnerChronology = true
                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axiosInstance.get(url.pathname + url.search, config)
                        .then(res => {
                            this.chronologies = res.data
                            if (payload.queryParams.scroll) {
                                this.arrayChronologysInfinity.push(...res.data.results)
                            } else {
                                this.arrayChronologysInfinity = res.data.results
                            }
                            this.spinnerChronology = false
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerChronology = false
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                } else {
                    axiosInstance.get(url.pathname, config)
                        .then(res => {
                            this.spinnerChronology = false
                            this.chronologies = res.data
                            let a = []
                            a.push(...res.data.results);
                            this.arrayChronologysInfinity = a
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerChronology = false
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                }

            })
        },
        getChronologysWithoutSetState(payload) {
            return new Promise((resolve, reject) => {
                let url = new URL(axiosInstance.defaults.baseURL + 'api/log-entries/')

                this.spinnerChronology = true
                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axiosInstance.get(url.pathname + url.search)
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerChronology = false
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                } else {
                    axiosInstance.get(url.pathname)
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerChronology = false
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                }

            })
        },
        getAllChronologys(payload) {
            return new Promise((resolve, reject) => {
                let url = new URL(axiosInstance.defaults.baseURL + 'api/log-entries/')

                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axiosInstance.get(url.pathname + url.search)
                        .then(res => {
                            this.allChronologies = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })

            })
        },
        getChronology(id) {
            return new Promise((resolve, reject) => {
                axiosInstance.get('/api/log-entries/' + id + '/')
                    .then(res => {
                        this.chronology = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        editChronology(payload) {
            return new Promise((resolve, reject) => {
                axiosInstance.patch('/api/log-entries/' + payload.id + '/',payload)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        deleteChronology(payload) {
            return new Promise((resolve, reject) => {
                axiosInstance.delete('/api/log-entries/' + payload + '/')
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        createChronology(payload) {
            return new Promise((resolve, reject) => {
                axiosInstance.post('/api/log-entries/', payload)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getOptionsChronology() {
            return new Promise((resolve, reject) => {
                axiosInstance.options('api/log-entries/')
                    .then(res => {
                        this.optionsChronology = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        }
    },

    getters: {
        chronologiesGetters: state => {
            return state.chronologies
        },
        allChronologiesGetters: state => {
            return state.allChronologies
        },
        chronologyGetters: state => {
            return state.chronology
        },
        optionsChronologyGetters: state => {
            return state.optionsChronology
        },
        arrayChronologysInfinityGetters: state => {
            return state.arrayChronologysInfinity
        },
        spinnerChronologyGetters: state => {
            return state.spinnerChronology
        },
        controllerGetChronologysGetters: state => {
            return state.controllerGetChronologys
        }

    }
})