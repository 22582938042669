<template>
  <div class="container-detail-category">
    <div :class="{'order_container_content_detail_category': showTabs}" class="container-content-detail-category">
      <div
          v-if="storeCategory.categoryGetters && !isEditing && (!showTabs) || (showManage && showTabs && !isEditing) && storeCategory.categoryGetters"
          class="container-head-detail">
        <div class="container-content-left-head-detail container-content-left-head-detail-favorites">
          <span class="text-14px-dark-grey-medium">Nome: </span>
          <span v-if="storeCategory.categoryGetters"
                class="text-14px-black-regular">{{ storeCategory.categoryGetters.name }}</span>
          <div v-if="storeCategory.categoryGetters.is_favorite"
               class="container-is-favorite text-14px-secondary-regular">Preferiti
          </div>
        </div>
        <div class="container-content-left-head-detail">
          <span class="text-14px-dark-grey-medium">Data creazione: </span>
          <span class="text-14px-black-regular">{{ returnDate(storeCategory.categoryGetters.created_at) }}</span>
        </div>
        <div class="container-content-left-head-detail">
          <span class="text-14px-dark-grey-medium">Proprietario: </span>
          <span v-if="storeCategory.categoryGetters.owner.user_detail" class="text-14px-secondary-regular">{{
              storeCategory.categoryGetters.owner.user_detail.first_name
            }} {{ storeCategory.categoryGetters.owner.user_detail.last_name }}</span>
          <span v-else class="text-14px-secondary-regular">{{ storeCategory.categoryGetters.owner.email }}</span>
        </div>
        <div class="container-content-left-head-detail">
          <span class="text-14px-dark-grey-medium">Descrizione: </span>
          <span v-b-popover="storeCategory.categoryGetters.description" v-if="storeCategory.categoryGetters.description"
                class="text-14px-black-regular text-overflow-custom">{{
              storeCategory.categoryGetters.description
            }}</span>
          <span v-else class="text-14px-black-regular">ND</span>
        </div>
        <div class="container-content-left-head-detail container-content-left-head-detail-tag">
          <span class="text-14px-dark-grey-medium">Tags: </span>
          <div v-if="storeCategory.categoryGetters.tag.length > 0" style="display: flex;flex-wrap: wrap">
            <span class="text-14px-secondary-regular text-tags" v-for="(tag,i) in storeCategory.categoryGetters.tag"
                  :key="i">{{
                tag.name
              }}</span>
          </div>
          <span v-else class="text-14px-secondary-regular">Nessun tag presente</span>
        </div>
      </div>
      <ComponentDynamicForm v-if="isEditing"
                            @closeForm="closeModal" @close-form-without-update="isEditing = false"
                            :currentObject="{0: currentObj}"
                            :options="{
                                  endpoints: [
                                      {
                                        'endpoint': 'categories',
                                        'type': TYPE_OF_OPTION.UPDATE,
                                        'path': [
                                          'categories',
                                           {'objectID':  storeCategory.categoryGetters.id}
                                        ]
                                      }
                                  ]
                              }"
      ></ComponentDynamicForm>

      <div v-if="!showTabs" class="container-content-right-head-detail">
        <ComponentText v-if="storeCategory.categoryGetters && !isEditing"
                       :button-disabled="storeCategory.categoryGetters.description === null || storeCategory.categoryGetters.description === ''"
                       :currentObject="storeCategory.categoryGetters"
                       :fields="arrayFieldsToTextMining"
                       :model="'category'" :id="storeCategory.categoryGetters.id"></ComponentText>
        <div style="display: flex;flex-direction: column;margin-left: 25px">
          <div class="container-content-top-right-head-detail">
            <span class="text-14px-dark-grey-regular">Gestisci la categoria</span>
            <div class="container-buttons">
            <span
                v-if="storeCategory.categoryGetters && storeAuth.userGetters && storeAuth.userGetters.user_type === 'ad'"
                v-show="!isEditing"
                @click="isEditing = true, currentObj = Object.assign({},storeCategory.categoryGetters)"
                class="text-14px-primary-medium text-14px-primary-medium-edit">Modifica</span>
              <div v-if="storeCategory.categoryGetters"
                   @click="storeFavorite.setStatusFavorites({params: storeCategory.categoryGetters.id,action: storeCategory.getCategory,object: storeCategory.categoryGetters,entity: 'category'})"
                   v-show="!storeCategory.categoryGetters.is_favorite"
                   :class="{'container_favorites': isEditing,'disable-pointer-custom': storeFavorite.debounceBooleanGetters}"
                   class="container-favorites">
                <img src="../../assets/shared/bookmarks.svg" alt="">
                <span class="text-14px-secondary-medium">Salva tra i preferiti</span>
              </div>
              <div v-if="storeCategory.categoryGetters" @click="deleteFavorite"
                   v-show="storeCategory.categoryGetters.is_favorite" class="container-favorites">
                <img src="../../assets/shared/bookmarks-dark-grey.svg" alt="">
                <span class="text-14px-dark-grey-medium">Rimuovi dai preferiti</span>
              </div>
              <button
                  v-if="storeCategory.categoryGetters && storeAuth.userGetters && storeAuth.userGetters.user_type === 'ad'"
                  @click="deleteCategory"
                  class="btn btn-danger-custom">Elimina
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="{'order_div_container_wiki': showTabs}" v-if="!showTabs || (showManage && showTabs)"
         v-show="!isEditing" style="display: flex;align-items: center">
      <div class="div-container-wiki"
           @click="showWiki = true, currentObj = Object.assign({},storeCategory.categoryGetters)">
        <div class="div-container-img-wiki">
          <img src="../../assets/shared/question-circle-secondary.svg" alt="">
        </div>
        <span class="text-16px-black-medium">Wiki</span>
      </div>

      <ComponentText v-if="storeCategory.categoryGetters && !isEditing && showTabs"
                     :button-disabled="storeCategory.categoryGetters.description === null || storeCategory.categoryGetters.description === ''"
                     :currentObject="storeCategory.categoryGetters"
                     :fields="arrayFieldsToTextMining"
                     :model="'category'" :id="storeCategory.categoryGetters.id"></ComponentText>
    </div>


    <div :class="{'container_tabs': showTabs, 'order_tabs': showTabs}" class="container-tabs">

      <span v-if="showTabs"
            @click="showProjects = false,showSubcategories = false,showManage = true"
            :class="{'text-14px-secondary-medium': showManage && !showProjects && !showSubcategories}"
            class="text-14px-grey-regular">Gestisci</span>
      <span v-if="storeCategory.categoryGetters"
            @click="showSubcategories = true,showProjects = false,showManage = false,isEditing = false"
            :class="{'text-14px-secondary-medium': showSubcategories && !showProjects && !showManage}"
            class="text-14px-grey-regular">Sottocategorie</span>
      <span v-if="storeCategory.categoryGetters"
            style="margin-right: 0px"
            @click="showProjects = true,showSubcategories = false, showManage = false,isEditing = false"
            :class="{'text-14px-secondary-medium': showProjects && !showSubcategories && !showManage}"
            class="text-14px-grey-regular">Progetti</span>
    </div>


    <div v-if="showSubcategories" class="container-single-child-detail">
      <div v-if="!showTabs" class="container-buttons-child">
        <div
            v-if="storeCategory.categoryGetters"
            class="text-16px-dark-grey-medium">
          Sottocategorie in
          <span v-if="storeCategory.categoryGetters"
                class="text-16px-primary-medium">{{ storeCategory.categoryGetters.name }}</span>
        </div>
        <button
            v-if="storeCategory.categoryGetters && storeAuth.userGetters && storeAuth.userGetters.user_type === 'ad'"
            @click="showAddSubcategory = true" class="btn btn-primary-custom btn-primary-custom-child">Crea una
          sottocategoria
        </button>
      </div>
      <ComponentListSubcategories :actionsSubcategories="actionsSubcategories"
                                  v-if="storeCategory.categoryGetters"
                                  @setBoolModal="showAddSubcategory = true"></ComponentListSubcategories>
    </div>
    <div v-if="showProjects" class="container-single-child-detail">
      <div v-if="!showTabs" class="container-buttons-child">
        <div class="text-16px-dark-grey-medium">
          Progetti in
          <span v-if="storeCategory.categoryGetters"
                class="text-16px-primary-medium">{{ storeCategory.categoryGetters.name }}</span>
        </div>
        <button v-if="storeCategory.categoryGetters && storeAuth.userGetters && storeAuth.userGetters.user_type === 'ad'"
                @click="setProjectInfo" class="btn btn-primary-custom btn-primary-custom-child">Crea un progetto
        </button>
      </div>
      <ComponentProjects :actionsProjects="actionsProjects" @setBoolModal="showAddProject = true" v-if="showProjects"
                         :fromEntity="'category'"></ComponentProjects>
    </div>
  </div>


  <b-modal :hide-footer="true" :hide-header="true" @hide="closeModal" v-model="showWiki">
    <ComponentDetailWiki :entity="'category'" v-if="showWiki" :id-prop="storeCategory.categoryGetters.wiki"
                         @closeModal="closeModal"></ComponentDetailWiki>
  </b-modal>

  <b-modal :hide-footer="true" :hide-header="true" @hide="closeModal" v-model="showAddSubcategory">
    <div class="container-head-form">
      <div class="container-content-head-form">
        <div v-if="storeCategory.categoryGetters" class="text-16px-black-bold">Aggiungi sottocategoria in <span
            class="text-16px-primary-bold">{{ storeCategory.categoryGetters.name }}</span></div>
      </div>
      <img @click="showAddSubcategory = false" class="img-close" src="../../assets/shared/close.svg" alt="">
    </div>
    <ComponentDynamicForm @close-form-without-update="showAddSubcategory = false" v-if="showAddSubcategory"
                          @closeForm="closeModal" :options="{
      endpoints:
      [
          {
            'endpoint': 'subcategories',
            'type': TYPE_OF_OPTION.CREATE,
            'path': [
              'subcategories'
            ]
          }
      ]
      }"></ComponentDynamicForm>
  </b-modal>
  <b-modal :hide-footer="true" :hide-header="true" @hide="closeModal" v-model="showAddProject">
    <div class="container-head-form">
      <div class="container-content-head-form">
        <div v-if="storeCategory.categoryGetters" class="text-16px-black-bold">Aggiungi progetto in <span
            class="text-16px-primary-bold">{{ storeCategory.categoryGetters.name }}</span></div>
      </div>
      <img @click="showAddProject = false" class="img-close" src="../../assets/shared/close.svg" alt="">
    </div>
    <ComponentDynamicForm @close-form-without-update="showAddProject = false" v-if="showAddProject"
                          @closeForm="closeModal" :options="{
      endpoints:
      [
          {
            'endpoint': 'projects',
            'type': TYPE_OF_OPTION.CREATE,
            'path': [
              'projects'
            ]
          }
      ]
      }"></ComponentDynamicForm>
  </b-modal>

  <ComponentFloatingActionButton @setBooleanModal="setBooleanModal"
                                 v-if="actions.length > 0 && showManage && storeCategory.categoryGetters"
                                 :actions="actions"></ComponentFloatingActionButton>

</template>

<script>
import ComponentDetailWiki from "@/components/wiki/ComponentDetailWiki.vue";
import ComponentListSubcategories from "@/components/subCategory/ComponentListSubcategories.vue";
import {useUtilsStore} from "@/stores/utils";
import {useCategoryStore} from "@/stores/categories";
import moment from "moment";
import ComponentProjects from "@/components/project/ComponentProjects";
import ComponentDynamicForm from "@/components/form/ComponentDynamicForm";
import {TYPE_OF_OPTION} from "@/globals";
import {useSubcategoryStore} from "@/stores/subcategories";
import {useProjectStore} from "@/stores/projects";
import {useFavoriteStore} from "@/stores/favorites";
import ComponentFloatingActionButton from "@/components/floatingActionButton/ComponentFloatingActionButton";
import {pages} from "../../../vue.config";
import ComponentText from "@/components/shared/ComponentText";
import {useAuthStore} from "@/stores/auth";

export default {
  name: "ComponentDetailCategory",
  components: {
    ComponentText,
    ComponentFloatingActionButton,
    ComponentDynamicForm, ComponentProjects, ComponentListSubcategories, ComponentDetailWiki
  },
  setup() {
    const storeCategory = useCategoryStore()
    const storeAuth = useAuthStore()
    const storeProject = useProjectStore()
    const storeFavorite = useFavoriteStore()
    const storeSubcategory = useSubcategoryStore()
    return {
      storeAuth,
      storeSubcategory,
      storeFavorite,
      storeProject,
      storeCategory,
      TYPE_OF_OPTION
    }
  },
  data() {
    return {
      arrayFieldsToTextMining: [],
      actions: [],
      actionsProjects: [],
      actionsSubcategories: [],
      innerWidth: window.innerWidth,
      showManage: false,
      isEditing: false,
      showTabs: false,
      queryParams: {
        category__in: null
      },
      showAddSubcategory: false,
      showAddProject: false,
      showSubcategories: true,
      showProjects: false,
      currentObj: null,
      showWiki: false
    }
  },
  async created() {
    useCategoryStore().errorMimeTypeImage = false
    await this.loadDetail()
  },
  watch: {
    category: {
      handler: function (val) {
        /*** costruisco le actions della categoria da passare al floating button in base ai permessi ***/
        this.actions = []

        if (this.storeAuth.userGetters && this.storeAuth.userGetters.user_type === 'ad')
          this.actions.push({action: 'edit', entity: 'category'})

        this.actions.push({
          action: 'favorite',
          entity: 'category',
          is_favorite: val.is_favorite
        })

        if (this.storeAuth.userGetters && this.storeAuth.userGetters.user_type === 'ad')
          this.actions.push({
            action: 'delete',
            entity: 'category'
          })
        /*** ***/

        /*** costruisco le actions della sottocategoria da passare al floating button in base ai permessi ***/
        this.actionsSubcategories = []

        this.actionsSubcategories.push({action: 'filter', entity: 'subcategory'})
        if (this.storeAuth.userGetters && this.storeAuth.userGetters.user_type === 'ad')
          this.actionsSubcategories.push({
            type: 'bulk',
            action: 'delete',
            entity: 'subcategory'
          })

        if (this.storeAuth.userGetters && this.storeAuth.userGetters.user_type === 'ad')
          this.actionsSubcategories.push({action: 'add', entity: 'subcategory'})

        /*** ***/

        /*** costruisco le actions del da passare al floating button in base ai permessi ***/
        this.actionsProjects = []
        this.actionsProjects.push({
          action: 'filter',
          entity: 'project'
        })
        if (this.storeAuth.userGetters && this.storeAuth.userGetters.user_type === 'ad')
          this.actionsProjects.push({type: 'bulk', action: 'delete', entity: 'project'})
        if (this.storeAuth.userGetters && this.storeAuth.userGetters.user_type === 'ad')
          this.actionsProjects.push({action: 'add', entity: 'project'})
        /*** ***/

      }
    },
    showProjects: {
      handler: function (value) {

        if (value) {
          useProjectStore().fromEntity.name = 'category'
          useProjectStore().fromEntity.id = this.storeCategory.categoryGetters.id
        } else {
          useProjectStore().fromEntity.name = 'subcategory'
          useProjectStore().fromEntity.id = null
        }
      }

    },
    showAddSubcategory: {
      handler: function (value) {
        if (!value)
          useSubcategoryStore().getSubcategories({queryParams: this.queryParams})
              .then(() => {
              })
              .catch(() => {
              })
      }
    },
    showAddProject: {
      handler: function (value) {
        if (!value)
          useProjectStore().getProjects({queryParams: this.queryParams})
              .then(() => {
              })
              .catch(() => {
              })
      }
    }
  },
  computed: {
    category() {
      return useCategoryStore().categoryGetters
    }
  },
  mounted() {
    document.title = 'Dettaglio categoria' + ' | ' + pages.index.title
    if (window.innerWidth < 991.98) {
      this.showTabs = true
      this.showManage = true
      this.showSubcategories = false
      this.showProjects = false

    } else {
      this.showTabs = false
      this.showManage = false
      this.showSubcategories = true
      this.showProjects = false
    }
    this.setStickyPositions()
    window.addEventListener('resize', this.handleResize)
    window.addEventListener('scroll', this.setStickyPositions)
  },
  methods: {
    handleResize() {
      this.innerWidth = window.innerWidth
      if (window.innerWidth < 991.98) {
        this.showTabs = true
      } else {
        this.showTabs = false
        this.showManage = false
        this.showSubcategories = true
        this.showProjects = false
      }
      this.setStickyPositions()
    },
    setBooleanModal(event) {
      if (event.action === 'favorite' && event.entity === 'category' && event.is_favorite) {
        this.deleteFavorite()
      }
      if (event.action === 'favorite' && event.entity === 'category' && !event.is_favorite)
        this.storeFavorite.setStatusFavorites({
          params: this.storeCategory.categoryGetters.id,
          action: this.storeCategory.getCategory,
          object: this.storeCategory.categoryGetters,
          entity: 'category'
        })
      if (event.action === 'edit' && event.entity === 'category')
        this.isEditing = true
      if (event.action === 'delete' && event.entity === 'category')
        this.deleteCategory()
    },
    deleteFavorite() {
      let item = {
        id: this.storeCategory.categoryGetters.favorite_id
      }
      useFavoriteStore().currentKeyDeleting = this.keyFavorite
      useUtilsStore().infoForToDelete['currentPosition'] = 'detail'
      useUtilsStore().infoForToDelete['confirmDelete'] = true
      useUtilsStore().infoForToDelete['action'] = this.storeFavorite.deleteFavorite
      useUtilsStore().infoForToDelete['actionAfterDelete']['action'] = this.storeCategory.getCategory
      useUtilsStore().infoForToDelete['actionAfterDelete']['payload'] = this.storeCategory.categoryGetters.id
      useUtilsStore().infoForToDelete['typeObjectToDelete'] = 'single'
      useUtilsStore().infoForToDelete['entity'] = 'favorite'
      useUtilsStore().infoForToDelete['name'] = 'preferito'
      useUtilsStore().infoForToDelete['objectToDelete'] = item
      useUtilsStore().infoForToDelete['messageToDelete']['is'] = 'div'
      useUtilsStore().infoForToDelete['messageToDelete']['innerHTML'] = "Sei sicuro di voler rimuovere dai preferiti la categoria " +
          "<span style='color: var(--primary-color)'>" + this.storeCategory.categoryGetters.name +
          "</span>" + "?"
    },
    setProjectInfo() {
      this.showAddProject = true
      this.storeProject.fromEntity.name = 'category'
      this.storeProject.fromEntity.id = this.storeCategory.categoryGetters.id
    },
    returnDate(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    async loadDetail() {
      await useCategoryStore().getCategory(parseInt(this.$route.params.categoryId))
          .then(res => {

            /*** costruisco array di fields da analizzare per il text mining ***/
            this.arrayFieldsToTextMining = []
            if (res.data.description !== null)
              this.arrayFieldsToTextMining.push({
                name_db: 'description',
                value: 'Descrizione categoria'
              })
            /*** ***/

            /*** costruisco le actions della categoria da passare al floating button in base ai permessi ***/
            this.actions = []

            if (this.storeAuth.userGetters && this.storeAuth.userGetters.user_type === 'ad')
              this.actions.push({action: 'edit', entity: 'category'})

            this.actions.push({
              action: 'favorite',
              entity: 'category',
              is_favorite: res.data.is_favorite
            })

            if (this.storeAuth.userGetters && this.storeAuth.userGetters.user_type === 'ad')
              this.actions.push({
                action: 'delete',
                entity: 'category'
              })
            /*** ***/

            /*** costruisco le actions della sottocategoria da passare al floating button in base ai permessi ***/
            this.actionsSubcategories = []

            this.actionsSubcategories.push({action: 'filter', entity: 'subcategory'})
            if (this.storeAuth.userGetters && this.storeAuth.userGetters.user_type === 'ad')
              this.actionsSubcategories.push({
                type: 'bulk',
                action: 'delete',
                entity: 'subcategory'
              })

            if (this.storeAuth.userGetters && this.storeAuth.userGetters.user_type === 'ad')
              this.actionsSubcategories.push({action: 'add', entity: 'subcategory'})

            /*** ***/

            /*** costruisco le actions del da passare al floating button in base ai permessi ***/
            this.actionsProjects = []
            this.actionsProjects.push({
              action: 'filter',
              entity: 'project'
            })
            if (this.storeAuth.userGetters && this.storeAuth.userGetters.user_type === 'ad')
              this.actionsProjects.push({type: 'bulk', action: 'delete', entity: 'project'})
            if (this.storeAuth.userGetters && this.storeAuth.userGetters.user_type === 'ad')
              this.actionsProjects.push({action: 'add', entity: 'project'})
            /*** ***/

            this.queryParams.category__in = res.data.id
            useUtilsStore().chronology = []
            useUtilsStore().chronology.push({
              route_name: 'archive',
              route_id: null,
              name: 'Archivio',
              display_name: 'Archivio'
            })
            useUtilsStore().chronology.push({
              route_name: 'detailCategory',
              route_id: res.data.id,
              name: 'Dettaglio categoria',
              display_name: useCategoryStore().categoryGetters.name + ' [' + useCategoryStore().categoryGetters.id + ']'
            })
            useUtilsStore().currentNamePage = res.data.name
            this.currentObj = Object.assign({}, res.data)
            this.isEditing = false
          })
          .catch(() => {
          })
    },
    setStickyPositions() {
      let container_content_header
      if (document.getElementById('container-content-header') && document.getElementById('container-content-header-mobile') && window.getComputedStyle(document.getElementById('container-content-header')).display === 'none')
        container_content_header = document.getElementById('container-content-header-mobile')
      else
        container_content_header = document.getElementById('container-content-header')

      let container_head_table = document.getElementById('container-head-table')
      let thead = document.getElementsByTagName('thead')[0]
      let container_buttons_categories = document.getElementById('container-buttons-categories')
      if (container_buttons_categories && container_content_header)
        container_buttons_categories.style.top = container_content_header.getBoundingClientRect().height + 'px'
      if (container_head_table && container_buttons_categories && container_content_header)
        container_head_table.style.top = container_buttons_categories.getBoundingClientRect().height + container_content_header.getBoundingClientRect().height + 'px'
      if (thead && container_content_header && container_head_table && container_buttons_categories)
        thead.style.top = container_buttons_categories.getBoundingClientRect().height + container_head_table.getBoundingClientRect().height + container_content_header.getBoundingClientRect().height + 'px'

    },
    deleteCategory() {
      let item = useCategoryStore().categoryGetters
      useUtilsStore().infoForToDelete['confirmDelete'] = true
      useUtilsStore().infoForToDelete['action'] = this.storeCategory.deleteCategory
      useUtilsStore().infoForToDelete['typeObjectToDelete'] = 'single'
      useUtilsStore().infoForToDelete['entity'] = 'categoria'
      useUtilsStore().infoForToDelete['objectToDelete'] = item
      useUtilsStore().infoForToDelete['currentPosition'] = 'detail'
      useUtilsStore().infoForToDelete['messageToDelete']['is'] = 'div'
      useUtilsStore().infoForToDelete['messageToDelete']['innerHTML'] = "Sei sicuro di voler eliminare definitivamente la categoria  " +
          "<span style='color: var(--primary-color)'>" + useCategoryStore().categoryGetters.name +
          "</span>" + "? L’operazione non è reversibile."
    },
    closeModal() {
      if (this.isEditing)
        this.loadDetail()

      this.showWiki = false
      this.showAddSubcategory = false
      this.showAddProject = false
      this.currentObj = Object.assign({}, null)
    }
  },
  updated() {
  },
  beforeUnmount() {
    if (useCategoryStore().controllerGetCategoryGetters)
      useCategoryStore().controllerGetCategoryGetters.abort()

    useCategoryStore().category = null
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('scroll', this.setStickyPositions)
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('scroll', this.setStickyPositions)
  }
}
</script>

<style scoped>
.div-container-wiki {
  margin-right: 20px;
}

.order_div_container_wiki {
  order: 2;
}

.order_container_content_detail_category {
  order: 1
}

.order_tabs {
  order: 0;
}

.container-single-child-detail {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#container-buttons-categories {
  background: var(--white-color);
  position: sticky;
  z-index: 1;
}

.container-buttons-wrap div {
  margin-right: 10px;
}

.text-14px-primary-medium {
  cursor: pointer;
}

.text-14px-primary-medium-edit {
  margin-right: 10px;
}

.container-buttons-wrap {
  margin: 10px 0px;
  flex-wrap: wrap;
}

.btn-primary-custom-child.btn-primary-custom {
  margin-left: 10px;
}

.text-tags {
  margin-right: 5px;
  margin-bottom: 5px;
}

.container-favorites span {
  white-space: nowrap;
}

.container-favorites img {
  margin-right: 5px;
}

.container-shared img {
  margin-right: 5px;
}

.container-shared {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.container-favorites {
  display: flex;
  cursor: pointer;
  align-items: center;
}

.btn-danger-custom {
  margin-left: 10px !important;
}

.container_favorites.container-favorites {
  margin-left: 0px;
}

.container-buttons {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.container-tabs span {
  margin-right: 25px;
  cursor: pointer;
}

.container-tabs {
  margin: 10px 0px;
}

.container-buttons-child {
  margin: 10px auto 10px 0px;
  display: flex;
  align-items: center;
}

.container-content-detail-category {
  display: flex;
}

.container-content-bottom-right-head-detail {
  display: flex;
  margin-top: 25px;
  flex-direction: column;
}

.container-content-top-right-head-detail {
  display: flex;
  flex-direction: column;
}

.container-content-right-head-detail {
  align-items: flex-start;
  display: flex;
  /*flex-direction: column;*/
  margin-left: auto;
}

.container-content-left-head-detail {
  display: grid;
  margin: 0px 0px 5px 0px;
  column-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
}

.container-head-detail {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
}

.container-detail-category {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px 15px;
}

@media (max-width: 575.98px) {
}

/*// Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
  .container-content-right-head-detail {
    margin-left: 0px;
  }

  .container-content-detail-category {
    flex-direction: column;
  }
}

/*// Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {

}

/*// Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {
}

/*// X-Large devices (large desktops, less than 1400px)*/
@media (max-width: 1399.98px) {
}
</style>