import {defineStore} from 'pinia'
import axiosInstance from "@/services/api";


export const useAnonymousReport = defineStore({
    id: 'anonymousReport',


    state: () => ({
    }),
    actions: {
        createAnonymousReport(payload){
            return new Promise((resolve,reject) => {
                
                axiosInstance.post('api/interactions/anonymous-complaint/',payload)
                    .then(res =>{
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        }
    },

    getters: {
    }
})


