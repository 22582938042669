import {defineStore} from 'pinia'
import axiosInstance from "@/services/api";
import {useAuthStore} from "@/stores/auth";
import {useApiSettingStore} from "@/stores/apiSettings";
import {useCategoryStore} from "@/stores/categories";

export const useSubcategoryStore = defineStore(
    "subcategory",
    {
        state: () => ({
            /*** controller ***/
            controllerGetSubcategories: null,
            controllerGetSubcategory: null,
            /*** ***/

            subcategories: null,
            arraySubcategoriesInfinity: [],
            optionsSubcategory: null,
            subcategory: null,
            allSubcategories: null,
            oldSubcategory: null,
            spinnerSubcategory: false
        }),
        actions: {
            getOptionsSubcategory() {
                return new Promise((resolve, reject) => {

                    axiosInstance.options('api/subcategories/')
                        .then(res => {
                            this.optionsSubcategory = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                })
            },
            getSubcategory(id) {
                return new Promise((resolve, reject) => {
                    this.controllerGetSubcategory = new AbortController()
                    const config = {
                        signal: useSubcategoryStore().controllerGetSubcategoryGetters.signal
                    }
                    axiosInstance.get('api/subcategories/' + id + '/', config)
                        .then(res => {
                            this.subcategory = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                })
            },
            getSubcategories(payload) {
                return new Promise((resolve, reject) => {
                    this.controllerGetSubcategories = new AbortController()
                    const config = {
                        signal: useSubcategoryStore().controllerGetSubcategoriesGetters.signal
                    }

                    let url = new URL(axiosInstance.defaults.baseURL + 'api/subcategories/')

                    this.spinnerSubcategory = true
                    if (payload.queryParams !== null) {
                        let params = new URLSearchParams()
                        for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                            params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                        }
                        url.search = params
                        axiosInstance.get(url.pathname + url.search, config)
                            .then(res => {
                                this.subcategories = res.data
                                if (payload.queryParams.scroll) {
                                    this.arraySubcategoriesInfinity.push(...res.data.results)
                                } else {
                                    this.arraySubcategoriesInfinity = res.data.results
                                }
                                this.spinnerSubcategory = false
                                resolve(res)
                            })
                            .catch(err => {
                                this.spinnerSubcategory = false
                                if (err.response && err.response.status === 500) {
                                    useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                                }
                                reject(err)
                            })
                    } else {
                        axiosInstance.get(url.pathname, config)
                            .then(res => {
                                this.spinnerSubcategory = false
                                this.subcategories = res.data
                                let a = []
                                a.push(...res.data.results);
                                this.arraySubcategoriesInfinity = a
                                resolve(res)
                            })
                            .catch(err => {
                                this.spinnerSubcategory = false
                                if (err.response && err.response.status === 500) {
                                    useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                                }
                                reject(err)
                            })
                    }

                })
            },
            getSubcategoriesWithoutSetState(payload) {
                return new Promise((resolve, reject) => {
                    let url = new URL(axiosInstance.defaults.baseURL + 'api/subcategories/')

                    this.spinnerSubcategory = true
                    if (payload.queryParams !== null) {
                        let params = new URLSearchParams()
                        for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                            params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                        }
                        url.search = params
                        axiosInstance.get(url.pathname + url.search)
                            .then(res => {
                                resolve(res)
                            })
                            .catch(err => {
                                this.spinnerSubcategory = false
                                if (err.response && err.response.status === 500) {
                                    useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                                }
                                reject(err)
                            })
                    } else {
                        axiosInstance.get(url.pathname)
                            .then(res => {
                                resolve(res)
                            })
                            .catch(err => {
                                this.spinnerSubcategory = false
                                if (err.response && err.response.status === 500) {
                                    useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                                }
                                reject(err)
                            })
                    }

                })
            },
            createSubcategory(payload) {
                return new Promise((resolve, reject) => {
                    if (payload instanceof FormData) {
                        payload.set('owner', useAuthStore().userGetters.user_id)
                        payload.set('category', useCategoryStore().categoryGetters.id)
                        payload.set('color', useCategoryStore().categoryGetters.color)
                    } else {
                        payload['owner'] = useAuthStore().userGetters.user_id
                        payload['category'] = useCategoryStore().categoryGetters.id
                        payload['color'] = useCategoryStore().categoryGetters.color
                    }
                    axiosInstance.post('/api/subcategories/', payload)
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            reject(err)
                        })
                })
            },
            getAllSubcategories(payload) {
                return new Promise((resolve, reject) => {
                    let url = new URL(axiosInstance.defaults.baseURL + 'api/subcategories/')

                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axiosInstance.get(url.pathname + url.search)
                        .then(res => {
                            this.allSubcategories = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })

                })
            },
            editSubcategory(payload) {
                return new Promise((resolve, reject) => {
                    payload.owner = useSubcategoryStore().subcategoryGetters.owner.id
                    axiosInstance.patch('api/subcategories/' + payload.id + '/', payload)
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                })
            },
            deleteSubcategory(id) {
                return new Promise((resolve, reject) => {
                    axiosInstance.delete('/api/subcategories/' + id + '/')
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                })
            }
        },
        getters: {
            subcategoriesGetters: state => {
                return state.subcategories
            },
            allSubcategoriesGetters: state => {
                return state.allSubcategories
            },
            oldSubcategoryGetters: state => {
                return state.oldSubcategory
            },
            subcategoryGetters: state => {
                return state.subcategory
            },
            arraySubcategoriesInfinityGetters: state => {
                return state.arraySubcategoriesInfinity
            },
            spinnerSubcategoryGetters: state => {
                return state.spinnerSubcategory
            },
            optionsSubcategoryGetters: state => {
                return state.optionsSubcategory
            },
            controllerGetSubcategoriesGetters: state => {
                return state.controllerGetSubcategories
            },
            controllerGetSubcategoryGetters: state => {
                return state.controllerGetSubcategory
            }
        }
    })