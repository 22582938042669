<template>
  <ComponentDetailProject></ComponentDetailProject>
</template>

<script>
import ComponentDetailProject from "@/components/project/ComponentDetailProject";
import {pages} from "../../vue.config";

export default {
  name: "DetailProjectView",
  components: {ComponentDetailProject},
  props: {
    prova: {
      type: String
    }
  },
  setup() {
    return {}
  },
  data() {
    return {}
  },
  created() {

  },
  mounted() {
    document.title = 'Dettaglio progetto' + ' | ' + pages.index.title
  }
}
</script>

<style scoped>

</style>