<template>
  <div class="container-router-view">
    <ComponentPresentationDynamic
        v-if="$route.path === '/login' || $route.path === '/register'"></ComponentPresentationDynamic>
    <ComponentSideBar
        v-else-if="!storeUtils.toggleHamburgerMenuGetters && storeAuth.authenticatedGetters && $route.path !== '/login'"></ComponentSideBar>
    <b-modal modal-class="modal-custom" content-class="content-custom" dialog-class="dialog-custom" v-if="showModal"
             body-class="body-custom" @hide="closeModal" :hide-footer="true" :hide-header="true" v-model="showModal">
      <ComponentHamburgerMenu></ComponentHamburgerMenu>
    </b-modal>
    <div id="container_views" class="container-views">
      <ComponentHeader v-if="storeAuth.authenticatedGetters"></ComponentHeader>
      <router-view v-if="storeUtils.globalSearchGetters === '' && storeAuth.authenticatedGetters"></router-view>
      <ComponentGlobalSearch v-if="storeUtils.globalSearchGetters !== ''"></ComponentGlobalSearch>

    </div>
    <b-modal style="z-index: 20000;" @closeModalConfirmDelete="closeModalConfirmDelete" @hide="closeModalConfirmDelete"
             :hide-footer="true" v-if="showModalConfirmDelete"
             :hide-header="true" v-model="showModalConfirmDelete">
      <ComponentConfirmDelete></ComponentConfirmDelete>
    </b-modal>
  </div>
</template>

<script>

import ComponentPresentationDynamic from "@/components/presentationDynamic/ComponentPresentationDynamic.vue";
import {useAuthStore} from "@/stores/auth";
import ComponentSideBar from "@/components/sidebar/ComponentSideBar.vue";
import ComponentHeader from "@/components/header/ComponentHeader.vue";
import {useUtilsStore} from "@/stores/utils";
import ComponentHamburgerMenu from "@/components/hamburgerMenu/ComponentHamburgerMenu.vue";
import {useApiSettingStore} from "@/stores/apiSettings";
import ComponentConfirmDelete from "@/components/confirmDelete/ComponentConfirmDelete";
import {useContentTypeStore} from "@/stores/contentType";
import ComponentGlobalSearch from "@/components/globalSearch/ComponentGlobalSearch";

export default {
  name: 'App',
  setup() {
    const storeAuth = useAuthStore()
    const storeUtils = useUtilsStore()
    const storeApi = useApiSettingStore()

    storeApi.getApiSettings()
        .then(() => {
        })
        .catch(() => {
        })
    return {
      storeAuth,
      storeUtils
    }
  },
  components: {
    ComponentGlobalSearch,
    ComponentConfirmDelete,
    ComponentHamburgerMenu, ComponentHeader, ComponentSideBar, ComponentPresentationDynamic
  },
  data() {
    return {
      innerWidth: window.innerWidth,
      showModalConfirmDelete: false,
      showModal: false
    }
  },
  created() {
    useUtilsStore().retry401Counter = 0
    useAuthStore().errorToken = ''
    useApiSettingStore().error500 = ''
    useUtilsStore().resetFilterAndOtherInfo()
    useUtilsStore().toggleHamburgerMenu = false
    useUtilsStore().globalSearch = ''
  },
  computed: {
    confirmDelete() {
      return useUtilsStore().infoForToDeleteGetters['confirmDelete']
    },
    toggleHamburgerMenu() {
      return useUtilsStore().toggleHamburgerMenuGetters
    }
  },
  watch: {
    toggleHamburgerMenu: {
      handler: function (value) {
        this.showModal = value
      }
    },
    confirmDelete: {
      handler: function (value) {
        this.showModalConfirmDelete = value
      }
    }
  },
  mounted() {
    this.$router.push({path: '/', query: {}}).catch(() => {
    })
    if (useAuthStore().authenticatedGetters && useAuthStore().userGetters.user_type === 'ad')
      useContentTypeStore().getContentTypes({
        queryParams: {
          pagination: false
        }
      })
          .then(() => {
          })
          .catch(() => {
          })

    useUtilsStore().resetConfirmDelete()
    let lastHeight = window.innerHeight;
    document.documentElement.style.setProperty('--heightScreen', lastHeight.toString() + 'px');
    window.addEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.innerWidth = window.innerWidth
      document.documentElement.style.setProperty('--heightScreen', window.innerHeight.toString() + 'px');
      if (window.innerWidth > 991.98)
        useUtilsStore().toggleHamburgerMenu = false
    },
    closeModal() {
      useUtilsStore().toggleHamburgerMenu = false
    },
    closeModalConfirmDelete() {
      useUtilsStore().spinnerDeleting = false
      useUtilsStore().resetConfirmDelete()
    }
  },
  updated() {
  },
  beforeUnmount() {
    useUtilsStore().toggleHamburgerMenu = false
    window.removeEventListener('resize', this.handleResize)
  },
  unmounted() {
    useUtilsStore().toggleHamburgerMenu = false
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style>
.container-views {
  display: flex;
  /*overflow: auto;*/
  flex-direction: column;
  height: 100%;
  width: 100%
}

#app {
  display: flex;
  background: var(--white-color);
  min-height: 100vh;
  width: 100%;
}

.body-custom.modal-body {
  padding: 0px !important;
}

.dialog-custom.modal-dialog {
  margin: 0px auto 0px 0px !important;
  width: 80% !important;
  max-width: 80% !important;
}

.modal-custom.modal {
  overflow: unset !important;
}

.content-custom.modal-content {
  max-width: 100%;
  margin-left: 0px !important;
}


.container-router-view {
  display: flex;
  width: calc(100% - 240px);
  margin-left: auto;
  min-height: 100vh;
}


/*// X-Small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
}

/*// Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
}

/*// Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {
  .container-router-view {
    width: 100%;
    max-width: 100% !important;
  }
}

/*// Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {
  .container-views {
    width: 100%;
  }
}

/*// X-Large devices (large desktops, less than 1400px)*/
@media (max-width: 1399.98px) {
}

</style>
