import {defineStore} from 'pinia'
import axiosInstance from "@/services/api";
import {useApiSettingStore} from "@/stores/apiSettings";

export const useSuggestionStore = defineStore("suggestion", {
    state: () => ({
        /*** controllers ***/
        controllerGetSuggestions: null,
        /*** ***/

        suggestions: [],
        arraySuggestionsInfinity: [],
        optionsSuggestion: null,
        optionsSuggestionDetail: null,
        suggestion: null,
        allSuggestions: null,
        oldSuggestion: null,
        spinnerSuggestion: false
    }),
    actions: {
        getOptionsSuggestion() {
            return new Promise((resolve, reject) => {
                axiosInstance.options('api/suggestions/')
                    .then(res => {
                        this.optionsSuggestion = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },

        getSuggestionsWithoutSetState(payload) {
            return new Promise((resolve, reject) => {
                let url = new URL(axiosInstance.defaults.baseURL + 'api/suggestions/')

                this.spinnerUser = true
                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axiosInstance.get(url.pathname + url.search)
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                } else {
                    axiosInstance.get(url.pathname)
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                }

            })
        },
        getSuggestion(id) {
            return new Promise((resolve, reject) => {
                axiosInstance.get('api/suggestions/' + id + '/')
                    .then(res => {
                        this.suggestion = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        getSuggestions(payload) {
            return new Promise((resolve, reject) => {
                this.controllerGetSuggestions = new AbortController()
                const config = {
                    signal: useSuggestionStore().controllerGetSuggestionsGetters.signal
                }

                let url = new URL(axiosInstance.defaults.baseURL + 'api/suggestions/')

                this.spinnerSuggestion = true
                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axiosInstance.get(url.pathname + url.search, config)
                        .then(res => {
                            this.suggestions = res.data
                            if (payload.queryParams.scroll) {
                                this.arraySuggestionsInfinity.push(...res.data.results)
                            } else {
                                this.arraySuggestionsInfinity = res.data.results
                            }
                            this.spinnerSuggestion = false
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerSuggestion = false
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                } else {
                    axiosInstance.get(url.pathname, config)
                        .then(res => {
                            this.spinnerSuggestion = false
                            this.suggestions = res.data
                            let a = []
                            a.push(...res.data.results);
                            this.arraySuggestionsInfinity = a
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerSuggestion = false
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                }

            })
        },

        createSuggestion(payload) {
            return new Promise((resolve, reject) => {
                axiosInstance.post('/api/suggestions/', payload)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getAllSuggestions(payload) {
            return new Promise((resolve, reject) => {
                let url = new URL(axiosInstance.defaults.baseURL + 'api/suggestions/')

                let params = new URLSearchParams()
                for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                    params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                }
                url.search = params
                axiosInstance.get(url.pathname + url.search)
                    .then(res => {
                        this.allSuggestions = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })

            })
        },
        editSuggestion(payload) {
            return new Promise((resolve, reject) => {
                axiosInstance.patch('api/suggestions/' + payload.id + '/', payload)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        deleteSuggestion(id) {
            return new Promise((resolve, reject) => {
                axiosInstance.delete('/api/suggestions/' + id + '/')
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        upVoteSuggestion(payload) {
            return new Promise((resolve, reject) => {
                axiosInstance.post('api/suggestions/' + payload + '/upvote/', {})
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        downVoteSuggestion(payload) {
            return new Promise((resolve, reject) => {
                axiosInstance.post('api/suggestions/' + payload + '/downvote/', {})
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        }
    },
    getters: {
        suggestionsGetters: state => {
            return state.suggestions
        },
        allSuggestionsGetters: state => {
            return state.allSuggestions
        },
        oldSuggestionGetters: state => {
            return state.oldSuggestion
        },
        suggestionGetters: state => {
            return state.suggestion
        },
        arraySuggestionsInfinityGetters: state => {
            return state.arraySuggestionsInfinity
        },
        spinnerSuggestionGetters: state => {
            return state.spinnerSuggestion
        },
        optionsSuggestionGetters: state => {
            return state.optionsSuggestion
        },
        optionsSuggestionDetailGetters: state => {
            return state.optionsSuggestionDetail
        },
        controllerGetSuggestionsGetters: state => {
            return state.controllerGetSuggestions
        }
    }
})