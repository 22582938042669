import {useAuthStore} from "@/stores/auth.js";
import keycloakService from '@/services/keycloak.js';
import setupInterceptors from '@/services/tokenInterceptors'

// Setup auth store as a plugin so it can be accessed globally in our FE
const AuthPlugin = {
    install(app, option) {
        const store = useAuthStore(option.pinia);

        // Global store
        app.config.globalProperties.$store = store;

        // Store keycloak user data into store
        keycloakService.CallInitStore(store).then();


        // Setup token interceptor so every FE API calls will have the access token for BE to verify
        setupInterceptors(store);
    }
}

export default AuthPlugin;