import {defineStore} from "pinia";
import keycloakService from "@/services/keycloak";
import axiosInstance from "@/services/api";
import {useUserStore} from "@/stores/users";
import {useContentTypeStore} from "@/stores/contentType";

export const useAuthStore = defineStore({
    id: "auth",
    state: () => {
        return {
            authenticated: false,
            keycloakUser: {},
            user: {}
        }
    },
    persist: true,
    getters: {
        authenticatedGetters: state => {
            return state.authenticated
        },
        userGetters: state => {
            return state.user
        },
        keycloakUserGetters: state => {
            return state.keycloakUser
        },
    },
    actions: {
        async getUser() {
            await axiosInstance.get('api/users/get-user/')
                .then(res => {
                    this.user = res.data
                    useUserStore().user = res.data
                })
                .catch(() => {
                })
        },
        // Initialize Keycloak OAuth
        async initOauth(keycloak, clearData = true) {
            if (clearData) {
                await this.clearUserData();
            }

            this.authenticated = keycloak.authenticated;
            this.keycloakUser.username = keycloak.idTokenParsed.preferred_username;
            this.keycloakUser.token = keycloak.token;
            this.keycloakUser.refToken = keycloak.refreshToken;

            await this.getUser()
            await useContentTypeStore().getContentTypes({
                queryParams: {
                    pagination: false
                }
            })
        },
        // Logout keycloakUser
        async logout() {
            try {
                await this.clearUserData();
                await keycloakService.CallLogout(process.env.VUE_APP_FRONTEND_HOST);
            } catch (error) {
                console.error(error);
            }
        },
        // Refresh keycloakUser's token
        async refreshUserToken() {
            try {
                const keycloak = await keycloakService.CallTokenRefresh();
                await this.initOauth(keycloak, false);
            } catch (error) {
                console.error(error);
            }
        },
        // Clear keycloakUser's store data
        clearUserData() {
            this.authenticated = false;
            this.keycloakUser = {};
            this.user = {};
        }
    }
});