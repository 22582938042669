<template>
  <div id="container-hamburger-menu" class="container-hamburger-menu">
    <img class="img-logo" src="../../assets/shared/logo.svg" alt="">
    <img @click="closeHamburgerMenu" class="img-close" src="../../assets/shared/close-black.svg" alt="">
    <div class="container-links container-links-knowledge">
      <span class="text-14px-grey-regular">Knowledge</span>
      <router-link @click="setCurrentNamePage('Dashboard')"
                   :class="{container_router_link: $route.name === 'dashboard'}"
                   class="container-router-link" to="/dashboard">
        <img v-show="$route.name === 'dashboard'" src="../../assets/sidebar/dashboard_active.svg" alt="">
        <img v-show="$route.name !== 'dashboard'" src="../../assets/sidebar/dashboard.svg" alt="">
        Dashboard
      </router-link>
      <router-link @click="setCurrentNamePage('Archivio')"
                   :class="{container_router_link: $route.path.includes('archive')}"
                   class="container-router-link" to="/archive">
        <img v-show="$route.path.includes('archive')" src="../../assets/sidebar/archive_active.svg" alt="">
        <img v-show="!($route.path.includes('archive'))" src="../../assets/sidebar/archive.svg" alt="">
        Archivio
      </router-link>
      <router-link @click="setCurrentNamePage('Wiki')" :class="{container_router_link: $route.name === 'wiki'}"
                   class="container-router-link" to="/wiki">
        <img v-show="$route.name === 'wiki'" src="../../assets/sidebar/wiki_active.svg" alt="">
        <img v-show="$route.name !== 'wiki'" src="../../assets/sidebar/wiki.svg" alt="">
        Wiki
      </router-link>
    </div>

    <div class="container-links-border"></div>

    <!-- NEW -->
    <div class="container-links">
      <span class="text-14px-grey-regular">Interazione Cooperativa</span>
      <router-link @click="setCurrentNamePage('Public Wall')"
                   :class="{container_router_link: $route.name === 'publicWall'}"
                   class="container-router-link" to="/public-wall">
        <img v-show="$route.name === 'publicWall'" src="../../assets/interaction_cc/earth_active.svg" alt="">
        <img v-show="$route.name !== 'publicWall'" src="../../assets/interaction_cc/earth.svg" alt="">
        <span class="text-ellipsis">Public Wall</span>
      </router-link>
      <router-link @click="setCurrentNamePage('Private Wall')"
                   :class="{container_router_link: $route.path.includes('private-wall')}"
                   class="container-router-link" to="/private-wall">
        <img v-show="$route.path.includes('private-wall')" src="../../assets/interaction_cc/earth_lucchetto_active.svg" alt="">
        <img v-show="(!($route.path.includes('private-wall')))" src="../../assets/interaction_cc/earth_lucchetto.svg" alt="">
        <span class="text-ellipsis">Private Wall</span>
      </router-link>
      <router-link @click="setCurrentNamePage('Chat')"
                   :class="{container_router_link: $route.name === 'chat'}"
                   class="container-router-link" to="/chat">
        <img v-show="$route.name === 'chat'" src="../../assets/interaction_cc/chat_active.svg" alt="">
        <img v-show="$route.name !== 'chat'" src="../../assets/interaction_cc/chat.svg" alt="">
        <span class="text-ellipsis">Chat</span>
      </router-link>
      <router-link @click="setCurrentNamePage('Calendario')"
                   :class="{container_router_link: $route.name === 'calendar'}"
                   class="container-router-link" to="/calendar">
        <img v-show="$route.name === 'calendar'" src="../../assets/interaction_cc/calendar_active.svg" alt="">
        <img v-show="$route.name !== 'calendar'" src="../../assets/interaction_cc/calendar.svg" alt="">
        <span class="text-ellipsis">Calendario</span>
      </router-link>
      <router-link @click="setCurrentNamePage('Suggerimenti')"
                   :class="{container_router_link: $route.name === 'suggestions'}"
                   class="container-router-link" to="/suggestions">
        <img v-show="$route.name === 'suggestions'" src="../../assets/interaction_cc/like-active.svg" alt="">
        <img v-show="$route.name !== 'suggestions'" src="../../assets/interaction_cc/like.svg" alt="">
        <span class="text-ellipsis">Suggerimenti</span>
      </router-link>
    </div>
    <!-- NEW -->

    <div class="container-links-border"></div>

    <router-link @click="setCurrentNamePage('Impostazioni')"
                 :class="{container_router_link: $route.path.includes('settings')}"
                 class="container-router-link container-router-link-settings"
                 to="/settings/users">
      <img v-show="$route.path.includes('settings')" src="../../assets/sidebar/settings_active.svg" alt="">
      <img v-show="!$route.path.includes('settings')" src="../../assets/sidebar/settings.svg" alt="">
      Impostazioni
    </router-link>
  </div>
</template>

<script>
import {useUtilsStore} from "@/stores/utils";

export default {
  name: "ComponentHamburgerMenu",
  setup() {
    return {}
  },
  data() {
    return {
      showInteraction: false,
      showModal: false
    }
  },
  created() {
  },
  watch: {},
  computed: {},
  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      let container_sidebar = document.getElementById('container-sidebar')
      if (container_sidebar)
        if (window.innerWidth > 991.98)
          container_sidebar.style.display = 'flex'
        else
          container_sidebar.style.display = 'none'
    },
    closeHamburgerMenu() {
      useUtilsStore().toggleHamburgerMenu = false
    },
    clickInteraction() {
      this.showInteraction = !this.showInteraction
    },
    setCurrentNamePage(str) {
      useUtilsStore().search = ''
      useUtilsStore().globalSearch = ''
      useUtilsStore().currentNamePage = str
      useUtilsStore().toggleHamburgerMenu = false
      this.showInteraction = false
    }
  },
  updated() {
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize)
    this.emitter.off('toggleHamburger', () => {
    })
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize)
    this.emitter.off('toggleHamburger', () => {
    })
  }
}
</script>

<style scoped>

.container-links-knowledge.container-links {
  margin-top: 10px;
}

.container-links-border {
  margin: 10px 0px;
  border-top: 1px solid var(--light-grey-color);
}

.text-14px-grey-regular {
  margin: 10px 0px;
  padding: 0px 20px;
}

.container-single-interaction span {
  margin-left: 15px;
  margin-right: 5px;
}

.container-single-interaction {
  display: flex;
  align-items: center;
}

.container-content-interaction a {
  padding: 10px 0px !important;
  border: unset !important;
}

.container-content-interaction {
  display: flex;
  flex-direction: column;
}

.container-interactions {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  cursor: pointer;
}

.container_interactions {
  background: var(--white-color);
  border: 1px solid var(--light-grey-color);
}

.img-close {
  cursor: pointer;
  display: block;
  position: absolute;
  right: 10px;
  top: 10px;
}

.container-router-link-settings.container-router-link {
  margin: auto 0px 50px 0px;
}

.container_router_link.container-router-link {
  background: var(--white-color);
  border: 1px solid var(--light-grey-color);
}

.container-router-link img {
  margin-right: 15px;
}

.container-router-link {
  background: transparent;
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

.container-links {
  display: flex;
  flex-direction: column;
}

.img-logo {
  margin: 35px;
}

.container-hamburger-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: var(--heightScreen);
  background: var(--dark-white-color);
  border: 1px solid var(--light-grey-color);
}

/*// X-Small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
}

/*// Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
  .img-logo{
    margin: 15px;
  }
}

/*// Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {

}

/*// Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {
}

/*// X-Large devices (large desktops, less than 1400px)*/
@media (max-width: 1399.98px) {
}

</style>