<template>
  <ComponentUsers v-if="$route.name === 'users'"></ComponentUsers>
  <router-view v-else></router-view>
</template>

<script>
import ComponentUsers from "@/components/settings/ComponentUsers.vue";
import {pages} from "../../vue.config";

export default {
  name: "UsersView",
  components: {ComponentUsers},
  created() {

  },
  mounted() {
    document.title = 'Lista utenti' + ' | ' + pages.index.title
  }
}
</script>

<style scoped>

</style>