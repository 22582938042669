<template>
  <div class="container-documents">
    <div class="container-content-documents">
      <div id="container-head-table" class="container-head-table">
        <b-form-input v-on:input="debounce(research)" v-model="search"
                      class="form-control search-bar"
                      placeholder="Cerca"></b-form-input>
        <div class="container-all-filter">
          <button @click="showConfirmDelete($event,null,'bulk')"
                  v-if="selectedDocuments.length > 0 && storeAuth.userGetters && storeAuth.userGetters.user_type === 'ad'"
                  class="btn btn-danger-custom">Elimina
          </button>
          <!--          <span @click="bulkDownload" v-if="selectedDocuments.length > 0" class="text-14px-primary-medium">Scarica documenti</span>-->
          <div v-if="innerWidth > 991.98" @click="showFilters = true" class="container-filter">
            <span :class="{'text-active-filters': storeUtils.checkIfThereAreActiveFilters('documents')}"
                  class="text-12px-dark-grey-medium">Filtri</span>
            <img v-show="!storeUtils.checkIfThereAreActiveFilters('documents')"
                 src="../../assets/shared/settings-filter.svg" alt="">
            <img v-show="storeUtils.checkIfThereAreActiveFilters('documents')"
                 src="../../assets/shared/settings-filter-active.svg" alt="">
          </div>
          <div v-else class="container-filter container-filter-no-background">
            <img v-show="!storeUtils.checkIfThereAreActiveFilters('documents')"
                 src="../../assets/shared/settings-filter.svg" alt="">
            <img v-show="storeUtils.checkIfThereAreActiveFilters('documents')"
                 src="../../assets/shared/settings-filter-active.svg" alt="">
            <span :class="{'text-active-filters': storeUtils.checkIfThereAreActiveFilters('documents')}"
                  class="text-12px-dark-grey-medium">Filtri</span>
          </div>
        </div>
      </div>
      <div style="width: 100%"
           v-if="!isLoading && storeDocuments.documentsGetters && storeDocuments.optionsDocumentGetters"
           :class="{'table-responsive': innerWidth < 767.98}">
        <table>
          <thead>
          <!--          <tr>-->
          <th>
            <b-form-checkbox @change="changeSelectedAll" v-model="selectedAll"></b-form-checkbox>
          </th>
          <th>

            <div class="container-th">
              ID
              <img v-show="id === ''" @click="ordering('id','th_id')" class="img-arrow-ordering"
                   src="../../assets/shared/arrow-drop-down-ordering.svg" alt="">

              <img @click="ordering('-id','th_id')" v-show="id === 'id'" class="img-arrow-ordering"
                   src="../../assets/shared/arrow-drop-down-ordering-primary.svg" alt="">
              <img class="img-arrow-ordering img-arrow-ordering-rotate" @click="ordering('','th_id')"
                   v-show="id === '-id'"
                   src="../../assets/shared/arrow-drop-down-ordering-primary.svg" alt="">
            </div>
          </th>
          <th>
            <div class="container-th">
              Nome
              <img v-show="name === ''"
                   @click="ordering('name','th_name')" class="img-arrow-ordering"
                   src="../../assets/shared/arrow-drop-down-ordering.svg" alt="">

              <img @click="ordering('-name','th_name')"
                   v-show="name === 'name'" class="img-arrow-ordering"
                   src="../../assets/shared/arrow-drop-down-ordering-primary.svg" alt="">
              <img class="img-arrow-ordering img-arrow-ordering-rotate" @click="ordering('','th_name')"
                   v-show="name === '-name'"
                   src="../../assets/shared/arrow-drop-down-ordering-primary.svg" alt="">
            </div>
          </th>
          <th>
            Tipologia
          </th>
          <th>Proprietario</th>
          <th class="th-width"></th>
          <th class="th-width"></th>
          <th class="th-width"></th>
          <!--          </tr>-->
          </thead>
          <tbody>
          <tr @click="download($event,document)"
              :class="{'disable-pointer-custom': storeFavorite.debounceBooleanGetters,'tr-active': selectedDocuments.includes(document)}"
              v-for="(document,index) in storeDocuments.documentsGetters.results" :key="index">
            <td>
              <input class="form-check-input" type="checkbox" @click="stopPropagation" :value="document"
                     v-model="selectedDocuments">
            </td>
            <td>{{ document.id }}</td>
            <td>{{ document.name }}</td>
            <td class="text-14px-grey-regular">
              {{ returnType(document.type) }}
            </td>
            <td v-if="document.owner.user_detail" class="text-14px-grey-regular">
              {{ document.owner.user_detail.first_name }} {{ document.owner.user_detail.last_name }}
            </td>
            <td v-else class="text-14px-grey-regular">
              {{ document.owner.email }}
            </td>
            <td>
              <img
                  @click="deleteFavorite($event,document)"
                  v-show="document.is_favorite" src="../../assets/shared/bookmarks.svg" alt="">
              <img
                  @click="$event.stopPropagation(),storeFavorite.setStatusFavorites({params: {'queryParams': {project__in: document.project.id,ordering: '-created_at'}},action: storeDocuments.getDocuments,object: document,entity: 'document'})"
                  v-show="!document.is_favorite" src="../../assets/shared/bookmarks-grey.svg" alt="">
            </td>
            <td>
              <img v-if="storeAuth.userGetters && storeAuth.userGetters.user_type === 'ad'"
                   @click="showedEditDocument($event,document)"
                   src="../../assets/shared/edit-primary.svg" alt="">
              <img class="cursor-disabled" v-else src="../../assets/shared/edit-grey.svg" alt="">
            </td>
            <td>
              <img v-if="storeAuth.userGetters && storeAuth.userGetters.user_type === 'ad'"
                   @click="showConfirmDelete($event,document,'single')" src="../../assets/shared/delete-red.svg"
                   alt="">
              <img class="cursor-disabled" v-else src="../../assets/shared/delete-grey.svg"
                   alt="">
              <!--                 @mouseover="user['is_hovered'] = true"-->
              <!--                 @mouseout="user['is_hovered'] = false"-->
              <!--            <img v-else @mouseover="user['is_hovered'] = true" @mouseout="user['is_hovered'] = false"-->
              <!--                 @click="showConfirmDelete($event,user,'single')"-->
              <!--                 src="../../assets/shared/delete-red.svg" alt="">-->
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <b-spinner v-if="isLoading"></b-spinner>
    </div>
    <div v-if="storeDocuments.documentsGetters" class="container-footer-documents">
      <span class="text-14px-grey-regular">{{ storeDocuments.documentsGetters.count }} risultati</span>
      <b-pagination
          v-if="storeDocuments.documentsGetters"
          v-model="currentPage"
          :total-rows="storeDocuments.documentsGetters.count"
          :per-page="storeApi.apiSettingsGetters.pagination.page_size"
          last-number
      ></b-pagination>
    </div>
    <b-modal v-model="showEditDocument" @hide="closeModalFilters" :hide-header="true" :hide-footer="true">
      <ComponentDynamicForm v-if="showEditDocument" @close-form-without-update="showEditDocument = false"
                            @closeForm="closeModal"
                            :currentObject="{0: currentObj}"
                            :options="{
                                  endpoints: [
                                      {
                                        'endpoint': 'documents',
                                        'type': TYPE_OF_OPTION.UPDATE,
                                        'path': [
                                          'documents',
                                           {'objectID':  currentObj.id}
                                        ]
                                      }
                                  ]
                              }"
      ></ComponentDynamicForm>
    </b-modal>
    <b-modal v-model="showFilters" @hide="closeModalFilters" :hide-header="true" :hide-footer="true">
      <ComponentFilters @resetDictFilter="resetDictFilter" @resetCurrentPage="currentPage = 1"
                        @closeWithoutUpdate="showFilters = false"
                        @closeModalFilters="closeModalFilters"
                        :myFilter="'documents'"></ComponentFilters>
    </b-modal>
  </div>
  <ComponentFloatingActionButton :selected="selectedDocuments" @setBooleanModal="setBooleanModal"
                                 v-if="innerWidth <= 991.98"
                                 :actions="actions"></ComponentFloatingActionButton>
</template>

<script>
import {useApiSettingStore} from "@/stores/apiSettings";
import {useUtilsStore} from "@/stores/utils";
import ComponentFilters from "@/components/filters/ComponentFilters";
import {useDocumentStore} from "@/stores/documents";
import ComponentDynamicForm from "@/components/form/ComponentDynamicForm";
import {TYPE_OF_OPTION} from "@/globals"
import {computed} from "vue";
import {useProjectStore} from "@/stores/projects";
import {useFavoriteStore} from "@/stores/favorites";
import ComponentFloatingActionButton from "@/components/floatingActionButton/ComponentFloatingActionButton";
import {useAuthStore} from "@/stores/auth";

export default {
  name: "ComponentListDocuments",
  emits: ['setAddDocument'],
  components: {ComponentFloatingActionButton, ComponentDynamicForm, ComponentFilters},
  props: {
    currentFolder: {
      type: String
    }
  },
  setup(props) {
    const current_folder = computed(() => {
      return props.currentFolder
    })

    const storeAuth = useAuthStore()
    const storeApi = useApiSettingStore()
    const storeFavorite = useFavoriteStore()
    const storeDocuments = useDocumentStore()
    const storeUtils = useUtilsStore()

    return {
      storeFavorite,
      storeAuth,
      current_folder,
      storeApi,
      storeUtils,
      TYPE_OF_OPTION,
      storeDocuments
    }
  },
  data() {
    return {
      actions: [],
      innerWidth: window.innerWidth,
      dictFilter: {},
      debounce: this.createInputTextDebounce(),
      search: '',
      currentIndex: -1,
      currentObj: null,
      selectedAll: false,
      showEditDocument: false,
      imageDeleteUrl: require("@/assets/shared/delete-grey.svg"),
      showFilters: false,
      selectedDocuments: [],
      currentPage: 1,
      payloadOrdering: new Set(),
      id: '',
      name: '',
      isLoading: false,
      response: null,
      fromEntity__in: ''
    }
  },
  async created() {
    useUtilsStore().resetFilterAndOtherInfo()
    await this.loadDocuments()
    await this.loadOptionDocuments()
  },
  watch: {
    infoForToDelete: {
      handler: function (e) {
        if (!e.confirmDelete)
          this.currentPage = 1
      }, deep: true
    },
    documents: {
      handler: function () {
        this.selectedDocuments = []
      }
    },
    current_folder: {
      handler: function (value) {
        if (value)
          this.storeUtils.dictionaryFilterFinal['type'] = this.current_folder
        else
          delete useUtilsStore().dictionaryFilterFinalGetters['type']
        this.storeUtils.dictionaryFilterFinalGetters['project__in'] = this.$route.params.projectId
        useUtilsStore().getInfoFromFilterOrSearchFromGenericAction({
          action: this.storeDocuments.getDocuments,
          queryParams: this.storeUtils.dictionaryFilterFinalGetters
        })
      }
    },
    page: {
      handler: function (page) {
        this.storeUtils.dictionaryFilterFinal['page'] = page
        useUtilsStore().getInfoFromFilterOrSearchFromGenericAction({
          action: this.storeDocuments.getDocuments,
          queryParams: this.storeUtils.dictionaryFilterFinalGetters
        })
      }
    }
  },
  computed: {
    documents() {
      return useDocumentStore().documentsGetters
    },
    infoForToDelete() {
      return useUtilsStore().infoForToDeleteGetters
    }
  },
  mounted() {
    this.setStickyPositions()
    window.addEventListener("resize", this.setStickyPositions)
    window.addEventListener("scroll", this.setStickyPositions)
  },
  methods: {
    setBooleanModal(event) {
      if (event.action === 'add' && event.entity === 'document')
        this.$emit('setAddDocument')

      if (event.action === 'filter' && event.entity === 'document')
        this.showFilters = true
      if (event.action === 'delete' && event.entity === 'document')
        this.showConfirmDelete(null, null, 'bulk')
    },
    deleteFavorite(e, obj) {
      e.stopPropagation()
      let item = {
        id: obj.favorite_id
      }
      useFavoriteStore().currentKeyDeleting = this.keyFavorite
      useUtilsStore().infoForToDelete['currentPosition'] = 'list'
      useUtilsStore().infoForToDelete['confirmDelete'] = true
      useUtilsStore().infoForToDelete['action'] = this.storeFavorite.deleteFavorite
      useUtilsStore().infoForToDelete['actionAfterDelete']['action'] = this.storeDocuments.getDocuments
      useUtilsStore().infoForToDelete['actionAfterDelete']['queryParams'] = {
        project__in: obj.project.id,
        ordering: '-created_at'
      }
      useUtilsStore().infoForToDelete['typeObjectToDelete'] = 'single'
      useUtilsStore().infoForToDelete['entity'] = 'favorite'
      useUtilsStore().infoForToDelete['name'] = 'preferito'
      useUtilsStore().infoForToDelete['objectToDelete'] = item
      useUtilsStore().infoForToDelete['messageToDelete']['is'] = 'div'
      useUtilsStore().infoForToDelete['messageToDelete']['innerHTML'] = "Sei sicuro di voler rimuovere dai preferiti il documento " +
          "<span style='color: var(--primary-color)'>" + obj.name +
          "</span>" + "?"
    },
    bulkDownload(event) {
      this.selectedDocuments.forEach(doc => {
        this.download(event, doc)
      })
    },
    download(event, doc) {
      event.stopPropagation();
      useDocumentStore().currentContentType = null;
      useDocumentStore().downloadDocument(doc.document_file)
          .then(res => {
            const url = URL.createObjectURL(res.data);
            const link = document.createElement('a');
            link.href = url;
            link.download = doc.name; // Replace with your desired file name
            link.click();
          })
          .catch(() => {
            // handle error
          });
    },
    setStickyPositions() {
      this.innerWidth = window.innerWidth
      let container_content_header
      if (document.getElementById('container-content-header') && document.getElementById('container-content-header-mobile') && window.getComputedStyle(document.getElementById('container-content-header')).display === 'none')
        container_content_header = document.getElementById('container-content-header-mobile')
      else
        container_content_header = document.getElementById('container-content-header')

      let container_sub_detail = document.getElementById('container-sub-detail')
      let container_head_table = document.getElementById('container-head-table')
      let thead = document.getElementsByTagName('thead')[0]
      if (container_content_header) {
        if (container_head_table && container_sub_detail) {
          container_head_table.style.top = container_content_header.getBoundingClientRect().height + container_sub_detail.getBoundingClientRect().height + 'px'
        }
        if (thead && container_sub_detail && container_head_table) {
          thead.style.top = container_head_table.getBoundingClientRect().height + container_content_header.getBoundingClientRect().height + container_sub_detail.getBoundingClientRect().height + 'px'
        }
      }
    },
    returnType(type) {
      let name = ''
      this.storeDocuments.optionsDocumentGetters.actions.POST.type.choices.find(el => {
        if (el.value === type)
          name = el.display_name
      })
      return name
    },
    closeModal() {
      this.loadDocuments()
    },
    showedEditDocument(e, document) {
      e.stopPropagation()
      this.currentObj = document
      this.showEditDocument = true
    },
    resetDictFilter() {
      this.showFilters = false
      this.dictFilter = {}
      this.currentPage = 1
      this.loadDocuments()
    },
    async loadOptionDocuments() {
      useDocumentStore().getOptionsDocument()
          .then(() => {
          })
          .catch(() => {
          })
    },
    async loadDocuments() {
      if (this.isLoading) return // evita di caricare nuovi dati se un caricamento è già in corso
      this.isLoading = true // indica che un caricamento è in corso

      let queryParams = {
        page: this.currentPage
      }
      queryParams['project__in'] = this.$route.params.projectId


      // gestione ordering
      if (this.payloadOrdering.size > 0)
        queryParams['ordering'] = Array.from(this.payloadOrdering).toString()
      else
        delete queryParams['ordering']
      // -------


      for (let key in this.storeUtils.dictionaryFilterFinalGetters) {
        queryParams[key] = this.storeUtils.dictionaryFilterFinalGetters[key]
      }

      if (this.search !== '')
        queryParams['search'] = this.search
      else
        delete queryParams['delete']

      await useDocumentStore().getDocuments({queryParams: queryParams})
          .then(() => {
            let queryParams = {
              project__in: this.$route.params.projectId,
              type: 'p'
            }
            useDocumentStore().checkProjectSheet({queryParams: queryParams})

            /*** costruisco le actions da passare al floating button in base ai permessi ***/
            this.actions = []

            this.actions.push({
              action: 'filter',
              entity: 'document'
            })

            if (this.storeAuth.userGetters && this.storeAuth.userGetters.user_type === 'ad')
              this.actions.push({
                type: 'bulk',
                action: 'delete',
                entity: 'document'
              })

            if (this.storeAuth.userGetters && this.storeAuth.userGetters.user_type === 'ad')
              this.actions.push({action: 'add', entity: 'document'})
            /*** ***/

            this.isLoading = false
            this.showEditDocument = false
            this.currentPage++
          })
          .catch(() => {
          })
          .finally(() => {
          })

    },
    createInputTextDebounce() {
      let timeout = null;
      return function (fnc, delayMs) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          fnc();
        }, delayMs || 500);
      };
    },
    research() {
      useUtilsStore().page = 1
      this.currentPage = 1
      this.loadDocuments()
    },
    ordering(type, th) {
      if (th === 'th_id') {
        if (type === 'id') {
          this.id = type
          this.payloadOrdering.delete('')
          this.payloadOrdering.add(this.id)
        } else if (type === '-id') {
          this.id = type
          this.payloadOrdering.delete('id')
          this.payloadOrdering.add(this.id)
        } else {
          this.id = type
          this.payloadOrdering.delete('-id')
          this.payloadOrdering.add(this.id)
        }
      }
      if (th === 'th_name') {
        if (type === 'name') {
          this.name = type
          this.payloadOrdering.delete('')
          this.payloadOrdering.add(this.name)
        } else if (type === '-name') {
          this.name = type
          this.payloadOrdering.delete('name')
          this.payloadOrdering.add(this.name)
        } else {
          this.name = type
          this.payloadOrdering.delete('-name')
          this.payloadOrdering.add(this.name)
        }
      }

      this.currentPage = 1
      this.loadDocuments()
    },
    changeSelectedAll() {
      if (this.selectedAll)
        this.selectedDocuments = useDocumentStore().documentsGetters.results.map(document => document)
      else
        this.selectedDocuments = []
    },
    closeModalFilters() {
      this.showFilters = false
      this.currentPage = 1
      this.loadDocuments()
    },
    showConfirmDelete(event, document, quantity) {
      if (event)
        event.stopPropagation()
      // delete custom and temporary data in single user in template
      // if (user)
      //   delete user['is_hovered']
      if (quantity === 'single') {
        useUtilsStore().infoForToDelete['currentPosition'] = ''
        useUtilsStore().infoForToDelete['confirmDelete'] = true
        useUtilsStore().infoForToDelete['typeObjectToDelete'] = 'single'
        useUtilsStore().infoForToDelete['entity'] = 'documents'
        useUtilsStore().infoForToDelete['name'] = 'documento'
        useUtilsStore().infoForToDelete['action'] = this.storeDocuments.deleteDocument
        useUtilsStore().infoForToDelete['actionAfterDelete']['action'] = this.storeDocuments.getDocuments
        useUtilsStore().infoForToDelete['actionAfterDelete']['queryParams'] = {
          project__in: useProjectStore().projectGetters.id,
          ordering: '-created_at'
        }
        useUtilsStore().infoForToDelete['objectToDelete'] = document
        useUtilsStore().infoForToDelete['listIds'] = []
        useUtilsStore().infoForToDelete['messageToDelete']['is'] = 'div'
        useUtilsStore().infoForToDelete['messageToDelete']['innerHTML'] = "Sei sicuro di voler eliminare definitivamente il documento " +
            "<span style='color: var(--primary-color)'>" + document.name +
            "</span>" + "? L’operazione non è reversibile."

      } else if (quantity === 'bulk') {
        useUtilsStore().infoForToDelete['currentPosition'] = ''
        useUtilsStore().infoForToDelete['confirmDelete'] = true
        useUtilsStore().infoForToDelete['typeObjectToDelete'] = 'bulk'
        useUtilsStore().infoForToDelete['entity'] = 'documents'
        useUtilsStore().infoForToDelete['name'] = ''
        useUtilsStore().infoForToDelete['action'] = this.storeUtils.bulkDeleteFromList
        useUtilsStore().infoForToDelete['actionAfterDelete']['action'] = this.storeDocuments.getDocuments
        useUtilsStore().infoForToDelete['actionAfterDelete']['queryParams'] = {
          project__in: useProjectStore().projectGetters.id,
          ordering: '-created_at'
        }
        useUtilsStore().infoForToDelete['objectToDelete'] = null
        useUtilsStore().infoForToDelete['listIds'] = this.selectedDocuments.map(el => el.id)
        useUtilsStore().infoForToDelete['messageToDelete']['is'] = 'div'
        useUtilsStore().infoForToDelete['messageToDelete']['innerHTML'] = "Sei sicuro di voler eliminare definitivamente i " +
            "<span style='color: var(--primary-color)'>" + "documenti selezionati" + "</span>" + "? L’operazione non è reversibile."
      }
    },
    stopPropagation(event) {
      event.stopPropagation()
    }
  },
  updated() {
  },
  beforeUnmount() {
    if (useDocumentStore().controllerGetDocumentsGetters)
      useDocumentStore().controllerGetDocumentsGetters.abort()

    window.removeEventListener("resize", this.setStickyPositions)
    window.removeEventListener("scroll", this.setStickyPositions)
  },
  unmounted() {
    window.removeEventListener("resize", this.setStickyPositions)
    window.removeEventListener("scroll", this.setStickyPositions)
  }
}
</script>

<style scoped>
.spinner-border {
  padding: 12.5px;
  margin: auto 0px !important;
}

.justify-content-start.pagination {
  margin: 0px 0px 10px auto;
}

.container-footer-documents {
  display: flex;
  width: 100%;
  margin: auto 0px 20px 0px;
  align-items: center;
}

.container-status-success, .container-status-light-grey {
  margin-right: auto;
}

.container-numbers-secondary {
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background: var(--secondary-color);
  padding: 5px 10px;
}

.container-filter {
  margin-left: 10px;
  height: 100%;
}

.text-14px-primary-medium {
  cursor: pointer;
  margin: 0px 10px;
  white-space: nowrap;
}

.container-all-filter {
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: auto;
}

.search-bar {
}

.container-documents {
  display: flex;
  flex-direction: column;
  padding: 10px 15px !important;
  margin: 0px !important;
  border-radius: 4px;
  width: 100% !important;
  height: 100%;
}

.container-content-documents {
  background: var(--white-color);
  box-shadow: 0px 2px 15px rgba(198, 198, 198, 0.25);
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  margin-bottom: 15px;
  align-items: center;
}

.container-head-table {
  border-bottom: 1px solid #E7EAED;
  width: 100%;
  display: flex;
  position: sticky;
  z-index: 1;
  align-items: center;
  background: var(--white-color);
  padding: 10px 15px;
}

@media (max-width: 575.98px) {
}

/*// Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
  .container-filter {
    height: auto;
  }

  .container-all-filter {
    flex-wrap: wrap;
  }

  .table-responsive {

  }
}

/*// Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {
  .container-footer-documents {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .container-numbers-secondary {
    min-width: 100%;
  }
}

/*// Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {
}

/*// X-Large devices (large desktops, less than 1400px)*/
@media (max-width: 1399.98px) {
}
</style>