import {defineStore} from 'pinia'
// import {useAuthStore} from "@/stores/auth";
import {useApiSettingStore} from "@/stores/apiSettings";
import axiosInstance from "@/services/api";

export const useThreadsStore = defineStore(
    "thread",
    {
        state: () => ({
            /*** controllers ***/
            controllerGetThreads: undefined,
            controllerGetThread: undefined,
            /*** ***/

            threads: null,
            threadsUnread: 0,
            arrayThreadsInfinity: [],
            optionsThreads: null,
            thread: null,
            allThreads: null,
            oldThreads: null,
            spinnerThreads: false
        }),
        actions: {
            getOptionsThreads() {
                return new Promise((resolve, reject) => {
                    axiosInstance.options('api/threads/')
                        .then(res => {
                            this.optionsThreads = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                })
            },
            getThread(id) {
                return new Promise((resolve, reject) => {
                    this.controllerGetThread = new AbortController()
                    const config = {
                        signal: useThreadsStore().controllerGetThreadGetters.signal
                    }
                    axiosInstance.get('api/threads/' + id + '/', config)
                        .then(res => {
                            this.thread = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                })
            },
            getThreadsUnread() {
                return new Promise((resolve, reject) => {
                    axiosInstance.get('api/threads/unread/')
                        .then(res => {
                            this.threadsUnread = res.data.unread_threads_count
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                })
            },
            getThreads(payload) {
                return new Promise((resolve, reject) => {
                    this.controllerGetThreads = new AbortController()
                    const config = {
                        signal: useThreadsStore().controllerGetThreadsGetters.signal
                    }

                    let url = new URL(axiosInstance.defaults.baseURL + 'api/threads/')

                    this.spinnerThreads = true
                    if (payload.queryParams !== null) {
                        let params = new URLSearchParams()
                        for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                            params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                        }
                        url.search = params
                        axiosInstance.get(url.pathname + url.search, config)
                            .then(res => {
                                this.threads = res.data
                                if (payload.queryParams.scroll) {
                                    this.arrayThreadsInfinity.push(...res.data.results)
                                } else {
                                    this.arrayThreadsInfinity = res.data.results
                                }
                                this.spinnerThreads = false
                                resolve(res)
                            })
                            .catch(err => {
                                this.spinnerThreads = false
                                if (err.response && err.response.status === 500) {
                                    useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                                }
                                reject(err)
                            })
                    } else {
                        axiosInstance.get(url.pathname, config)
                            .then(res => {
                                this.spinnerThreads = false
                                this.threads = res.data
                                let a = []
                                a.push(...res.data.results);
                                this.arrayThreadsInfinity = a
                                resolve(res)
                            })
                            .catch(err => {
                                this.spinnerThreads = false
                                if (err.response && err.response.status === 500) {
                                    useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                                }
                                reject(err)
                            })
                    }

                })
            },
            getThreadsWithoutSetState(payload) {
                return new Promise((resolve, reject) => {
                    let url = new URL(axiosInstance.defaults.baseURL + 'api/threads/')

                    this.spinnerThreads = true
                    if (payload.queryParams !== null) {
                        let params = new URLSearchParams()
                        for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                            params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                        }
                        url.search = params
                        axiosInstance.get(url.pathname + url.search)
                            .then(res => {
                                resolve(res)
                            })
                            .catch(err => {
                                this.spinnerThreads = false
                                if (err.response && err.response.status === 500) {
                                    useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                                }
                                reject(err)
                            })
                    } else {
                        axiosInstance.get(url.pathname)
                            .then(res => {
                                resolve(res)
                            })
                            .catch(err => {
                                this.spinnerThreads = false
                                if (err.response && err.response.status === 500) {
                                    useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                                }
                                reject(err)
                            })
                    }

                })
            },
            createThread(payload) {
                return new Promise((resolve, reject) => {
                    axiosInstance.post('/api/threads/', payload)
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            reject(err)
                        })
                })
            },
            getAllThreads(payload) {
                return new Promise((resolve, reject) => {
                    let url = new URL(axiosInstance.defaults.baseURL + 'api/threads/')

                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axiosInstance.get(url.pathname + url.search)
                        .then(res => {
                            this.allThreads = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })

                })
            },
            editThread(payload) {
                return new Promise((resolve, reject) => {
                    axiosInstance.patch('api/threads/' + payload.id + '/', payload)
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                })
            },
            deleteThread(id) {
                return new Promise((resolve, reject) => {
                    axiosInstance.delete('/api/threads/' + id + '/')
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                })
            },
            leaveThread(id) {
                return new Promise((resolve, reject) => {
                    axiosInstance.delete('/api/threads/' + id + '/leave/')
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                })
            }
        },
        getters: {
            threadsGetters: state => {
                return state.threads
            },
            controllerGetThreadsGetters: state => {
                return state.controllerGetThreads
            },
            controllerGetThreadGetters: state => {
                return state.controllerGetThread
            },
            allThreadsGetters: state => {
                return state.allThreads
            },
            oldThreadsGetters: state => {
                return state.oldThreads
            },
            threadGetters: state => {
                return state.thread
            },
            arrayThreadsInfinityGetters: state => {
                return state.arrayThreadsInfinity
            },
            spinnerThreadsGetters: state => {
                return state.spinnerThreads
            },
            optionsThreadsGetters: state => {
                return state.optionsThreads
            },
            threadsUnreadGetters: state => {
                return state.threadsUnread
            }
        }
    })