import {defineStore} from 'pinia'
import axiosInstance from "@/services/api";
import {useAuthStore} from "@/stores/auth";
import {useApiSettingStore} from "@/stores/apiSettings";
// eslint-disable-next-line no-unused-vars
import {useCategoryStore} from "@/stores/categories";
import {useProjectStore} from "@/stores/projects";

export const useDocumentStore = defineStore(
    "document",
    {
        state: () => ({
            /*** controller ***/
            controllerGetDocuments: null,
            /*** ***/

            documents: null,
            projectHasProjectSheet: null,
            arrayDocumentsInfinity: [],
            optionsDocument: null,
            document: null,
            allDocuments: null,
            oldDocument: null,
            spinnerDocument: false,
            currentContentType: null,
        }),
        actions: {
            getOptionsDocument() {
                return new Promise((resolve, reject) => {
                    axiosInstance.options('api/documents/')
                        .then(res => {
                            this.optionsDocument = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                })
            },
            getDocument(id) {
                return new Promise((resolve, reject) => {
                    axiosInstance.get('api/documents/' + id + '/')
                        .then(res => {
                            this.document = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                })
            },
            downloadDocument(payload) {
                return new Promise((resolve, reject) => {
                    const config = {
                        responseType: 'blob'
                    }
                    axiosInstance.get(payload, config)
                        .then(res => {
                            this.currentContentType = res.headers['content-type']
                            resolve(res)

                        })
                        .catch(() => {
                            reject()
                        })
                })
            },
            getDocuments(payload) {
                return new Promise((resolve, reject) => {
                    this.controllerGetDocuments = new AbortController()
                    const config = {
                        signal: useDocumentStore().controllerGetDocumentsGetters.signal
                    }

                    let url = new URL(axiosInstance.defaults.baseURL + 'api/documents/')

                    this.spinnerDocument = true
                    if (payload.queryParams !== null) {
                        let params = new URLSearchParams()
                        for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                            params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                        }

                        url.search = params
                        axiosInstance.get(url.pathname + url.search, config)
                            .then(res => {
                                this.documents = res.data
                                if (payload.queryParams.scroll) {
                                    this.arrayDocumentsInfinity.push(...res.data.results)
                                } else {
                                    this.arrayDocumentsInfinity = res.data.results
                                }
                                this.spinnerDocument = false
                                resolve(res)
                            })
                            .catch(err => {
                                this.spinnerDocument = false
                                if (err.response && err.response.status === 500) {
                                    useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                                }
                                reject(err)
                            })
                    } else {
                        axiosInstance.get(url.pathname, config)
                            .then(res => {
                                this.spinnerDocument = false
                                this.documents = res.data
                                let a = []
                                a.push(...res.data.results);
                                this.arrayDocumentsInfinity = a
                                resolve(res)
                            })
                            .catch(err => {
                                this.spinnerDocument = false
                                if (err.response && err.response.status === 500) {
                                    useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                                }
                                reject(err)
                            })
                    }

                })
            },
            getDocumentsWithoutSetState(payload) {
                return new Promise((resolve, reject) => {
                    let url = new URL(axiosInstance.defaults.baseURL + 'api/documents/')

                    this.spinnerDocument = true
                    if (payload.queryParams !== null) {
                        let params = new URLSearchParams()
                        for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                            params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                        }

                        url.search = params
                        axiosInstance.get(url.pathname + url.search)
                            .then(res => {
                                resolve(res)
                            })
                            .catch(err => {
                                this.spinnerDocument = false
                                if (err.response && err.response.status === 500) {
                                    useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                                }
                                reject(err)
                            })
                    } else {
                        axiosInstance.get(url.pathname)
                            .then(res => {
                                resolve(res)
                            })
                            .catch(err => {
                                this.spinnerDocument = false
                                if (err.response && err.response.status === 500) {
                                    useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                                }
                                reject(err)
                            })
                    }

                })
            },
            checkProjectSheet(payload) {
                return new Promise((resolve, reject) => {
                    let url = new URL(axiosInstance.defaults.baseURL + 'api/documents/')

                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }

                    url.search = params
                    axiosInstance.get(url.pathname + url.search)
                        .then(res => {
                            if (res.data.count > 0)
                                this.projectHasProjectSheet = res.data.results[0]
                            else
                                this.projectHasProjectSheet = null
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                })
            },
            createDocument(payload) {
                return new Promise((resolve, reject) => {
                    if (payload instanceof FormData) {
                        payload.set('color', useCategoryStore().categoryGetters.color)
                        payload.set('project', useProjectStore().projectGetters.id)

                    }
                    axiosInstance.post('/api/documents/', payload)
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                })
            },
            getAllDocuments(payload) {
                return new Promise((resolve, reject) => {
                    let url = new URL(axiosInstance.defaults.baseURL + 'api/documents/')

                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axiosInstance.get(url.pathname + url.search)
                        .then(res => {
                            this.allDocuments = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })

                })
            },
            editDocument(payload) {
                return new Promise((resolve, reject) => {
                    if (payload.get('document_file') === 'null')
                        payload.delete('document_file')
                    payload.set('owner', useAuthStore().userGetters.user_id)
                    payload.set('project', useProjectStore().projectGetters.id)
                    axiosInstance.patch('api/documents/' + payload.get('id') + '/', payload)
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                })
            },
            deleteDocument(id) {
                return new Promise((resolve, reject) => {
                    axiosInstance.delete('/api/documents/' + id + '/')
                        .then(res => {
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                })
            }
        },
        getters: {
            documentsGetters: state => {
                return state.documents
            },
            allDocumentsGetters: state => {
                return state.allDocuments
            },
            oldDocumentGetters: state => {
                return state.oldDocument
            },
            documentGetters: state => {
                return state.document
            },
            arrayDocumentsInfinityGetters: state => {
                return state.arrayDocumentsInfinity
            },
            spinnerDocumentGetters: state => {
                return state.spinnerDocument
            },
            optionsDocumentGetters: state => {
                return state.optionsDocument
            },
            projectHasProjectSheetGetters: state => {
                return state.projectHasProjectSheet
            },
            currentContentTypeGetters: state => {
                return state.currentContentType
            },
            controllerGetDocumentsGetters: state => {
                return state.controllerGetDocuments
            }
        }
    })