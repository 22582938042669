<template>
  <div class="container-global-search">
    <b-form-input id="global-search" v-if="$route.path.includes('archive') && innerWidth > 991.98"
                  v-on:input="debounce(setSearchFinal)"
                  type="search" v-model="search" placeholder="Cerca"
                  class="form-control search-bar"></b-form-input>
    <div class="container-head-global-search">
      <img src="../../assets/shared/search-secondary.svg" alt="">
      <span class="text-16px-secondary-medium">{{ returnCount() }}</span>
      <span class="text-16px-dark-grey-medium">&nbsp;Risultati di ricerca per&nbsp;</span>
      <span class="text-16px-secondary-medium">"{{ storeUtils.globalSearchGetters }}"</span>
    </div>
    <div style="display: flex;flex-direction: column" class="container-single-generic-mini-detail" :key="i"
         v-for="(key,i) in Object.keys(storeUtils.dictionarySearchGetters)">
      <div
          class="container-head-single-generic-mini-detail">
        <div style="display: flex;align-items: center">
          <span class="text-16px-black-medium">{{ storeUtils.dictionarySearchGetters[key].name }}&nbsp;</span>
          <span v-if="storeUtils.dictionarySearchGetters[key].count"
                class="text-16px-black-medium">({{ storeUtils.dictionarySearchGetters[key].count }})</span>
        </div>
      </div>
      <ComponentGenericMiniDetail :ref="key"
                                  :currentObjectProp="storeUtils.dictionarySearchGetters"
                                  :keyFavorite="key"></ComponentGenericMiniDetail>
    </div>
  </div>
</template>

<script>
import {useUtilsStore} from "@/stores/utils";
import ComponentGenericMiniDetail from '../shared/ComponentGenericListMiniDetails.vue'

export default {
  name: "ComponentGlobalSearch",
  components: {
    ComponentGenericMiniDetail
  },
  setup() {
    const storeUtils = useUtilsStore()
    return {
      storeUtils
    }
  },
  data() {
    return {
      search: '',
      debounce: this.createInputTextDebounce(),
      innerWidth: window.innerWidth
    }
  },
  created() {
  },
  watch: {
    globalSearch: {
      handler: function (value) {
        if (value !== '') {
          let global_search = document.getElementById('global-search')
          if (global_search)
            global_search.focus()
        }
      }
    },
    // search: {
    //   handler: function (value) {
    //     if (value !== '') {
    //       let global_search = document.getElementById('global-search')
    //       if (global_search)
    //         global_search.focus()
    //     }
    //   }
    // }
  },
  computed: {
    globalSearch() {
      return useUtilsStore().globalSearchGetters
    },
    // search() {
    //   return useUtilsStore().searchGetters
    // }
  },
  mounted() {
    let global_search = document.getElementById('global-search')
    if (global_search) {
      global_search.focus()
      global_search.value = useUtilsStore().globalSearchGetters
    }
    window.addEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.innerWidth = window.innerWidth
    },
    createInputTextDebounce() {
      let timeout = null;
      return function (fnc, delayMs) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          fnc();
        }, delayMs || 500);
      };
    },
    setSearchFinal() {
      useUtilsStore().globalSearch = this.search
    },
    returnCount() {
      let count = 0
      for (let key in this.storeUtils.dictionarySearchGetters) {
        count += this.storeUtils.dictionarySearchGetters[key].count
      }
      return count
    }
  },
  updated() {
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize)
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize)
  }


}
</script>

<style scoped>
.container-head-global-search {
  display: flex;
  margin: 10px 0px;
  align-items: center;
}

.container-global-search {
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
}

.container-single-generic-mini-detail {
  margin: 10px 0px;
}
</style>