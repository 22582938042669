import {createRouter, createWebHistory} from 'vue-router'
import {useAuthStore} from "@/stores/auth";
import DetailCategoryView from "@/views/DetailCategoryView";
// eslint-disable-next-line no-unused-vars
import ComponentUsers from "@/components/settings/ComponentUsers";
// eslint-disable-next-line no-unused-vars
import ComponentDetailUser from "@/components/settings/ComponentDetailUser";
import DetailSubCategoryView from "@/views/DetailSubCategoryView";
import ArchiveView from "@/views/ArchiveView";
import DetailProjectView from "@/views/DetailProjectView";
import DetailUserView from "@/views/DetailUserView.vue";
import UsersView from "@/views/UsersView.vue";
import AcconutView from "@/views/AcconutView.vue";

const routes = [
    // {
    //     path: '/login',
    //     name: 'login',
    //     meta: {
    //         hideForAuth: true
    //     },
    //     component: () => import(/* webpackChunkName: "Login" */ '../views/LoginView.vue')
    // },
    // {
    //     path: '/register',
    //     name: 'register',
    //     meta: {
    //         hideForAuth: true
    //     },
    //     component: () => import(/* webpackChunkName: "Register" */ '../views/RegisterView.vue')
    // },
    {
        path: '/',
        name: 'dashboard',
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "HomeView" */ '../views/HomeView')
    },
    {
        path: '/video-call/:room',
        name: 'video-call',
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "HomeView" */ '../views/Interactions/RoomCallView.vue')
    },
    {
        path: '/archive',
        name: 'archive',
        component: ArchiveView,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: 'category/:categoryId',
                name: 'detailCategory',
                component: DetailCategoryView,
                meta: {
                    requiresAuth: true
                },
                children: [
                    {
                        path: 'subcategory/:subcategoryId',
                        name: 'detailSubcategory',
                        component: DetailSubCategoryView,
                        meta: {
                            requiresAuth: true
                        },
                        children: [
                            {
                                path: 'project/:projectId',
                                name: 'detailProjectFromSubcategory',
                                meta: {
                                    requiresAuth: true
                                },
                                component: DetailProjectView
                            }
                        ]
                    },
                    {
                        path: 'project/:projectId',
                        name: 'detailProjectFromCategory',
                        meta: {
                            requiresAuth: true
                        },
                        component: DetailProjectView
                    }
                ]
            }
        ]
    },

    {
        path: '/wiki',
        name: 'wiki',
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "WikiView" */ '../views/WikiView')
    },
    {
        path: '/settings',
        name: 'settings',
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: 'users/',
                name: 'users',
                component: UsersView,
                children: [
                    {
                        path: ':id',
                        name: 'detailUser',
                        component: DetailUserView
                    }
                ],
                meta: {
                    requiresAuth: true
                },
                exact: true
            },
            {
                path: 'account/',
                name: 'account',
                component: AcconutView,
                meta: {
                    requiresAuth: true
                },
                exact: true
            }
        ],
        component: () => import(/* webpackChunkName: "SettingsView" */ '../views/SettingsView')
    },
    {
        path: '/public-wall',
        name: 'publicWall',
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "PublicWallView" */ '../views/Interactions/PublicWallView.vue')
    },
    {
        path: '/public-wall/:id',
        name: 'detailPublicWall',
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "DetailPublicWallView" */ '../views/Interactions/DetailPublicWallView')
    },
    {
        path: '/private-wall',
        name: 'privateWall',
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "PrivateWallView" */ '../views/Interactions/PrivateWallView.vue')
    },
    {
        path: '/private-wall/:id',
        name: 'detailPrivateWall',
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "DetailPublicWallView" */ '../views/Interactions/DetailPublicWallView')
    },
    {
        path: '/chat',
        name: 'chat',
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "ChatView" */ '../views/Interactions/ChatView')
    },
    {
        path: '/calendar',
        name: 'calendar',
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "CalendarView" */ '../views/Interactions/CalendarView')
    },
    {
        path: '/suggestions',
        name: 'suggestions',
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "SuggestionsView" */ '../views/Interactions/SuggestionsView')
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: "/",
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (useAuthStore().authenticatedGetters) {
            next();
        }
    } else {
        next();
    }
});


export default router
